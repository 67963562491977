const styles = () => ({
  formContainer: {
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid #dedede",
    width: "100%",
    backgroundColor: "#ffffff",
    marginBottom: "24px",
    padding: "20px 0",
    gap: "28px",
  },
  filterContainer: {
    display: "flex",
    gap: "28px",
    alignItems: "baseline",
  },
  downloadReport: {
    margin: "8px 0 16px",
  },
  taskHeader: {
    display: "flex",
    margin: "25px 0 15px 0",
    gap: "10px",
    color: "#333333",
    fontSize: "14px",
    fontWeight: "600",
  },
  backButton: {
    cursor: "pointer",
  },
  inputField: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    minWidth: "300px",
  },
  errorMessage: {
    display: "block",
    color: "red",
    margin: "8px 0 10px",
  },
  filterSelect: {
    display: "flex",
    alignItems: "baseline",
    gap: "10px",
  },
  select: {
    minWidth: "150px",
  },
  clearFilter: {
    background: "none",
    border: "none",
    padding: "0",
    color: "#2170f4",
    cursor: "pointer",
    fontWeight: "600",
  },
  articleLink: {
    marginBottom: "16px",
  },
  selectedItem: {
    fontSize: "14px",
  },
  menuItem: {
    fontSize: "14px",
  },
});

export default styles;
