import * as React from 'react';
import AlertModal from './AlertModal';
import { injectIntl, WrappedComponentProps } from "react-intl"

export interface UpdateUserModalProps {
    heading: string,
    subheading: string,
    description: string,
    isLoadingInProgress: boolean,
    shouldShowError: boolean,
    shouldShowSuccess: boolean,
    errorText: string
    buttonAction: () => any
    closeAction: (a: boolean) => void
}

type Props = UpdateUserModalProps & WrappedComponentProps


class UpdateUserModal extends React.Component<Props> {

    private timeoutId?: NodeJS.Timeout | null

    constructor(props: Props) {
        super(props)
        this.handleCloseClick = this.handleCloseClick.bind(this)
    }

    componentDidUpdate() {
        if (this.props.shouldShowSuccess) {
            this.timeoutId = setTimeout(() => this.props.closeAction(true), 1000)
        }
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }

    handleCloseClick() {
        this.props.closeAction(this.props.shouldShowSuccess)
    }

    render() {
        return (
            <AlertModal
                heading={this.props.heading}
                subheading={this.props.subheading}
                description={this.props.description}
                descriptionClass='update-user-modal-description'
                buttonText={this.props.intl.formatMessage({ id: "confirm" })}
                buttonLoadingText={this.props.intl.formatMessage({ id: "confirming" })}
                buttonSuccessText={this.props.intl.formatMessage({ id: "done" })}
                shouldShowLoaderInButton={true}
                isLoadingInProgress={this.props.isLoadingInProgress}
                shouldShowError={this.props.shouldShowError}
                shouldShowSuccess={this.props.shouldShowSuccess}
                buttonAction={this.props.buttonAction}
                successAction={this.handleCloseClick}
                errorText={this.props.errorText}
                closeAction={this.handleCloseClick}
            />
        );
    }
}

export default injectIntl(UpdateUserModal);