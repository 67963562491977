import React from 'react'
import AlertModal from "./AlertModal";
import { RootState } from '../reducers';
import { bindActionCreators } from 'redux';
import { reactivateDomain } from '../actions/orderActions'
import { connect } from "react-redux";
import { ReactivateDomainInfo, DomainReactivationCheckInfo } from "../model/reactivateDomainInfo"
import { injectIntl, WrappedComponentProps } from "react-intl";

type Props = {
    reactivateDomainInfo: ReactivateDomainInfo,
    domainReactivationCheckInfo: DomainReactivationCheckInfo,
    domainName: string,
    domainId: number,
    accountId: number | null,
    authToken: string,
    currentRegion: string,
    reactivateDomain: (id: number, token: string, region: string) => void,
    handleCloseOrderChange: (domainId: number | null, accountId: number | null) => void
} & WrappedComponentProps

class ReactivateDomainModal extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
        this.reactivateDomain = this.reactivateDomain.bind(this)
        this.handleCloseClick = this.handleCloseClick.bind(this)
    }

    private timeoutId?: NodeJS.Timeout | null

    componentDidUpdate() {
        if (this.props.reactivateDomainInfo.isReactivateDomainSuccess) {
            this.timeoutId = setTimeout(() => this.props.handleCloseOrderChange(this.props.domainId, this.props.accountId), 1000)
        }
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }

    reactivateDomain() {
        this.props.reactivateDomain(this.props.domainId, this.props.authToken, this.props.currentRegion)
    }

    handleCloseClick() {
        if (this.props.reactivateDomainInfo.isReactivateDomainSuccess) {
            this.props.handleCloseOrderChange(this.props.domainId, this.props.accountId)
        }
        else {
            this.props.handleCloseOrderChange(null, null)
        }
    }

    getReactivationNotAllowedDesc(reactivationCheckInfo: DomainReactivationCheckInfo): string {
        let reason = reactivationCheckInfo.reactivationNotAllowedReason
        let msgGetter = this.props.intl.formatMessage
        switch (reason) {
            case "DOMAIN_NOT_IN_PENDING_DELETED_STATE":
            case "NON_FLOCK_CLOUD_DOMAIN":
            case "NON_TITAN_CLOUD_DOMAIN":
            case "USER_NOT_ACTIVE_ON_SERVER":
            case "USER_NOT_ACTIVE_IN_SYSTEM":
            case "DOMAIN_PURGED":
            case "ACTIVE_DOMAIN_EXISTS":
            case "SERVER_DOES_NOT_ALLOW_DOMAIN_REACTIVATION" : return msgGetter({id: reason})
            default : return msgGetter({id: "cannot_reactivate_domain_desc"})
        }
    }

    render() {
        let heading = ""
        let subheading = this.props.domainName
        let description = ""
        if (this.props.domainReactivationCheckInfo.isDomainReactivationCheckSuccess) {
            if (!this.props.domainReactivationCheckInfo.isReactivationAllowed) {
                heading = this.props.intl.formatMessage({ id: 'cannot_reactivate_domain_heading' })
                description = this.getReactivationNotAllowedDesc(this.props.domainReactivationCheckInfo)
                return (
                    <AlertModal
                        heading={heading}
                        subheading={subheading}
                        description={description}
                        descriptionClass='reactivate-domain-description'
                        buttonText={this.props.intl.formatMessage({ id: 'ok' })}
                        buttonLoadingText={null}
                        buttonSuccessText={null}
                        shouldShowLoaderInButton={false}
                        isLoadingInProgress={null}
                        shouldShowError={null}
                        shouldShowSuccess={null}
                        buttonAction={this.handleCloseClick}
                        successAction={null}
                        errorText={null}
                        closeAction={this.handleCloseClick} />
                )
            }
            else {
                heading = this.props.intl.formatMessage({ id: 'reactivate_domain_heading' })
                description = this.props.intl.formatMessage({ id: 'reactivate_domain_desc' })
                return (
                    <AlertModal
                        heading={heading}
                        subheading={subheading}
                        description={description}
                        descriptionClass='reactivate-domain-description'
                        buttonText={this.props.intl.formatMessage({ id: 'reactivate' })}
                        buttonLoadingText={this.props.intl.formatMessage({ id: 'reactivating' })}
                        buttonSuccessText={this.props.intl.formatMessage({ id: 'reactivated' })}
                        shouldShowLoaderInButton={true}
                        isLoadingInProgress={this.props.reactivateDomainInfo.isReactivateDomainInProgress}
                        shouldShowError={this.props.reactivateDomainInfo.isReactivateDomainError}
                        shouldShowSuccess={this.props.reactivateDomainInfo.isReactivateDomainSuccess}
                        buttonAction={this.reactivateDomain}
                        successAction={() => this.props.handleCloseOrderChange(this.props.domainId, this.props.accountId)}
                        errorText={this.props.intl.formatMessage({ id: 'reactivation_error_text' })}
                        closeAction={this.handleCloseClick} />
                )
            }
        }
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        reactivateDomainInfo: (state.orders as any).reactivateDomainInfo,
        domainReactivationCheckInfo: (state.orders as any).domainReactivationCheckInfo,
        authToken: state.auth.token
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ reactivateDomain }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReactivateDomainModal));