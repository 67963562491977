import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { forgotPasswordAction, clearForgotPasswordState } from "../actions/authActions";
import { ReactComponent as Titan } from "../assets/titan.svg"
import { RootState } from '../reducers';
import { isEmailValid } from "./utils";
import { ForgotPasswordInfo } from "../model/ForgotPasswordInfo"
import { FormattedMessage } from "react-intl";

export interface Props {
    forgotPasswordInfo: ForgotPasswordInfo,
    forgotPasswordAction: (email: string) => void,
    clearForgotPasswordState: () => void
}

class ForgotPassword extends Component<Props> {
    state = {
        emailEntered: "",
        resendClicked: false
    }
    constructor(props: Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
    }

    componentWillUnmount() {
        this.props.clearForgotPasswordState()
    }

    handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailEntered = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            emailEntered: emailEntered
        }))
    }

    handleSubmit = (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        if (this.props.forgotPasswordInfo.inProgress) return
        this.props.forgotPasswordAction(this.state.emailEntered.trim())
    };

    sendResetPasswordMailAgain = () => {
        this.setState(prevState => ({
            ...prevState,
            resendClicked: true
        }))
        this.props.forgotPasswordAction(this.state.emailEntered)
    }

    render() {
        return (
            <div className="table-body">
                <div className="login-logo" >
                    <Titan />
                </div>
                {this.props.forgotPasswordInfo.success || this.state.resendClicked ? this.getSuccessLayout() : this.getEmailInputLayout()}
            </div >
        );
    }

    getEmailInputLayout = () => {
        const buttonText = <div className="action-button-div">
            <div className="action-button-spinner-container">
                {this.props.forgotPasswordInfo.inProgress ? <div className="spinner-action-button" /> : ""}
            </div>
            <span className="action-button-text">
                <FormattedMessage id="forgot-password-button-text" />
            </span>
        </div>
        return (
            <div className="login-layout">
                <div>
                    <div className="forgot-password-heading">
                        <FormattedMessage id="forgot-password-header" />
                    </div>
                    <div className="forgot-password-subheading">
                        <FormattedMessage id="forgot-password-subheader" />
                    </div>
                </div>
                <div className="form">
                    <form onSubmit={this.handleSubmit}>
                        <h4 className="input-label">
                            <FormattedMessage id="login-form-email-header" />
                        </h4>
                        <div className="input input-box input-div">
                            <input
                                className="no-border-input"
                                placeholder="ex. john@doe.com"
                                onChange={this.handleEmailChange}
                                value={this.state.emailEntered} />
                        </div>
                        <button
                            className="button button-primary login-form-button"
                            disabled={!isEmailValid(this.state.emailEntered)}>
                            {buttonText}
                        </button>
                        {this.props.forgotPasswordInfo.error && <div className="error login-error">
                            {this.props.forgotPasswordInfo.error}</div>}
                    </form>
                </div>
            </div>
        )
    }

    getSuccessLayout = () => {
        return (
            <div className="login-layout forgot-password-success-div">
                <div className={this.props.forgotPasswordInfo.inProgress ? "forgot-password-re-send" : ""}>
                    <div className="password-reset-mail-header">
                        <FormattedMessage id="forgot-password-success-header" />
                    </div>
                    <div className="password-reset-mail-text">
                        <FormattedMessage id="forgot-password-message-sent" />
                    </div>
                    <div className="text-bold">{this.getMaskedEmail()}</div>
                    <div className="password-reset-mail-text">
                        <FormattedMessage id="forgot-password-reset-direction" />
                    </div>
                    {this.state.resendClicked ? <div className="password-reset-mail-text">
                        <FormattedMessage id="forgot-password-message-not-received" />
                        <span className="try-again" onClick={this.sendResetPasswordMailAgain}>
                            <FormattedMessage id="forgot-password-try-again" />
                        </span>
                    </div>
                        :
                        <div className="password-reset-mail-text">
                            <FormattedMessage id="forgot-password-message-not-received" />
                            <FormattedMessage id="forgot-password-click-here"
                                values={{ span: (word: any) => <span className="try-again" onClick={this.sendResetPasswordMailAgain} >{word} </span> }} />
                        </div>}
                </div>
                {this.props.forgotPasswordInfo.inProgress ?
                    <div className="forgot-password-success-spinner-container">
                        <div className="forgot-password-success-spinner">
                            <div className="spinner-reset-password" />
                            <span className="loading-spinner-text">
                                <FormattedMessage id="forgot-password-loading" />
                            </span>
                        </div>
                    </div> : ""
                }
            </div>
        )
    }

    getMaskedEmail() {
        const email = this.state.emailEntered
        const idx = email.indexOf("@");
        if (idx > 8) {
            return email.slice(0, 4) + "*****" + email.slice(idx);
        } else {
            return email.slice(0, idx / 2) + "****" + email.slice(idx);
        }
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        forgotPasswordInfo: state.auth.forgotPasswordState,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ forgotPasswordAction, clearForgotPasswordState }, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
