import { handleResponse, exception, getAuthHeadersForMethod, handleApiError } from "./utils";
import { Exception, ExceptionResponse } from "./utils";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getSupportDocsApi = (partnerId, migrationType, migrateMailBox, authToken) => {
  const requestOptions = getAuthHeadersForMethod("GET", authToken);
  const params = { 
    partnerId: partnerId,
    migrationType: migrationType,
    migrateMailBox: migrateMailBox
  };
  const paramString = new URLSearchParams(params);
  return fetch(
    `${BASE_URL}migration/support-doc?${paramString.toString()}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleApiError);
};


export const getUploadUrls = (partnerId, authToken) => {
  const requestOptions = {
    ...getAuthHeadersForMethod("POST", authToken),
    body: JSON.stringify({ partnerId }),
  };

  return fetch(`${BASE_URL}migration/upload-files`, requestOptions)
    .then(handleResponse)
    .catch(handleApiError);
};

export const uploadFileToUrl = async (url, file) => {
  const uploadResponse = await fetch(url, {
    method: "put",
    headers: {
      "Content-Type": file.type,
      "Content-Disposition": `filename=${encodeURIComponent(file.name)}`,
      "x-amz-acl": "bucket-owner-full-control",
    },
    body: file,
  });

  if (!uploadResponse.ok) {
    throw new Error("Failed to upload file to S3");
  }

  return uploadResponse;
};

export const validateMigration = (partnerId, requestConfig, pathId, authToken) => {
  const requestOptions = {
    ...getAuthHeadersForMethod("POST", authToken),
    body: JSON.stringify({ partnerId, requestConfig, pathId }),
  };

  return fetch(`${BASE_URL}migration/validate`, requestOptions)
    .then(handleResponse)
    .catch((err) => {
      if (String(err) === Exception.NETWORK_FAILURE)
        return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE);
      throw new Error(String(err));
    });
};

export const startMigration = (partnerId, iid, authToken) => {
  const requestOptions = {
    ...getAuthHeadersForMethod("POST", authToken),
    body: JSON.stringify({ partnerId, iid }),
  };

  return fetch(`${BASE_URL}migration/init`, requestOptions)
    .then(handleResponse)
    .catch((err) => {
      if (String(err) === Exception.NETWORK_FAILURE)
        return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE);
      throw new Error(String(err));
    });
};

export const getTasks = (filterType, filterValue, partnerId, authToken) => {
  const requestOptions = getAuthHeadersForMethod("GET", authToken);
  const params = { partnerId };

  if (filterType && filterValue) {
    params[filterType] = filterValue;
  }
  const paramString = new URLSearchParams(params);
  return fetch(
    `${BASE_URL}migration/get-partner-migrations?${paramString.toString()}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleApiError);
  
};


export const getTaskReport = (partnerId, iid, authToken) => {
  const requestOptions = getAuthHeadersForMethod("GET", authToken);
  const params = { partnerId, iid };
  const paramString = new URLSearchParams(params);

  return fetch(
    `${BASE_URL}migration/result?${paramString.toString()}`,
    requestOptions
  )
    .then(handleResponse)
    .catch(handleApiError);
};


