import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class OrderDetailsItem extends Component {

  static defaultProps = {
    itemValue: '',
    itemName: '',
    style: {}
  };

  state = {
    showToolTip: false
  }

  constructor(props) {
    super(props)
    this.myRef = React.createRef();
  }

  render() {
    const { style } = this.props;
    return (
      <div className="order-details-item" style={style}>
        <span className="order-details-item-text">
          <FormattedMessage id={this.props.itemName} />
        </span>
        <span className="order-details-item-text">{this.props.itemValue} </span>
      </div>
    );
  }

}

export default OrderDetailsItem;
