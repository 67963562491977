const styles = () => ({
  accordionStepsContainer: {
    width: "80%",
    padding: "40px 20px",
  },

  title: {
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: "20px",
    color: "#000000",
  },

  taskDetailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "25px",
    paddingBottom: "10px",
  },

  // accordion left side container
  formContainer: {
    flex: 1,
  },

  // accordion right side note information
  instructionWrapper: {
    flex: 1,
  },

  noteContainer: {
    borderRadius: 4,
    background: "#EFF5FF",
    boxShadow: "0px 1px 8px 0px rgba(217, 217, 217, 0.50)",
    height: "fit-content",
    padding: "16px",
  },
  noteTitle: {
    fontWeight: 600,
    marginBottom: "8px",
  },
  bulletList: {
    paddingLeft: "16px",
    "& li": {
      marginBottom: "8px",
    },
  },

  // upload file
  csvInputLabel: {
    marginBottom: "10px",
    fontWeight: 600,
  },

  fileButton: {
    border: "1px solid #2170F4",
    backgroundColor: "transparent",
    color: "#2170F4",
    marginBottom: "20px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 255, 0.1)",
    },
  },
  viewSampleFile: {
    display: "flex",
    alignItems: "center",
  },

  // validation check
  failureTitle: {
    fontWeight: 600,
    color: "red",
    fontSize: "16px",
  },

  validationSubtitle: {
    margin: "8px 0 16px 0",
  },

  successTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },

  supportLink: {
    display: "block",
    margin: "8px 0 16px 0",
  },

  helpfulLink: {
    fontWeight: 600,
  },

  // review summary
  summaryTitle: {
    fontWeight: 600,
    fontSize: "20px",
  },

  summaryContainer: {
    margin: "24px 0",
  },

  summaryDetail: {
    marginBottom: "12px",
  },

  taskQueueSuccessTitle: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "8px",
  },

  taskQueueSuccessSubtitle: {
    marginBottom: "16px",
  },

  nextStepsTitle: {
    fontSize: "16px",
    fontWeight: 600,
    margin: "20px 0 8px 0",
  },

  errorMsg: {
    marginTop: "16px",
    color: "red",
  },
});

export default styles;
