import * as React from 'react';
import { Component } from 'react';

interface Props {
    value: String
}


class OrderDetailsTooltipItem extends React.Component<Props> {

    state = {
        showToolTip: false
    }

    myRef: React.RefObject<HTMLSpanElement>

    constructor(props: Props) {
        super(props)
        this.myRef = React.createRef();

    }

    componentDidMount() {
        //250 is the max-width set for the span element. We need to show the tooltip only if the text overflows in the span
        //TODO - Fix this logic
        if (this.myRef.current && this.myRef.current.scrollWidth >= 250) {
            this.setState({
                showToolTip: true
            })
        }
    }

    render() {
        const itemValueSpan = <span className={` order-details-overflow-item `} ref={this.myRef}> {this.props.value}</span>
        return this.state.showToolTip ?
            <div className="tooltip">
                {itemValueSpan}
                <span className="tooltiptext">{this.props.value}</span>
            </div>
            :
            itemValueSpan
    }
}

export default OrderDetailsTooltipItem;