import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { RootState } from '../reducers';
import { Popover } from "antd";
import { ReactComponent as Hover } from "../assets/hover.svg"
import { ReactComponent as DownloadIcon } from "../assets/download.svg"
import { getAllSuspensions, getCustomerSuspensions, downloadSuspensionLogs } from "../actions/orderActions"
import {  ENTITY_TYPE, REGION, getActorRoleString, isRoleTitan } from './utils';
import { suspensionListInfoType } from '../model/suspensionList';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { downloadFromUrl } from '../utils/helper';
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';

interface SuspensionPopoverProps {
    token: string,
    userRole: string,
    suspensionListInfo: suspensionListInfoType
    getAllSuspensions: (domainId: Number | null, accountId: Number | null, authToken: string, suspensionType: string, region: string) => void,
    downloadSuspensionLogs: (entityType: string, entityID: number, fileIDs: string[], authToken: string) => void;
    getCustomerSuspensions: (payload: any, authToken: string) => void,
    shouldShowUnsuspendOption: boolean | null,
    handleUnsuspendClick: () => void | null,
    hoverIconCss: string,
    currentRegion?: string,
    customerIdRegionDetails: any
}

interface ParentProps {
    entityType: ENTITY_TYPE,
    domainId: Number | null,
    accountId: Number | null,
    customerEmail: string | null,
    customerIds: number[] | null
}

interface State {
    visible: boolean
    loadingLinks: Record<string, boolean>
}

type Props = SuspensionPopoverProps & ParentProps & WrappedComponentProps

export interface SubCategory {
  displayName?: string;
  subCategory: string;
  resolutions: string[];
}

export interface AbuseDetails {
  subCategories: SubCategory[];
  s3Path?: string;
  reason?: string;
}

export interface Metadata {
  abuseDetails?: AbuseDetails;
}

export interface Suspension {
  role: string;
  userEmail: string;
  notes: string[];
  suspensionType: string;
  metadata?: Metadata;
}

class SuspensionPopover extends React.Component<Props> {

    state: State = {
        visible: false,
        loadingLinks: {},
    }

    constructor(prop: Props) {
        super(prop)
        this.createContent = this.createContent.bind(this)
        this.fetchSuspensionList = this.fetchSuspensionList.bind(this)
        this.createEmptyContent = this.createEmptyContent.bind(this)
        this.createErrorContent = this.createErrorContent.bind(this)
        this.getSuspensionListObject = this.getSuspensionListObject.bind(this)
        this.unsuspendClick = this.unsuspendClick.bind(this)
    }

    createEmptyContent() {
        return (<div className="suspension-popover-empty-container">
            <div className="loader"></div>
        </div>)
    }

    createErrorContent() {
        return (<div><div className="suspension-popover-error-text">
            <FormattedMessage id="unable-to-fetch-suspensions" />
        </div>
            <button
                className="button button-primary suspension-popover-retry-button"
                onClick={this.fetchSuspensionList}>
                <FormattedMessage id="retry" />
            </button>
        </div>)

    }

    getSuspensionListObject() {
        switch (this.props.entityType) {
            case ENTITY_TYPE.DOMAIN: return this.props.suspensionListInfo.domain;
            case ENTITY_TYPE.ACCOUNT: return this.props.suspensionListInfo.account;
            case ENTITY_TYPE.CUSTOMER: return this.props.suspensionListInfo.customer
        }
    }

    unsuspendClick = () => {
        this.setState({
            visible: false
        })
        this.props.handleUnsuspendClick()
    }

    createContent() {
        const tableHeaders: any = [];
        const suspensionListInfo = this.getSuspensionListObject()
        const fetchSuspensionListInProgress = suspensionListInfo.fetchSuspensionListInProgress
        const fetchSuspensionListError = suspensionListInfo.suspensionListError
        const isTitanUser = isRoleTitan(this.props.userRole);
        if (fetchSuspensionListInProgress) {
            return this.createEmptyContent()
        }
        else if (fetchSuspensionListError) {
            return this.createErrorContent()
        }
        tableHeaders.push(<th className="suspension-popover-table-header">
            <FormattedMessage id="user" />
        </th>)
        tableHeaders.push(<th className="suspension-popover-table-header">
            <FormattedMessage id="reason" />
        </th>)

        let suspensionsList = suspensionListInfo.suspensionList
        if (this.props.entityType === ENTITY_TYPE.CUSTOMER) {
            let newSuspensionList: any[] = []
            suspensionsList.forEach(s => newSuspensionList.push(...s.details))
            suspensionsList = newSuspensionList
        }
        
        const handleDownloadClick = async (s3Path: string) => {
          this.setState(
            (
              prevState: Readonly<{ loadingLinks: Record<string, boolean> }>
            ) => ({
              loadingLinks: {
                ...prevState.loadingLinks,
                [s3Path]: true,
              },
            })
          );

          try {
            const responseData = await downloadSuspensionLogs(
              this.props.entityType === ENTITY_TYPE.DOMAIN
                ? "Domain"
                : "Account",
              this.props.entityType === ENTITY_TYPE.DOMAIN
                ? this.props.domainId
                : this.props.accountId,
              [s3Path],
              this.props.token
            );

            if (responseData && responseData.files[s3Path]) {
              // Extract the download URL from the responseData
              const downloadUrl = responseData.files[s3Path];
              if (downloadUrl) {
                downloadFromUrl(downloadUrl, "suspension_logs.csv");
              }
            }
          } catch (error) {
            toast.info((error as Error).message);
          } finally {
            this.setState(
              (
                prevState: Readonly<{ loadingLinks: Record<string, boolean> }>
              ) => ({
                loadingLinks: {
                  ...prevState.loadingLinks,
                  [s3Path]: false,
                },
              })
            );
          }
        };

        return (
          <div className="suspension-popover-container">
            <div>
              <div className="suspension-popover-heading">
                {this.props.entityType === ENTITY_TYPE.CUSTOMER ? (
                  <FormattedMessage id="suspension-popover-heading-customer-suspensions" />
                ) : (
                  <FormattedMessage id="suspension-popover-heading-suspensions" />
                )}
                {this.props.shouldShowUnsuspendOption ? (
                  <div
                    className="suspension-popover-unsuspend-option"
                    onClick={this.unsuspendClick}
                  >
                    <FormattedMessage id="unsuspend" />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="suspension-popover-table scrollbar">
                <div className="suspension-table-container">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="cell">
                          <FormattedMessage id="user" />
                        </th>
                        {isTitanUser && (
                          <th className="cell">
                            <FormattedMessage id="reason" />
                          </th>
                        )}
                        <th className="cell">
                          <FormattedMessage id="category" />
                        </th>
                        <th className="cell">
                          <FormattedMessage id="sub-category" />
                        </th>
                        <th className="cell">
                          <FormattedMessage id="resolution" />
                        </th>
                        <th className="cell">
                          <FormattedMessage id="logs" />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="suspension-table-body">
                      {suspensionsList.map(
                        (suspension: Suspension, index: number) => {
                          const hasSubCategories =
                            !!suspension.metadata?.abuseDetails?.subCategories;
                          const subCategories =
                            suspension.metadata?.abuseDetails?.subCategories ||
                            [];
                          const rowSpanValue = hasSubCategories
                            ? subCategories.length
                            : 1;
                          const s3Path =
                            suspension.metadata?.abuseDetails?.s3Path;
                          const category =
                            suspension.metadata?.abuseDetails?.reason ||
                            suspension.suspensionType ||
                            "Abuse";

                          return (
                            <React.Fragment key={index}>
                              {subCategories.length > 0 ? (
                                subCategories.map(
                                  (
                                    subCategory: SubCategory,
                                    subIndex: number
                                  ) => (
                                    <tr key={subIndex}>
                                      {subIndex === 0 && (
                                        <>
                                          <td
                                            rowSpan={rowSpanValue}
                                            className="cell"
                                          >
                                            <div>
                                              {getActorRoleString(
                                                suspension.role,
                                                this.props.userRole
                                              )}
                                            </div>
                                            <div>{suspension.userEmail}</div>
                                          </td>
                                          {isTitanUser && (
                                            <td
                                              rowSpan={rowSpanValue}
                                              className="cell"
                                            >
                                              {suspension.notes.join(", ")}
                                            </td>
                                          )}
                                          <td
                                            rowSpan={rowSpanValue}
                                            className="cell"
                                          >
                                            {category}
                                          </td>
                                        </>
                                      )}
                                      <td className="cell">
                                        {subCategory.displayName ||
                                          subCategory.subCategory}
                                      </td>
                                      <td className="cell">
                                        <ul className="bullet-list">
                                          {subCategory.resolutions &&
                                          subCategory.resolutions.length > 0 ? (
                                            subCategory.resolutions.map(
                                              (
                                                resolution: string,
                                                resIndex: number
                                              ) => {
                                                const sanitizedHTML =
                                                  DOMPurify.sanitize(
                                                    resolution,
                                                    {
                                                      ADD_ATTR: ["target"],
                                                    }
                                                  );
                                                return (
                                                  <li
                                                    key={resIndex}
                                                    dangerouslySetInnerHTML={{
                                                      __html: sanitizedHTML,
                                                    }}
                                                  />
                                                );
                                              }
                                            )
                                          ) : (
                                            <li>
                                              <FormattedMessage id="contact-titan-support" />
                                            </li>
                                          )}
                                        </ul>
                                      </td>
                                      {subIndex === 0 && (
                                        <td
                                          rowSpan={rowSpanValue}
                                          className="cell-large"
                                        >
                                          {s3Path ? (
                                            <button
                                              className={`download-logs ${
                                                this.state.loadingLinks[s3Path]
                                                  ? "loading"
                                                  : ""
                                              }`}
                                              onClick={() =>
                                                handleDownloadClick(s3Path)
                                              }
                                              disabled={
                                                this.state.loadingLinks[s3Path]
                                              }
                                            >
                                              <DownloadIcon />
                                              <FormattedMessage id="download_logs" />
                                            </button>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td rowSpan={rowSpanValue} className="cell">
                                    <div>
                                      {getActorRoleString(
                                        suspension.role,
                                        this.props.userRole
                                      )}
                                    </div>
                                    <div>{suspension.userEmail}</div>
                                  </td>
                                  {isTitanUser && (
                                    <td rowSpan={rowSpanValue} className="cell">
                                      {suspension.notes.join(", ")}
                                    </td>
                                  )}
                                  <td rowSpan={rowSpanValue} className="cell">
                                    {category}
                                  </td>
                                  <td className="cell">-</td>
                                  <td className="cell">
                                    <FormattedMessage id="contact-titan-support" />
                                  </td>
                                  <td className="cell-large">
                                    {s3Path ? (
                                      <button
                                        className={`download-logs ${
                                          this.state.loadingLinks[s3Path]
                                            ? "loading"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleDownloadClick(s3Path)
                                        }
                                        disabled={
                                          this.state.loadingLinks[s3Path]
                                        }
                                      >
                                        <DownloadIcon />
                                        <FormattedMessage id="download_logs" />
                                      </button>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        );
    }

    handlePopoverVisibilityChange = () => {
        this.setState((prevState: State) => ({
            visible: !prevState.visible
        }))
        this.fetchSuspensionList()
    }

    fetchSuspensionList() {
        const suspensionListInfo = this.getSuspensionListObject()
        const shouldFetchSuspensionList =
            !suspensionListInfo.fetchSuspensionListInProgress &&
            !suspensionListInfo.fetchSuspensionListSuccess
        if (shouldFetchSuspensionList) {
            if (this.props.entityType === ENTITY_TYPE.CUSTOMER) {
                const customerIds_US = this.props.customerIds ? this.props.customerIds.filter(id => this.props.customerIdRegionDetails[id] === REGION.US) : []
                const customerIds_EU = this.props.customerIds ? this.props.customerIds.filter(id => this.props.customerIdRegionDetails[id] === REGION.EU) : []
                const customerIdRegionPayloadMap: any = {}
                customerIdRegionPayloadMap[REGION.US] = customerIds_US
                customerIdRegionPayloadMap[REGION.EU] = customerIds_EU
                const requestBody = {
                    "customerEmail": this.props.customerEmail,
                    "customerIdsRegionDetails": customerIdRegionPayloadMap
                }
                this.props.getCustomerSuspensions(requestBody, this.props.token)
            }
            else {
                this.props.getAllSuspensions(
                    this.props.domainId, this.props.accountId, this.props.token, this.props.entityType, this.props.currentRegion ? this.props.currentRegion : "")
            }
        }
    }

    render() {
        const popOverContent = this.createContent()
        const tooltipPlacement: TooltipPlacement = this.props.entityType === ENTITY_TYPE.ACCOUNT ? "left" : "right"
        return (
            <div>
                <Popover
                    placement={tooltipPlacement}
                    content={popOverContent}
                    trigger="hover"
                    visible={this.state.visible}
                    onVisibleChange={this.handlePopoverVisibilityChange}>
                    <Hover className={this.props.hoverIconCss} />
                </Popover>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const orders = state.orders as any
    return {
        token: state.auth.token,
        userRole: state.auth.userRole,
        suspensionListInfo: orders.suspensionListInfo,
        customerIdRegionDetails: orders.customerIdRegionDetails
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ getAllSuspensions, getCustomerSuspensions, downloadSuspensionLogs }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SuspensionPopover));