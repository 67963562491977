import React, { Component } from 'react';
import { connect } from "react-redux"
import { FormattedMessage } from 'react-intl';

import { validate } from '../utils/helper';

class DashboardForm extends Component {
  state = {
    queryText: "",
    querySubmitted: "",
    validationError: false
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange = e => {
    const query = e.target.value
    this.setState(prevState => ({
      ...prevState,
      queryText: query
    }))
  }

  handleFormSubmit = e => {
    e.preventDefault()
    const searchedText = this.state.queryText.trim()

    if (!validate.email(searchedText) && !validate.domain(searchedText)) {
      this.setState({ validationError: true });

      return false;
    }

    this.setState({
      validationError: false,
      querySubmitted: searchedText
    })
    this.props.onSubmitDashboardForm(searchedText)
  }

  render() {
    const error = (this.props.orderListError
      && (this.state.queryText === this.state.querySubmitted)
      && !this.props.isOrderListLoading) || this.state.validationError;

    return (
      <form name="formDiv" id="formDiv" className="dashboard-form" onSubmit={this.handleFormSubmit}>
        <label className="dashboard-label input-label">
          <FormattedMessage id="order-info-help-text" />
        </label>
        <div>
          <input name="domainName"
            type="text"
            className={`dashboard-input input ${error ? 'input-error' : ''}`}
            placeholder="abc@example.com / example.com"
            autoFocus
            onChange={this.handleChange}
            value={this.state.queryText} />
          <div className="error dashboard-error">
            {
              this.state.validationError
                ? <FormattedMessage id="search-field-validation-error" />
                : this.props.orderListError
            }
          </div>
        </div>
        <button type="primary"
          className="button button-primary dashboard-button"
          name="btndashBoard"
          disabled={this.props.isOrderListLoading}>
          <FormattedMessage id="order-info-button-text" />
        </button>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    isOrderListLoading: state.orders.isOrderListLoading,
    orderListError: state.orders.orderListError
  }
}

export default connect(mapStateToProps)(DashboardForm);
