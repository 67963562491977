import { handleResponse, exception, getAuthHeadersForMethod, handleApiError } from "./utils";
import { Exception, ExceptionResponse } from "./utils";
import { getFormattedMessage } from "../components/utils";

export const getOrderList = (query, authToken) => {
  const requestOptions = getAuthHeadersForMethod("GET", authToken);
  const params = { query: query };
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}order-list?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      if (String(err) === Exception.NETWORK_FAILURE)
        return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE);
      return exception(String(err))
    })
};

export const sendInvoice = (payload, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(payload)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/send-payment-invoice`,
    requestOptions
  ).then(handleResponse)
    .catch((err) => {
      if (String(err) === Exception.NETWORK_FAILURE)
        return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE)
      return exception(String(err));
    })
};

export const getOrderDetails = (query, domainId, partnerId, accountId, authToken, region) => {
  const requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { query: query, domainId: domainId, partnerId: partnerId };
  if (accountId) params.accountId = accountId;
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}order-details?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      if (String(err) === Exception.NETWORK_FAILURE)
        return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE)
      return exception(String(err))
    })
};

export const getDnsDetails = (domainId, authToken, region) => {
  const requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { domainId: domainId };
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}dns-details?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      if (String(err) === Exception.NETWORK_FAILURE)
        return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE)
      return exception(String(err))
    })
};

export const orderDeleteRequest = (payload, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(payload)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}delete-order`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return exception(getFormattedMessage("delete-order-request-error"))
    })
};

export const accountsExceedingQuotaRequest = (domainId, authToken, region) => {
  const requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { domainId: domainId };
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}exceed-free-quota?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      if (String(err) === "TypeError: Failed to fetch")
        return Promise.reject(getFormattedMessage("error-unable-to-connect"))
      return Promise.reject(String(err))
    })
};

export const customerDomainListRequest = (userId, authToken, region) => {
  const requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { userId: userId };
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}customer-domain-list?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      if (String(err) === "TypeError: Failed to fetch")
        return Promise.reject(getFormattedMessage("error-unable-to-connect"))
      return Promise.reject(String(err))
    })
}

export const orderChangePlanRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}configure-trial`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(err || getFormattedMessage("request-failed-try-again"))
    })
}

export const orderExtendPlanRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}change-plan`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(err || getFormattedMessage("request-failed-try-again"))
    })
}

export const orderDowngradePlanRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}change-plan`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(err || getFormattedMessage("request-failed-try-again"))
    })
}

export const mailServiceChangeRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}toggle-mail-service`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}

export const suspendDomainRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/add-suspension`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("domain-suspension-error"))
    })
}

export const suspendAccountRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}email/add-suspension`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("account-suspension-error"))
    })
}

export const suspendCustomerRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}user/add-suspension`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("customer-suspension-error"))
    })
}

export const getAllDomainSuspensionsRequest = (domainId, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { domainId: domainId };
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/get-all-suspensions?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}

export const getAllAccountSuspensionsRequest = (domainId, accountId, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { domainId: domainId, accountId: accountId };
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}email/get-all-suspensions?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}

export const unsuspendDomainRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/remove-suspensions`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("unsuspension-error"))
    })
}

export const unsuspendAccountRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}email/remove-suspensions`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("unsuspension-error"))
    })
}

export const domainActionHistoryRequest = (domainId, limit, lastId, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { domainId: domainId, limit };
  if (lastId) {
    params.lastId = lastId;
  }
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/action-history-list/v2?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}

export const accountsActionHistoryRequest = (accountIds, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { accountIds };
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}email/action-history-list/v2?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}

export const fetchActionHistoryDetailsRequest = (actionId, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { actionId: actionId };
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}action-history-details?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}

export const fetchEmailAccountsToRecoverRequest = (domainName, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { domainName };
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/get-recoverable-accounts?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}

export const recoverEmailAccountsRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/recover-accounts`,
    requestOptions
  ).then(handleResponse)
    .catch(err => Promise.reject(err))
}

export const domainReactivateRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/reactivate`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("domain-reactivation-error"))
    })
}

export const domainReactivationCheckRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/check-reactivation-eligibility`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("domain-reactivation-check-error"))
    })
}

export const changeCustomerEmail = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/update-customer-email`,
    requestOptions
  )
    .then(handleResponse)
    .catch(() => Promise.reject(getFormattedMessage("update-customer-email-server-error")))
}

export const modifyAccountLimit = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/modify-account-limit`,
    requestOptions
  )
    .then(handleResponse)
    .catch(() => Promise.reject(getFormattedMessage("set-account-limit-server-error")))
}

export const fetchRecoveryTasksForDomain = (domainId, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { domainId };
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/fetch-recovery-tasks?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}

export const getCustomerOrderList = (customerEmail, customerIds, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  const requestBody = { customerEmail, customerIds }
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}customer-order-list/v2`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}

export const getAllCustomerSuspensionsRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}user/get-all-suspensions/v2`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}

export const downloadSuspensionLogFile = (
  entityType,
  entityID,
  fileIDs,
  authToken
) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify({ type: entityType, entityID, fileIDs })
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${baseUrl}suspension/file/links`, requestOptions)
    .then(handleResponse)
    .catch(handleApiError);
};

export const unsuspendCustomerRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}user/remove-suspensions`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("unsuspension-error"))
    })
}

export const getAllTransactionsRequest = (domainId, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("GET", authToken, region);
  const params = { domainId };
  const paramString = new URLSearchParams(params);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/get-all-transactions?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}

export const refundRequest = (requestBody, authToken, region) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken, region);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(requestBody)
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    `${baseUrl}domain/process-refund`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("refund-process-error"))
    })
}