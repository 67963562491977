import * as React from 'react';
import { ReactComponent as Collapse } from "../assets/collapse.svg"
import { FormattedMessage } from 'react-intl';

interface ManageButtonProps {
    optionList: ManageButtonDropdownOptions[],
    shouldShowSpinner: boolean,
    showDropdownOnTop: boolean | null
}

interface ManageButtonDropdownOptions {
    header: string,
    buttons: ManageButtonOption[]
}

interface ManageButtonOption {
    title: string,
    buttonName: string,
    onClick: (action: string) => void
}

interface ManageButtonState {
    dropDownVisibility: boolean
}


class ManageButton extends React.Component<ManageButtonProps, ManageButtonState> {

    state: ManageButtonState = {
        dropDownVisibility: false
    }
    private node?: HTMLElement | null;

    constructor(props: ManageButtonProps) {
        super(props)
        this.handleButtonClick = this.handleButtonClick.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    handleClickOutside(e: any) {
        if (!this.node)
            return
        if (!this.node.contains(e.target)) {
            this.setState({
                dropDownVisibility: false
            })
        }
    }

    handleButtonClick() {
        this.setState((prevState: ManageButtonState) => ({
            ...prevState,
            dropDownVisibility: !prevState.dropDownVisibility
        }))
    }
    render() {
        const buttonText = this.props.shouldShowSpinner ? <div className="manage-button-div">
            <FormattedMessage id="manage-button-text" />
            <div className="spinner-manage" /></div> : <FormattedMessage id="manage-button-text" />
        let manageButtonCss = 'manage-dropdown-content left-0'
        if (this.props.showDropdownOnTop) {
            manageButtonCss += ' manage-dropdown-content-on-top'
        }
        const dropdownContent = this.props.optionList.map((item: ManageButtonDropdownOptions) => {
            const clickableList = item.buttons.map((button: ManageButtonOption, index: number) => {
                let dropdownItemCss = "dropdown-menu-item"
                return <div>
                    <div className={dropdownItemCss} onClick={() => {
                        this.handleButtonClick()
                        button.onClick(button.buttonName)
                    }
                    }>{button.title}</div>
                </div>
            })
            return <div>
                <div className="manage-button-header-container">
                    <div className="manage-button-header">
                        {item.header}
                    </div>
                    <div className="manage-button-divider" />
                </div>
                {clickableList}
            </div>
        })
        return (
            <div className="dropdown" ref={(node) => this.node = node}>
                <button className="button button-secondary order-details-button manage-button-div"
                    onClick={this.handleButtonClick}>
                    {buttonText}
                    <Collapse className="order-details-dropdown-collapse" />
                </button>
                {this.state.dropDownVisibility ?
                    <div className={manageButtonCss}>
                        {dropdownContent}
                    </div> : ""}
            </div>
        )
    }
}

export default ManageButton;