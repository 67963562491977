import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { Grid, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { fetchSupportDocs, uploadFilesToUrls } from '../../actions/createTaskActions';
import TaskInstructions from './TaskInstructions';

const initialUploadState = {
  isLoading: false,
  uploadError: '',
  supportDocs: { sampleDomainCSV: '', sampleEmailCSV: '' },
  failedToFetchSupportDocs: false,
};

const UploadFiles = ({ classes, bulkMigrationFormData, handleFileChange, handleUploadSuccess, setAccordionState, isOpen }) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.token);
  const ordersFileInputRef = useRef(null);
  const mailboxesFileInputRef = useRef(null);
  const [uploadState, setUploadState] = useState(initialUploadState);

  const fetchDocs = async () => {
    setUploadState((prevState) => ({
      ...prevState,
      failedToFetchSupportDocs: false,
    }));
    try {
      const response = await dispatch(
        fetchSupportDocs(
          bulkMigrationFormData.selectedPartnerId,
          bulkMigrationFormData.createOnTitan,
          bulkMigrationFormData.migrateEmails === "yes",
          authToken
        )
      );
      setUploadState((prevState) => ({ ...prevState, supportDocs: response }));
    } catch (error) {
      console.error("Failed to fetch support docs", error);
      setUploadState((prevState) => ({
        ...prevState,
        failedToFetchSupportDocs: true,
      }));
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchDocs();
    }
  }, [isOpen, bulkMigrationFormData.createOnTitan, bulkMigrationFormData.migrateEmails, authToken]);

  const handleUploadFiles = async () => {
    setUploadState(prevState => ({ ...prevState, isLoading: true, uploadError: '' }));
    try {
      const uploadedFilesResponse = await dispatch(uploadFilesToUrls(
        bulkMigrationFormData.selectedPartnerId,
        authToken,
        bulkMigrationFormData.ordersFile,
        bulkMigrationFormData.mailboxesFile
      ));
      handleUploadSuccess(uploadedFilesResponse);
    } catch (error) {
      setUploadState(prevState => ({
        ...prevState,
        uploadError: error.message || 'Failed to upload files'
      }));
      setAccordionState('panel2', { enabled: true, expanded: true });
    } finally {
      setUploadState(prevState => ({ ...prevState, isLoading: false }));
    }
  };

  const { isLoading, uploadError, supportDocs, failedToFetchSupportDocs } = uploadState;

  return (
    <Grid container spacing={2} className={classes.taskDetailsContainer}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.csvInputLabel}>
              <FormattedMessage id="choose-csv-relevant-orders" />
            </div>
            <Grid container spacing={2}>
              <Grid item>
                <button
                  type="button"
                  className="button button-secondary"
                  onClick={() => ordersFileInputRef.current.click()}
                >
                  <FormattedMessage id="choose-file" />
                </button>
                <input
                  type="file"
                  ref={ordersFileInputRef}
                  name="ordersFile"
                  accept=".csv"
                  hidden
                  onChange={handleFileChange}
                />
              </Grid>
              {!failedToFetchSupportDocs && (
                <a
                  href={supportDocs.sampleDomainCSV}
                  target="_blank"
                  rel="noopener"
                  className={classes.viewSampleFile}
                >
                  <FormattedMessage id="view-sample-file" />
                </a>
              )}
            </Grid>
            {bulkMigrationFormData.ordersFile && <div>{bulkMigrationFormData.ordersFile.name}</div>}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.csvInputLabel}>
              <FormattedMessage id="choose-csv-relevant-mailboxes" />
            </div>
            <Grid container spacing={2}>
              <Grid item>
                <button
                  type="button"
                  className="button button-secondary"
                  onClick={() => mailboxesFileInputRef.current.click()}
                >
                  <FormattedMessage id="choose-file" />
                </button>
                <input
                  type="file"
                  ref={mailboxesFileInputRef}
                  name="mailboxesFile"
                  accept=".csv"
                  hidden
                  onChange={handleFileChange}
                />
              </Grid>
              {!failedToFetchSupportDocs && (
                <a
                  href={supportDocs.sampleEmailCSV}
                  target="_blank"
                  rel="noopener"
                  className={classes.viewSampleFile}
                >
                  <FormattedMessage id="view-sample-file" />
                </a>
              )}
            </Grid>
            {bulkMigrationFormData.mailboxesFile && <div>{bulkMigrationFormData.mailboxesFile.name}</div>}
          </Grid>
          <Grid item xs={12}>
            <button
              type="primary"
              className="button button-primary"
              onClick={handleUploadFiles}
              disabled={!bulkMigrationFormData.ordersFile || !bulkMigrationFormData.mailboxesFile || isLoading}
            >
              {isLoading ? (
                <div className="button-loader"></div>
              ) : (
                <FormattedMessage id="confirm-and-upload-files" />
              )}
            </button>
            {uploadError && (
              <div className={classes.errorMsg}>{uploadError}</div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.instructionWrapper}>
        <TaskInstructions bulkMigrationFormData={bulkMigrationFormData} />
      </div>
    </Grid>
  );
};

export default withStyles(styles)(UploadFiles);