import { handleResponse, exception, getAuthHeadersForMethod, Exception, ExceptionResponse } from "./utils";
import { getFormattedMessage } from "../components/utils";

export const getLogs = (queryParams, authToken) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(queryParams),
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${baseUrl}mail/logs/get`, requestOptions)
    .then(handleResponse)
    .catch((err) => {
      if (String(err) === "TypeError: Failed to fetch")
        return exception(getFormattedMessage("error-unable-to-connect"));
      return exception(String(err));
    });
};

export const getIpInfo = (queryParams, authToken) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify(queryParams),
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${baseUrl}ip/info`, requestOptions)
    .then(handleResponse)
    .catch((err) => {
      if (String(err) === "TypeError: Failed to fetch")
        return exception(getFormattedMessage("error-unable-to-connect"));
      return exception(String(err));
    });
};

export const getRedisCachedMailbox = (queryParams, authToken) => {
  let requestOptions = getAuthHeadersForMethod("GET", authToken);
  const paramString = new URLSearchParams(queryParams);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    baseUrl + `recipient/bounce/fetch?${paramString.toString()}`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      if (String(err) === "TypeError: Failed to fetch")
        return exception(getFormattedMessage("error-unable-to-connect"))
      return exception(String(err))
    })
};


export const removeEmailFromRedisCache = (email, authToken) => {
  const requestOptions = {
    ...getAuthHeadersForMethod("POST", authToken),
    body: JSON.stringify({ email }),
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(`${baseUrl}recipient/bounce/delete`, requestOptions)
    .then(handleResponse)
    .catch((err) => {
      if (String(err) === Exception.NETWORK_FAILURE)
        return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE);
      throw new Error(String(err));
    });
};
