import { handleResponse, exception, getAuthHeadersForMethod } from "./utils";
import { Exception, ExceptionResponse } from "./utils";
import { getFormattedMessage, getCurrentLocale } from "../components/utils";

export const login = (email, password) => {
  let requestOptions = getAuthHeadersForMethod("POST", null);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify({ email, password })
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(baseUrl + "login", requestOptions)
    .then(handleResponse)
    .catch(err => {
      if (String(err) === Exception.NETWORK_FAILURE)
        return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE)
      return exception(String(err))
    })
};

export const reset = (oldPassword, newPassword, authToken) => {
  let requestOptions = getAuthHeadersForMethod("POST", authToken);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify({ oldPassword, newPassword })
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  return fetch(
    baseUrl + `resetPassword`,
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      if (String(err) === Exception.NETWORK_FAILURE)
        return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE)
      return exception(String(err))
    })
};

export const forgotPassword = (email) => {
  let requestOptions = getAuthHeadersForMethod("POST", null);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify({ emailId: email, locale: getCurrentLocale() })
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(baseUrl + `forgot-password`, requestOptions)
    .then(handleResponse)
    .catch(err => {
      if (String(err) === Exception.NETWORK_FAILURE)
        return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE)
      return exception(String(err))
    })
}

export const setNewPassword = (emailId, resetToken, password) => {
  let requestOptions = getAuthHeadersForMethod("POST", null);
  requestOptions = {
    ...requestOptions,
    body: JSON.stringify({ emailId, resetToken, password })
  };
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(baseUrl + `set-password`, requestOptions)
    .then(handleResponse)
    .catch(err => {
      if (String(err) === Exception.NETWORK_FAILURE)
        return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE)
      return exception(String(err))
    })
}

export const fetchUserInfo = (authToken) => {
  let requestOptions = getAuthHeadersForMethod("GET", authToken);
  const baseUrl = process.env.REACT_APP_API_BASE_URL
  return fetch(
    baseUrl + 'user/v2',
    requestOptions
  ).then(handleResponse)
    .catch(err => {
      return Promise.reject(getFormattedMessage("request-failed-try-again"))
    })
}
