export interface NavBarOption {
    optionName: string,
    displayValue: string,
    visibility: boolean
}

export const NavBarDisplayValue = {
    ORDERS: "orders",
    LOGS: "logs",
    ADMIN: "admin",
    CREATE_TASK: "createTasks",
    MONITOR_TASK: "monitorTasks",
}

export const NavBarOptionValue = {
    ORDERS: "dashboard",
    LOGS: "getlogs",
    ADMIN: "admin",
    CREATE_TASK: "createTask",
    MONITOR_TASK: "monitorTasks",
}
