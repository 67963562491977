import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { closeInvalidateModal } from '../actions/authActions';

class TokenInvalid extends Component {
    state = {}
    render() {
        return (
            <div className="modal" onClick={this.props.closeInvalidateModal}>
                <div className="modal-content invalidate-token-modal">
                    <div className="invalidate-token-content">
                        <span className="invalidate-token-header">
                            Session Expired
                        </span>
                        <div className="invalidate-token-message">
                            Your session expired. Please log in again
                        </div>
                        <button
                            className="button button-primary invalidate-token-button"
                            onClick={this.props.closeInvalidateModal}> OK </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ closeInvalidateModal }, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(TokenInvalid);