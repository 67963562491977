import Papa from 'papaparse';
import { downloadFromUrl } from './helper';

type PapaData = 
  | object[]  
  | string[][] 
  | { fields: string[]; data: string[][] };

export const downloadCSV = (
  data: PapaData, 
  filename: string = 'download.csv',
  config?: Papa.UnparseConfig
): void => {
  // Check if the data is empty based on its type
  if (
    (Array.isArray(data) && data.length === 0) || // Array of Objects or Array of Arrays
    (typeof data === 'object' && 'data' in data && data.data.length === 0) // Object with fields and data
  ) {
    return; // Avoid errors if data is empty
  }

  // Generate CSV using PapaParse with optional config
  const csv = Papa.unparse(data, config);

  // Create a blob from the CSV data
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);

  // Use the helper function to download the file
  downloadFromUrl(url, filename);

  window.URL.revokeObjectURL(url); // Release memory by revoking the object URL after it's no longer needed
};
