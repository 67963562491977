import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { useForm, SubmitHandler } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import * as Joi from "joi";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl"

import { RootState } from '../reducers';
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import { ReactComponent as Success } from "../assets/suspend-success-tick.svg"
import { changeCustomerEmailAction, resetChangeCustomerEmailFlagsAction } from '../actions/orderActions'

interface ParentComponentProps {
  closeModal: (domainId: string|null, accountId: string|null) => void,
  email: string,
  userId: string,
  domainId: string,
  accountId: string,
  currentRegion: string,
}

interface ReduxProps {
  changingCustomerEmail: boolean,
  changedCustomerEmail: boolean,
  changeCustomerEmailError: string,
  authToken: string,
  resetChangeCustomerEmailFlagsAction: () => any,
  changeCustomerEmailAction:
    (userId: string, customerEmail: string, reason: string, authToken: string, region: string) => any,
}

type Props = ParentComponentProps & ReduxProps & WrappedComponentProps;

type FormValues = {
  email: any; // had to mention else `errors.email?.type` throws error
  reason: any;
};

const schema = Joi.object({
  email: Joi.string().trim().email({ tlds: {allow: false} }).required(),
  reason: Joi.string().trim().required()
});

const ChangeCustomerEmailModal = ({
  closeModal, email, userId, intl, authToken,
  changeCustomerEmailAction, resetChangeCustomerEmailFlagsAction,
  currentRegion, domainId, accountId,
  changeCustomerEmailError, changingCustomerEmail, changedCustomerEmail
}: Props) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormValues>({
    resolver: joiResolver(schema)
  });
  const _onSubmit: SubmitHandler<FormValues> = (values) => {
    changeCustomerEmailAction(userId, values.email, values.reason, authToken, currentRegion)
      .then(() => {
        setTimeout(() => {
          closeModal(domainId, accountId);
          resetChangeCustomerEmailFlagsAction();
        }, 1000); // close modal after 1sec delay
      });
  };
  const _onCancel = () => closeModal(null, null);
  const _getSubmitButtonText = () => {
    if (changedCustomerEmail) {
      return (
        <div className="modal-button-div">
          <Success className="modal-button-success-icon" />
          {intl.formatMessage({ id: "submitted" })}
        </div>
      );
    } else if (changingCustomerEmail) {
      return (
        <div className="modal-button-div">
          <div className="modal-spinner" />
          {intl.formatMessage({ id: "submitting" })}
        </div>
      );
    } else {
      return (
        <div className="modal-button-div">
          {intl.formatMessage({ id: "submit" })}
        </div>
      );
    }
  }
  const disableSubmit = changingCustomerEmail || changedCustomerEmail || (watch("email") === email);

  return (
    <div>
        <CloseGrey className="alert-modal-close-button" onClick={() => _onCancel()} />
        <div className="t-modal-content">
          <div className="form-heading">{intl.formatMessage({ id: "change-customer-email" })}</div>
          <div className="form-subheading">{email}</div>
          <form onSubmit={handleSubmit(_onSubmit)}>
            <fieldset className='t-form-field'>
              <FormattedMessage tagName="label" id="customer-email-label" />
              <input
                placeholder={intl.formatMessage({ id: "customer-email" })}
                {...register("email", { required: true })}
              />
              {errors.email?.type && <FormattedMessage tagName="span" id={errors.email?.type} />}
            </fieldset>
            <fieldset className='t-form-field'>
              <FormattedMessage tagName="label" id="customer-email-reason-label" />
              <input
                placeholder={intl.formatMessage({ id: "customer-email-reason" })}
                {...register("reason", { required: true })}
              />
              {errors.reason?.type && <FormattedMessage tagName="span" id={errors.reason?.type} />}
            </fieldset>
            {changeCustomerEmailError && <span className='server-error'>{changeCustomerEmailError}</span>}
            <div className="t-button-container">
              <button
                className="button button-primary"
                disabled={disableSubmit}
              >
                { _getSubmitButtonText() }
              </button>
              <button
                className="button button-secondary"
                onClick={_onCancel}
                disabled={changingCustomerEmail}
              >
                <FormattedMessage id="cancel" />
              </button>
            </div>
          </form>
        </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    changingCustomerEmail,
    changedCustomerEmail,
    changeCustomerEmailError
  } = (state.orders as any).changeCustomerEmailInfo;
  return {
    authToken: state.auth.token,
    changingCustomerEmail,
    changedCustomerEmail,
    changeCustomerEmailError
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    ...bindActionCreators({ changeCustomerEmailAction }, dispatch),
    ...bindActionCreators({ resetChangeCustomerEmailFlagsAction }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangeCustomerEmailModal));
