import React, { Component } from "react"
import { ReactComponent as DropDownArrow } from "../assets/dropdown-arrow.svg"
import { throwStatement } from "@babel/types";

interface Props {
    mainClass: string
    headerClass: string
    headerTitleClass: string
    listContainerClass: string
    dropDownItemClass: string
    onItemClick: (item: DropdownOption, index: number) => void,
    defaultItemSelectedIndexes: number[] | null,
    placeholderText: string,
    placeholderClass: string
    list: DropdownOption[],
    shouldAllowMultiSelect: boolean,
    multiSelectTextClass?: string
}

export interface DropdownOption {
    label: string,
    value: any,
    isSelected?: boolean
}

interface State {
    listOpen: boolean,
    headerTitle: string
}

class Dropdown extends React.PureComponent<Props> {
    state: State
    private node?: HTMLElement | null;
    constructor(props: Props) {
        super(props)
        this.state = {
            listOpen: false,
            headerTitle: (this.props.defaultItemSelectedIndexes === null || this.props.defaultItemSelectedIndexes.length === 0) ?
                this.props.placeholderText :
                this.createHeaderFromSelectedValues(this.props.defaultItemSelectedIndexes)
        }
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.createHeaderFromSelectedValues = this.createHeaderFromSelectedValues.bind(this)
        this.node = undefined
    }

    createHeaderFromSelectedValues(selectedIndexes: number[]) {
        let heading = ""
        selectedIndexes.forEach((selectedIndex, index) => {
            let value = this.props.list[selectedIndex].label
            if (index != 0) {
                value += ` (${index + 1})`
            }
            heading += value + ", "
        })
        return heading.slice(0, -2)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    componentDidUpdate() {
        if (this.props.shouldAllowMultiSelect) {
            let selectedIndexes = this.props.list.map((item, index) => item.isSelected ? index : -1).filter(item => item !== -1)
            if (selectedIndexes.length === 0) {
                this.setState({
                    headerTitle: this.props.placeholderText
                })
            }
            else {
                this.setState({
                    headerTitle: this.createHeaderFromSelectedValues(selectedIndexes)
                })
            }
        }
    }

    handleClickOutside(e: any) {
        if (!this.node)
            return
        if (!this.node.contains(e.target)) {
            this.setState({
                listOpen: false
            })
        }
    }
    toggleList() {
        this.setState((prevState: State) => ({
            listOpen: !prevState.listOpen
        }))
    }
    onItemClick(item: DropdownOption, index: number) {
        if (!this.props.shouldAllowMultiSelect) {
            this.setState({
                headerTitle: item.label
            })
            this.toggleList()
        }
        this.props.onItemClick(item, index)
    }
    render() {
        const { list } = this.props
        const { listOpen, headerTitle } = this.state
        const headerTitleCss = this.state.headerTitle === this.props.placeholderText ? this.props.placeholderClass : this.props.headerTitleClass
        return (
            <div className={this.props.mainClass} ref={(node) => this.node = node}>
                <div className={this.props.headerClass} onClick={() => this.toggleList()}>
                    <div className={headerTitleCss}>{headerTitle}</div>
                    <DropDownArrow />
                </div>
                {listOpen && <div className={this.props.listContainerClass}>
                    {list.map((item, index) => {
                        if (this.props.shouldAllowMultiSelect) {
                            return <div className={this.props.dropDownItemClass} onClick={() => this.onItemClick(item, index)} >
                                <input type='checkbox' checked={item.isSelected} />
                                <span className={this.props.multiSelectTextClass}>{item.label}</span>
                            </div>
                        }
                        return <div className={this.props.dropDownItemClass} key={item.value}
                            onClick={() => this.onItemClick(item, index)}>{item.label}</div>
                    })}
                </div>}
            </div>
        )
    }
}

export default Dropdown