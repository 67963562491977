import React, { Component } from 'react';
import { connect } from "react-redux";
import { changeMailService } from "../actions/orderActions";
import { bindActionCreators } from 'redux';
import { ReactComponent as Success } from "../assets/checked.svg"
import { FormattedMessage, injectIntl } from 'react-intl';

class ChangeMailService extends Component {
    constructor(props) {
        super(props);
        this.userId = this.props.userId;
        this.mailService = this.props.mailService;
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.domainName = this.props.domainName;
        this.accountId = this.props.accountId;
    }

    handleButtonClick() {
        let desiredMailService;
        if (this.mailService == "partner_hosting")
            desiredMailService = "flock_cloud"
        else
            desiredMailService = "partner_hosting"
        const ChangeMailServiceRequestBody = {
            "userId": this.userId,
            "mailService": desiredMailService
        }
        this.props.changeMailService(ChangeMailServiceRequestBody, this.props.token, this.props.currentRegion);
    }
    render() {
        const shouldShowError = this.props.mailServiceChangeError && !this.props.isMailServiceChangeInProgress;
        let moveMailServiceText;
        if (this.mailService == "partner_hosting")
            moveMailServiceText = "Flock Cloud";
        else if (this.mailService == "flock_cloud")
            moveMailServiceText = "cPanel"
        if (this.props.isMailServiceChangeSuccess) {
            const newDomainId = this.props.toggledDomains.find(domainInfo => {
                return domainInfo.domainName === this.domainName
            }).domainId
            return (
                <div className="configure-trial-success">
                    <Success />
                    <span className="configure-trial-success-header">
                        <FormattedMessage id="congratulations" />
                    </span>
                    <span className="configure-trial-success-message">
                        <FormattedMessage id="domains-moved-to-mail-service" values={{ mailService: moveMailServiceText }} />
                    </span>
                    <div className="configure-trial-success-button-div">
                        <button className="button button-primary configure-trial-success-button"
                            onClick={() => this.props.closeModal(newDomainId, this.accountId)}>
                            <FormattedMessage id="close" />
                        </button>
                    </div>
                </div>
            )
        }
        else {
            let modalHeading, modalMessage;
            modalHeading = this.props.intl.formatMessage({ id: "move-customer-to-mail-service" }, { mailService: moveMailServiceText })
            if (this.props.fetchCustomerDomainListError) {
                modalMessage = this.props.intl.formatMessage({ id: "move-cpanel-modal-message-domains-not-fetched" }, { domain: this.domainName })
            }
            else {
                modalMessage = this.props.intl.formatMessage({ id: "move-action-modal-message-domains-fetched" }, { mailService: moveMailServiceText })
            }
            let domainsList = this.props.domainsForCustomer.map(domainInfo => <div>- {domainInfo.domainName}</div>)
            return (
                <div className="delete-order-content">
                    <span className="delete-orders-header">{modalHeading}</span>
                    {shouldShowError ? <div className="delete-order-error">{this.props.mailServiceChangeError} </div> : ""}
                    <div className="delete-orders-message">
                        {modalMessage}
                        <div className="order-modal-list">
                            {domainsList}
                        </div>
                        <div className="delete-orders-note">
                            <FormattedMessage id="change-mail-service-note" />
                        </div>
                    </div>
                    <div className="delete-buttons">
                        <button className='button button-primary delete-order-button'
                            onClick={() => this.handleButtonClick()}
                            disabled={this.props.isMailServiceChangeInProgress}>
                            <FormattedMessage id="move-button-text" />
                        </button>
                        <button className="button button-cancel delete-order-button"
                            onClick={() => this.props.closeModal(null, null)}
                            disabled={this.props.isMailServiceChangeInProgress}>
                            <FormattedMessage id="cancel" />
                        </button>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isMailServiceChangeInProgress: state.orders.isMailServiceChangeInProgress,
        isMailServiceChangeSuccess: state.orders.isMailServiceChangeSuccess,
        mailServiceChangeError: state.orders.mailServiceChangeError,
        domainsForCustomer: state.orders.domainsForCustomer,
        fetchCustomerDomainListError: state.orders.fetchCustomerDomainListError,
        toggledDomains: state.orders.toggledDomains
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ changeMailService }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangeMailService));
