import React, { ChangeEvent, FC, memo, useMemo, useState } from 'react';
import Joi from 'joi';
import { Table } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"

import { sendInvoice } from '../service/orderService';
import moment from 'moment';
import { showPicker } from '../utils/helper';
import { today } from '../utils/date';

type IProps = {
    closeModal: () => unknown;
    token: string,
    region: string,
    domainId: string,
}

type FormState = {
    paymentsAfterEpoch: string;
    paymentsBeforeEpoch: string;
    receivers: string;
    sendToCustomer: boolean;
};

type SendInvoiceApiResp = {
    invoiceDetails: {
        billedOn: string,
        totalAmount: number
    }[]
};

const emptyFormState = {
    paymentsAfterEpoch: '',
    paymentsBeforeEpoch: '',
    receivers: '',
    sendToCustomer: false,
};

// pagination table props
const paginationProps = { pageSize: 5 };

const GenerateInvoice: FC<IProps> = ({ closeModal, token, region, domainId }) => {
    const intl = useIntl();
    const handleCloseClick = () => closeModal();
    const [formData, setFormData] = useState<FormState>(emptyFormState);
    const [formErrors, setFormErrors] = useState<Record<string, string> | null>(null);
    const [tableData, setTableData] = useState<SendInvoiceApiResp["invoiceDetails"] | null>(null);

    // Schema definition
    const schema = useMemo(() => Joi.object({
        paymentsAfterEpoch: Joi.date().required().messages({
        'date.base': intl.formatMessage({ id: 'val-date.base'}),
        'date.empty': intl.formatMessage({ id: 'val-date.empty'}),
        }),
        paymentsBeforeEpoch: Joi.date().greater(Joi.ref('paymentsAfterEpoch')).required().messages({
            'date.base': intl.formatMessage({ id: 'val-date.base'}),
            'date.empty': intl.formatMessage({ id: 'val-date.empty'}),
            'date.greater': intl.formatMessage({ id: 'val-date.greater'})
        }),
        receivers: Joi.array().items(
            Joi.string().email({ tlds: { allow: false } }).messages({
                'string.empty': intl.formatMessage({ id: 'val-string.empty'}),
                'string.email': intl.formatMessage({ id: 'val-string.email'})
            })
        ).required().messages({
            'array.base': intl.formatMessage({ id: 'val-array.base'}),
            'array.includes': intl.formatMessage({ id: 'val-array.includes'}),
            'array.includesRequiredUnknowns': intl.formatMessage({ id: 'val-array.includesRequiredUnknowns'}),
        }),
        sendToCustomer: Joi.bool(),
    }), [intl]);
    const columns = [
        {
          title: intl.formatMessage({ id: 'billed-on'}),
          dataIndex: 'billedOn',
          key: 'billedOn',
        },
        {
          title: intl.formatMessage({ id: 'total-amount'}),
          dataIndex: 'totalAmount',
          key: 'totalAmount',
        }
    ];

    // setter function to set form state
    const setFieldVal = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target[e.target.name === 'sendToCustomer' ? 'checked': 'value']
        })
    };

    // function to validate form field, with help of joi
    const validateForm = (payload: Omit<FormState, 'receivers'> & {receivers: string[]}) => {
        const { error } = schema.validate(payload, { abortEarly: false });
        setFormErrors(null);

        if (error) {
            // Extract all error messages with field names
            const errorMessages = error.details.map(detail => ({
              field: detail.path.join('.'),
              message: detail.message
            }));

            if (errorMessages?.length) {
                setFormErrors(errorMessages.reduce((acc, e) => {
                    return {
                        ...acc,
                        [e.field.split('.')[0]]: e.message
                    }
                }, {}));
            }

            return false;
        }

        return true;
    }

    const resetFormData = () => setFormData(emptyFormState);

    const onSubmit = async () => {
        try {
            const payload = {
                ...formData,
                receivers: formData.receivers.replace(/\s+/g, '').split(",")
            }

            if (!validateForm(payload)) {
                return false;
            }

            const resp: SendInvoiceApiResp =  await sendInvoice({
                domainId,
                ...payload,
                paymentsAfterEpoch: (new Date(payload.paymentsAfterEpoch)).getTime(),
                paymentsBeforeEpoch: (new Date(payload.paymentsBeforeEpoch)).getTime()
            }, token, region);

            setTableData(resp.invoiceDetails.map(i => ({
                billedOn: moment(i.billedOn).format('DD/MM/YYYY'),
                totalAmount: i.totalAmount
            })));

            resetFormData();
        } catch (err) {
            toast((
                (err as { message: string })?.message) ||
                intl.formatMessage({ id: 'invoice-send-failure'})
            );
        }
    };

    return (
        <div>
            <CloseGrey className="alert-modal-close-button" onClick={handleCloseClick} />
            <div className='p1'>
                <h2><FormattedMessage id="generate-invoice" /></h2>
                <div className='p1'>
                    <fieldset className="t-form-field mt2">
                        <FormattedMessage tagName="label" id="payment-after" />
                        <input
                            type="date"
                            max={today}
                            value={formData.paymentsAfterEpoch}
                            name="paymentsAfterEpoch"
                            onFocus={showPicker}
                            onChange={setFieldVal}
                        />
                        {formErrors?.paymentsAfterEpoch && <span>{formErrors?.paymentsAfterEpoch}</span>}
                    </fieldset>
                    <fieldset className="t-form-field mt2">
                        <FormattedMessage tagName="label" id="payment-before" />
                        <input
                            type="date"
                            max={today}
                            value={formData.paymentsBeforeEpoch}
                            name="paymentsBeforeEpoch"
                            onFocus={showPicker}
                            onChange={setFieldVal}
                        />
                        {formErrors?.paymentsBeforeEpoch && <span>{formErrors?.paymentsBeforeEpoch}</span>}
                    </fieldset>
                    <fieldset className="t-form-field mt2">
                        <FormattedMessage tagName="label" id="payment-receivers" />
                        <input
                            type="text"
                            name="receivers"
                            value={formData.receivers}
                            placeholder={intl.formatMessage({ id: 'payment-receivers-placeholder'})}
                            onChange={setFieldVal}
                        />
                        {formErrors?.receivers && <span>{formErrors?.receivers}</span>}
                    </fieldset>
                    <fieldset className="mt2">
                        <input
                            type="checkbox"
                            id="sendToCustomer"
                            name="sendToCustomer"
                            className="mr1"
                            onChange={setFieldVal}
                            checked={!!formData.sendToCustomer}
                        />
                        <label htmlFor='sendToCustomer'>{intl.formatMessage({ id: 'send-to-customer'})}</label>
                    </fieldset>
                    <div className="flex">
                    <button onClick={onSubmit} className="button button-primary refund-button">
                        <FormattedMessage id='submit' />
                    </button>
                    <button
                        className="button refund-button modal-cancel-button"
                        onClick={handleCloseClick}>
                        <FormattedMessage id='cancel' />
                    </button>
                </div>
                </div>
            </div>
            <div className='mt2'>
                { tableData ? <>
                    <h2><FormattedMessage id="invoice-records" /></h2>
                    <Table
                        pagination={paginationProps}
                        dataSource={tableData}
                        columns={columns}
                    />
                </> : null }
            </div>
        </div>
    )
}

export default GenerateInvoice;
