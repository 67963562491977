import { getTaskReport, getTasks } from "../service/bulkService";


export const fetchTasks =
  (filterType, filterValue, partnerId, authToken) => async () => {
    try {
      const tasksResponse = await getTasks(filterType, filterValue, partnerId, authToken);
      return tasksResponse;
    } catch (error) {
      console.error("Error in fetchTasks thunk:", error);
      throw new Error(error);
    }
  };

export const fetchTaskReport = (partnerId, iid, authToken) => async () => {
  try {
    const reportResponse = await getTaskReport(partnerId, iid, authToken);
    return reportResponse;
  } catch (error) {
    console.error("Error in fetchTaskReport thunk:", error);
    throw new Error(error);
  }
};
