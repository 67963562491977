import * as React from 'react';
import { UserDetail } from '../reducers/userManagementReducer';
import { isRoleAdmin, isRoleTitan } from "./utils";
import { RootState } from '../reducers';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";

export interface UserManagementDropdownProps {
    user: UserDetail,
    userRole: string,
    onClick: (buttonName: string) => void,
    handleClickOutside: () => void
}

interface DropDownOption {
    title: string | React.ReactElement,
    buttonName: string
}

export const USER_MANAGEMENT_DROPDOWN_OPTION = {
    EDIT: "edit",
    MAKE_ADMIN: "makeAdmin",
    REVOKE_ADMIN: "revokeAdmin",
    DELETE_ACCOUNT: "deleteAccount"
}

class UserManagementDropdown extends React.Component<UserManagementDropdownProps> {

    private node?: HTMLElement | null;

    constructor(props: UserManagementDropdownProps) {
        super(props)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleButtonClick = this.handleButtonClick.bind(this)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    handleClickOutside(e: any) {
        if (!this.node)
            return
        if (!this.node.contains(e.target)) {
            this.props.handleClickOutside()
        }
    }

    handleButtonClick = (buttonName: string) => {
        this.props.onClick(buttonName)
    }

    render() {
        const dropDownOptions: DropDownOption[] = []
        if (isRoleTitan(this.props.userRole)) {
            dropDownOptions.push({
                title: <FormattedMessage id='edit_account'/>,
                buttonName: USER_MANAGEMENT_DROPDOWN_OPTION.EDIT
            })
        } else {
            if (isRoleAdmin(this.props.user.role)) {
                dropDownOptions.push({
                    title: <FormattedMessage id='revoke_admin_rights'/>,
                    buttonName: USER_MANAGEMENT_DROPDOWN_OPTION.REVOKE_ADMIN
                })
            }
            else {
                dropDownOptions.push({
                    title: <FormattedMessage id='make_admin'/>,
                    buttonName: USER_MANAGEMENT_DROPDOWN_OPTION.MAKE_ADMIN
                })
            }
        }
        dropDownOptions.push({
            title: <FormattedMessage id='delete_account'/>,
            buttonName: USER_MANAGEMENT_DROPDOWN_OPTION.DELETE_ACCOUNT
        })
        const dropDownList = dropDownOptions.map(item => (
            <div className="dropdown-menu-item"
                onClick={() => this.handleButtonClick(item.buttonName)}>
                {item.title}
            </div>
        ))
        return (
            <div className="user-option-dropdown-container" ref={(node) => this.node = node}>
                {dropDownList}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        userRole: state.auth.userRole
    }
}

export default connect(mapStateToProps)(UserManagementDropdown);