import React, { Component } from "react";
import MainContent from "./MainContent";
import Topbar from "./Topbar";

class BodyComponent extends Component {
  state = {};

  render() {
    return (
      <div className="body-component">
        <Topbar />
        <MainContent />
      </div>
    );
  }
}

export default (BodyComponent);
