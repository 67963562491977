import { UserDetail, PartnerDetailsInfo, DeleteUserInfo, UpdateUserInfo, CreateUserInfo, PartnerInfo } from "../reducers/userManagementReducer";
import * as React from 'react';
import { RootState } from "../reducers";
import { bindActionCreators } from 'redux'
import { fetchUsers, fetchPartners, closeModal, deleteUser, updateUser } from '../actions/adminActions'
import { connect } from 'react-redux'
import { isRoleTitan, isRoleAdmin, ROLE, getPartnerIdsForUser } from './utils'
import { ReactComponent as Overflow } from "../assets/overflow-dot.svg"
import AddUserModal, { MODE } from './addUserModal'
import UserManagementDropdown, { USER_MANAGEMENT_DROPDOWN_OPTION } from "./userManagementDropdown";
import UpdateUserModal from "./updateUserModal";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import AddUserSuccessModal from "./AddUserSuccessModal";

type Props = {
    userList: UserDetail[],
    token: string,
    userRole: string,
    userPartners: PartnerInfo[],
    fetchUsers: (a: string) => void,
    fetchPartners: (a: string) => void,
    partnerDetails: PartnerDetailsInfo,
    closeModal: () => void,
    createUserInfo: CreateUserInfo,
    updateUserInfo: UpdateUserInfo,
    deleteUserDetails: DeleteUserInfo
    updateUser: (email: string, partnerIds: number[], role: string, token: string) => void
    deleteUser: (email: string, token: string) => void
} & WrappedComponentProps

class UserList extends React.PureComponent<Props> {

    constructor(props: Props) {
        super(props)
        this.handleAddUserClick = this.handleAddUserClick.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.handleOverflowButtonClick = this.handleOverflowButtonClick.bind(this)
        this.hideOverflowDropdown = this.hideOverflowDropdown.bind(this)
        this.deleteAccount = this.deleteAccount.bind(this)
    }

    ADD_USER_BUTTON = "addUser"

    state = {
        shouldShowModal: false,
        selectedUser: null,
        dropdownVisibilityInfo: this.props.userList.reduce((ac, a) => ({ ...ac, [a.emailId]: false }), {}),
        buttonClicked: ""
    }

    handleAddUserClick = () => {
        if (isRoleTitan(this.props.userRole) && this.props.partnerDetails.partnerList.length <= 0) {
            this.props.fetchPartners(this.props.token)
        }
        this.setState({
            shouldShowModal: true,
            buttonClicked: this.ADD_USER_BUTTON
        })
    }

    closeModal = (shouldRefetchUserList: boolean) => {
        this.setState({
            shouldShowModal: false
        })
        this.props.closeModal()
        if (shouldRefetchUserList) {
            this.props.fetchUsers(this.props.token)
        }
    }

    handleOverflowButtonClick = (user: UserDetail) => {
        const newDropdownState: any = {
            ...this.state.dropdownVisibilityInfo
        }
        newDropdownState[user.emailId] = !newDropdownState[user.emailId]
        this.setState({
            dropdownVisibilityInfo: newDropdownState,
            selectedUser: user
        })
    }

    hideOverflowDropdown = () => {
        const newDropdownState = { ...this.state.dropdownVisibilityInfo }
        Object.keys(newDropdownState).forEach(k => {
            (newDropdownState as any)[k] = false
        })
        this.setState({
            dropdownVisibilityInfo: newDropdownState
        })
    }

    handleOverflowDropdownOptionClick = (buttonName: string) => {
        this.hideOverflowDropdown()
        if (buttonName === USER_MANAGEMENT_DROPDOWN_OPTION.EDIT &&
            this.props.partnerDetails.partnerList.length <= 0) {
            this.props.fetchPartners(this.props.token)
        }
        this.setState({
            buttonClicked: buttonName,
            shouldShowModal: true
        })
    }

    updateUser = (makeAdmin: boolean) => {
        if (this.state.selectedUser === null)
            return
        else {
            const user = this.state.selectedUser as unknown as UserDetail
            let newRole = user.role
            if (makeAdmin) {
                if (user.role === ROLE.PARTNER) {
                    newRole = ROLE.PARTNER_ADMIN
                }
                else if (user.role === ROLE.TITAN) {
                    newRole = ROLE.TITAN_ADMIN
                }
            }
            else {
                if (user.role === ROLE.PARTNER_ADMIN) {
                    newRole = ROLE.PARTNER
                }
                else if (user.role === ROLE.TITAN_ADMIN) {
                    newRole = ROLE.TITAN
                }
            }
            this.props.updateUser(user.emailId, getPartnerIdsForUser(user), newRole, this.props.token)
        }
    }

    deleteAccount = () => {
        if (this.state.selectedUser === null)
            return
        else {
            const user = this.state.selectedUser as unknown as UserDetail
            this.props.deleteUser(user.emailId, this.props.token)
        }
    }

    compareAccountPriority = (u1: UserDetail, u2: UserDetail) => {
        const partnerName1 = u1?.partners[0]?.partnerName
        const partnerName2 = u2?.partners[0]?.partnerName
        if (partnerName1 === partnerName2) {
            const isUser1Admin = isRoleAdmin(u1.role)
            const isUser2Admin = isRoleAdmin(u2.role)
            if (isUser1Admin && !isUser2Admin) return -1;
            else if (!isUser1Admin && isUser2Admin) return 1;
            else return u1.emailId.localeCompare(u2.emailId)
        }
        return partnerName1?.localeCompare(partnerName2)
    }

    render() {
        const selectedUser = this.state.selectedUser as unknown as UserDetail
        this.props.userList.sort(this.compareAccountPriority)
        const userDetailsRow = this.props.userList.map((user: UserDetail) => {
        const partners = user.partners.map(partnerInfo => partnerInfo?.partnerName).join(',')
            return (<tr>
                <td className="user-list-email">
                    <div className="user-list-email-container">
                        <div className="user-list-email-text">
                            {user.emailId}
                        </div>
                        {isRoleAdmin(user.role) ? <div className="user-list-admin"> ADMIN </div> : ""}
                    </div>
                </td>
                {isRoleTitan(this.props.userRole) &&
                    <td className="user-list-center-text">
                        {partners.length > 30 ?
                            <div className="tooltip">
                                {partners.substr(0, 30) + '...'}
                                <span className="tooltiptext">
                                    {partners}
                                </span>
                            </div>
                            :
                            partners}
                    </td>
                }
                <td className="user-list-table-button-container">
                    <div className="overflow-button-container">
                        {this.props.partnerDetails.isPartnersInfoFetchInProgress &&
                            selectedUser != null &&
                            this.state.buttonClicked === USER_MANAGEMENT_DROPDOWN_OPTION.EDIT &&
                            user.emailId === selectedUser.emailId ? <div className="spinner-action"></div> : ""}
                        <Overflow className="overflow-button"
                            onClick={() => this.handleOverflowButtonClick(user)} />
                        {(this.state.dropdownVisibilityInfo as any)[user.emailId] ?
                            <UserManagementDropdown
                                user={user}
                                handleClickOutside={this.hideOverflowDropdown}
                                onClick={this.handleOverflowDropdownOptionClick} /> : ""}
                    </div>
                </td>
            </tr>
            )
        })
        const isPartnerFetchSuccessful = isRoleTitan(this.props.userRole) ? this.props.partnerDetails.isPartnerInfoFetchSuccess : true
        let showModal = (this.state.buttonClicked === this.ADD_USER_BUTTON ||
            this.state.buttonClicked === USER_MANAGEMENT_DROPDOWN_OPTION.EDIT) ?
            this.state.shouldShowModal && isPartnerFetchSuccessful : this.state.shouldShowModal
        showModal = showModal || this.props.createUserInfo.isCreateUserRequestSuccess != null;
        const buttonText = this.state.buttonClicked === this.ADD_USER_BUTTON &&
            this.props.partnerDetails.isPartnersInfoFetchInProgress
            ? <div className="add-user-button-div"><FormattedMessage id='add_new_user' /> <div className="add-user-spinner" /></div>
            : <FormattedMessage id='add_new_user' />
        let modalContent, modalContentCss = "";
        switch (this.state.buttonClicked) {
            case USER_MANAGEMENT_DROPDOWN_OPTION.MAKE_ADMIN: {
                modalContent = <UpdateUserModal
                    heading={this.props.intl.formatMessage({ id: "make_admin" })}
                    subheading={(this.state.selectedUser as any).emailId}
                    description={this.props.intl.formatMessage({ id: "admin-rights-enabled-for-user" })}
                    isLoadingInProgress={this.props.updateUserInfo.isUpdateUserRequestInProgress}
                    shouldShowError={this.props.updateUserInfo.updateUserRequestError.length > 0}
                    shouldShowSuccess={this.props.updateUserInfo.isUpdateUserRequestSuccess}
                    buttonAction={() => this.updateUser(true)}
                    errorText={this.props.updateUserInfo.updateUserRequestError}
                    closeAction={this.closeModal} />
                break;
            }
            case USER_MANAGEMENT_DROPDOWN_OPTION.REVOKE_ADMIN: {
                modalContent = <UpdateUserModal
                    heading="Revoke admin rights"
                    subheading={(this.state.selectedUser as any).emailId}
                    description={this.props.intl.formatMessage({ id: "admin-rights-revoked-for-user" })}
                    isLoadingInProgress={this.props.updateUserInfo.isUpdateUserRequestInProgress}
                    shouldShowError={this.props.updateUserInfo.updateUserRequestError.length > 0}
                    shouldShowSuccess={this.props.updateUserInfo.isUpdateUserRequestSuccess}
                    buttonAction={() => this.updateUser(false)}
                    errorText={this.props.updateUserInfo.updateUserRequestError}
                    closeAction={this.closeModal} />
                break;
            }
            case USER_MANAGEMENT_DROPDOWN_OPTION.DELETE_ACCOUNT: {
                modalContent = <UpdateUserModal
                    heading={this.props.intl.formatMessage({ id: "delete_account" })}
                    subheading={(this.state.selectedUser as any).emailId}
                    description={this.props.intl.formatMessage({ id: "delete-user-account-modal-message" })}
                    isLoadingInProgress={this.props.deleteUserDetails.isDeleteUserRequestInProgress}
                    shouldShowError={this.props.deleteUserDetails.deleteUserError.length > 0}
                    shouldShowSuccess={this.props.deleteUserDetails.isDeleteUserRequestSuccess}
                    buttonAction={this.deleteAccount}
                    errorText={this.props.deleteUserDetails.deleteUserError}
                    closeAction={this.closeModal} />
                break;
            }
            case USER_MANAGEMENT_DROPDOWN_OPTION.EDIT: {
                modalContentCss = "add-user-modal"
                modalContent = <AddUserModal
                    currentUserPartnerIds={[]}
                    mode={MODE.EDIT}
                    user={this.state.selectedUser}
                    closeModal={this.closeModal} />
                break;
            }
            case this.ADD_USER_BUTTON: {
                const addUsersResponse = this.props.createUserInfo.isCreateUserRequestSuccess
                if (addUsersResponse != null) {
                    modalContent = <AddUserSuccessModal
                        closeModal={this.closeModal}
                        numberOfInvitesSent={addUsersResponse && addUsersResponse.invitesSent || 0}
                        numberOfInvitesNotSent={addUsersResponse && addUsersResponse.invitesFailed || 0}
                    />
                    modalContentCss = "add-user-success-modal"
                } else {
                    modalContent = <AddUserModal
                        currentUserPartnerIds={!isRoleTitan(this.props.userRole) ? this.props.userPartners.map(partnerInfo => partnerInfo.partnerId) : []}
                        mode={MODE.CREATE}
                        user={null}
                        closeModal={this.closeModal} />
                    modalContentCss = "add-user-modal"
                }
                break;
            }
        }
        return <div className="user-list-container">
            <div className="user-list-manage-accounts">
                <div><FormattedMessage id='manage_accounts' /></div>
                <div>
                    <button
                        className="button button-primary add-user-button"
                        onClick={this.handleAddUserClick}>
                        {buttonText}
                    </button>
                </div>
            </div>
            <div className="user-list-table-container">
                <table className="user-list-table">
                    <tr>
                        <th className="user-list-email"><FormattedMessage id='email' /></th>
                        {isRoleTitan(this.props.userRole) ? <th className="user-list-center-text"><FormattedMessage id='partner' /></th> : ""}
                        <th />
                    </tr>
                    {userDetailsRow}
                </table>
            </div>
            {(showModal ?
                <div className="modal">
                    <div className={"modal-content " + modalContentCss}>
                        {modalContent}
                    </div>
                </div>
                : "")}
        </div>
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        token: state.auth.token,
        userList: state.users.userDetails.userList,
        userRole: state.auth.userRole,
        userPartners: state.auth.userPartners,
        partnerDetails: state.users.partnerDetails,
        updateUserInfo: state.users.updateUserDetails,
        deleteUserDetails: state.users.deleteUserDetails,
        createUserInfo: state.users.createUserDetails,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ fetchUsers, fetchPartners, closeModal, updateUser, deleteUser }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserList));