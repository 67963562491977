import React from 'react';
import { FormattedMessage } from "react-intl";
import { Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { ReactComponent as NavigateBack } from "../../assets/navigate_back.svg";
import { getFormattedDate } from '../../utils/helper';
import { HELP_ARTICLE_URLS } from '../../utils/const';

const TaskDetails = ({
  selectedTask,
  handleBack,
  handleViewReport,
  reportLoading,
  reportUrl,
  reportError,
  classes,
}) => {
  return (
    <div className="order-details-column">
      <div className={classes.taskHeader}>
        <NavigateBack onClick={handleBack} className={classes.backButton} />
        <div className="order-details-column-header-text">
          <FormattedMessage id="task-information" />
        </div>
        <div className="rectangle"></div>
      </div>
      <div className="order-info-box">
        <div className="order-details-item">
          <div className="order-details-item-text">
            <FormattedMessage id="name" />
          </div>
          <div className="order-details-item-text">{selectedTask.taskName}</div>
        </div>
        <div className="order-details-item">
          <div className="order-details-item-text">
            <FormattedMessage id="task-id-dropdown" />
          </div>
          <div className="order-details-item-text">{selectedTask.iid}</div>
        </div>
        <div className="order-details-item">
          <div className="order-details-item-text">
            <FormattedMessage id="initiated-by-info" />
          </div>
          <div className="order-details-item-text">
            {selectedTask.requester}
          </div>
        </div>
        <div className="order-details-item">
          <div className="order-details-item-text">
            <FormattedMessage id="creation-date" />
          </div>
          <div className="order-details-item-text">
            {getFormattedDate(selectedTask.createdAt)}
          </div>
        </div>
        <div className="order-details-item">
          <div className="order-details-item-text">
            <FormattedMessage id="number-of-domain-in-list" />
          </div>
          <div className="order-details-item-text">
            {selectedTask.totalOrders}
          </div>
        </div>
        <div className="order-details-item">
          <div className="order-details-item-text">
            <FormattedMessage id="number-of-mailboxes-in-list" />
          </div>
          <div className="order-details-item-text">
            {selectedTask.totalAccounts}
          </div>
        </div>
        <div className="order-details-item">
          <div className="order-details-item-text">
            <FormattedMessage id="order-details-status" />
          </div>
          <div className="order-details-item-text">{selectedTask.status}</div>
        </div>
        <div className="domain-info-buttons-container">
          <button
            className="button button-secondary"
            onClick={handleViewReport}
            disabled={reportLoading}
          >
            {reportLoading ? (
              <div className="button-loader" />
            ) : (
              <FormattedMessage id="view-progress-error-report" />
            )}
          </button>
        </div>
        {reportUrl && (
          <div className={classes.downloadReport}>
            <a href={reportUrl} target="_blank">
              <FormattedMessage id="download-report" />
            </a>
          </div>
        )}
        {reportError && (
          <div className={classes.errorMessage}>{reportError}</div>
        )}
        <div className={classes.articleLink}>
          <a
            href={HELP_ARTICLE_URLS.MIGRATION_TOOLKIT_FAQ}
            target="_blank"
          >
            <FormattedMessage id="help-article" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(TaskDetails);
