import React, { ReactElement } from 'react';
import { connect } from "react-redux";
import { RootState } from '../reducers';
import { bindActionCreators } from 'redux';
import { ENTITY_TYPE, disableSuspension, getActorRoleString, getActiveAccountsForCustomerOrders, getPaidAccountsForCustomerOrders, getEntityTypeFromSuspensionType, REGION, unwrapSuspensionReason, isRoleTitan } from './utils';
import { removeSuspensions, removeCustomerSuspensions } from '../actions/orderActions'
import { ReactComponent as Success } from "../assets/suspend-success-tick.svg"
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import { ReactComponent as Alert } from "../assets/action_history_alert.svg"
import { suspensionListInfoType } from '../model/suspensionList';
import { CustomerSuspensionSource, CustomerSuspensionParams, RemoveCustomerSuspensionPayload } from '../model/customerSuspensionDetails';
import { toTitleCase } from './utils';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

export interface UnsuspensionDetailsProps {
    token: string,
    userRole: string,
    suspensionListInfo: suspensionListInfoType,
    isUnsuspensionInProgress: boolean,
    isUnsuspensionSuccess: boolean,
    unsuspensionError: string,
    customerSuspensionOrderInfo: CustomerSuspensionParams,
    customerIdRegionDetails: any,
    removeSuspensions: (queryParams: any, token: string, suspensionType: string, region: string) => any,
    removeCustomerSuspensions: (requestBody: RemoveCustomerSuspensionPayload, token: string) => void,
    isCustomerSuspended: boolean
}

export interface ParentComponentProps {
    suspensionType: ENTITY_TYPE,
    domainId: Number,
    domainName: string,
    accountId: Number,
    closeModal: (...args: any[]) => void,
    email: string | null,
    source: CustomerSuspensionSource | null,
    currentRegion?: string
}

type Props = UnsuspensionDetailsProps & ParentComponentProps & WrappedComponentProps

class Unsuspension extends React.Component<Props> {

    state = {
        note: "",
        headerBoxChecked: false,
    }

    private timeoutId?: NodeJS.Timeout | null

    constructor(props: Props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCloseClick = this.handleCloseClick.bind(this)
        this.getSuspensionDetailsList = this.getSuspensionDetailsList.bind(this)
        this.getCustomerOrders = this.getCustomerOrders.bind(this)
        this.getAlertDivForUnsuspension = this.getAlertDivForUnsuspension.bind(this)
        this.isUnsuspensionBlocked = this.isUnsuspensionBlocked.bind(this)
        this.createUnsuspensionText = this.createUnsuspensionText.bind(this)
    }

    componentDidUpdate() {
        if (this.props.isUnsuspensionSuccess) {
            this.timeoutId = setTimeout(() => this.handleCloseClick(), 1000)
        }
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }

    handleNoteChange = (item: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            note: item.target.value
        })
    }

    getSuspensionDetailsList() {
        switch (this.props.suspensionType) {
            case ENTITY_TYPE.DOMAIN: return this.props.suspensionListInfo.domain.suspensionList;
            case ENTITY_TYPE.ACCOUNT: return this.props.suspensionListInfo.account.suspensionList;
            case ENTITY_TYPE.CUSTOMER: {
                let newSuspensionList: any[] = []
                this.props.suspensionListInfo.customer.suspensionList.forEach(s => newSuspensionList.push(...s.details))
                return newSuspensionList
            }
        }
    }

    getCustomerOrders() {
        return this.props.customerSuspensionOrderInfo.orderInfo.orders
    }

    handleSubmit = () => {
        if (this.props.isUnsuspensionSuccess) {
            this.handleCloseClick()
            return
        }
        const suspensionTypes: Array<string> = [];
        this.getSuspensionDetailsList().forEach((item) => {
            if (item.isChecked) {
                suspensionTypes.push(item.suspensionType)
            }
        })
        if (this.props.suspensionType === ENTITY_TYPE.CUSTOMER) {
            let selectedUserIds: number[] = []
            this.props.suspensionListInfo.customer.suspensionList.forEach(suspensionInfo => {
                suspensionInfo.details.forEach((detail: any) => {
                    if (detail.isChecked) {
                        selectedUserIds.push(suspensionInfo.userId)
                    }
                })
            })
            selectedUserIds = Array.from(new Set(selectedUserIds))
            const userIds_US = selectedUserIds.filter(userId => this.props.customerIdRegionDetails[userId] === REGION.US)
            const userIds_EU = selectedUserIds.filter(userId => this.props.customerIdRegionDetails[userId] === REGION.EU)
            const suspensionType_US: Array<string> = []
            const suspensionType_EU: Array<string> = []
            this.props.suspensionListInfo.customer.suspensionList.forEach(suspensionInfo => {
                suspensionInfo.details.forEach((detail: any) => {
                    if (detail.isChecked && this.props.customerIdRegionDetails[suspensionInfo.userId] === REGION.US) {
                        suspensionType_US.push(detail.suspensionType)
                    }
                    else if (detail.isChecked && this.props.customerIdRegionDetails[suspensionInfo.userId] === REGION.EU) {
                        suspensionType_EU.push(detail.suspensionType)
                    }
                })
            })
            const customerSuspensionDetails_US = {
                "userIds": userIds_US,
                "suspensionTypes": suspensionType_US
            }

            const customerSuspensionDetails_EU = {
                "userIds": userIds_EU,
                "suspensionTypes": suspensionType_EU
            }
            let customerSuspensionsDetailsMap: any = {}
            if (userIds_US.length !== 0 && suspensionType_US.length !== 0) {
                customerSuspensionsDetailsMap[REGION.US] = customerSuspensionDetails_US
            }
            if (userIds_EU.length !== 0 && suspensionType_EU.length !== 0) {
                customerSuspensionsDetailsMap[REGION.EU] = customerSuspensionDetails_EU
            }
            const requestBody: RemoveCustomerSuspensionPayload = {
                suspensionDetails: customerSuspensionsDetailsMap,
                note: this.state.note
            }
            this.props.removeCustomerSuspensions(requestBody, this.props.token)
        }
        else {
            const payload: any = {
                "domainId": this.props.domainId,
                "suspensionTypes": suspensionTypes,
                "note": this.state.note,
            }
            if (this.props.suspensionType === ENTITY_TYPE.ACCOUNT) {
                payload["accountId"] = this.props.accountId
            }
            this.props.removeSuspensions(payload, this.props.token, this.props.suspensionType, this.props.currentRegion ? this.props.currentRegion : "")
        }
    }

    handleCloseClick() {
        if (this.props.isUnsuspensionSuccess) {
            if (this.props.suspensionType === ENTITY_TYPE.CUSTOMER && this.props.source === CustomerSuspensionSource.OrderList) {
                this.props.closeModal(this.props.email)
            }
            else {
                this.props.closeModal(this.props.domainId, this.props.accountId)
            }
        }
        else {
            this.props.closeModal(null, null)
        }
    }

    toggleAllBoxes = () => {
        const newValue = !this.state.headerBoxChecked
        this.setState({
            headerBoxChecked: newValue
        })
        const suspensionList = this.getSuspensionDetailsList()
        if (suspensionList) {
            suspensionList.forEach((item) => {
                if (!disableSuspension(this.props.userRole, item.role)) {
                    item.isChecked = newValue
                }
            })
        }
    }

    toggleSelected = (item: any) => {
        item.isChecked = !item.isChecked
        this.setState({
            headerBoxChecked: this.getSuspensionDetailsList().every(item => item.isChecked)
        })
    }

    getAlertDivForUnsuspension = () => {
        return (
            <div className="alert-container-unsuspension">
                <div className="alert-container-icon">
                    <Alert />
                </div>
                <span className="alert-container-text">
                    <FormattedMessage id="action-blocked-customer-suspended"
                        values={{ entity: getEntityTypeFromSuspensionType(this.props.suspensionType) }} />
                </span>
            </div>
        )
    }

    isUnsuspensionBlocked = () => {
        return this.props.isCustomerSuspended && this.props.suspensionType !== ENTITY_TYPE.CUSTOMER
    }

    createUnsuspensionText = () => {
        switch (this.props.suspensionType) {
            case ENTITY_TYPE.ACCOUNT: return toTitleCase(this.props.intl.formatMessage({ id: "unsuspend-account" }))
            case ENTITY_TYPE.DOMAIN: return toTitleCase(this.props.intl.formatMessage({ id: "unsuspend-domain" }))
            case ENTITY_TYPE.CUSTOMER: return toTitleCase(this.props.intl.formatMessage({ id: "unsuspend-customer" }))
        }
    }

    render() {
        const suspensionType = this.props.suspensionType
        const modalSubtitle = suspensionType === ENTITY_TYPE.DOMAIN ? `@${this.props.domainName}` : this.props.email
        const suspensionList = this.getSuspensionDetailsList()
        let disableUnsuspendButton = !suspensionList.some(item => item.isChecked) ||
            this.props.isUnsuspensionInProgress || this.isUnsuspensionBlocked();
        const unsuspensionNotePlaceholder = this.props.intl.formatMessage({ id: "unsuspension-note-placeholder" })
        let unsuspensionFooterNote
        if (suspensionList.every(item => item.isChecked) || !suspensionList.some(item => item.isChecked)) {
            unsuspensionFooterNote = ""
        }
        else {
            unsuspensionFooterNote = this.props.intl.formatMessage({ id: "unsuspension-footer-note-other-suspension-present" },
                { entity: getEntityTypeFromSuspensionType(this.props.suspensionType) })
        }
        let unsuspendButtonText: string | ReactElement = this.props.intl.formatMessage({ id: "unsuspend" })
        if (this.props.isUnsuspensionInProgress) {
            unsuspendButtonText = <div className="modal-button-div">
                <div className="modal-spinner" />
                <FormattedMessage id="unsuspending" />
            </div>
        }
        else if (this.props.isUnsuspensionSuccess) {
            unsuspendButtonText = <div className="modal-button-div">
                <Success className="modal-button-success-icon" />
                <FormattedMessage id="unsuspended" />
            </div>
        }
        const areAllSuspensionsBlocked = suspensionList.every(item => { return disableSuspension(this.props.userRole, item.role) })
        const visibilityStyle = {
            visibility: areAllSuspensionsBlocked ? "hidden" : "visible"
        } as React.CSSProperties
        const tableHeaders = [];
        tableHeaders.push(<input type='checkbox' checked={this.state.headerBoxChecked}
            onClick={() => this.toggleAllBoxes()} className="unsuspension-checkbox-header"
            style={visibilityStyle} />)
        tableHeaders.push(<th className="unsuspension-table-header-user">
            <FormattedMessage id="user" />
        </th>)
        tableHeaders.push(<th className="unsuspension-table-header-reason">
            <FormattedMessage id="reason" />
        </th>)

        const suspensionsList = suspensionList ? suspensionList : []
        const allSuspensions = suspensionsList.map((item: any) => {
            const disableRow = disableSuspension(this.props.userRole, item.role)
            const disabledNote = this.props.intl.formatMessage({ id: "contact-titan-support-to-unsuspend" })
            const suspensionRoleString = getActorRoleString(item.role, this.props.userRole)
            const suspensionTypeString = this.props.intl.formatMessage({ id: unwrapSuspensionReason(item.suspensionType) })
            const note = item.notes.filter((note: string) => note).join("\n--\n")
            const isTitanUser = isRoleTitan(this.props.userRole);
            return (
                <tr className={disableRow ? "unsuspension-table-data-row-disabled" : "unsuspension-table-data-row-enabled"}>
                    <td className="unsuspension-checkbox">
                        <input type='checkbox' checked={item.isChecked}
                            onClick={() => this.toggleSelected(item)} className={disableRow ? "checkbox-hidden" : "checkbox-shown"} />
                    </td>
                    <td>
                        <div className="unsuspension-table-cell unsuspension-table-cell-email">
                            <text className="unsuspension-table-data-heading">
                                {suspensionRoleString}
                            </text>
                            <text className="unsuspension-table-data-subheading">
                                {disableRow ? "" : item.userEmail}
                            </text>
                        </div>
                    </td>
                    <td>
                        <div className="unsuspension-table-cell">
                            <text className="unsuspension-table-data-heading">
                                {suspensionTypeString}
                            </text>
                            {isTitanUser && <div className="unsuspension-table-data-subheading">
                                {disableRow ? disabledNote : note}
                            </div>}
                        </div>
                    </td>
                </tr>
            )
        })
        return (
            <div>
                <CloseGrey className="alert-modal-close-button" onClick={this.handleCloseClick} />
                <div className="unsuspension-container">
                    <div className="suspension-heading">{this.createUnsuspensionText()}</div>
                    <div className="suspension-subheading">{modalSubtitle}</div>
                    {this.props.suspensionType === ENTITY_TYPE.CUSTOMER ?
                        <div className="customer-suspension-order-summary-unsuspension">
                            <div className="customer-suspension-order-summary-heading">
                                <FormattedMessage id="orders-header" />
                            </div>
                            <div className="customer-suspension-order-summary-value">
                                {this.getCustomerOrders().length}
                            </div>
                            <div className="customer-suspension-order-summary-heading">
                                <FormattedMessage id="active-accounts-header" />
                            </div>
                            <div className="customer-suspension-order-summary-value">
                                {getActiveAccountsForCustomerOrders(this.getCustomerOrders())}
                            </div>
                            <div className="customer-suspension-order-summary-heading">
                                <FormattedMessage id="paid-accounts-header" />
                            </div>
                            <div className="customer-suspension-order-summary-value">
                                {getPaidAccountsForCustomerOrders(this.getCustomerOrders())}
                            </div>
                        </div> : ""}
                    <div className="unsuspension-list-heading">
                        <FormattedMessage id="suspensions-to-undo" />
                    </div>
                    {this.isUnsuspensionBlocked() ?
                        this.getAlertDivForUnsuspension()
                        : ""}
                    <div className="unsuspension-table">
                        <table>
                            <tr className="unsuspension-table-header-row">{tableHeaders}</tr>
                            {allSuspensions}
                        </table>
                    </div>
                    <textarea
                        className="unsuspension-note"
                        placeholder={unsuspensionNotePlaceholder}
                        onChange={this.handleNoteChange}
                        value={this.state.note} />
                    <div className="suspension-button-container">
                        <button
                            className="button button-primary suspension-button"
                            disabled={disableUnsuspendButton}
                            onClick={this.handleSubmit}>
                            {unsuspendButtonText}
                        </button>
                        <button
                            className="button suspension-button modal-cancel-button"
                            onClick={this.handleCloseClick}>
                            <FormattedMessage id="cancel" />
                        </button>
                    </div>
                    <div className="unsuspension-footer-note">{unsuspensionFooterNote}</div>
                    {this.props.unsuspensionError ? <div className="suspension-error">{this.props.unsuspensionError} </div> : ""}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        token: state.auth.token,
        userRole: state.auth.userRole,
        //TODO - Convert detailsReducer to ts with correct types
        suspensionListInfo: (state.orders as any).suspensionListInfo,
        isUnsuspensionInProgress: (state.orders as any).isUnsuspensionInProgress,
        isUnsuspensionSuccess: (state.orders as any).isUnsuspensionSuccess,
        unsuspensionError: (state.orders as any).unsuspensionError,
        customerSuspensionOrderInfo: (state.orders as any).customerSuspensionOrderInfo,
        isCustomerSuspended: (state.orders as any).isCustomerSuspendedOrderDetails,
        customerIdRegionDetails: (state.orders as any).customerIdRegionDetails
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ removeSuspensions, removeCustomerSuspensions }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Unsuspension));