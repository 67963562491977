const styles = () => ({
  dropdown: {
    marginBottom: "14px",
    minWidth: "200px",
    fontSize: "14px",
  },
  selectedItem: {
    fontSize: '14px',
  },
  menuItem: {
    fontSize: "14px",
  },
});

export default styles;
