const styles = () => ({
  accordion: {
    marginBottom: "20px",
    borderRadius: "4px",
    "&:before": {
      display: "none",
    },
    boxShadow: "none",
    border: "1px solid #ccc",
  },

  accordionTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
});

export default styles;
