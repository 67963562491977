import React, { Component } from 'react';
import moment from "moment"
import { ReactComponent as Green } from "../assets/oval_green.svg"
import { ReactComponent as Red } from "../assets/oval_red.svg"
import { getDomainType, ORDER, isRoleTitan, capitalize } from "./utils"
import { FormattedMessage } from 'react-intl';


class OrderListItem extends Component {
    state = {}
    constructor(props) {
        super(props);
        this.getStatusLogo = this.getStatusLogo.bind(this);
    }

    getStatusLogo = (status) => {
        if (status.toLowerCase() === "active") {
            return (<div><Green className="state-logo" /> {status} </div>)
        } else {
            return (<div><Red className="state-logo" /> {status} </div>)
        }
    }

    render() {
        const order = this.props.order;
        let source
        if (this.props.orderType === ORDER.DOMAIN) {
            source = order.source ? <span>{order.source + " : "}</span> : ""
        } else if (this.props.orderType === ORDER.CUSTOMER) {
            source = <span className="order-column-domain">{order.domainName + " : "}</span>
        }
        const domainType = <span>{getDomainType(order.domainType)}</span>
        const customerId = <span>{" : " + order.customerId}</span>
        const sourceValue = <div> {source} {domainType} {customerId} </div>
        let status = capitalize(order.status);
        status = this.getStatusLogo(status);
        let creationDate = order.createdOn;
        let noOfAccsCreated = order.noOfAccountsCreated;
        let maxNoOfAccs = order.maxNoOfAccounts;
        let noOfAccs = noOfAccsCreated + " / " + maxNoOfAccs;
        creationDate = moment(creationDate, "YYYY-MM-DD").format("Do MMM YYYY");
        let columns = [];
        if (isRoleTitan(this.props.userRole) || this.props.accessToMultiplePartners) {
            columns.push(<td className="order-column-partner">{order.partnerName}</td>)
        }
        columns.push(<td className="order-column-source">{sourceValue}</td>)
        columns.push(<td className="order-column-accounts">{noOfAccs}</td>)
        columns.push(<td className="order-column-creation-date">{creationDate}</td>)
        columns.push(<td className="order-column-status">{status}</td>)
        columns.push(<td>
            <button
                className="button button-secondary orders-table-button"
                loading={this.props.order.isOrderDetailsLoading}
                disabled={this.props.order.isOrderDetailsLoading}
                onClick={() => this.props.onViewClicked(this.props.index)}>
                <FormattedMessage id="order-list-button-text" />
            </button>
        </td>)
        return (
            <tr className={this.props.isLastOrderInList ? "order-list-row no-border-order-list-row" : "order-list-row"}>
                {columns}
            </tr>
        );
    }
}

export default OrderListItem;