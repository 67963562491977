import { getRedisCachedMailbox, removeEmailFromRedisCache } from "../service/logsService";


// Action Types
export const FETCH_REDIS_CACHED_MAILBOX_REQUEST = "FETCH_REDIS_CACHED_MAILBOX_REQUEST";
export const FETCH_REDIS_CACHED_MAILBOX_SUCCESS = "FETCH_REDIS_CACHED_MAILBOX_SUCCESS";
export const FETCH_REDIS_CACHED_MAILBOX_ERROR = "FETCH_REDIS_CACHED_MAILBOX_ERROR";
export const CLEAR_REDIS_CACHED_MAILBOX_REQUEST = "CLEAR_REDIS_CACHED_MAILBOX_REQUEST";

// Action Creators
const fetchRedisCachedMailboxRequest = () => ({
  type: FETCH_REDIS_CACHED_MAILBOX_REQUEST
});

const fetchRedisCachedMailboxSuccess = (data) => ({
  type: FETCH_REDIS_CACHED_MAILBOX_SUCCESS,
  payload: data
});

const fetchRedisCachedMailboxError = (error) => ({
  type: FETCH_REDIS_CACHED_MAILBOX_ERROR,
  payload: error
});

export const clearRedisCachedMailboxRequest = () => ({
  type: CLEAR_REDIS_CACHED_MAILBOX_REQUEST
});

// Thunks
export const fetchRedisCachedMailbox = (fetchLogsQueryParams, authToken) => {
  return (dispatch) => {
    dispatch(fetchRedisCachedMailboxRequest());
    getRedisCachedMailbox(fetchLogsQueryParams, authToken)
      .then((response) => {
        dispatch(fetchRedisCachedMailboxSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchRedisCachedMailboxError(error));
      });
  };
};

export const removeEmailFromCache = (email, authToken) => async () => {
  try {
    const data = await removeEmailFromRedisCache(email, authToken);
    return data;
  } catch (error) {
    console.error("Error in removeEmailFromCache thunk:", error);
    throw new Error(error);
  }
};
