import React from "react";
import { dateTimeFormat } from "../../utils/helper";
import { CopyButton } from "../CopyButton";
import { copyToClipboard } from "../utils";
import { IP_TYPE } from ".";

type IpType = typeof IP_TYPE[keyof typeof IP_TYPE];

interface LogDetailsRowProps {
  item: Record<string, any>;
  type: IpType;
}

const LogDetailsRow: React.FC<LogDetailsRowProps> = ({ item, type }) => {
  
  return (
    <tr>
      <td>{item.ip}</td>
      <td className="log-details-time">
        {type === IP_TYPE.REJECT
          ? dateTimeFormat(item.dateTime)
          : dateTimeFormat(item.created)}
      </td>
      {type === IP_TYPE.BLOCK && (
        <td className="log-details-time">{dateTimeFormat(item.expiry)}</td>
      )}
      {type === IP_TYPE.BLOCK && <td>{item.action}</td>}
      {type === IP_TYPE.REJECT && <td>{item.info}</td>}
      <td>{item.direction}</td>
      <td>{item.reason}</td>
      <td className="copy-button">
        <CopyButton onClick={() => copyToClipboard(JSON.stringify(item))} />
      </td>
    </tr>
  );
};

export default LogDetailsRow;
