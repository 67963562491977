import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { logoutUser, initiateResetPassword, changeNavbarOption } from "../actions/authActions";
import { cancelLogsRequest } from "../actions/logsActions";
import { clearRedisCachedMailboxRequest } from "../actions/redisCachedMailboxActions";
import { cancelOrdersRequest } from "../actions/orderActions"
import { ReactComponent as TitanLogo } from "../assets/titan-navbar.svg"
import { ReactComponent as CaretDown } from "../assets/caret-down.svg"
import { isRoleAdmin } from "./utils";
import { RootState } from "../reducers";
import { NavBarOptionValue, NavBarDisplayValue } from "../model/navbarOptions";
import { fetchUsers, clearUserList } from "../actions/adminActions"
import { FormattedMessage } from "react-intl";
import { getMigrationRelevantPartnerIds } from "../utils/bulk-migration";

interface NavBarOption {
  optionName: string,
  displayValue: string
}

interface Partner {
  partnerName: string;
  partnerId: number;
}

interface Props {
  token: string,
  email: string,
  userRole: string,
  userPartners: Partner[],
  changeNavbarOption: (a: string) => void,
  initiateResetPassword: () => void,
  logoutUser: () => void,
  cancelOrdersRequest: () => void,
  cancelLogsRequest: () => void,
  clearRedisCachedMailboxRequest: () => void,
  fetchUsers: (a: string) => void,
  clearUserList: () => void
}

interface State {
  isPopOverVisible: boolean,
  selectedNavbarOptionIndex: number
}

class NavBar extends React.Component<Props> {

  state: State = {
    isPopOverVisible: false,
    selectedNavbarOptionIndex: 0
  }


  navbarOptions: NavBarOption[] = [
    { optionName: NavBarOptionValue.ORDERS, displayValue: NavBarDisplayValue.ORDERS },
    { optionName: NavBarOptionValue.LOGS, displayValue: NavBarDisplayValue.LOGS },
    { optionName: NavBarOptionValue.ADMIN, displayValue: NavBarDisplayValue.ADMIN },
    { optionName: NavBarOptionValue.CREATE_TASK, displayValue: NavBarDisplayValue.CREATE_TASK },
    { optionName: NavBarOptionValue.MONITOR_TASK, displayValue: NavBarDisplayValue.MONITOR_TASK }
  ]

  constructor(props: Props) {
    super(props);
    this.togglePopOverVisibility = this.togglePopOverVisibility.bind(this)
    this.updateNavbarIndex = this.updateNavbarIndex.bind(this)
    this.getFilteredNavbarOptions = this.getFilteredNavbarOptions.bind(this)
  }

  updateNavbarIndex = (index: number) => {
    if (this.state.selectedNavbarOptionIndex !== index) {
      this.setState(prevState => ({
        ...prevState,
        selectedNavbarOptionIndex: index
      }))
    }
    const navbarOptionSelected = this.navbarOptions[index]
    this.props.changeNavbarOption(navbarOptionSelected.optionName)
    switch (navbarOptionSelected.displayValue) {
      case NavBarDisplayValue.ORDERS: {
        this.props.cancelLogsRequest()
        this.props.clearRedisCachedMailboxRequest()
        this.props.clearUserList()
        break;
      }
      case NavBarDisplayValue.LOGS: {
        this.props.cancelOrdersRequest()
        this.props.clearUserList()
        break;
      }
      case NavBarDisplayValue.ADMIN: {
        this.props.cancelOrdersRequest();
        this.props.cancelLogsRequest();
        this.props.clearRedisCachedMailboxRequest();
        this.props.fetchUsers(this.props.token)
      }
    }
  }

  togglePopOverVisibility = () => {
    this.setState((prevState: State) => ({
      ...prevState,
      isPopOverVisible: !prevState.isPopOverVisible
    }))
  }

  getFilteredNavbarOptions = (): NavBarOption[] => {
    const relevantPartnerIds = getMigrationRelevantPartnerIds();
    const hasMigrationPartner = this.props.userPartners?.some(partner => relevantPartnerIds.includes(partner.partnerId));

    // If userPartners is empty, show both options; otherwise, filter based on the relevant partner IDs
    return this.navbarOptions.filter(option => {
      if (
        (option.optionName === NavBarOptionValue.CREATE_TASK || option.optionName === NavBarOptionValue.MONITOR_TASK)
        && this.props.userPartners?.length > 0 && !hasMigrationPartner
      ) {
        return false;
      }
      return true;
    });
  };

  render() {
    const filteredNavbarOptions = this.getFilteredNavbarOptions();
    const popUpOptionContent = (
      <div>
        <div onClick={this.props.initiateResetPassword} className="dropdown-item">
          <div className="dropdown-menu-item">
            <FormattedMessage id="change-password" />
          </div>
        </div>
        <div onClick={this.props.logoutUser} className="dropdown-next-item" >
          <div className="dropdown-menu-item">
            <FormattedMessage id="logout" />
          </div>
        </div>
      </div>
    )

    const navBarContent = filteredNavbarOptions.map((navbarOption, index) => (
      (navbarOption.optionName !== NavBarOptionValue.ADMIN || isRoleAdmin(this.props.userRole)) ? (
        <div
          key={index}
          onClick={() => this.updateNavbarIndex(index)}
          className={`navbar-item navbar-item-width ${index === this.state.selectedNavbarOptionIndex ? 'navbar-item-active' : ''}`}
        >
          <FormattedMessage id={navbarOption.displayValue} />
        </div>
      ) : null
    ));
    return (
      <div className="clearfix flex flex-wrap content-stretch navbar">
        <div className="logo">
          <TitanLogo className="titan-logo" />
          <div className="flock-logo-text">
            <FormattedMessage id="navbar-support-text" />
          </div>
        </div>
        <div className="flex flexgrow">
          {navBarContent}
          <div data-details className="navbar-item navbar-options right-0 fixed top-0">
            <span className="navbar-options-text"> {this.props.email} </span>
            <div className="dropdown" onClick={this.togglePopOverVisibility}>
              <CaretDown className="navbar-options-logo" />
              {this.state.isPopOverVisible && <div>
                <div className="dropdown-arrow" />
                <div className="dropdown-content options-dropdown right-0">
                  {popUpOptionContent}
                </div>
              </div>}
            </div>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: RootState) => {
  return {
    token: store.auth.token,
    email: store.auth.email,
    userRole: store.auth.userRole,
    userPartners: store.auth.userPartners
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ...bindActionCreators({
      logoutUser, initiateResetPassword, changeNavbarOption,
      cancelLogsRequest, cancelOrdersRequest, fetchUsers, clearUserList, clearRedisCachedMailboxRequest
    }, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
