import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import DnsDetails from "./dnsDetails";
import OrderDetailsItem from "./orderDetailsItem";
import ProgressBar from "./progressBar";
import DeleteOrder from "./deleteOrder";
import ChangePlanOrder from "./changePlanOrder"
import ChangeCustomerEmailModal from "./ChangeCustomerEmailModal"
import ModifyAccountLimitModal from "./modifyAccountLimitModal"
import Suspension from "./suspension"
import Unsuspension from "./unsuspension"
import SuspensionPopover from "./suspensionPopover"
import SuspendCustomerConfirmation from "./suspendCustomerConfirmation"
import { navigateBackOrderList } from "../actions/orderActions";
import { isFreePlan as _isFreePlan, getFirstTrialPlan, toCapitalize } from "../utils/helper";
import {
  initiateChangeOrder, closeChangeOrder, fetchNoOfAccountsExceedingQuota,
  fetchCustomerDomainList, getAllSuspensions, fetchActionHistory, fetchEmailAccountsToRecover,
  fetchCustomerOrders, getCustomerSuspensions, checkDomainReactivation, getAllTransactions
} from "../actions/orderActions";
import { ReactComponent as Green } from "../assets/oval_green.svg"
import { ReactComponent as Red } from "../assets/oval_red.svg"
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import { ReactComponent as NoMailContentLogo } from "../assets/no-mail-content.svg"
import { ReactComponent as NavigateBack } from "../assets/navigate_back.svg"
import { showEmailSection, isRoleTitan, ENTITY_TYPE, capitalize, PLAN_TYPE, DEFAULT_PAGINATION_LIMIT } from "./utils";
import ChangeMailService from "./changeMailService";
import ReactivateDomainModal from './reactivateDomainModal'
import { getDomainType } from "./utils"
import ManageButton from "./manageButton";
import AlertModal from "./AlertModal";
import { CustomerSuspensionSource } from "../model/customerSuspensionDetails";
import ProcessRefund from "./processRefund";
import { FormattedMessage, injectIntl } from "react-intl";
import OrderDetailsTooltipItem from "./orderDetailsTooltipItem";
import { ORDER_DETAIL_ACTION } from "../utils/const";
import medusa from "../utils/medusa";
import { toast } from "react-toastify";
import GenerateInvoice from "./generateInvoice";

class OrderDetails extends Component {
  state = {
    changePlanAction: "",
    buttonClicked: ""
  };

  constructor(props) {
    super(props)
    this.handleAdminLoginPanelClick = this.handleAdminLoginPanelClick.bind(this)
    this.handleChangePlanOrderClick = this.handleChangePlanOrderClick.bind(this)
    this.handleToggleMailServiceClick = this.handleToggleMailServiceClick.bind(this)
    this.handleCloseChangeOrderClick = this.handleCloseChangeOrderClick.bind(this)
    this.getStatusLogo = this.getStatusLogo.bind(this);
    this.navigateBack = this.navigateBack.bind(this);
    this.handleManageButtonClick = this.handleManageButtonClick.bind(this);
    this.handleUnsuspendButtonClick = this.handleUnsuspendButtonClick.bind(this)
    this.retryGetAllSuspensions = this.retryGetAllSuspensions.bind(this)
    this.refreshOrderDetailsOnModalClose = this.refreshOrderDetailsOnModalClose.bind(this)
    this.closeAlertModal = this.closeAlertModal.bind(this)
    this.getAccountId = this.getAccountId.bind(this)
    this.handleDomainHistoryClicked = this.handleDomainHistoryClicked.bind(this)
    this.handleAccountHistoryClicked = this.handleAccountHistoryClicked.bind(this)
    this.handleSuspendCustomerClick = this.handleSuspendCustomerClick.bind(this)
    this.openCustomerSuspensionModal = this.openCustomerSuspensionModal.bind(this)
    this.getCustomerEmailStatus = this.getCustomerEmailStatus.bind(this)
    this.getCustomerEmail = this.getCustomerEmail.bind(this)
    this.getCustomerId = this.getCustomerId.bind(this)
    this.handleRecoverDataButtonClick = this.handleRecoverDataButtonClick.bind(this)
    this.handleChangeCustomerEmailButtonClick = this.handleChangeCustomerEmailButtonClick.bind(this)
    this.handleReactivateDomainClicked = this.handleReactivateDomainClicked.bind(this)
    this.getReactivateButton = this.getReactivateButton.bind(this)
    this.getAccountIdsForCurrentOrder = this.getAccountIdsForCurrentOrder.bind(this)
    this.handleSetAccountLimitButtonClick = this.handleSetAccountLimitButtonClick.bind(this)
    this.handleProcessRefundClick = this.handleProcessRefundClick.bind(this)
    this.getCurrentRegion = this.getCurrentRegion.bind(this)
    this.topDivRef = React.createRef();
  }

  componentDidMount() {
    this.topDivRef.current.scrollIntoView()
  }

  handleAdminLoginPanelClick = () => {
    const url = this.props.orderDetails.domain.adminPanelUrl;
    window.open(url);
  }

  handleDomainHistoryClicked = (domainId) => {
    this.props.fetchActionHistory(domainId, null, ENTITY_TYPE.DOMAIN, this.props.token, this.getCurrentRegion(), DEFAULT_PAGINATION_LIMIT)
  }

  handleAccountHistoryClicked = () => {
    this.props.fetchActionHistory(null, this.getAccountIdsForCurrentOrder(), ENTITY_TYPE.ACCOUNT, this.props.token, this.getCurrentRegion())
  }

  handleChangePlanOrderClick = (action) => {
    this.handleManageButtonClick("changePlanOrder")
    this.setState({
      changePlanAction: action
    })

    // initially this check was for move to free
    // now we don't have that case, its just a downgrade we don't have downgrade plan
    if (action === ORDER_DETAIL_ACTION.CHANGE_PLAN) {
      this.props.fetchNoOfAccountsExceedingQuota(this.props.orderDetails.domain.id, this.props.token, this.getCurrentRegion())
    }
  }

  handleToggleMailServiceClick = (item) => {
    this.handleManageButtonClick(item)
    this.props.fetchCustomerDomainList(this.props.orderDetails.domain.userId, this.props.token, this.getCurrentRegion())
  }

  handleUnsuspendButtonClick = (item) => {
    this.handleManageButtonClick(item)
    const suspensionType = (item === "unsuspendDomain") ? ENTITY_TYPE.DOMAIN : ENTITY_TYPE.ACCOUNT
    this.props.getAllSuspensions(this.props.orderDetails.domain.id, this.getAccountId(),
      this.props.token, suspensionType, this.getCurrentRegion())
  }

  getCustomerEmail = () => {
    return this.props.orderDetails.domain.customerEmail
  }

  getCustomerId = () => {
    return [this.props.orderDetails.domain.userId]
  }

  getCurrentRegion = () => {
    return this.props.currentOrderSelected.region
  }

  handleSuspendCustomerClick = (item) => {
    this.handleManageButtonClick(item)
    this.props.fetchCustomerOrders(this.getCustomerEmail(), this.getCustomerId(), this.props.token, this.getCurrentRegion())
  }

  handleUnsuspendCustomerClick = (item) => {
    this.handleManageButtonClick(item)
    const customerIdRegionMap = {}
    customerIdRegionMap[this.getCurrentRegion()] = this.getCustomerId()
    const payload = {
      "customerEmail": this.getCustomerEmail(),
      "customerIdsRegionDetails": customerIdRegionMap
    }
    this.props.getCustomerSuspensions(payload, this.props.token)
  }

  handleRecoverDataButtonClick = (item) => {
    this.props.fetchEmailAccountsToRecover(this.props.orderDetails.domain.name, this.props.token, this.getCurrentRegion())
  }

  handleChangeCustomerEmailButtonClick = () => {
    this.handleManageButtonClick("changeCustomerEmail");
  };

  handleSetAccountLimitButtonClick = () => {
    this.handleManageButtonClick("modifyAccountLimit");
  };

  handleManageButtonClick = async (buttonName) => {
    // we don't want to show anything its just a medusa events
    if (buttonName === "markDomainSafe") {
      await medusa.markDomainSafe();
      return toast.info("Domain has been marked as safe");
    } else if (buttonName === "markCustomerSafe") {
      await medusa.markCustomerSafe();
      return toast.info("Customer has been marked as safe");
    } else if (buttonName === "markAccountSafe") {
      await medusa.markMailAccountSafe();
      return toast.info("Account has been marked as safe");
    }

    // show corresponding action modal
    this.props.initiateChangeOrder();
    this.setState({
      buttonClicked: buttonName
    })
  }

  handleCloseChangeOrderClick = (domainId, accountId) => {
    this.props.closeChangeOrder(domainId, this.props.partnerId, accountId, this.props.queryText, this.props.partnerName, this.props.token, this.getCurrentRegion());
  }

  handleReactivateDomainClicked = () => {
    this.props.initiateChangeOrder();
    this.setState({
      buttonClicked: "reactivateDomain"
    })
    this.props.checkDomainReactivation(this.props.orderDetails.domain.id, this.props.token, this.getCurrentRegion())
  }

  handleProcessRefundClick = (item) => {
    this.handleManageButtonClick(item)
    this.props.getAllTransactions(this.props.orderDetails.domain.id, this.props.token, this.getCurrentRegion())
  }

  getReactivateButton = () => {
    let buttonText = <FormattedMessage id="reactivate" />
    if (this.props.domainReactivationCheckInfo.isDomainReactivationCheckInProgress) {
      buttonText = <div className="manage-button-div">
        <div className="spinner-manage reactive-check" />
        <FormattedMessage id="checking" />
      </div>
    }
    return <button className="button button-secondary order-details-button"
      onClick={() => { this.handleReactivateDomainClicked() }} >
      {buttonText}
    </button>
  }

  getStatusLogo = (status, type) => {
    if (status.toLowerCase() === "active") {
      return (<div><Green className="state-logo" /> {status} </div>)
    } else {
      const isStatusSuspended = status.toLowerCase() === "suspended"
      return (<div className="order-status-container">
        <div>
          <Red className="state-logo" />
          <b className="order-status-text">{status}</b>
        </div>{isStatusSuspended ?
          <SuspensionPopover
            entityType={type}
            accountId={this.getAccountId()}
            domainId={this.props.orderDetails.domain.id}
            currentRegion={this.getCurrentRegion()}
            hoverIconCss='order-status-popup-icon'
          />
          : ""}
      </div>)
    }
  }

  navigateBack = () => {
    this.props.navigateBackOrderList(this.props.queryText, this.props.token);
  }

  getAccountId() {
    const account = this.props.orderDetails.account
    return (account) ? account.id : null
  }

  getAccountIdsForCurrentOrder() {
    const accounts = this.props.currentOrderSelected.accounts
    if (accounts) {
      return accounts.map(account => account.id)
    }
  }

  retryGetAllSuspensions() {
    const suspensionType = this.state.buttonClicked === "unsuspendDomain" ? ENTITY_TYPE.DOMAIN :
      ENTITY_TYPE.ACCOUNT
    const accountId = this.getAccountId()
    this.props.getAllSuspensions(this.props.orderDetails.domain.id, accountId,
      this.props.token, suspensionType, this.getCurrentRegion())
  }

  closeAlertModal() {
    this.handleCloseChangeOrderClick(null, null)
  }

  refreshOrderDetailsOnModalClose() {
    const domainId = this.props.orderDetails.domain.id
    const accountId = this.getAccountId()
    this.handleCloseChangeOrderClick(domainId, accountId)
  }

  isStatusDeleted = (status) => {
    return status.toLowerCase() === "deleted"
  }

  openCustomerSuspensionModal = () => {
    this.setState({
      buttonClicked: "suspendCustomerConfirmation"
    })
  }

  getCustomerEmailStatus = (customerName, customerEmail) => {
    if (this.props.isCustomerSuspendedOrderDetails) {
      return <div className="flex">
        <div className="customer-email-suspension-container">
          <Red className="state-logo" />
          <b className="customer-email-suspension-status-text">Suspended</b>
          <div className="customer-email-suspension-subheading">
            {this.getCustomerDetails(customerName, customerEmail)}
          </div>
        </div>
        <SuspensionPopover
          entityType={ENTITY_TYPE.CUSTOMER}
          accountId={this.getAccountId()}
          domainId={this.props.orderDetails.domain.id}
          hoverIconCss='order-status-popup-icon order-status-popup-icon-customer-email'
          customerEmail={customerEmail}
          currentRegion={this.getCurrentRegion()}
          customerIds={this.getCustomerId()} />
      </div>
    }
    else {
      return this.getCustomerDetails(customerName, customerEmail)
    }
  }

  getEmailWithTooltip(email) {
    if (email.length > 30) {
      return <div class="tooltip">
        {email.substr(0, 8) + '...' + email.substr(email.length - 10, email.length)}
        <span class="tooltiptext tooltiptextCustomer">
          {email}
        </span>
      </div>
    }
    return email
  }

  getCustomerNameWithTooltip(name) {
    if (name.length > 20) {
      return <div class="tooltip">
        {name.substr(0, 10) + '...'}
        <span class="tooltiptext tooltiptextCustomer">
          {name}
        </span>
      </div>
    }
    return name
  }

  getCustomerDetails(customerName, customerEmail) {
    if (customerName) {
      return <div className="order-details-customer-container">
        <span className="order-details-customer-name-text">{this.getCustomerNameWithTooltip(customerName)}</span>
        <div className="order-details-customer-name-separator" />
        <div>{this.getEmailWithTooltip(customerEmail)}</div>
      </div>
    } else {
      return <div className="order-details-customer-email-text">
        {this.getEmailWithTooltip(customerEmail)}
      </div>
    }
  }

  getFormattedDate(date) {
    return moment(date, "YYYY-MM-DD").format("Do MMM YYYY")
  }

  render() {
    const { domain, account, availablePlans } = this.props.orderDetails;
    const roleIsTitan = isRoleTitan(this.props.userRole);
    const usage = domain.usage.accounts;
    const domainId = domain.id;
    const maxNoOfAccs = domain.plan.quota.noOfAccounts || 0;
    const allowAccountLimitExtension = roleIsTitan;
    const noOfAccsCreated = usage.active + usage.suspended;
    const billingManagedBy = capitalize(domain.paymentManagedBy);
    const showAdminPanelButton = domain.adminPanelUrl;
    const domainName = domain.name;
    const allowAccountLimitReduction = maxNoOfAccs > noOfAccsCreated;
    const showModifyAccountLimit = allowAccountLimitExtension || allowAccountLimitReduction;
    let accountId = null;
    let usageInfo;
    let usagePercent;
    let usageSummary;
    let displayUsageBar;
    let domainstatus = capitalize(domain.status);
    const isDeletedOrder = this.isStatusDeleted(domainstatus)
    const plan = availablePlans.find(p => p.planType === domain.plan.type);
    const planName = plan.name;
    const isTrialPlan = plan.isTrail;
    const isFreePlan = _isFreePlan(plan);
    const isPaidOrder = plan.isPaid;
    const expiryDate = domain.plan.expiry
    const domainCreationDate = domain.createdOn
    const manageButtonDomainOptions = []
    const manageButtonCustomerOptions = []

    if (!plan) return null;

    if (isFreePlan) {
      const firstTrialPlan = getFirstTrialPlan(availablePlans);
      if (firstTrialPlan) {
        manageButtonDomainOptions.push({
          title: `${this.props.intl.formatMessage({ id: 'move-to-trial' }, { planName: firstTrialPlan.name })}`,
          buttonName: ORDER_DETAIL_ACTION.MOVE_TO_TRIAL_PLAN,
          onClick: this.handleChangePlanOrderClick
        })
      }
    }

    if (roleIsTitan && !isFreePlan) {
      manageButtonDomainOptions.push({
        title: this.props.intl.formatMessage({ id: 'extend-current-plan' }, { plan: toCapitalize(planName) }),
        buttonName: ORDER_DETAIL_ACTION.EXTEND_PLAN,
        onClick: this.handleChangePlanOrderClick
      });

      manageButtonDomainOptions.push({
        title: this.props.intl.formatMessage({ id: 'change-plan' }),
        buttonName: ORDER_DETAIL_ACTION.CHANGE_PLAN,
        onClick: this.handleChangePlanOrderClick
      })
    }

    manageButtonDomainOptions.push({
      title: this.props.intl.formatMessage({ id: 'change-customer-email' }),
      buttonName: 'changeCustomerEmail',
      onClick: this.handleChangeCustomerEmailButtonClick
    })
    if (isPaidOrder && showModifyAccountLimit) {
      manageButtonDomainOptions.push({
        title: this.props.intl.formatMessage({ id: 'account-limit' }),
        buttonName: 'modifyAccountLimit',
        onClick: this.handleSetAccountLimitButtonClick
      })
    }
    manageButtonDomainOptions.push({
      title: this.props.intl.formatMessage({ id: 'recover-deleted-data' }),
      buttonName: 'recoverDeletedOrderData',
      onClick: this.handleRecoverDataButtonClick
    })

    if (domainstatus === "Suspended") {
      manageButtonDomainOptions.push({
        title: this.props.intl.formatMessage({ id: 'unsuspend-domain' }),
        buttonName: 'unsuspendDomain',
        onClick: this.handleUnsuspendButtonClick
      })
    } else {
      manageButtonDomainOptions.push({
        title: this.props.intl.formatMessage({ id: 'suspend-domain' }),
        buttonName: 'suspendDomain',
        onClick: this.handleManageButtonClick
      })
      if (roleIsTitan) {
        manageButtonDomainOptions.push({
          title: this.props.intl.formatMessage({ id: 'mark-domain-safe' }),
          buttonName: 'markDomainSafe',
          onClick: this.handleManageButtonClick
        })
      }
    }

    if (domainstatus === "Pending") {
      manageButtonDomainOptions.push({
        title: this.props.intl.formatMessage({ id: 'reactivate' }),
        buttonName: 'reactivateDomain',
        onClick: this.handleReactivateDomainClicked
      })
    }
    if (this.props.isCustomerSuspendedOrderDetails) {
      manageButtonCustomerOptions.push({
        title: this.props.intl.formatMessage({ id: 'unsuspend-customer' }),
        buttonName: 'unsuspendCustomer',
        onClick: this.handleUnsuspendCustomerClick
      })
    }
    else {
      manageButtonCustomerOptions.push({
        title: this.props.intl.formatMessage({ id: 'suspend-customer' }),
        buttonName: 'suspendCustomer',
        onClick: this.handleSuspendCustomerClick
      })
      if (roleIsTitan) {
        manageButtonCustomerOptions.push({
          title: this.props.intl.formatMessage({ id: 'mark-customer-safe' }),
          buttonName: 'markCustomerSafe',
          onClick: this.handleManageButtonClick
        })
      }
    }

    let isCloudDomain = this.props.orderDetails.domain.isCloudDomain
    let serverType = this.props.orderDetails.domain.serverType
    if (this.props.orderDetails.domain.shouldShowToggleOption) {
      var toggleMailServiceButtonText = ""
      if (isCloudDomain && serverType === "cpanel") {
        toggleMailServiceButtonText = this.props.intl.formatMessage({ id: 'move-to-cpanel' })
      } else if (!isCloudDomain) {
        toggleMailServiceButtonText = this.props.intl.formatMessage({ id: 'move-to-flock-cloud' })
      }
      if (toggleMailServiceButtonText !== "") {
        manageButtonDomainOptions.push({
          title: toggleMailServiceButtonText,
          buttonName: 'toggleMailService',
          onClick: this.handleToggleMailServiceClick
        })
      }
    }
    manageButtonDomainOptions.push({
      title: this.props.intl.formatMessage({ id: 'delete-domain' }),
      buttonName: 'deleteOrder',
      onClick: this.handleManageButtonClick
    })

    if (roleIsTitan && domain.isCloudDomain) {
      manageButtonDomainOptions.push({
        title: this.props.intl.formatMessage({ id: 'process-refund' }),
        buttonName: 'processRefund',
        onClick: this.handleProcessRefundClick
      })
    }

    if (roleIsTitan) {
      manageButtonDomainOptions.push({
        title: this.props.intl.formatMessage({ id: 'generate-invoice' }),
        buttonName: 'generateInvoice',
        onClick: this.handleManageButtonClick
      })
    }

    const accessToMultiplePartners = this.props.userPartners && this.props.userPartners.length > 1;
    let forwards;
    let domainToBeDisplayedMap = {};
    if (roleIsTitan || accessToMultiplePartners) {
      let region = this.props.region
      let regionInfo = region && region !== "us" ? ` (${region.toUpperCase()})` : "";
      domainToBeDisplayedMap["order-details-partner-info"] = this.props.partnerName + regionInfo
    }
    if (domain.customerEmail) {
      (domainToBeDisplayedMap["order-details-customer-account"] = this.getCustomerEmailStatus(domain.customerName, domain.customerEmail))
    }

    domainToBeDisplayedMap["partnerId"] = this.props.partnerId
    domainToBeDisplayedMap["order-details-domainId"] = <OrderDetailsTooltipItem value={domainId} />
    domainToBeDisplayedMap["order-details-domain"] = <OrderDetailsTooltipItem value={domainName} />
    domainToBeDisplayedMap["order-details-status"] = this.getStatusLogo(domainstatus, ENTITY_TYPE.DOMAIN)
    domainToBeDisplayedMap["order-details-no-of-accounts"] = <OrderDetailsTooltipItem value={noOfAccsCreated + " / " + maxNoOfAccs} />
    domainToBeDisplayedMap["order-details-plan"] = <OrderDetailsTooltipItem value={planName.toUpperCase()} />
    if (roleIsTitan) {
      domainToBeDisplayedMap["order-details-plan-id"] = <OrderDetailsTooltipItem
        value={plan.id}
      />
      domainToBeDisplayedMap["order-details-plan-type"] = <OrderDetailsTooltipItem
        value={plan.planType}
      />
    }
    domainToBeDisplayedMap["order-details-creation-date"] = <OrderDetailsTooltipItem value={this.getFormattedDate(domainCreationDate)} />;
    domainToBeDisplayedMap["order-details-expiry-date"] = <OrderDetailsTooltipItem value={expiryDate ? this.getFormattedDate(domain.plan.expiry) : ""} />;
    domainToBeDisplayedMap["order-details-source"] = <OrderDetailsTooltipItem value={(domain.source ? domain.source + " : " : "") +
      getDomainType(domain.domainType) + " : " + domain.customerId} />
    if (roleIsTitan) {
      domainToBeDisplayedMap["order-details-billing-managed-by"] = <OrderDetailsTooltipItem value={billingManagedBy} />
    }

    const domainObj = Object.keys(domainToBeDisplayedMap).map(itemName => <OrderDetailsItem itemName={itemName} itemValue={domainToBeDisplayedMap[itemName]} />)
    const mailSectionVisible = showEmailSection(this.props.queryText);
    let suspensions = usage.suspended ? ((usage.suspended < 10 && usage.suspended > 0) ? "0" + usage.suspended : usage.suspended) : "-";
    let catchall = domain.catchall ? ((domain.catchall < 10 && domain.catchall > 0) ? "0" + domain.catchall : domain.catchall) : "-";

    let showMailInfo = false;
    let emailInfoToBeDisplayedMap, emailObj;
    let accountstatus;
    let isDeletedAccount = false
    let email;
    let quota;
    if (account) {
      accountId = account.id;
      email = account.email;
      showMailInfo = true;
      accountstatus = capitalize(account.status);
      isDeletedAccount = this.isStatusDeleted(accountstatus)
      emailInfoToBeDisplayedMap = {
        "order-details-status": this.getStatusLogo(accountstatus, ENTITY_TYPE.ACCOUNT),
      }
      usageInfo = account ? (account.usage.storageInMB) : 0;
      quota = account.usage.storageLimitInMB;
      usagePercent = ((usageInfo * 100) / quota) + "%";
      usageSummary = usageInfo + " MB / " + quota + " MB";
      displayUsageBar = usageInfo > 0 ? true : false;//"block" : "none";
      forwards = (account.forward ? account.forward.forwardingAddress : "-");
      emailObj = Object.keys(emailInfoToBeDisplayedMap).map(itemName => <OrderDetailsItem itemName={itemName} itemValue={emailInfoToBeDisplayedMap[itemName]} />)
    }

    let modalContent = "";
    let suspensionType;
    let suspensionListError;
    let fetchSuspensionListSuccess;
    let suspensionList = [];
    switch (this.state.buttonClicked) {
      case "deleteOrder":
        modalContent =
          <DeleteOrder
            domainName={domainName}
            domainId={domainId}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)} />
        break;
      case "changePlanOrder":
        modalContent =
          <ChangePlanOrder
            domainName={domainName}
            domainId={domainId}
            partnerId={this.props.partnerId}
            accountId={accountId}
            partnerName={this.props.partnerName}
            action={this.state.changePlanAction}
            plan={plan}
            totalAccountsInUse={domain?.plan?.quota?.noOfAccounts}
            expiryDate={expiryDate}
            domainCreationDate={domainCreationDate}
            currentRegion={this.getCurrentRegion()}
            availablePlans={availablePlans}
            isTrialPlan={isTrialPlan}
            isFreePlan={isFreePlan}
            isPaidOrder={isPaidOrder}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)}
          />
        break;
      case "toggleMailService":
        modalContent =
          <ChangeMailService
            userId={this.props.orderDetails.domain.userId}
            mailService={this.props.orderDetails.domain.isCloudDomain ? "flock_cloud" : "partner_hosting"}
            domainName={domainName}
            accountId={accountId}
            currentRegion={this.getCurrentRegion()}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)} />
        break;
      case "suspendDomain":
        modalContent =
          <Suspension
            suspensionType={ENTITY_TYPE.DOMAIN}
            domainId={domainId}
            domainName={domainName}
            accountId={accountId}
            currentRegion={this.getCurrentRegion()}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)} />
        break;
      case "suspendAccount":
        modalContent =
          <Suspension
            suspensionType={ENTITY_TYPE.ACCOUNT}
            domainId={domainId}
            domainName={domainName}
            accountId={accountId}
            currentRegion={this.getCurrentRegion()}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)}
            email={this.props.queryText} />
        break;
      case "unsuspendDomain":
        suspensionType = ENTITY_TYPE.DOMAIN
        suspensionListError = this.props.suspensionListInfo[suspensionType].suspensionListError
        fetchSuspensionListSuccess = this.props.suspensionListInfo[suspensionType].fetchSuspensionListSuccess
        suspensionList = this.props.suspensionListInfo[suspensionType].suspensionList
        modalContent =
          <Unsuspension
            suspensionType={suspensionType}
            source={CustomerSuspensionSource.OrderDetails}
            domainId={domainId}
            domainName={domainName}
            accountId={accountId}
            currentRegion={this.getCurrentRegion()}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)} />
        break;
      case "unsuspendAccount":
        suspensionType = ENTITY_TYPE.ACCOUNT
        suspensionListError = this.props.suspensionListInfo[suspensionType].suspensionListError
        fetchSuspensionListSuccess = this.props.suspensionListInfo[suspensionType].fetchSuspensionListSuccess
        suspensionList = this.props.suspensionListInfo[suspensionType].suspensionList
        modalContent =
          <Unsuspension
            suspensionType={suspensionType}
            source={CustomerSuspensionSource.OrderDetails}
            domainId={domainId}
            email={email}
            accountId={accountId}
            currentRegion={this.getCurrentRegion()}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)} />
        break;
      case "suspendCustomer":
        modalContent =
          <SuspendCustomerConfirmation
            closeModal={() => this.handleCloseChangeOrderClick(null, null)}
            handleProceedClick={this.openCustomerSuspensionModal} />
        break;
      case "suspendCustomerConfirmation":
        modalContent =
          <Suspension
            suspensionType={ENTITY_TYPE.CUSTOMER}
            domainId={domainId}
            email={domain.customerEmail}
            domainName={domainName}
            accountId={accountId}
            currentRegion={this.getCurrentRegion()}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)} />
        break;
      case "unsuspendCustomer":
        suspensionType = ENTITY_TYPE.CUSTOMER
        suspensionListError = this.props.suspensionListInfo[suspensionType].suspensionListError
        fetchSuspensionListSuccess = this.props.suspensionListInfo[suspensionType].fetchSuspensionListSuccess
        suspensionList = this.props.suspensionListInfo[suspensionType].suspensionList
        modalContent =
          <Unsuspension
            suspensionType={suspensionType}
            source={CustomerSuspensionSource.OrderDetails}
            domainId={domainId}
            email={email}
            accountId={accountId}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)} />
        break;
      case "reactivateDomain":
        modalContent =
          <ReactivateDomainModal
            domainName={domainName}
            domainId={domainId}
            accountId={accountId}
            currentRegion={this.getCurrentRegion()}
            handleCloseOrderChange={this.handleCloseChangeOrderClick}
          />
        break;
      case "processRefund":
        modalContent =
          <ProcessRefund
            domainId={domainId}
            domainName={domainName}
            plan={plan}
            currentRegion={this.getCurrentRegion()}
            availablePlans={availablePlans}
            noOfAccounts={domain?.plan?.quota?.noOfAccounts}
            totalAccountsInUse={domain?.totalAccountsInUse}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)} />
        break;
      case "changeCustomerEmail":
        modalContent =
          <ChangeCustomerEmailModal
            domainId={domainId}
            accountId={accountId}
            userId={this.props.orderDetails.domain.userId}
            email={domain.customerEmail}
            currentRegion={this.getCurrentRegion()}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)}
          />;
        break;
      case "modifyAccountLimit":
        modalContent =
          <ModifyAccountLimitModal
            allowAccountLimitExtension
            domainId={domainId}
            accountId={accountId}
            maxNoOfAccs={maxNoOfAccs}
            noOfAccsCreated={noOfAccsCreated}
            currentRegion={this.getCurrentRegion()}
            closeModal={(domainId, accountId) => this.handleCloseChangeOrderClick(domainId, accountId)}
          />;
        break;
      case "generateInvoice":
        modalContent =
          <GenerateInvoice
            domainId={domainId}
            closeModal={() => this.handleCloseChangeOrderClick(domainId, accountId)}
            token={this.props.token}
            region={this.getCurrentRegion()}
          />;
        break;
      default:
    }
    const reactivateDomainCheckError = this.props.domainReactivationCheckInfo.isDomainReactivationCheckFailure
    if (suspensionListError) {
      let heading;
      let subheading
      let description
      if (suspensionType === ENTITY_TYPE.DOMAIN) {
        heading = this.props.intl.formatMessage({ id: "unsuspend-domain" })
        subheading = domainName
        description = this.props.intl.formatMessage({ id: "could-not-fetch-suspension" }, { entity: domainName })
      } else {
        heading = this.props.intl.formatMessage({ id: "unsuspend-account" })
        subheading = email
        description = this.props.intl.formatMessage({ id: "could-not-fetch-suspension" }, { entity: email })
      }
      modalContent =
        <AlertModal
          heading={heading}
          subheading={subheading}
          description={description}
          buttonText={this.props.intl.formatMessage({ id: "retry" })}
          buttonAction={this.retryGetAllSuspensions}
          closeAction={this.closeAlertModal}
        />
    }
    else if (fetchSuspensionListSuccess && suspensionList.length === 0) {
      let heading
      let subheading
      let description = "No active suspensions on this "
      if (suspensionType === ENTITY_TYPE.DOMAIN) {
        heading = this.props.intl.formatMessage({ id: "unsuspend-domain" })
        subheading = domainName
        description = this.props.intl.formatMessage({ id: "no-active-suspension-domain" })
      } else {
        heading = this.props.intl.formatMessage({ id: "unsuspend-account" })
        subheading = email
        description = this.props.intl.formatMessage({ id: "no-active-suspension-account" })
      }
      modalContent =
        <AlertModal
          heading={heading}
          subheading={subheading}
          description={description}
          buttonText={this.props.intl.formatMessage({ id: "close" })}
          buttonAction={this.refreshOrderDetailsOnModalClose}
          closeAction={this.refreshOrderDetailsOnModalClose}
        />
    }
    else if (this.props.customerSuspensionOrderInfo.fetchCustomerOrderListError) {
      let heading = this.props.intl.formatMessage({ id: "suspend-customer" })
      let subheading = this.getCustomerEmail()
      let description = this.props.intl.formatMessage({ id: "order-list-customer-error" })
      modalContent =
        <AlertModal
          heading={heading}
          subheading={subheading}
          description={description}
          buttonText={this.props.intl.formatMessage({ id: "retry" })}
          buttonAction={() => this.handleSuspendCustomerClick("suspendCustomer")}
          closeAction={this.closeAlertModal}
        />
    }
    else if (reactivateDomainCheckError) {
      let heading = this.props.intl.formatMessage({ id: "reactivate-domain-header" })
      let subheading = domainName
      let description = this.props.intl.formatMessage({ id: "reactivate-domain-check-failed" })
      modalContent =
        <AlertModal
          heading={heading}
          subheading={subheading}
          description={description}
          buttonText={this.props.intl.formatMessage({ id: "retry" })}
          buttonAction={() => this.handleReactivateDomainClicked()}
          closeAction={this.closeAlertModal}
        />
    }
    let modalContentCss = "delete-order-modal";
    if (this.props.isOrderChangePlanSuccess || this.props.isMailServiceChangeSuccess) {
      modalContentCss = "configure-trial-success-modal"
    } else if (this.state.buttonClicked === "suspendDomain" || this.state.buttonClicked === "suspendAccount") {
      modalContentCss = "suspension-modal"
    } else if (fetchSuspensionListSuccess && suspensionList.length > 0) {
      modalContentCss = "unsuspension-modal"
    } else if (suspensionListError || (fetchSuspensionListSuccess && suspensionList.length === 0) || reactivateDomainCheckError) {
      modalContentCss = "alert-modal"
    } else if (this.state.buttonClicked === "suspendCustomer" && !this.props.customerSuspensionOrderInfo.fetchCustomerOrderListError) {
      modalContentCss = "customer-order-for-suspension-modal"
    } else if (this.state.buttonClicked === "suspendCustomerConfirmation") {
      modalContentCss = "suspension-modal"
    }
    const manageButtonAccountOptions = []
    manageButtonAccountOptions.push({
      title: this.props.intl.formatMessage({ id: "suspend-account" }),
      buttonName: 'suspendAccount',
      onClick: this.handleManageButtonClick
    })
    if (roleIsTitan) {
      manageButtonAccountOptions.push({
        title: this.props.intl.formatMessage({ id: 'mark-account-safe' }),
        buttonName: 'markAccountSafe',
        onClick: this.handleManageButtonClick
      })
    }
    if (accountstatus === "Suspended") {
      manageButtonAccountOptions.push({
        title: this.props.intl.formatMessage({ id: "unsuspend-account" }),
        buttonName: 'unsuspendAccount',
        onClick: this.handleUnsuspendButtonClick
      })
    }
    const fetchDomainSuspensionListInProgress = this.props.suspensionListInfo[ENTITY_TYPE.DOMAIN].fetchSuspensionListInProgress && this.props.isOrderChangeInitiate
    const fetchCustomerSuspensionListInProgress = this.props.suspensionListInfo[ENTITY_TYPE.CUSTOMER].fetchSuspensionListInProgress && this.props.isOrderChangeInitiate
    const fetchAccountSuspensionListInProgress = this.props.suspensionListInfo[ENTITY_TYPE.ACCOUNT].fetchSuspensionListInProgress && this.props.isOrderChangeInitiate
    // show modal only when action completes with success or error
    const isCustomerOrderFetchInProgress = this.props.customerSuspensionOrderInfo.fetchCustomerOrderListInProgress
    const getAllTransactionsInProgress = this.props.allTransactionsInfo.inProgress
    const showSpinnerInDomainManageButton = this.props.isFetchAccountExceedingQuotaInProgress ||
      this.props.isFetchCustomerDomainListInProgress ||
      fetchDomainSuspensionListInProgress ||
      this.props.recoverEmailAccountState.fetchRecoverableAccountsInProgress ||
      fetchCustomerSuspensionListInProgress ||
      isCustomerOrderFetchInProgress ||
      getAllTransactionsInProgress ||
      this.props.domainReactivationCheckInfo.isDomainReactivationCheckInProgress
    const showSpinnerInAccountManageButton = fetchAccountSuspensionListInProgress
    const showSpinnerInReactivateButton = this.props.domainReactivationCheckInfo.isDomainReactivationCheckInProgress
    const showModal = this.props.isOrderChangeInitiate && !showSpinnerInDomainManageButton &&
      !showSpinnerInAccountManageButton && !showSpinnerInReactivateButton
    const domainActionHistoryButton = (this.props.isFetchActionHistoryListInProgress && this.props.actionHistoryEntityType === ENTITY_TYPE.DOMAIN) ?
      <div className="action-history-button-div">
        <FormattedMessage id="order-details-view-history-button-text" />
        <div className="spinner-manage" /></div> :
      <FormattedMessage id="order-details-view-history-button-text" />
    const accountActionHistoryButton = (this.props.isFetchActionHistoryListInProgress && this.props.actionHistoryEntityType === ENTITY_TYPE.ACCOUNT) ?
      <div className="action-history-button-div">
        <FormattedMessage id="order-details-view-account-history-button-text" />
        <div className="spinner-manage" /></div> :
      <FormattedMessage id="order-details-view-account-history-button-text" />
    const manageButtonDomainProp = [{ header: this.props.intl.formatMessage({ id: "manage-domain" }), buttons: manageButtonDomainOptions }, { header: this.props.intl.formatMessage({ id: "manage-customer" }), buttons: manageButtonCustomerOptions }]
    const manageButtonAccountProp = [{ header: this.props.intl.formatMessage({ id: "manage-account" }), buttons: manageButtonAccountOptions }]
    return (
      <div ref={this.topDivRef} className="order-details-container">
        {
          this.props.isFetchingOrderDetails ?
          <CircularProgress className="t-data-loader" size={26} /> : null
        }
        <div className="order-details-box">
          {!this.props.isSingleOrder && <NavigateBack className="navigate-back" onClick={this.navigateBack} />}
          <div className="order-details-column">
            <div className="order-details-column-header">
              <div className="order-details-column-header-column">
                <div className="order-details-column-header-text">
                  <FormattedMessage id="order-details-domain-information" />
                </div>
                <div className="rectangle"></div>
              </div>
            </div>
            <div className="order-info-box">
              {domainObj}
              <div className="flex domain-info-buttons-container">
                {isDeletedOrder ?
                  this.getReactivateButton() :
                  <ManageButton
                    optionList={manageButtonDomainProp}
                    shouldShowSpinner={showSpinnerInDomainManageButton}
                    showDropdownOnTop={true} />}
                <button className="button button-secondary order-details-button"
                  onClick={() => { this.handleDomainHistoryClicked(domainId) }}>
                  {domainActionHistoryButton}
                </button>
                {showAdminPanelButton &&
                  <button className="button button-secondary order-details-button admin-panel-button"
                    onClick={this.handleAdminLoginPanelClick}>
                    <FormattedMessage id="order-details-admin-panel-button-text" />
                  </button>}
              </div>
            </div>
            <DnsDetails domainId={domainId} />
            <div className="order-info-single-item">
              <OrderDetailsItem itemName="order-details-catch-all" itemValue={catchall} />
            </div>
            <div className="order-info-single-item">
              <OrderDetailsItem itemName="order-details-accounts-suspended" itemValue={suspensions} />
            </div>
          </div>
          {mailSectionVisible ? (
            <div className="order-details-column order-details-column-email">
              <div className="order-details-column-header">
                <div className="order-details-column-header-column">
                  <div className="order-details-column-header-text">
                    <FormattedMessage id="order-details-email-information" />
                  </div>
                  <div className="rectangle"></div>
                </div>
              </div>
              {showMailInfo ? (<div>
                <div className="order-info-box">
                  {emailObj}
                  <div className="flex account-info-buttons-container">
                    {!isDeletedAccount ? <ManageButton
                      optionList={manageButtonAccountProp}
                      shouldShowSpinner={showSpinnerInAccountManageButton}
                    /> : ""}
                    <button className="button button-secondary order-details-button"
                      onClick={() => { this.handleAccountHistoryClicked() }}>
                      {accountActionHistoryButton}
                    </button>
                  </div>
                </div>
                {
                  account?.flags?.length ?
                    <div className="order-info-single-item">
                      <OrderDetailsItem itemName="order-details-flags" itemValue="" />
                      <div className="mt-4">
                        <ul className="list-unstyled">
                          {
                            account?.flags.map((flag, index) => {
                              return <li key={index}>
                                {flag} (<FormattedMessage id={`accountFlag_${flag}`}/>)
                              </li>
                            })
                          }
                        </ul>
                      </div>
                    </div> : null
                }
                <div className="order-info-single-item">
                  <OrderDetailsItem itemName="order-details-quota" itemValue={usageSummary} />
                  {displayUsageBar && <div>
                    <ProgressBar style={{ width: usagePercent, height: "4px" }} />
                  </div>}
                </div>
                <div className="order-info-single-item">
                  <OrderDetailsItem itemName="order-details-forwards" itemValue={forwards} />
                  {account.forward ? <div className="forward-keep-copy-div">
                    {account.forward.keepCopy ? `Keeping a copy at Titan` :
                      <div>
                        <span className="forward-not-keep-copy-text">
                          Not
                        </span> keeping a copy at Titan </div>}
                  </div> : ""}
                </div>
              </div>) :
                (<div>
                  <div className="no-mail-div">
                    <div className="no-mail-logo">
                      <NoMailContentLogo />
                    </div>
                    <div className="no-mail-content">
                      <FormattedMessage id="email-address-not-found"
                        values={{ email: this.props.queryText, b: (word) => <b>{word}</b> }} />
                    </div>
                  </div>
                </div>)
              }
            </div>) : ""}
          {showModal ?
            <div className="modal">
              <div className={"modal-content " + modalContentCss}>
                {this.props.isOrderDeleteSuccess && <span className="close-modal" onClick={() => this.handleCloseChangeOrderClick(domainId, accountId)}><CloseGrey /></span>}
                {modalContent}
              </div>
            </div>
            : ""}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    partnerName: state.orders.orderDetails.partnerName,
    region: state.orders.orderDetails.region,
    partnerId: state.orders.partnerId,
    orderDetails: state.orders.orderDetails.orderDetailsResponse,
    dnsDetails: state.orders.orderDetails.dnsDetails,
    queryText: state.orders.queryText,
    isSingleOrder: state.orders.isSingleOrder,
    isOrderChangeInitiate: state.orders.isOrderChangeInitiate,
    token: state.auth.token,
    userRole: state.auth.userRole,
    userPartners: state.auth.userPartners,
    isFetchAccountExceedingQuotaInProgress: state.orders.isFetchAccountExceedingQuotaInProgress,
    noOfAccountsExceedingQuota: state.orders.noOfAccountsExceedingQuota,
    fetchAccountExceedingQuotaError: state.orders.fetchAccountExceedingQuotaError,
    isOrderDeleteSuccess: state.orders.isOrderDeleteSuccess,
    isOrderChangePlanSuccess: state.orders.isOrderChangePlanSuccess,
    isFetchCustomerDomainListInProgress: state.orders.isFetchCustomerDomainListInProgress,
    isMailServiceChangeSuccess: state.orders.isMailServiceChangeSuccess,
    suspensionListInfo: state.orders.suspensionListInfo,
    isFetchActionHistoryListInProgress: state.orders.isFetchActionHistoryListInProgress,
    actionHistoryEntityType: state.orders.actionHistoryEntityType,
    customerSuspensionOrderInfo: state.orders.customerSuspensionOrderInfo,
    isCustomerSuspendedOrderDetails: state.orders.isCustomerSuspendedOrderDetails,
    recoverEmailAccountState: state.orders.recoverEmailAccountState,
    domainReactivationCheckInfo: state.orders.domainReactivationCheckInfo,
    currentOrderSelected: state.orders.currentOrderSelected,
    allTransactionsInfo: state.orders.allTransactionsInfo,
    customerIdRegionDetails: state.orders.customerIdRegionDetails,
    isFetchingOrderDetails: state.orders.isFetchingOrderDetails,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
      navigateBackOrderList, initiateChangeOrder, closeChangeOrder,
      fetchNoOfAccountsExceedingQuota, fetchCustomerDomainList, getAllSuspensions,
      fetchActionHistory, fetchCustomerOrders, getCustomerSuspensions,
      fetchEmailAccountsToRecover, checkDomainReactivation, getAllTransactions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderDetails));
