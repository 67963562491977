import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { removeEmailFromCache } from "../actions/redisCachedMailboxActions";

const RedisCachedMailbox = ({ intl }) => {
  const dispatch = useDispatch();
  const { data, error } = useSelector((state) => state.redisCachedMailbox);
  const authToken = useSelector((state) => state.auth.token);
  const [state, setState] = useState({
    successMessage: "",
    errorMessage: "",
    loading: false,
  });

  const email = data && data.recipients && Object.keys(data.recipients).length > 0 ? Object.keys(data.recipients)[0] : null;

  const removeFromInvalidCache = async (email) => {
    setState({ ...state, loading: true });
    try {
      await dispatch(removeEmailFromCache(email, authToken));
      setState({
        successMessage: intl.formatMessage({ id: 'email-removed-from-cache' }),
        errorMessage: "",
        loading: false,
      });
    } catch (error) {
      setState({
        successMessage: "",
        errorMessage: error.message,
        loading: false,
      });
    }
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  const { successMessage, errorMessage, loading } = state;

  return (
    <>
      {email ? (
        <>
          <div className="email-list-container">
            {successMessage ? (
              <div className="success-removed-from-cache">{successMessage}</div>
            ) : (
              <div className="cached-mailbox-container">
                <div className="cached-mailbox-email">{email}</div>
                <button
                  className="button button-secondary"
                  onClick={() => removeFromInvalidCache(email)}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="button-loader" />
                  ) : (
                    <FormattedMessage id="remove-from-list" />
                  )}
                </button>
              </div>
            )}
          </div>
          {errorMessage && <div className="error">{errorMessage}</div>}
        </>
      ) : (
        <div className="email-not-found">
          <FormattedMessage
            id="email-not-present-in-redis-cache-list"
            values={{
              strong: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        </div>
      )}
    </>
  );
};

export default injectIntl(RedisCachedMailbox);
