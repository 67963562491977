export interface CustomerSuspensionParams {
    fetchCustomerOrderListInProgress: boolean,
    fetchCustomerOrderListSuccess: boolean,
    fetchCustomerOrderListError: boolean,
    orderInfo: CustomerSuspensionOrderInfo
}

export enum CustomerSuspensionSource {
    OrderList = "orderList",
    OrderDetails = "orderDetails"
}

interface CustomerSuspensionOrderInfo {
    email: string,
    orders: CustomerOrder[]
}

export interface CustomerOrder {
    domainName: string,
    source: string,
    noOfAccountsCreated: number,
    maxNoOfAccounts: number,
    status: string,
    userId: number,
    isPaid: boolean,
    planType: string,
    region: string
}

export interface RemoveCustomerSuspensionPayload {
    suspensionDetails: any
    note: string | null
}

export interface AddCustomerSuspensionPayload {
    userIds: number[],
    suspensionType: string,
    note: string | null,
    source: string,
    modelFeedback: string,
}