import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import './index.css';
import App from './App';
import ForgotPassword from './components/ForgotPassword'
import SetNewPassword from './components/setNewPassword'
import AutoLogin from './components/autoLogin'
import Notfound from './components/notFound'
import store from "./store";
import { Provider } from "react-redux";
import * as serviceWorker from './serviceWorker';
import "antd/dist/antd.css"
import './styles/basscss/basscss/src/basscss.css';
import './styles/masterlayout.css';
import './styles/login.css';
import './styles/forms.css';
import './styles/buttons.css';
import './styles/navbar.css';
import './styles/topbar.css';
import './styles/dashboardform.css';
import './styles/dropdown.css';
import './styles/mainlayout.css';
import './styles/orderlist.css';
import './styles/orderDetails.css';
import './styles/logo.css';
import './styles/toast.css';
import './styles/dns.css';
import './styles/tooltip.css';
import './styles/modal.css';
import './styles/loader.css';
import './styles/logs.css';
import './styles/suspensionPopover.css';
import './styles/actionhistory.css'
import './styles/customersuspension.css'
import './styles/recoverEmailAccounts.css'
import './styles/confirmationModal.css'
import './styles/recoveryWidget.css'
import './styles/forgotPassword.css'
import './styles/userList.css'
import './styles/addUserModal.css'
import './styles/updateUserModal.css'
import './styles/processRefund.css'
import './styles/swapIconPopover.css'
import './styles/addUserSuccessModal.css'
import './styles/rangePicker.css'
import './styles/common.css';

import { IntlProvider } from "react-intl"
import { getCurrentLocale, getLocaleMessages } from './components/utils';
import { init3rdPartyIntegrations } from './integrations';

// start 3rd party integrations
init3rdPartyIntegrations();

const routing = (
    <IntlProvider locale={getCurrentLocale()} messages={getLocaleMessages()}>
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route exact path="/" component={App} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/set-password" component={SetNewPassword} />
                    <Route path="/auto-login" component={AutoLogin} />
                    <Route component={Notfound} />
                </Switch>
            </Router>
        </Provider>
    </IntlProvider>
)
ReactDOM.render(routing, document.getElementById('root'));

// keeping build details
window.__buildDetails = {
    version: process.env.REACT_APP_BUILD_VERSION,
    appEnv: process.env.REACT_APP_ENV,
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
