import {
    handleResponse, exception, getAuthHeadersForMethod,
    Exception, ExceptionResponse
} from "./utils"
import { AddUsersPayload, User } from '../model/createUserPayload'
import { DeleteUserPayload } from "../model/deleteUserPayload";

export const userListRequest = (authToken: string) => {
    let requestOptions = getAuthHeadersForMethod("GET", authToken);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    return fetch(
        baseUrl + "users/v2",
        requestOptions
    ).then(handleResponse)
        .catch(err => {
            if (String(err) === Exception.NETWORK_FAILURE)
                return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE);
            return exception(String(err))
        })
};

export const partnerInfoRequest = (authToken: string) => {
    let requestOptions = getAuthHeadersForMethod("GET", authToken);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    return fetch(
        baseUrl + "partners",
        requestOptions
    ).then(handleResponse)
        .catch(err => {
            if (String(err) === Exception.NETWORK_FAILURE)
                return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE);
            return exception(String(err))
        })
}

export const createUsersRequest = (requestBody: AddUsersPayload, authToken: string) => {
    let requestOptions = getAuthHeadersForMethod("POST", authToken) as any
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    requestOptions = {
        ...requestOptions,
        body: JSON.stringify(requestBody)
    };
    return fetch(
        baseUrl + "add-users",
        requestOptions
    ).then(handleResponse)
        .catch(err => {
            if (String(err) === Exception.NETWORK_FAILURE)
                return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE);
            return exception(String(err))
        })
}

export const updateExistingUser = (requestBody: User, authToken: string) => {
    let requestOptions = getAuthHeadersForMethod("POST", authToken) as any
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    requestOptions = {
        ...requestOptions,
        body: JSON.stringify(requestBody)
    };
    return fetch(
        baseUrl + "update-user/v2",
        requestOptions
    ).then(handleResponse)
        .catch(err => {
            if (String(err) === Exception.NETWORK_FAILURE)
                return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE);
            return exception(String(err))
        })
}

export const deleteUserAccount = (requestBody: DeleteUserPayload, authToken: string) => {
    let requestOptions = getAuthHeadersForMethod("POST", authToken) as any
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    requestOptions = {
        ...requestOptions,
        body: JSON.stringify(requestBody)
    };
    return fetch(
        baseUrl + "delete-user",
        requestOptions
    ).then(handleResponse)
        .catch(err => {
            if (String(err) === Exception.NETWORK_FAILURE)
                return exception(ExceptionResponse.NETWORK_FAILURE_RESPONSE);
            return exception(String(err))
        })
}