import React, { useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import CustomAccordion from "../Common/CustomAccordion";
import TaskDetails, { formConfig } from "./TaskDetails";
import UploadFiles from "./UploadFiles";
import ValidationChecks from "./ValidationChecks";
import ReviewSummary from "./ReviewSummary";
import PartnerSelection from "../PartnerSelection";
import { BULK_MIGRATION_ACTION } from "../../utils/const";

// Initial state for the bulk migration form data
const initialBulkMigrationFormData = {
  taskName: "",
  createOnTitan: BULK_MIGRATION_ACTION.CREATE_MAILBOXES_ONLY,
  migrateEmails: "yes",
  ordersFile: null,
  mailboxesFile: null,
  selectedPartnerId: "",
};

// Initial state for the accordion panels
const initialAccordionState = {
  panel1: { expanded: true, enabled: true },
  panel2: { expanded: false, enabled: false },
  panel3: { expanded: false, enabled: false },
  panel4: { expanded: false, enabled: false },
};

const BulkMigration = ({ classes }) => {
  const [bulkMigrationFormData, setBulkMigrationFormData] = useState(initialBulkMigrationFormData);
  const [accordions, setAccordions] = useState(initialAccordionState);
  const [pathId, setPathId] = useState(null);
  const [validationData, setValidationData] = useState(null);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setBulkMigrationFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleFileChange = useCallback((event) => {
    const { name, files } = event.target;
    setBulkMigrationFormData((prevState) => ({
      ...prevState,
      [name]: files[0],
    }));
  }, []);

  const handleAccordionChange = useCallback(
    (panel) => (event, isExpanded) => {
      if (accordions[panel].enabled) {
        setAccordions((prevState) => {
          const newAccordions = Object.keys(prevState).reduce((acc, key) => {
            acc[key] = { ...prevState[key], expanded: false };
            return acc;
          }, {});
          newAccordions[panel].expanded = isExpanded;
          return newAccordions;
        });
      }
    },
    [accordions]
  );

  const setAccordionState = useCallback((panel, changes) => {
    setAccordions((prevState) => ({
      ...prevState,
      [panel]: { ...prevState[panel], ...changes },
    }));
  }, []);

  const validateForm = useCallback(
    (config) => {
      return config.every(
        (field) => !(field.validation?.required && !bulkMigrationFormData[field.name])
      );
    },
    [bulkMigrationFormData]
  );

  const handleNextChooseFileUpload = useCallback(() => {
    if (validateForm(formConfig)) {
      setAccordionState("panel2", { enabled: true, expanded: true });
      setAccordionState("panel1", { expanded: false });
    }
  }, [validateForm, setAccordionState]);

  const handleUploadSuccess = useCallback(
    (uploadData) => {
      setPathId(uploadData.pathId);
      setAccordionState("panel2", { expanded: false });
      setAccordionState("panel3", { enabled: true, expanded: true });
    },
    [setAccordionState]
  );

  const handleValidationSuccess = useCallback(
    (validationData) => {
      setValidationData(validationData);
      setAccordionState("panel4", {
        enabled: validationData.status === "success",
      });
    },
    [setAccordionState]
  );

  const handleReUpload = useCallback(() => {
    setPathId(null); // Reset pathId to ensure re-validation
    setAccordionState("panel3", { enabled: false, expanded: false });
    setAccordionState("panel2", { enabled: true, expanded: true });
  }, [setAccordionState]);

  return (
    <div className={classes.accordionStepsContainer}>
      <div className={classes.title}>
        <FormattedMessage id="migration-toolkit" />
      </div>
      <PartnerSelection
        selectedPartnerId={bulkMigrationFormData.selectedPartnerId}
        setSelectedPartnerId={(partnerId) =>
          setBulkMigrationFormData((prevState) => ({
            ...prevState,
            selectedPartnerId: partnerId,
          }))
        }
      />
      {bulkMigrationFormData.selectedPartnerId && (
        <>
          <CustomAccordion
            title={<FormattedMessage id="step1-task-details" />}
            expanded={accordions.panel1.expanded}
            onChange={handleAccordionChange("panel1")}
          >
            <TaskDetails
              bulkMigrationFormData={bulkMigrationFormData}
              handleInputChange={handleInputChange}
              validateForm={validateForm}
              handleNextChooseFileUpload={handleNextChooseFileUpload}
            />
          </CustomAccordion>
          <CustomAccordion
            title={<FormattedMessage id="step2-upload-files" />}
            expanded={accordions.panel2.expanded}
            onChange={handleAccordionChange("panel2")}
            disabled={!accordions.panel2.enabled}
          >
            <UploadFiles
              bulkMigrationFormData={bulkMigrationFormData}
              handleFileChange={handleFileChange}
              handleUploadSuccess={handleUploadSuccess}
              setAccordionState={setAccordionState}
              isOpen={accordions.panel2.expanded}
            />
          </CustomAccordion>
          <CustomAccordion
            title={<FormattedMessage id="step3-validation-checks" />}
            expanded={accordions.panel3.expanded}
            onChange={handleAccordionChange("panel3")}
            disabled={!accordions.panel3.enabled}
          >
            <ValidationChecks
              bulkMigrationFormData={bulkMigrationFormData}
              pathId={pathId}
              handleAccordionChange={handleAccordionChange}
              handleValidationSuccess={handleValidationSuccess}
              handleReUpload={handleReUpload}
            />
          </CustomAccordion>
          <CustomAccordion
            title={<FormattedMessage id="step4-review-summary" />}
            expanded={accordions.panel4.expanded}
            onChange={handleAccordionChange("panel4")}
            disabled={!accordions.panel4.enabled}
          >
            <ReviewSummary validationData={validationData} bulkMigrationFormData={bulkMigrationFormData} />
          </CustomAccordion>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(BulkMigration);
