import * as React from 'react';
import { RootState } from '../reducers';
import { connect } from "react-redux";
import { ReactComponent as NavigateBack } from "../assets/navigate_back.svg"
import { recoverEmailAccounts, navigateBackRecoverEmailAccounts } from '../actions/orderActions'
import { bindActionCreators } from 'redux';
import { getDomainType, isRoleTitan } from "./utils"
import ConfirmationModal from "./ConfirmationModal";
import { ReactComponent as Alert } from "../assets/action_history_alert.svg"
import { RecoverAccountsInfo, RecoverableOrder, RecoverableAccount } from "../model/RecoverAccountsInfo"
import { startRecoveryTaskSync } from "../service/recoveryTaskSyncService"
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";

type Props = {
    token: string,
    userRole: string,
    orderDetails: any,
    partnerName: string,
    recoverAccountsInfo: RecoverAccountsInfo,
    recoverableOrders: Array<RecoverableOrder>
    currentOrderSelected: any,
    recoverEmailAccounts: (requestBody: object, domainName: string, token: string, region: string) => void
    navigateBackRecoverEmailAccounts: () => void
} & WrappedComponentProps

class RecoverEmailAccounts extends React.Component<Props> {
    state = {
        showConfirmationModal: false
    }
    constructor(props: Props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getDomainId = this.getDomainId.bind(this)
        this.getDomainName = this.getDomainName.bind(this)
        this.props.recoverableOrders.forEach((domain: any) => {
            domain.isChecked = false;
            domain.accountDetails.forEach((account: any) => {
                account.isChecked = false;
            })
        })
    }
    toggleSelected = (item: any) => {
        item.isChecked = !item.isChecked;
        this.forceUpdate();
    }
    toggleAll = (item: RecoverableOrder) => {
        const newValue = !item.isChecked
        item.isChecked = newValue
        item.accountDetails.forEach((element) => {
            if (element.isRecoverable) {
                element.isChecked = newValue
            }
        });
        this.forceUpdate();
    }
    handleSubmit = () => {
        const accounts: Array<number> = []
        this.props.recoverableOrders.forEach((domain: any) => {
            accounts.push(...domain.accountDetails
                .filter((item: any) => item.isChecked)
                .map((item: any) => item.id)
            )
        })
        if (accounts.length > 0) {
            const requestBody = {
                domainId: this.getDomainId(),
                accounts: accounts
            }
            this.props.recoverEmailAccounts(requestBody, this.getDomainName(), this.props.token, this.props.currentOrderSelected.region)
        }
    }
    showModal = () => {
        this.setState({
            showConfirmationModal: true
        })
    }
    closeModal = () => {
        this.setState({
            showConfirmationModal: false
        })
    }
    getDomainName = () => {
        return this.props.orderDetails.domain.name
    }
    getDomainId = () => {
        return this.props.orderDetails.domain.id
    }
    closeModalOnSuccess = () => {
        this.closeModal()
        startRecoveryTaskSync(this.getDomainName(), this.getDomainId(), this.props.token, this.props.currentOrderSelected.region)
        this.props.navigateBackRecoverEmailAccounts()
    }
    getRecoverableAccounts = () => {
        return this.props.recoverableOrders.map((item: RecoverableOrder) => {
            const header = `${item.partnerName} | ${item.source} | ${getDomainType(item.domainType)} | 
            ${item.planType} | ${item.customerEmail}`
            return (
                item.accountDetails.length > 0 ? <div className="recoverable-accounts-container">
                    <text className="accounts-details-header">{header}</text>
                    <table className="account-details-table">
                        <tr className="account-details-row">
                            <th>
                                <input type='checkbox' checked={item.isChecked}
                                    disabled={this.isOrderRecoveryDisabled(item.accountDetails)}
                                    onClick={() => this.toggleAll(item)} className={"account-checkbox"} />
                            </th>
                            <th className="source-order-table-header account-detail-column1">
                                <FormattedMessage id='account_heading' />
                            </th>
                            <th className="source-order-table-header account-detail-column2">
                                <FormattedMessage id='quota_header' />
                            </th>
                        </tr>
                        {this.getAccountsList(item.accountDetails)}
                    </table>
                </div> : ""
            );
        })
    }
    getAccountsList = (accountDetails: RecoverableAccount[]) => {
        const accountsWithEmailInActiveOrders =
            accountDetails.filter((item: RecoverableAccount) => item.isRecoverable)
                .sort(this.compareStorageUsed)
        const accountsWithoutEmailInActiveOrders =
            accountDetails.filter((item: RecoverableAccount) => !item.isRecoverable)
                .sort(this.compareStorageUsed)
        const result: JSX.Element[] = [];
        (accountsWithEmailInActiveOrders.forEach((item: RecoverableAccount) => {
            result.push(
                <tr className="account-details-row">
                    <td>
                        <input type='checkbox' checked={item.isChecked}
                            onClick={() => this.toggleSelected(item)} className={"account-checkbox"} />
                    </td>
                    <td className="account-details-column-data account-detail-column1">{item.email}</td>
                    <td className="account-details-column-data account-detail-column2">
                        {item.storageUsedInMB + " MB / " + item.storageLimitInMB + " MB"}
                    </td>
                </tr>
            )
        }))
        if (accountsWithoutEmailInActiveOrders.length > 0) {
            result.push(
                <tr>
                    <td colSpan={3} className="recover-email-account-alert">
                        <div className="recover-email-account-alert-container">
                            <Alert />
                            <span className="recover-email-account-alert-text">
                                <FormattedMessage id='recover_email_account_alert_text' />
                            </span>
                        </div>
                    </td>
                </tr>
            )
            accountsWithoutEmailInActiveOrders.forEach((item: RecoverableAccount) => {
                result.push(
                    <tr className="account-details-row">
                        <td>
                            <input type='checkbox' checked={item.isChecked}
                                disabled={true}
                                onClick={() => this.toggleSelected(item)} className={"account-checkbox"} />
                        </td>
                        <td className="account-details-column-data-disabled account-detail-column1">{item.email}</td>
                        <td className="account-details-column-data-disabled account-detail-column2">
                            {item.storageUsedInMB + " MB / " + item.storageLimitInMB + " MB"}
                        </td>
                    </tr>
                )
            })
        }
        return result
    }

    isOrderRecoveryDisabled(accounts: RecoverableAccount[]) {
        return accounts.every(account => !account.isRecoverable)
    }

    compareStorageUsed(a: RecoverableAccount, b: RecoverableAccount) {
        return b.storageUsedInMB - a.storageUsedInMB
    }

    disableRecoverButton = () => {
        let accountsCheckedState: Array<boolean> = [];
        this.props.recoverableOrders.forEach((domain: any) => {
            domain.accountDetails.forEach((account: any) => {
                accountsCheckedState.push(account.isChecked);
            })
        })
        return accountsCheckedState.every(item => item === false)
    }
    render() {
        const heading = <FormattedMessage id='recover_email_accounts_header' />
        const { domain } = this.props.orderDetails;
        const source = (domain.source ? domain.source + " : " : "") +
            getDomainType(domain.domainType) + " : " + domain.customerId
        const usage = domain.usage.accounts;
        let maxNoOfAccs = domain.plan.quota.noOfAccounts;
        maxNoOfAccs = maxNoOfAccs ? maxNoOfAccs : 0;
        const noOfAccsCreated = usage.active + usage.suspended + usage.blocked;
        const accounts = `${noOfAccsCreated} / ${maxNoOfAccs}`;
        return (
            <div className="recover-accounts-container">
                <div className="recover-accounts-heading-container">
                    <NavigateBack className="recover-accounts-navigate-back"
                        onClick={this.props.navigateBackRecoverEmailAccounts} />
                    <div className="recover-accounts-heading">
                        <span className="recover-accounts-heading-text">{heading}</span>
                    </div>
                </div>
                <table className="source-order-container">
                    <tr className="account-details-header-row">
                        {isRoleTitan(this.props.userRole) ?
                            <th className="source-order-table-header column-partner">
                                <FormattedMessage id='recover-table-header-partner' />
                            </th> : ""}
                        <th className="source-order-table-header column-source">
                            <FormattedMessage id='recover-table-header-source' />
                        </th>
                        <th className="source-order-table-header column-accounts">
                            <FormattedMessage id='recover-table-header-accounts' />
                        </th>
                        <th className="source-order-table-header column-plan">
                            <FormattedMessage id='recover-table-header-plan' />
                        </th>
                        <th className="source-order-table-header column-customer-email">
                            <FormattedMessage id='recover-table-header-customer-email' />
                        </th>
                    </tr>
                    <tr className="source-order-row">
                        {isRoleTitan(this.props.userRole) ?
                            <td className="source-order-column-data column-partner">
                                {this.props.partnerName}</td> : ""}
                        <td className="source-order-column-data column-source">{source}</td>
                        <td className="source-order-column-data column-accounts">{accounts}</td>
                        <td className="source-order-column-data column-plan">{domain.plan.type}</td>
                        <td className="source-order-column-data column-customer-email">{domain.customerEmail}
                        </td>
                    </tr>
                </table>
                <text className="accounts-list-header"><FormattedMessage id='select-emails-to-recover' /></text>
                <div className="account-details-container">
                    <div className="account-details-table-container">
                        {this.getRecoverableAccounts()}
                    </div>
                    <div className="recover-button-container">
                        <button
                            className="button button-primary recover-button"
                            disabled={this.disableRecoverButton()}
                            onClick={this.showModal}>
                            <FormattedMessage id='recover-accounts' />
                        </button>
                    </div>
                </div>
                {this.state.showConfirmationModal ?
                    <div className="modal">
                        <div className="modal-content confirmation-modal">
                            <ConfirmationModal
                                heading={this.props.intl.formatMessage({ id: 'are-you-sure' })}
                                description={this.props.intl.formatMessage({ id: 'action-cannot-be-undone' })}
                                positiveButtonText={this.props.intl.formatMessage({ id: 'recover-accounts-positive-button-text' })}
                                negativeButtonText={this.props.intl.formatMessage({ id: 'cancel' })}
                                shouldShowLoader={true}
                                shouldShowSuccess={this.props.recoverAccountsInfo.recoverAccountsSuccess}
                                requestFailed={this.props.recoverAccountsInfo.recoverAccountsError}
                                isLoadingInProgress={this.props.recoverAccountsInfo.recoverAccountsInProgress}
                                buttonLoadingText={this.props.intl.formatMessage({ id: 'requesting' })}
                                buttonSuccessText={this.props.intl.formatMessage({ id: 'submitted' })}
                                positiveButtonAction={this.handleSubmit}
                                negativeButtonAction={this.closeModal}
                                successAction={this.closeModalOnSuccess}
                                failureAction={this.closeModal}
                                closeAction={this.closeModal}
                            />
                        </div>
                    </div>
                    : ""}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const orders = state.orders as any
    return {
        token: state.auth.token,
        data: orders.data,
        userRole: state.auth.userRole,
        partnerName: orders.orderDetails.partnerName,
        orderDetails: orders.orderDetails.orderDetailsResponse,
        recoverableOrders: orders.recoverEmailAccountState.recoverableOrders,
        recoverAccountsInfo: orders.recoverAccountsInfo,
        currentOrderSelected: orders.currentOrderSelected
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ recoverEmailAccounts, navigateBackRecoverEmailAccounts }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RecoverEmailAccounts))