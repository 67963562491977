import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { fetchOrderDetails, fetchOrderList, resetCustomerSuspensionChanges } from "../actions/orderActions";
import { isRoleTitan, ENTITY_TYPE, getUserIdsFromCustomerOrders } from "./utils"
import OrderListItem from "./orderListItem"
import { ORDER } from "./utils";
import SuspensionPopover from "./suspensionPopover"
import Unsuspension from "./unsuspension"
import { CustomerSuspensionSource } from "../model/customerSuspensionDetails";
import { FormattedMessage } from "react-intl";

class OrderList extends Component {

  state = {
    showCustomerSuspensionModal: false
  }

  constructor(props) {
    super(props);
    this.handleOrderClick = this.handleOrderClick.bind(this);
    this.handleCustomerOrderClick = this.handleCustomerOrderClick.bind(this);
    this.handleUnsuspendClick = this.handleUnsuspendClick.bind(this)
    this.getCustomerIds = this.getCustomerIds.bind(this)
  }

  handleOrderClick = index => {
    const { domainOrders } = this.props;
    const query = this.props.query;
    const authToken = this.props.authToken;
    const accountId = domainOrders[index].accounts ? this.getAccountId(domainOrders[index]) : null;
    const { partnerId, partnerName, id, region } = domainOrders[index];
    this.props.fetchOrderDetails(query, id, partnerId, accountId, authToken, index, partnerName, region, ORDER.DOMAIN);
  };

  handleCustomerOrderClick = index => {
    const { customerOrders } = this.props;
    const query = this.props.query;
    const authToken = this.props.authToken;
    const accountId = customerOrders[index].accounts ? this.getAccountId(customerOrders[index]) : null;
    const { partnerId, partnerName, id, region } = customerOrders[index];
    this.props.fetchOrderDetails(query, id, partnerId, accountId, authToken, index, partnerName, region, ORDER.CUSTOMER);
  };

  handleUnsuspendClick = () => {
    this.setState({
      showCustomerSuspensionModal: true
    })
  }

  handleModalClose = (email) => {
    if (email) {
      this.props.fetchOrderList(email, this.props.authToken, false)
    }
    else {
      this.props.resetCustomerSuspensionChanges()
    }
    this.setState({
      showCustomerSuspensionModal: false
    })
  }

  getAccountId = orderItem => {
    const accounts = orderItem.accounts;
    if (accounts.length === 0) return null;
    /*
      Send id of first active account to get the account details
    */
    const activeAccounts = accounts.filter(account => account.status === "active");
    if (activeAccounts.length > 0) {
      return activeAccounts[0].id;
    }
    accounts.sort(this.compareCreationDate);
    /*
      In case of no active accounts, send id of first non-deleted account
    */
    const nonDeletedAccounts = accounts.filter(account => account.status !== "deleted");
    if (nonDeletedAccounts.length > 0) {
      return nonDeletedAccounts[0].id;
    }
    /*
      In case all accounts are deleted, send id of first account thats deleted
    */
    return accounts[0].id;
  };

  compareCreationDate = (a, b) => {
    if (a.creationDate > b.creationDate) return 1;
    else if (a.creationDate < b.creationDate) return -1;
    return 0;
  };

  getCustomerIds = () => {
    return getUserIdsFromCustomerOrders(this.props.customerOrders)
  }

  render() {
    const currentDomainName = this.props.domainName;
    const currentEmail = this.props.email;
    const shouldShowDomainOrders = this.props.domainOrders && this.props.domainOrders.length > 0;
    const shouldShowCustomerOrders = this.props.customerOrders && this.props.customerOrders.length > 0;
    const accessToMultiplePartners = this.props.userPartners && this.props.userPartners.length > 1;
    const orderListHeaders = [];
    if (isRoleTitan(this.props.userRole) || accessToMultiplePartners) {
      orderListHeaders.push(<th className="order-column-partner order-column-header">
        <FormattedMessage id="order-list-header-partner" />
      </th>)
    }
    orderListHeaders.push(<th className="order-column-source order-column-header">
      <FormattedMessage id="order-list-header-source" />
    </th>)
    orderListHeaders.push(<th className="order-column-accounts order-column-header">
      <FormattedMessage id="order-list-header-accounts" />
    </th>)
    orderListHeaders.push(<th className="order-column-creation-date order-column-header">
      <FormattedMessage id="order-list-header-date" />
    </th>)
    orderListHeaders.push(<th className="order-column-status order-column-header">
      <FormattedMessage id="order-list-header-status" />
    </th>)
    const orderListHeaderItemComponent = <tr className="order-list-row order-list-row-header">{orderListHeaders}</tr>

    const customerOrderListHeaders = [];
    if (isRoleTitan(this.props.userRole) || accessToMultiplePartners) {
      customerOrderListHeaders.push(<th className="order-column-partner order-column-header">
        <FormattedMessage id="order-list-header-partner" />
      </th>)
    }
    customerOrderListHeaders.push(<th className="order-column-source order-column-header">
      <FormattedMessage id="order-list-header-domain-name" />
    </th>)
    customerOrderListHeaders.push(<th className="order-column-accounts order-column-header">
      <FormattedMessage id="order-list-header-accounts" />
    </th>)
    customerOrderListHeaders.push(<th className="order-column-creation-date order-column-header">
      <FormattedMessage id="order-list-header-date" />
    </th>)
    customerOrderListHeaders.push(<th className="order-column-status order-column-header">
      <FormattedMessage id="order-list-header-status" />
    </th>)
    const customerOrderListHeaderItemComponent =
      <tr className="order-list-row order-list-row-header">{customerOrderListHeaders}</tr>

    const domainOrderListItemComponent = this.props.domainOrders.map((order, index) => {
      return <OrderListItem
        order={order}
        index={index}
        orderType={ORDER.DOMAIN}
        userRole={this.props.userRole}
        accessToMultiplePartners={accessToMultiplePartners}
        isLastOrderInList={index === this.props.domainOrders.length - 1}
        onViewClicked={(index) => this.handleOrderClick(index)}></OrderListItem>
    })
    const customerOrderListItemComponent = shouldShowCustomerOrders ? this.props.customerOrders.map((order, index) => {
      return <OrderListItem
        order={order}
        index={index}
        orderType={ORDER.CUSTOMER}
        domainName={currentDomainName}
        userRole={this.props.userRole}
        accessToMultiplePartners={accessToMultiplePartners}
        isLastOrderInList={index === this.props.customerOrders.length - 1}
        onViewClicked={(index) => this.handleCustomerOrderClick(index)}></OrderListItem>
    }) : []
    return (
      <div className="orders-response">
        {shouldShowDomainOrders ?
          <div>
            <div className="domain-order-for">
              <text>
                <FormattedMessage id="order-list-domain" />
              </text>
              <text className="domain-name">{currentDomainName}</text>
            </div>
            <table className="order-list-box">
              {orderListHeaderItemComponent}
              {domainOrderListItemComponent}
            </table>
          </div> : ""}
        {shouldShowCustomerOrders ?
          <div>
            <div className="domain-order-for">
              <text>
                <FormattedMessage id="order-list-customer" />
              </text>
              <text className="domain-name">{currentEmail}</text>
              {this.props.isCustomerSuspendedOrderList ?
                <div className="order-list-suspension-container">
                  <div className="order-list-suspension-separator" />
                  <span className="suspended-text">Suspended</span>
                  <SuspensionPopover
                    entityType={ENTITY_TYPE.CUSTOMER}
                    customerEmail={currentEmail}
                    customerIds={this.getCustomerIds()}
                    shouldShowUnsuspendOption={isRoleTitan(this.props.userRole)}
                    handleUnsuspendClick={this.handleUnsuspendClick}
                    hoverIconCss='order-list-suspension-icon'
                  />
                </div>
                : ""}
            </div>
            <table className="order-list-box">
              {customerOrderListHeaderItemComponent}
              {customerOrderListItemComponent}
            </table>
          </div> : ""}
        {this.state.showCustomerSuspensionModal ?
          <div className="modal">
            <div className="modal-content unsuspension-modal">
              <Unsuspension
                suspensionType={ENTITY_TYPE.CUSTOMER}
                source={CustomerSuspensionSource.OrderList}
                email={currentEmail}
                closeModal={this.handleModalClose}
              />
            </div>
          </div> : ""}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    domainOrders: state.orders.domainOrders,
    customerOrders: state.orders.customerOrders,
    domainName: state.orders.currentDomainName,
    email: state.orders.currentEmail,
    authToken: state.auth.token,
    userRole: state.auth.userRole,
    userPartners: state.auth.userPartners,
    query: state.orders.queryText,
    isCustomerSuspendedOrderList: state.orders.isCustomerSuspendedOrderList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ fetchOrderDetails, fetchOrderList, resetCustomerSuspensionChanges }, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
