import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";

import { changePlanOrder } from "../actions/orderActions";
import { ReactComponent as Success } from "../assets/checked.svg"
import moment from "moment";
import { injectIntl, FormattedMessage } from "react-intl";
import {
    getFirstTrialPlan as _getFirstTrialPlan,
    isFreePlan as _isFreePlan,
    showPicker,
    toCapitalize
} from "../utils/helper";
import { ORDER_DETAIL_ACTION } from "../utils/const";

const tomorrow = moment().add(1, 'day').startOf('day');

class ChangePlanOrder extends Component {
    constructor(props) {
        super(props);
        this.domainName = this.props.domainName;
        this.domainId = this.props.domainId;
        this.partnerId = this.props.partnerId;
        this.partnerName = this.props.partnerName;
        this.expiryDate = this.props.expiryDate;
        this.accountId = this.props.accountId;
        this.action = this.props.action
        this.orderExpiryDate = this.expiryDate || new Date();
        this.plan = this.props.plan
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.changeExpiryDate = this.changeExpiryDate.bind(this);
        this.updatePlanType = this.updatePlanType.bind(this);
        this.changeReason = this.changeReason.bind(this);
        this.minDate = tomorrow.clone().format('YYYY-MM-DD');
    }

    componentDidMount() {
        // incase of free plan keep selected plan as trial plan
        // as we don't show plan selection for free plan
        if (this.props.isFreePlan) {
            const firstTrialPlan = this.getFirstTrialPlan()?.planType;
            if (firstTrialPlan) {
                this.setState({ selectedPlanType: firstTrialPlan });
            }
        }
    }

    // initial state of cmp
    // this obj can be use to reset to initial version
    initialState = {
        reason: '',
        formErrors: {},
        newExpiryDate: null,
        selectedPlanType: this.props.availablePlans[0]?.planType || null
    }

    // cmp state
    state = this.initialState

    // fun to set/update reason
    changeReason = (e) => {
        this.setState({ reason: e.target.value });
    }

    // fun to set/update expiry date
    changeExpiryDate = (e) => {
        if (e.target.value) this.setState({ newExpiryDate: e.target.value });
    }

    // fun to reset form values
    resetForm = () => {
        this.setState({ ...this.initialState });
    }

    // fun to close modal
    // reset form
    // call closeModal prop method
    closeModal = () => {
        this.resetForm();
        this.props.closeModal(null, null);
    }

    // fun to update selected plan type
    updatePlanType = (e) => {
        this.setState({ selectedPlanType: e.target.value });
    }

    // fun to get plan details by plan type
    getPlanDetails = (planType) => this.props.availablePlans.find(plan => plan.planType === planType);

    // fun to check if selected plan is free plan
    isFreePlan = (planType) => {
        const selectedPlan = this.getPlanDetails(planType);
        return _isFreePlan(selectedPlan);
    }

    // fun to get first trial plan
    getFirstTrialPlan = () => _getFirstTrialPlan(this.props.availablePlans);

    // fun to validate the form values
    // right now we are validating both the field just to keep code light weight
    // unfortunately we can't use react-hook-form here (OR at least not by straight forward way)
    validateForm = () => {
        const { newExpiryDate, reason } = this.state;
        const formErrors = {};
        const { intl } = this.props;

        if (!this.state.selectedPlanType) {
            formErrors.planType = intl.formatMessage({ id: 'string.empty' });
        } else if (!this.isFreePlan(this.state.selectedPlanType) && !newExpiryDate) {
            formErrors.newExpiryDate = intl.formatMessage({ id: 'string.empty' });
        } else if (!reason || !reason.trim()) {
            formErrors.reason = intl.formatMessage({ id: 'string.empty' });
        }

        // set new errors
        this.setState({ formErrors });

        if (Object.keys(formErrors).length) {
            return false;
        }

        return true;
    }

    handleButtonClick() {
        // make sure form values are valid
        if (!this.validateForm()) {
            return false;
        }

        const { selectedPlanType, newExpiryDate, reason } = this.state;

        const requestPayload = {
            domainName: this.domainName,
            domainId: this.domainId,
            partnerId: this.partnerId,
            partnerName: this.partnerName,
            action: this.action,
            prevPlan: this.plan.planType,
            prevExpiry: this.expiryDate,
            newExpiry: newExpiryDate,
            orderId: this.props.currentOrderId,
            noOfAccounts: this.props.totalAccountsInUse,
            newPlan: this.action === ORDER_DETAIL_ACTION.EXTEND_PLAN
                ? this.props.plan.planType
                : selectedPlanType,
            reason,
        };

        this.props.changePlanOrder(
            requestPayload,
            this.props.token,
            this.props.currentRegion
        );
    }

    render() {
        const shouldShowError = this.props.orderChangePlanError && !this.props.isOrderChangePlanInProgress;
        let buttonText, modalHeading, modalMessage;
        let cancelButtonText = this.props.intl.formatMessage({ id: "cancel" })
        const { reason, formErrors, selectedPlanType } = this.state;
        const { intl, availablePlans } = this.props;
        const isSelectPlanAFreePlan = this.isFreePlan(this.state.selectedPlanType);
        const isExtendPlan = this.action === ORDER_DETAIL_ACTION.EXTEND_PLAN;

        switch (this.action) {
            case ORDER_DETAIL_ACTION.MOVE_TO_TRIAL_PLAN:
                const currentPlan = this.getPlanDetails(selectedPlanType);
                const intlProps = {
                    domainName: this.domainName,
                    planName: currentPlan?.name
                };
                buttonText = intl.formatMessage({ id: "move-to-trial" }, { planName: currentPlan?.name })
                modalHeading = intl.formatMessage({ id: "move-to-trial-modal-heading" }, intlProps)
                modalMessage = intl.formatMessage({ id: "move-to-trial-modal-message" }, intlProps)
                break

            case ORDER_DETAIL_ACTION.EXTEND_PLAN:
                const planName = toCapitalize(this.plan.name);
                buttonText = intl.formatMessage({ id: "extend-current-plan-modal-button" }, { plan: planName })
                modalHeading = intl.formatMessage({ id: "extend-current-plan-modal-heading" }, {
                    domainName: this.domainName,
                    plan: planName
                })
                modalMessage = intl.formatMessage({ id: "extend-current-plan-modal-message" }, {
                    plan: planName,
                    domainName: this.domainName,
                })
                break

            case ORDER_DETAIL_ACTION.CHANGE_PLAN:
                let noOfAccountsExceedingQuota = this.props.noOfAccountsExceedingQuota
                if (this.props.fetchAccountExceedingQuotaError) {
                    modalHeading = intl.formatMessage({ id: "unable-to-fetch-mailbox-quota" })
                    modalMessage = intl.formatMessage({ id: "free-storage-exceeded-error" })
                    buttonText = intl.formatMessage({ id: "continue-downgrade" })
                    cancelButtonText = intl.formatMessage({ id: "cancel-and-check-manually" })
                }
                else if (noOfAccountsExceedingQuota > 0) {
                    let mailboxString = this.props.noOfAccountsExceedingQuota === 1 ?
                        intl.formatMessage({ id: "mailbox" }) :
                        intl.formatMessage({ id: "mailboxes" })
                    modalHeading = intl.formatMessage({ id: "account-exceeding-quota-modal-heading" }, { numberOfAccounts: this.props.noOfAccountsExceedingQuota, mailbox: mailboxString })
                    modalMessage = intl.formatMessage({ id: "account-exceeding-quota-modal-message" }, { numberOfAccounts: this.props.noOfAccountsExceedingQuota, mailbox: mailboxString })
                    buttonText = intl.formatMessage({ id: "continue-downgrade" })
                    cancelButtonText = intl.formatMessage({ id: "cancel-and-inform-customer" })
                }
                else if (noOfAccountsExceedingQuota === 0) {
                    const selectedPlan = this.getPlanDetails(selectedPlanType);
                    const intlProps = {
                        domainName: this.domainName,
                        currentPlan: this.plan.name,
                        newPlanName: selectedPlan.name,
                    };
                    buttonText = intl.formatMessage({ id: "change-plan" })
                    modalHeading = intl.formatMessage({ id: "change-plan-modal-heading" }, intlProps)
                    modalMessage = intl.formatMessage({ id: "change-plan-modal-message" }, intlProps)
                }
                break
            default:
                break
        }

        if (this.props.isOrderChangePlanSuccess) {
            return (
                <div className="configure-trial-success">
                    <Success />
                    <span className="configure-trial-success-header">
                        <FormattedMessage id="congratulations" />
                    </span>
                    <span className="configure-trial-success-message">
                    {
                        this.action === ORDER_DETAIL_ACTION.EXTEND_PLAN ?
                        <FormattedMessage id="plan-updated-successful" /> :
                        <FormattedMessage id="plan-changed-successful" />
                    }
                    </span>
                    <div className="configure-trial-success-button-div">
                        <button className="button button-primary configure-trial-success-button"
                            onClick={() => this.props.closeModal(this.domainId, this.accountId)}>
                            <FormattedMessage id="close" />
                        </button>
                    </div>
                </div>
            )
        }
        else {
            const allPlansExcludingCurrent = availablePlans.filter(p => p.planType !== this.plan.planType);
            return (
                <div className="delete-order-content">
                    <span className="delete-orders-header">{modalHeading}</span>
                    {shouldShowError ? <div className="delete-order-error">{this.props.orderChangePlanError} </div> : ""}
                    <div className="delete-orders-message">
                        <form>
                            {modalMessage}
                            {
                                // show plan selection incase of downgrade flow
                                // non-free plan to any plan
                                !this.props.isFreePlan && !isExtendPlan ?
                                    <fieldset className="t-form-field mt2">
                                        <FormattedMessage className="col" tagName="label" id="select-plan-type" />
                                        <select
                                            className="block"
                                            value={selectedPlanType}
                                            onChange={this.updatePlanType}
                                        >
                                            {
                                                allPlansExcludingCurrent.map((plan) => (
                                                    <option
                                                        key={plan.planType}
                                                        value={plan.planType}
                                                    >
                                                        {plan.name} (ID: {plan.id} Type: {plan.planType})
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        {formErrors?.planType && <span>{formErrors?.planType}</span>}
                                    </fieldset> : null
                            }
                            {
                                // show expiry date incase of non-free plan
                                !isSelectPlanAFreePlan || isExtendPlan ?
                                    <fieldset className="t-form-field mt2">
                                        <FormattedMessage className="col" tagName="label" id="new-expiry" />
                                        <input
                                            type="date"
                                            min={this.minDate}
                                            onFocus={showPicker}
                                            onChange={this.changeExpiryDate}
                                            value={this.state.newExpiryDate}
                                        />
                                        {formErrors?.newExpiryDate && <span>{formErrors?.newExpiryDate}</span>}
                                    </fieldset>: null
                            }
                            <span className="t-separator"></span>
                            <fieldset className="t-form-field">
                                <FormattedMessage tagName="label" id="change-plan-reason-label" />
                                <input
                                    placeholder={intl.formatMessage({ id: "change-plan-reason" })}
                                    onChange={this.changeReason}
                                    onBlur={this.validateForm}
                                    value={reason}
                                />
                                {formErrors?.reason && <span>{formErrors?.reason}</span>}
                            </fieldset>
                        </form>
                    </div>
                    <div className="delete-buttons">
                        <button className='button button-primary delete-order-button'
                            onClick={this.handleButtonClick}
                            disabled={this.props.isOrderChangePlanInProgress}
                        >{buttonText}</button>
                        <button className="button button-cancel delete-order-button"
                            onClick={() => this.closeModal()}
                            disabled={this.props.isOrderChangePlanInProgress}
                        >{cancelButtonText}</button>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isOrderChangePlanInProgress: state.orders.isOrderChangePlanInProgress,
        currentOrderId: state.orders.currentOrderId,
        isOrderChangePlanSuccess: state.orders.isOrderChangePlanSuccess,
        orderChangePlanError: state.orders.orderChangePlanError,
        noOfAccountsExceedingQuota: state.orders.noOfAccountsExceedingQuota,
        fetchAccountExceedingQuotaError: state.orders.fetchAccountExceedingQuotaError
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ changePlanOrder }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangePlanOrder));