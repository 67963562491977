import React, { Component } from "react";
import OrderList from "./orderList";
import { connect } from "react-redux";
import OrderDetails from "./orderDetails";
import LogDetails from "./LogDetails";
import NoContent from "./noContent";
import ActionHistoryList from "./actionHistoryList"
import ActionHistoryDetails from "./actionHistoryDetails";
import RecoverEmailAccounts from "./recoverEmailAccounts";
import RecoveryWidget from "./recoveryWidget";
import UserList from "./userList"
import { NavBarOptionValue } from "../model/navbarOptions";
import { bindActionCreators } from 'redux';
import { fetchUserInfoAction } from "../actions/authActions";
import BulkMigration from "./BulkMigration";
import MonitorTask from "./MonitorTask";
import RedisCachedMailbox from "./RedisCachedMailbox";
import { isEmptyObject } from "./utils";

class MainContent extends Component {
  scrollableDiv
  constructor() {
    super()
    this.scrollableDivRef = React.createRef()
  }

  componentDidMount() {
    this.props.fetchUserInfoAction(this.props.authToken)
  }

  render() {
    const navbarOption = this.props.navbarOption;
    const isOrderDetailsPresent = Object.entries(this.props.orderDetails).length !== 0
    const isActionHistoryListPresent = this.props.actionHistoryListSuccess
    const isRecoverableAccountsPresent = this.props.recoverEmailAccountState.fetchRecoverableAccountsSuccess
    const isActionHistoryDetailsPresent = Object.keys(this.props.actionHistoryDetails).length !== 0
    const isOrderListPresent = this.props.domainOrders.length !== 0 || this.props.customerOrders.length !== 0
    const isLogDetailsPresent = this.props.logDetails.length !== 0 || (this.props.ipLogsData && !isEmptyObject(this.props.ipLogsData));
    let componentToDisplay = <NoContent />;
    const componentPriorityForOrders = [
      { renderCondition: isActionHistoryDetailsPresent, component: <ActionHistoryDetails /> },
      { renderCondition: isActionHistoryListPresent, component: <ActionHistoryList /> },
      { renderCondition: isRecoverableAccountsPresent, component: <RecoverEmailAccounts /> },
      { renderCondition: isOrderDetailsPresent, component: <OrderDetails /> },
      { renderCondition: isOrderListPresent, component: <OrderList /> }
    ]
    const componentPriorityForLogs = [
      { renderCondition: (this.props.fetchingLogs && this.props.pageToFetch === 1 || this.props.redisCachedMailbox.loading), component: <div className="log-loader"></div> },
      { renderCondition: isLogDetailsPresent, component: <LogDetails scrollableDivRef={this.scrollableDivRef} /> },
      { renderCondition: this.props.redisCachedMailbox.data?.recipients || this.props.redisCachedMailbox.error, component: <RedisCachedMailbox/> }
    ]
    const componentPriorityForAdmin = [
      { renderCondition: this.props.userDetails.isUserListFetchInProgress && this.props.userDetails.userList.length === 0, component: <div className="log-loader"></div> },
      { renderCondition: this.props.userDetails.userList.length !== 0, component: <UserList /> },
    ]
    const componentPriorityForCreateTask = [
      { renderCondition: true, component: <BulkMigration /> },
    ]
    const componentPriorityForMonitorTask = [
      { renderCondition: true, component: <MonitorTask /> },
    ]
    let componentPriorityToConsider;
    switch (navbarOption) {
      case NavBarOptionValue.ORDERS: {
        componentPriorityToConsider = componentPriorityForOrders
        break;
      }
      case NavBarOptionValue.LOGS: {
        componentPriorityToConsider = componentPriorityForLogs
        break;
      }
      case NavBarOptionValue.ADMIN: {
        componentPriorityToConsider = componentPriorityForAdmin
        break;
      }
      case NavBarOptionValue.CREATE_TASK: {
        componentPriorityToConsider = componentPriorityForCreateTask
        break;
      }
      case NavBarOptionValue.MONITOR_TASK: {
        componentPriorityToConsider = componentPriorityForMonitorTask
        break;
      }
      default: break;
    }
    if (componentPriorityToConsider) {
      const componentInfo = componentPriorityToConsider.find((item) => item.renderCondition)
      if (componentInfo) {
        componentToDisplay = componentInfo.component
      }
    }
    return (
      <div className="main-layout" ref={this.scrollableDivRef}>
        <div id="mainDiv" name="mainDiv" className="main-content">
          {componentToDisplay}
        </div>
        {this.props.recoveryTaskInfo.length !== 0 ? <RecoveryWidget /> : ""}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    actionHistoryListSuccess: state.orders.actionHistoryListSuccess,
    actionHistoryDetails: state.orders.actionHistoryDetails,
    orderDetails: state.orders.orderDetails,
    domainOrders: state.orders.domainOrders,
    customerOrders: state.orders.customerOrders,
    logDetails: state.logs.fetchLogsPayload,
    navbarOption: state.auth.navbarOption,
    fetchingLogs: state.logs.fetchingLogs,
    pageToFetch: state.logs.page,
    recoverEmailAccountState: state.orders.recoverEmailAccountState,
    recoveryTaskInfo: state.orders.recoveryTaskInfo,
    userDetails: state.users.userDetails,
    authToken: state.auth.token,
    redisCachedMailbox: state.redisCachedMailbox,
    ipLogsData: state.logs.ipLogsData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ fetchUserInfoAction }, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);
