import {
  CLEAR_REDIS_CACHED_MAILBOX_REQUEST,
  FETCH_REDIS_CACHED_MAILBOX_ERROR,
  FETCH_REDIS_CACHED_MAILBOX_REQUEST,
  FETCH_REDIS_CACHED_MAILBOX_SUCCESS,
} from "../actions/redisCachedMailboxActions";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const redisCachedMailboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REDIS_CACHED_MAILBOX_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_REDIS_CACHED_MAILBOX_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case FETCH_REDIS_CACHED_MAILBOX_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case CLEAR_REDIS_CACHED_MAILBOX_REQUEST:
      return initialState;
    default:
      return state;
  }
};

export default redisCachedMailboxReducer;
