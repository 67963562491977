import * as React from 'react';
import { ReactComponent as Caret } from '../assets/recover_email_caret.svg'
import { ReactComponent as Success } from "../assets/recover_email_success.svg"
import { ReactComponent as Error } from "../assets/recover_email_failure.svg"
import { ReactComponent as Waiting } from "../assets/recover_email_waiting.svg"
import { ReactComponent as Close } from "../assets/recover_email_close.svg"
import { RootState } from '../reducers';
import { RecoveryTaskInfo, RecoveryTask } from "../model/recoveryTaskInfo"
import { connect } from "react-redux";
import { TASK_STATUS, startRecoveryTaskSync } from '../service/recoveryTaskSyncService';
import { bindActionCreators } from 'redux'
import { clearRecoveryTaskInfo } from '../actions/orderActions'
import { FormattedMessage } from "react-intl";

export interface RecoveryWidgetProps {
    recoveryTaskInfo: RecoveryTaskInfo[],
    clearRecoveryTaskInfo: () => void,
    token: string
}

interface State {
    visibility: boolean,
    shouldExpandOnRecoveryComplete: boolean
}

class RecoveryWidget extends React.Component<RecoveryWidgetProps> {
    state: State = {
        visibility: true,
        shouldExpandOnRecoveryComplete: true,
    }

    constructor(props: RecoveryWidgetProps) {
        super(props)
        this.toggleVisibility = this.toggleVisibility.bind(this)
        this.getHeadingText = this.getHeadingText.bind(this)
        this.getUnfinishedTaskCount = this.getUnfinishedTaskCount.bind(this)
        this.clearRecoverInfo = this.clearRecoverInfo.bind(this)
    }

    componentDidMount() {
        this.props.recoveryTaskInfo.forEach(taskInfo => {
            startRecoveryTaskSync(taskInfo.domainName, taskInfo.domainId, this.props.token, taskInfo.region)
        })
    }

    componentDidUpdate() {
        if (this.getUnfinishedTaskCount() === 0) {
            if (this.state.shouldExpandOnRecoveryComplete) {
                this.setState({
                    visibility: true,
                    shouldExpandOnRecoveryComplete: false
                })
            }
        }
    }

    toggleVisibility() {
        this.setState((prevState: State) => ({
            visibility: !prevState.visibility
        }))

    }

    getHeadingText() {
        const unfinishedTaskCount = this.getUnfinishedTaskCount()
        if (unfinishedTaskCount === 0) {
            let shouldShowErrorIcon = false
            this.props.recoveryTaskInfo.forEach(emailInfo => {
                emailInfo.recoveryTasks.forEach(task => {
                    if (task.status === TASK_STATUS.FAILED || task.status === TASK_STATUS.INTERRUPTED)
                        shouldShowErrorIcon = true

                })
            })
            return <div className="recovery-widget-heading-center-container"> {shouldShowErrorIcon ? <Error /> : <Success />}
                <span className="recovery-widget-heading-text">
                    <FormattedMessage id='recovery-complete' />
                </span>
            </div>
        }
        return `Recovering ${unfinishedTaskCount} ${unfinishedTaskCount === 1 ? 'account' : 'accounts'}`
    }

    getUnfinishedTaskCount() {
        let unfinishedTaskCount = 0
        this.props.recoveryTaskInfo.forEach((emailInfo: RecoveryTaskInfo) => {
            emailInfo.recoveryTasks.forEach((task: RecoveryTask) => {
                if (task.status === TASK_STATUS.INITIALIZED || task.status === TASK_STATUS.STARTED) {
                    unfinishedTaskCount += 1
                }
            })
        })
        return unfinishedTaskCount
    }

    clearRecoverInfo() {
        this.props.clearRecoveryTaskInfo()
    }

    getIconForStatus(status: string) {
        switch (status) {
            case TASK_STATUS.INITIALIZED: return <Waiting />
            case TASK_STATUS.STARTED: return <div className="recovery-widget-spinner" />
            case TASK_STATUS.FAILED: return <Error />
            case TASK_STATUS.SUCCESSFUL: return <Success />
            default: return <div></div>
        }
    }

    getTextForStatus(status: string, email: string) {
        if (status === TASK_STATUS.FAILED) {
            return <div>
                <div className="recovery-widget-table-item">{email}</div>
                <div className="recovery-widget-item-error">
                    <FormattedMessage id='account-recovery-failed' />
                </div>
            </div>
        }
        else {
            return <div className="recovery-widget-table-item">{email}</div>
        }
    }

    render() {
        const tableComponent = this.props.recoveryTaskInfo.map((statusInfo: RecoveryTaskInfo) => {
            const emailStatusComponent = statusInfo.recoveryTasks.map((task: RecoveryTask) => {
                return <tr>
                    <td>
                        <div className="recovery-widget-table-item-container" >
                            <div>
                                {this.getIconForStatus(task.status)}
                            </div>
                            {this.getTextForStatus(task.status, task.email)}
                        </div>
                    </td>
                </tr>
            })
            return <div> <tr>
                <td className="recovery-widget-table-domain">{statusInfo.domainName}</td>
            </tr>
                {emailStatusComponent}
            </div>
        })
        return (<div className="recovery-widget-container">
            <div className="recovery-widget-heading"> {this.getHeadingText()}
                <div className="recovery-widget-heading-center-container">
                    <div className="recovery-widget-header-icon" onClick={this.toggleVisibility}>
                        <Caret />
                    </div>
                    {this.getUnfinishedTaskCount() === 0 ?
                        <div className="recovery-widget-close-icon">
                            <Close onClick={this.clearRecoverInfo} />
                        </div> : ""}
                </div>
            </div>
            {this.state.visibility ?
                <div>
                    <div className="recovery-widget-subheading">
                        <FormattedMessage id='recovery-widget-minimize-text' />
                    </div>
                    <table className="recovery-widget-table">
                        {tableComponent}
                    </table>
                </div>
                : ""}
        </div>)
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        recoveryTaskInfo: (state.orders as any).recoveryTaskInfo,
        token: state.auth.token
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ clearRecoveryTaskInfo }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryWidget);