import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Grid, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { validateMigrationData } from "../../actions/createTaskActions";
import { HELP_ARTICLE_URLS, VALIDATION_STATUS } from "../../utils/const";

const ValidationChecks = ({ classes, bulkMigrationFormData, pathId, handleAccordionChange, handleValidationSuccess, handleReUpload }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [validationState, setValidationState] = useState({
    loading: false,
    error: "",
    status: null,
    errorReport: "",
  });

  useEffect(() => {
    if (pathId) {
      const validate = async () => {
        setValidationState({ loading: true, error: "", status: null, errorReport: "" });

        try {
          const validationResponse = await dispatch(validateMigrationData(
            bulkMigrationFormData.selectedPartnerId,
            {
              migrationType: bulkMigrationFormData.createOnTitan,
              migrateMails: bulkMigrationFormData.migrateEmails === "yes",
              taskName: bulkMigrationFormData.taskName,
            },
            pathId,
            token
          ));
          setValidationState({
            loading: false,
            status: validationResponse.status,
            errorReport: validationResponse.status === VALIDATION_STATUS.FAILURE ? validationResponse.resultCSVUrl : "",
            error: "",
          });
          handleValidationSuccess(validationResponse);
        } catch (error) {
          setValidationState({
            loading: false,
            error: error.message || "Failed to validate migration data",
            status: null,
            errorReport: "",
          });
        }
      };

      validate();
    }
  }, [pathId]);

  const { loading, error, status, errorReport } = validationState;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading ? (
          <div className="button-loader"></div>
        ) : status === VALIDATION_STATUS.SUCCESS ? (
          <>
            <div className={classes.successTitle}>
              <FormattedMessage id="file-validation-successful" />
            </div>
            <div className={classes.validationSubtitle}>
              <FormattedMessage id="files-expected-format" />
            </div>
            <button
              type="primary"
              className="button button-primary"
              onClick={() => handleAccordionChange("panel4")(null, true)}
            >
              <FormattedMessage id="next-review-summary" />
            </button>
          </>
        ) : status === VALIDATION_STATUS.FAILURE ? (
          <>
            <div className={classes.failureTitle}>
              <FormattedMessage id="file-validation-failed" />
            </div>
            <div className={classes.validationSubtitle}>
              <FormattedMessage id="found-errors-uploaded-files" />{" "}
              <a href={errorReport} target="_blank">
                <FormattedMessage id="view-error-report" />
              </a>
            </div>
            <div className={classes.helpfulLink}>
              <FormattedMessage id="helpful_links" />
            </div>
            <a
              href={HELP_ARTICLE_URLS.MIGRATION_TOOLKIT}
              target="_blank"
              className={classes.supportLink}
            >
              <FormattedMessage id="support_article_creating_task" />
            </a>
            <button
              type="primary"
              className="button button-primary"
              onClick={handleReUpload}
            >
              <FormattedMessage id="re-upload-files" />
            </button>
          </>
        ) : error ? (
          <div className={classes.errorMsg}>{error}</div>
        ) : (
          <div>
            <FormattedMessage id="awaiting-validation" />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ValidationChecks);
