import React, { Component } from "react";
import "../styles/progressbar.css";

class ProgressBar extends Component{
    state={}
    constructor(props){
        super(props);
        this.style = this.props.style;
    }

    render(){
        return(
            <div className="progress-div">
                <div className="progress-bar">
                    <div className="progress" style={this.style}/>
                </div>
            </div>
        );
    }

}

export default ProgressBar;