import {
  getOrderList, getOrderDetails, getDnsDetails, orderDeleteRequest, orderChangePlanRequest, accountsExceedingQuotaRequest, customerDomainListRequest, mailServiceChangeRequest, suspendDomainRequest,
  suspendAccountRequest, getAllDomainSuspensionsRequest, getAllAccountSuspensionsRequest,
  unsuspendDomainRequest, unsuspendAccountRequest, domainActionHistoryRequest, accountsActionHistoryRequest,
  fetchActionHistoryDetailsRequest, domainReactivateRequest, fetchEmailAccountsToRecoverRequest, recoverEmailAccountsRequest, changeCustomerEmail, modifyAccountLimit,
  getCustomerOrderList, getAllCustomerSuspensionsRequest, unsuspendCustomerRequest,
  suspendCustomerRequest, domainReactivationCheckRequest, getAllTransactionsRequest, refundRequest, orderExtendPlanRequest, orderDowngradePlanRequest, downloadSuspensionLogFile
} from "../service/orderService";
import { toast } from "react-toastify";
import { ORDER, ENTITY_TYPE, getFormattedMessage } from "../components/utils";
import { ORDER_DETAIL_ACTION } from "../utils/const";
import moment from "moment";


const fetchOrderListRequest = (shouldClearPreviousList) => {
  return {
    type: "FETCH_ORDER_LIST_REQUEST",
    payload: { clearOrderList: shouldClearPreviousList }
  };
};

const fetchOrderListSuccess = (data, queryText) => {
  return {
    type: "FETCH_ORDER_LIST_SUCCESS",
    payload: { data: data, queryText: queryText }
  };
};

const fetchSingleOrderListDetailsSuccess = (data, queryText, currentOrder) => {
  return {
    type: "FETCH_SINGLE_ORDER_LIST_DETAIL_SUCCESS",
    payload: {
      data: data,
      queryText: queryText,
      currentOrder: currentOrder
    }
  };
};

const fetchOrderListError = error => {
  return {
    type: "FETCH_ORDER_LIST_ERROR",
    payload: { error: error }
  };
};

const fetchOrderDetailsRequest = (index, orderType) => {
  return {
    type: "FETCH_ORDER_DETAILS_REQUEST",
    payload: {
      index: index,
      orderType: orderType
    }
  };
};

const fetchOrderDetailsSuccess = (data, partnerId) => {
  return {
    type: "FETCH_ORDER_DETAILS_SUCCESS",
    payload: { data: data }
  };
};

const fetchOrderDetailsError = error => {
  return {
    type: "FETCH_ORDER_DETAILS_ERROR",
    payload: { error: error }
  };
};

const navigateBackToOrderList = () => {
  return {
    type: "NAVIGATE_BACK_TO_ORDER_LIST",
  };
}

const fetchDnsDetailsRequest = domainId => {
  return {
    type: "FETCH_DNS_DETAILS_REQUEST",
    payload: { domainId: domainId }
  };
};

const fetchDnsDetailsSuccess = data => {
  return {
    type: "FETCH_DNS_DETAILS_SUCCESS",
    payload: data
  };
};

const fetchDnsDetailsError = data => {
  return {
    type: "FETCH_DNS_DETAILS_ERROR",
    payload: data
  };
};

const initiateOrderChange = () => {
  return {
    type: "INITIATE_ORDER_CHANGE",
  };
};

const closeOrderChange = () => {
  return {
    type: "CLOSE_ORDER_CHANGE",
  };
};

const deleteOrderRequest = () => {
  return {
    type: "ORDER_DELETE_REQUEST",
  };
};

const deleteOrderSuccess = data => {
  return {
    type: "ORDER_DELETE_SUCCESS",
    payload: data
  };
};

const deleteOrderError = data => {
  return {
    type: "ORDER_DELETE_ERROR",
    payload: data
  };
};

const fetchAccountsExceedingQuotaRequest = () => {
  return {
    type: "FETCH_ACCOUNTS_EXCEEDING_QUOTA_REQUEST"
  }
}

const fetchAccountsExceedingQuotaSuccess = data => {
  return {
    type: "FETCH_ACCOUNTS_EXCEEDING_QUOTA_SUCCESS",
    payload: data
  }
}

const fetchAccountsExceedingQuotaError = error => {
  return {
    type: "FETCH_ACCOUNTS_EXCEEDING_QUOTA_ERROR",
    payload: error
  }
}

const fetchCustomerDomainListRequest = () => {
  return {
    type: "FETCH_CUSTOMER_DOMAIN_LIST_REQUEST"
  }
}

const fetchCustomerDomainListSuccess = data => {
  return {
    type: "FETCH_CUSTOMER_DOMAIN_LIST_SUCCESS",
    payload: data
  }
}

const fetchCustomerDomainListError = error => {
  return {
    type: "FETCH_CUSTOMER_DOMAIN_LIST_ERROR",
    payload: error
  }
}


const changePlanOrderRequest = () => {
  return {
    type: "ORDER_CHANGE_PLAN_REQUEST"
  }
}

const changePlanOrderSuccess = () => {
  return {
    type: "ORDER_CHANGE_PLAN_SUCCESS"
  }
}

const changePlanOrderError = data => {
  return {
    type: "ORDER_CHANGE_PLAN_ERROR",
    payload: data
  };
}

const changeMailServiceRequest = () => {
  return {
    type: "MAIL_SERVICE_CHANGE_REQUEST"
  }
}

const changeMailServiceSuccess = data => {
  return {
    type: "MAIL_SERVICE_CHANGE_SUCCESS",
    payload: data
  }
}

const changeMailServiceError = error => {
  return {
    type: "MAIL_SERVICE_CHANGE_ERROR",
    payload: error
  };
}

const clearStore = () => {
  return {
    type: "CLEAR_ORDER_STORE"
  }
}

export const cancelOrdersRequest = () => {
  return {
    type: "CANCEL_ORDER_REQUEST"
  }
};

const suspensionRequest = () => {
  return {
    type: "SUSPENSION_REQUEST"
  }
}

const suspensionSuccess = () => {
  return {
    type: "SUSPENSION_SUCCESS"
  }
}

const suspensionError = error => {
  return {
    type: "SUSPENSION_ERROR",
    payload: error
  }
}

const allSuspensionsRequest = (entityType) => {
  return {
    type: "ALL_SUSPENSIONS_REQUEST",
    payload: {
      entityType: entityType
    }
  }
}

const allSuspensionsSuccess = (data, entityType) => {
  return {
    type: "ALL_SUSPENSIONS_SUCCESS",
    payload: {
      data: data,
      entityType: entityType
    }
  }
}

const allSuspensionsError = (error, entityType) => {
  return {
    type: "ALL_SUSPENSIONS_ERROR",
    payload: {
      error: error,
      entityType: entityType
    }
  }
}

const unsuspensionRequest = () => {
  return {
    type: "UNSUSPENSION_REQUEST"
  }
}

const unsuspensionSuccess = () => {
  return {
    type: "UNSUSPENSION_SUCCESS"
  }
}

const unsuspensionError = error => {
  return {
    type: "UNSUSPENSION_ERROR",
    payload: error
  }
}

const actionHistoryRequest = entityType => {
  return {
    type: "FETCH_HISTORY_REQUEST",
    payload: entityType
  }
}

const actionHistoryPaginationRequest = entityType => {
  return {
    type: "FETCH_HISTORY_PAGINATION_REQUEST",
    payload: entityType
  }
}

const actionHistorySuccess = data => {
  return {
    type: "FETCH_HISTORY_SUCCESS",
    payload: data
  }
}

const actionHistoryError = error => {
  return {
    type: "FETCH_HISTORY_ERROR",
    payload: error
  }
}

export const navigateBackActionHistoryList = () => {
  return {
    type: "NAVIGATE_BACK_HISTORY_LIST"
  }
}

const actionHistoryDetailsRequest = actionType => {
  return {
    type: "FETCH_HISTORY_DETAILS_REQUEST",
    payload: actionType
  }
}

const actionHistoryDetailsSuccess = data => {
  return {
    type: "FETCH_HISTORY_DETAILS_SUCCESS",
    payload: data
  }
}

const actionHistoryDetailsError = error => {
  return {
    type: "FETCH_HISTORY_DETAILS_ERROR",
    payload: error
  }
}

const customerOrderListRequest = () => {
  return {
    type: "FETCH_CUSTOMER_ORDER_LIST_REQUEST"
  }
}

const customerOrderListSuccess = (email, orders) => {
  return {
    type: "FETCH_CUSTOMER_ORDER_LIST_SUCCESS",
    payload: { email, orders }
  }
}

const customerOrderListError = () => {
  return {
    type: "FETCH_CUSTOMER_ORDER_LIST_ERROR"
  }
}

const emailAccountsToRecoverRequest = () => {
  return {
    type: "EMAIL_ACCOUNTS_TO_RECOVER_REQUEST"
  }
}

const emailAccountsToRecoverSuccess = data => {
  return {
    type: "EMAIL_ACCOUNTS_TO_RECOVER_SUCCESS",
    payload: data
  }
}

const emailAccountsToRecoverError = data => {
  return {
    type: "EMAIL_ACCOUNTS_TO_RECOVER_ERROR",
    payload: data
  }
}

const recoverAccountsRequest = () => {
  return {
    type: "RECOVER_ACCOUNTS_REQUEST"
  }
}

const recoverAccountsSuccess = (data, domainName) => {
  return {
    type: "RECOVER_ACCOUNTS_SUCCESS",
    payload: { data, domainName }
  }
}

const recoverAccountsError = data => {
  return {
    type: "RECOVER_ACCOUNTS_ERROR",
    payload: data
  }
}

export const navigateBackRecoverEmailAccounts = () => {
  return {
    type: "NAVIGATE_BACK_RECOVER_EMAIL_ACCOUNTS"
  }
}

export const navigateBackActionHistoryDetails = () => {
  return {
    type: "NAVIGATE_BACK_HISTORY_DETAILS"
  }
}


export const resetCustomerSuspensionChanges = () => {
  return {
    type: "RESET_CUSTOMER_SUSPENSION_CHANGES"
  }
}

const reactivateDomainRequest = () => {
  return {
    type: "REACTIVATE_DOMAIN_REQUEST"
  }
}

const reactivateDomainSuccess = () => {
  return {
    type: "REACTIVATE_DOMAIN_SUCCESS"
  }
}

const reactivateDomainError = () => {
  return {
    type: "REACTIVATE_DOMAIN_ERROR"
  }
}

const checkDomainReactivationRequest = () => {
  return {
    type: "DOMAIN_REACTIVATION_CHECK_REQUEST"
  }
}

const checkDomainReactivationSuccess = payload => {
  return {
    type: "DOMAIN_REACTIVATION_CHECK_SUCCESS",
    payload: payload
  }
}

const checkDomainReactivationFailure = () => {
  return {
    type: "DOMAIN_REACTIVATION_CHECK_FAILURE"
  }
}

export const resetChangeCustomerEmailFlagsAction = () => {
  return {
    type: "CHANGE_CUSTOMER_EMAIL_RESET_FLAGS"
  }
}

const changeCustomerEmailRequest = () => {
  return {
    type: "CHANGE_CUSTOMER_EMAIL_REQUEST"
  }
}

const changeCustomerEmailSuccess = () => {
  return {
    type: "CHANGE_CUSTOMER_EMAIL_SUCCESS"
  }
}

const changeCustomerEmailFailure = (error) => {
  return {
    type: "CHANGE_CUSTOMER_EMAIL_FAILURE",
    payload: (error || 'Something went wrong').toString()
  }
}

const modifyAccountLimitRequest = () => {
  return {
    type: "MODIFY_ACCOUNT_LIMIT_REQUEST"
  }
}

const modifyAccountLimitSuccess = () => {
  return {
    type: "MODIFY_ACCOUNT_LIMIT_SUCCESS"
  }
}

const modifyAccountLimitFailure = (error) => {
  return {
    type: "MODIFY_ACCOUNT_LIMIT_FAILURE",
    payload: (error || 'Something went wrong').toString()
  }
}

export const resetAccountLimitPropsAction = () => {
  return {
    type: "RESET_ACCOUNT_LIMIT_PROPS"
  }
}

export const recoveryTaskSyncSuccess = payload => {
  return {
    type: "RECOVERY_TASK_SYNC_SUCCESS",
    payload: payload
  }
}

export const clearRecoveryTaskInfo = () => {
  return {
    type: "CLEAR_RECOVERY_TASK_INFO"
  }
}

export const allTransactionsRequest = () => {
  return {
    type: "ALL_TRANSACTIONS_REQUEST"
  }
}

export const allTransactionsSuccess = payload => {
  return {
    type: "ALL_TRANSACTIONS_SUCCESS",
    payload: payload
  }
}

export const allTransactionsError = payload => {
  return {
    type: "ALL_TRANSACTIONS_ERROR",
    payload: payload
  }
}

export const processRefundRequest = () => {
  return {
    type: "REFUND_REQUEST",
  }
}

export const processRefundSuccess = payload => {
  return {
    type: "REFUND_SUCCESS",
    payload: payload
  }
}

export const processRefundError = payload => {
  return {
    type: "REFUND_ERROR",
    payload: payload
  }
}

export const closeChangeOrder = (domainId, partnerId, accountId, queryText, partnerName, authToken, region) => {
  return function (dispatch) {
    dispatch(closeOrderChange());
    if (domainId) {
      dispatch(fetchOrderDetails(queryText, domainId, partnerId, accountId, authToken, null, partnerName, region));
    }
  }
}

export const initiateChangeOrder = () => {
  return function (dispatch) {
    dispatch(initiateOrderChange());
  }
}

export const fetchOrderList = (queryText, authToken, shouldClearPreviousList = true) => {
  return function (dispatch) {
    dispatch(fetchOrderListRequest(shouldClearPreviousList));
    getOrderList(queryText, authToken)
      .then(responseData => {
        if (responseData.domainOrders.length === 1 && responseData.domainOrders[0].details) {
          const domainOrder = responseData.domainOrders[0]
          domainOrder.details.partnerName = domainOrder.partnerName
          domainOrder.details.region = domainOrder.region
          dispatch(fetchSingleOrderListDetailsSuccess(responseData, queryText, domainOrder))
        } else if (responseData.customerOrders.length === 1 && responseData.customerOrders[0].details) {
          const customerOrder = responseData.customerOrders[0]
          customerOrder.details.partnerName = customerOrder.partnerName
          customerOrder.details.region = customerOrder.region
          dispatch(fetchSingleOrderListDetailsSuccess(responseData, queryText, customerOrder))
        } else {
          dispatch(fetchOrderListSuccess(responseData, queryText));
        }
      })
      .catch(error => {
        dispatch(fetchOrderListError(error));
      });
  };
};

export const fetchOrderDetails = (
  queryText,
  domainId,
  partnerId,
  accountId,
  authToken,
  index,
  partnerName,
  region,
  orderType = ORDER.DOMAIN
) => {
  return function (dispatch) {
    dispatch(fetchOrderDetailsRequest(index, orderType));
    getOrderDetails(queryText, domainId, partnerId, accountId, authToken, region)
      .then(responseData => {
        responseData.partnerName = partnerName
        responseData.partnerId = partnerId
        responseData.region = region
        dispatch(fetchOrderDetailsSuccess(responseData));
      })
      .catch(error => {
        dispatch(fetchOrderDetailsError(error));
      });
  };
};

export const fetchDnsDetails = (domainId, authToken, region) => {
  return function (dispatch) {
    dispatch(fetchDnsDetailsRequest(domainId));
    getDnsDetails(domainId, authToken, region)
      .then(responseData => {
        dispatch(fetchDnsDetailsSuccess(responseData));
      })
      .catch(error => {
        dispatch(fetchDnsDetailsError(error));
      });
  };
}

export const navigateBackOrderList = (query, authToken) => {
  return function (dispatch) {
    dispatch(navigateBackToOrderList());
    dispatch(fetchOrderList(query, authToken, false));
  };
}

export const deleteOrder = (payload, authToken, region) => {
  return function (dispatch) {
    dispatch(deleteOrderRequest());
    orderDeleteRequest(payload, authToken, region)
      .then(responseData => {
        dispatch(deleteOrderSuccess(responseData));
      })
      .catch(error => {
        dispatch(deleteOrderError(error));
      });
  };
}

/**
 * this is common handler which will be called for all the change plan actions
 * action includes: move to trial plan, change plan, extend plan
 */
const changePlanServiceRequest = (payload, authToken, region) => {
  switch (payload.action) {
    case ORDER_DETAIL_ACTION.MOVE_TO_TRIAL_PLAN:
      return orderChangePlanRequest({
        domainId: payload.domainId,
        planType: payload.newPlan,
        expiryDate: payload.newExpiry,
        reason: payload.reason
      }, authToken, region);

    case ORDER_DETAIL_ACTION.CHANGE_PLAN:
      return orderDowngradePlanRequest({
        domainId: payload.domainId,
        newExpiry: payload.newExpiry,
        newPlan: payload.newPlan,
        reason: payload.reason,
      }, authToken, region);

    case ORDER_DETAIL_ACTION.EXTEND_PLAN:
      return orderExtendPlanRequest({
        domainId: payload.domainId,
        newExpiry: payload.newExpiry,
        reason: payload.reason,
      }, authToken, region);

    default:
      return Promise.reject('Invalid action');
  }
}

export const changePlanOrder = (changePlanRequestBody, authToken, region) => {
  return function (dispatch) {
    dispatch(changePlanOrderRequest());
    changePlanServiceRequest(changePlanRequestBody, authToken, region)
      .then(() => {
        dispatch(changePlanOrderSuccess());
      })
      .catch(error => {
        dispatch(changePlanOrderError(error))
      })
  }
}

export const fetchNoOfAccountsExceedingQuota = (domainId, authToken, region) => {
  return function (dispatch) {
    dispatch(fetchAccountsExceedingQuotaRequest());
    accountsExceedingQuotaRequest(domainId, authToken, region)
      .then(responseData => {
        dispatch(fetchAccountsExceedingQuotaSuccess(responseData))
      })
      .catch(error => {
        dispatch(fetchAccountsExceedingQuotaError(error))
      })
  }
}

export const fetchCustomerDomainList = (userId, authToken, region) => {
  return function (dispatch) {
    dispatch(fetchCustomerDomainListRequest());
    customerDomainListRequest(userId, authToken, region)
      .then(responseData => {
        dispatch(fetchCustomerDomainListSuccess(responseData))
      })
      .catch(error => {
        dispatch(fetchCustomerDomainListError(error))
      })
  }
}

export const changeMailService = (changeMailServiceRequestBody, authToken, region) => {
  return function (dispatch) {
    dispatch(changeMailServiceRequest());
    mailServiceChangeRequest(changeMailServiceRequestBody, authToken, region)
      .then(responseData => {
        dispatch(changeMailServiceSuccess(responseData));
      })
      .catch(error => {
        dispatch(changeMailServiceError(error))
      })
  }
}

export const addSuspension = (suspendRequestBody, authToken, suspensionType, region) => {
  return function (dispatch) {
    dispatch(suspensionRequest());
    const promise = suspensionType === ENTITY_TYPE.DOMAIN ? suspendDomainRequest(suspendRequestBody, authToken, region) :
      suspendAccountRequest(suspendRequestBody, authToken, region)
    promise
      .then(responseData => {
        dispatch(suspensionSuccess());
      })
      .catch(error => {
        dispatch(suspensionError(error))
      })
  }
}

export const addCustomerSuspension = (requestBody, authToken, region) => {
  return function (dispatch) {
    dispatch(suspensionRequest());
    suspendCustomerRequest(requestBody, authToken, region)
      .then(responseData => {
        dispatch(suspensionSuccess());
      })
      .catch(error => {
        dispatch(suspensionError(error))
      })
  }
}

export const getAllSuspensions = (domainId, accountId, authToken, suspensionType, region) => {
  return function (dispatch) {
    dispatch(allSuspensionsRequest(suspensionType));
    const promise = suspensionType === ENTITY_TYPE.DOMAIN ?
      getAllDomainSuspensionsRequest(domainId, authToken, region) :
      getAllAccountSuspensionsRequest(domainId, accountId, authToken, region)
    promise
      .then(responseData => {
        dispatch(allSuspensionsSuccess(responseData, suspensionType));
      })
      .catch(error => {
        dispatch(allSuspensionsError(error, suspensionType))
      })
  }
}

export const getCustomerSuspensions = (requestBody, authToken) => {
  return function (dispatch) {
    dispatch(allSuspensionsRequest(ENTITY_TYPE.CUSTOMER))
    getAllCustomerSuspensionsRequest(requestBody, authToken)
      .then(responseData => {
        const responsePayload = {
          email: requestBody.customerEmail,
          suspensions: responseData.suspensions,
          orders: responseData.orders
        }
        dispatch(allSuspensionsSuccess(responsePayload, ENTITY_TYPE.CUSTOMER))
      })
      .catch(error => {
        dispatch(allSuspensionsError(error, ENTITY_TYPE.CUSTOMER))
      })
  }
}

export const downloadSuspensionLogs = (
  entityType,
  entityID,
  fileIDs,
  authToken
) => {
  return downloadSuspensionLogFile(entityType, entityID, fileIDs, authToken)
    .then(responseData => {
      return Promise.resolve(responseData);
    })
    .catch(error => {
      throw new Error(error);
    });
};

export const removeSuspensions = (unsuspendDomainRequestBody, authToken, suspensionType, region) => {
  return function (dispatch) {
    dispatch(unsuspensionRequest());
    const promise = suspensionType === ENTITY_TYPE.DOMAIN ?
      unsuspendDomainRequest(unsuspendDomainRequestBody, authToken, region) :
      unsuspendAccountRequest(unsuspendDomainRequestBody, authToken, region)
    promise
      .then(responseData => {
        dispatch(unsuspensionSuccess());
      })
      .catch(error => {
        dispatch(unsuspensionError(error))
      })
  }
}

export const removeCustomerSuspensions = (requestBody, authToken) => {
  return function (dispatch) {
    dispatch(unsuspensionRequest())
    unsuspendCustomerRequest(requestBody, authToken)
      .then(responseData => {
        dispatch(unsuspensionSuccess());
      })
      .catch(error => {
        dispatch(unsuspensionError(error))
      })
  }
}

export const fetchActionHistory = (
  domainId, accountIds, entityType, token, region,
  limit, lastId
) => {
  return function (dispatch) {
    const appendRecords = !!lastId;
    if (entityType === ENTITY_TYPE.DOMAIN && appendRecords) {
      // as `lastId` is only applicable to pagination flow
      dispatch(actionHistoryPaginationRequest(entityType))
    } else {
      dispatch(actionHistoryRequest(entityType))
    }
    const promise = entityType === ENTITY_TYPE.DOMAIN ?
      domainActionHistoryRequest(domainId, limit, lastId, token, region) :
      accountsActionHistoryRequest(accountIds, token, region)
    promise
      .then(responseData => {
        dispatch(actionHistorySuccess({ ...responseData, appendRecords }));
      })
      .catch(error => {
        dispatch(actionHistoryError(error))
        let toastText
        if (entityType == ENTITY_TYPE.DOMAIN) {
          toastText = getFormattedMessage("error-toast-domain-action-history")
        }
        else if (entityType == ENTITY_TYPE.ACCOUNT) {
          toastText = getFormattedMessage("error-toast-account-action-history")
        }
        toast.info(toastText)
      })
  }
}

export const fetchAccountHistoryDetails = (actionType, actionId, token, region, paginationProps) => {
  return function (dispatch) {
    dispatch(actionHistoryDetailsRequest(actionType))
    fetchActionHistoryDetailsRequest(actionId, token, region)
      .then(responseData => {
        dispatch(actionHistoryDetailsSuccess({ data: responseData, paginationProps }))
      })
      .catch(error => {
        dispatch(actionHistoryDetailsError(error))
        const toastText = getFormattedMessage("error-toast-action-history-details")
        toast.info(toastText)
      })
  }
}

export const fetchCustomerOrders = (customerEmail, customerIds, token, region) => {
  return function (dispatch) {
    dispatch(customerOrderListRequest())
    getCustomerOrderList(customerEmail, customerIds, token, region)
      .then(responseData => {
        dispatch(customerOrderListSuccess(customerEmail, responseData.orders))
      })
      .catch(error => {
        dispatch(customerOrderListError())
      })
  }
}

export const fetchEmailAccountsToRecover = (domainName, authToken, region) => {
  return function (dispatch) {
    dispatch(emailAccountsToRecoverRequest());
    fetchEmailAccountsToRecoverRequest(domainName, authToken, region)
      .then(responseData => {
        const orders = responseData.recoverableOrders
        if (orders.length > 0 && orders.some((item) => { return item.accountDetails.length > 0 })) {
          dispatch(emailAccountsToRecoverSuccess(responseData))
        } else {
          const toastText = getFormattedMessage("error-toast-no-accout-recover")
          toast.info(toastText)
          dispatch(emailAccountsToRecoverError(toastText))
        }
      })
      .catch(error => {
        dispatch(emailAccountsToRecoverError(error))
        const toastText = getFormattedMessage("error-toast-accout-recover-failure")
        toast.info(toastText)
      })
  }
}
export const recoverEmailAccounts = (requestBody, domainName, authToken, region) => {
  return function (dispatch) {
    dispatch(recoverAccountsRequest())
    recoverEmailAccountsRequest(requestBody, authToken, region)
      .then(responseData => {
        dispatch(recoverAccountsSuccess(responseData, domainName))
      })
      .catch(error => {
        dispatch(recoverAccountsError(error))
        toast.info(error)
      })
  }
}
export const clearOrderStore = () => {
  return function (dispatch) {
    dispatch(clearStore())
  }
}

export const reactivateDomain = (domainId, authToken, region) => {
  return function (dispatch) {
    dispatch(reactivateDomainRequest())
    const requestBody = { domainId }
    domainReactivateRequest(requestBody, authToken, region)
      .then(responseData => {
        dispatch(reactivateDomainSuccess(responseData))
      })
      .catch(error => {
        dispatch(reactivateDomainError())
      })
  }
}

export const checkDomainReactivation = (domainId, authToken, region) => {
  return function (dispatch) {
    dispatch(checkDomainReactivationRequest())
    const requestBody = { domainId }
    domainReactivationCheckRequest(requestBody, authToken, region)
      .then(responseData => {
        dispatch(checkDomainReactivationSuccess(responseData))
      })
      .catch(error => {
        dispatch(checkDomainReactivationFailure())
      })
  }
}

export const changeCustomerEmailAction = (userId, customerEmail, reason, authToken, region) => {
  return function (dispatch) {
    dispatch(changeCustomerEmailRequest())
    const requestBody = { userId, customerEmail, reason }
    return changeCustomerEmail(requestBody, authToken, region)
      .then(responseData => {
        dispatch(changeCustomerEmailSuccess(responseData));
        return Promise.resolve(responseData);
      })
      .catch(error => {
        dispatch(changeCustomerEmailFailure(error));
        return Promise.reject(error);
      });
  }
}

export const modifyAccountLimitAction = (domainId, newAccountLimit, reason, authToken, region) => {
  return function (dispatch) {
    dispatch(modifyAccountLimitRequest())
    const requestBody = { domainId, newAccountLimit, reason }
    return modifyAccountLimit(requestBody, authToken, region)
      .then(responseData => {
        dispatch(modifyAccountLimitSuccess(responseData));
        return Promise.resolve(responseData);
      })
      .catch(error => {
        dispatch(modifyAccountLimitFailure(error));
        return Promise.reject(error);
      });
  }
}

export const getAllTransactions = (domainId, authToken, region) => {
  return function (dispatch) {
    dispatch(allTransactionsRequest())
    getAllTransactionsRequest(domainId, authToken, region)
      .then(responseData => {
        dispatch(allTransactionsSuccess(responseData))
      })
      .catch(error => {
        const toastText = getFormattedMessage("request-failed-try-later")
        toast.info(toastText)
        dispatch(closeOrderChange());
        dispatch(allTransactionsError(error))
      })
  }
}

export const requestRefundAction = (requestBody, authToken, region) => {
  return function (dispatch) {
    dispatch(processRefundRequest())
    refundRequest(requestBody, authToken, region)
      .then(responseData => {
        dispatch(processRefundSuccess(responseData))
      })
      .catch(error => {
        dispatch(processRefundError(error))
      })
  }
}