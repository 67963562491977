import * as React from 'react';
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import InvitesSentImg from "../assets/invites_sent.png"
import { FormattedMessage } from "react-intl";

type Props = {
    closeModal: (shouldRefetchUserList: boolean) => void,
    numberOfInvitesSent: number,
    numberOfInvitesNotSent: number
}

class AddUserSuccessModal extends React.PureComponent<Props> {
    render() {
        return (
            <div>
                <CloseGrey className="alert-modal-close-button" onClick={() => this.props.closeModal(true)} />
                <div className="add-user-success-modal-container">
                    <img className="invites-sent-modal-image" src={InvitesSentImg} alt="invites sent img"/>
                    <div className="invites-sent-text"><FormattedMessage id='new-user-invites-sent' 
                        values={{ count: this.props.numberOfInvitesSent }} /></div>
                    { this.props.numberOfInvitesNotSent > 0 && <div className="invites-not-sent-text"><FormattedMessage id='invites-not-sent' 
                        values={{ count: this.props.numberOfInvitesNotSent }} /></div>}
                </div>
            </div>
        )
    }
}

export default AddUserSuccessModal