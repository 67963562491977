import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { resetPassword } from "../actions/authActions";
import { ReactComponent as OpenEye } from "../assets/open-eye.svg"
import { ReactComponent as CloseEye } from "../assets/close-eye.svg"
import { FormattedMessage, injectIntl } from "react-intl";
import { validatePassword } from './utils';

class ResetPasswordForm extends Component {

    constructor(props) {
        super(props);
        this.isOldPassword = false;
        this.isNewPassword = false;
        this.isConfirmPassword = false;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleOldPasswordVisibility = this.toggleOldPasswordVisibility.bind(this);
        this.toggleNewPasswordVisibility = this.toggleNewPasswordVisibility.bind(this);
        this.handleOldPasswordChange = this.handleOldPasswordChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
        this.evaluateNewPasswordError = this.evaluateNewPasswordError.bind(this);
        this.evaluateConfirmPasswordError = this.evaluateConfirmPasswordError.bind(this);
        this.clearNewPasswordError = this.clearNewPasswordError.bind(this);
        this.clearConfirmPasswordError = this.clearConfirmPasswordError.bind(this);
        this.state = {
            oldPasswordIsMasked: true,
            newPasswordIsMasked: true,
            isOldPassword: true,
            isNewPassword: true,
            isConfirmPassword: true,
            oldPasswordSubmitted: "",
            newPasswordEntered: "",
            confirmPasswordEntered: "",
            oldPasswordEntered: "",
            oldPasswordError: "",
            newPasswordError: "",
            confirmPasswordError: ""
        };
    }

    evaluateOldPasswordError = () => {
        if (this.props.passwordResetError && (this.state.oldPasswordEntered === this.state.oldPasswordSubmitted)) {
            this.setState(prevState => ({
                ...prevState,
                oldPasswordError: this.props.passwordResetError
            }))
            this.isOldPassword = false
        }
    }
    evaluateNewPasswordError = (newPassword, oldPassword) => {
        if (newPassword && !validatePassword(newPassword)) {
            this.isNewPassword = false
            return <FormattedMessage id="password-weak-error" />;
        }
        else if (newPassword && newPassword === oldPassword) {
            this.isNewPassword = false;
            return <FormattedMessage id="old-password-new-password-mismatch" />;
        }
        else {
            this.isOldPassword = true;
            this.isNewPassword = true;
            return '';
        }
    };

    evaluateConfirmPasswordError = (confirmPassword, newPassword) => {
        if (!confirmPassword) {
            return '';
        }
        else if (confirmPassword !== newPassword) {
            this.isConfirmPassword = false
            return <FormattedMessage id="password-mismatch-error" />
        }
        else {
            this.isConfirmPassword = true;
            return '';
        }
    }

    toggleOldPasswordVisibility = () => {
        this.setState(prevState => ({
            ...prevState,
            oldPasswordIsMasked: !prevState.oldPasswordIsMasked,
        }));
    }

    toggleNewPasswordVisibility = () => {
        this.setState(prevState => ({
            ...prevState,
            newPasswordIsMasked: !prevState.newPasswordIsMasked,
        }));
    }

    handleOldPasswordChange = e => {
        const passwordEntered = e.target.value
        this.setState(prevState => ({
            ...prevState,
            oldPasswordEntered: passwordEntered,
            newPasswordError: this.evaluateNewPasswordError(this.state.newPasswordEntered, passwordEntered)
        }))
    }

    clearNewPasswordError = () => {
        this.setState(prevState => ({
            ...prevState,
            newPasswordError: ""
        }))
        this.isNewPassword = true
    }

    clearConfirmPasswordError = () => {
        this.setState(prevState => ({
            ...prevState,
            confirmPasswordError: ""
        }))
        this.isConfirmPassword = true
    }

    handleNewPasswordChange = e => {
        const passwordEntered = e.target.value
        this.setState(prevState => ({
            ...prevState,
            newPasswordEntered: passwordEntered,
            newPasswordError: this.evaluateNewPasswordError(passwordEntered, this.state.oldPasswordEntered),
            confirmPasswordError: this.evaluateConfirmPasswordError(this.state.confirmPasswordEntered, passwordEntered)
        }))
    }

    handleConfirmPasswordChange = e => {
        const passwordEntered = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            confirmPasswordEntered: passwordEntered,
            confirmPasswordError: this.evaluateConfirmPasswordError(passwordEntered, this.state.newPasswordEntered)
        }))
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            oldPasswordSubmitted: this.state.oldPasswordEntered
        })
        const token = this.props.token;
        this.props.resetPassword(this.state.oldPasswordEntered, this.state.newPasswordEntered, token);
        console.log(this.state)
    };

    render() {
        const passwordResetErrorVisibility = this.props.passwordResetError && (this.state.oldPasswordEntered === this.state.oldPasswordSubmitted) && !this.props.isPasswordChanging
        const { oldPasswordIsMasked, newPasswordIsMasked } = this.state;
        const openEye = <OpenEye className="toggle-password-icon" />
        const closeEye = <CloseEye className="toggle-password-icon" />
        return (
            <div className="reset-password">
                <div className="form-header">
                    <h3 className="reset-password-heading">
                        <FormattedMessage id="set-password-change-password-header" />
                    </h3>
                    <h3 className="form-sub-heading">
                        <FormattedMessage id="set-password-subheader" />
                    </h3>
                </div>
                <div className="form reset-password-form">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-item">
                            <div>
                                <label className="input-label reset-label">
                                    <FormattedMessage id="set-password-old-password-header" />
                                </label>
                            </div>
                            <div className="input input-box input-div">
                                {<input
                                    className="no-border-input"
                                    type={oldPasswordIsMasked ? 'password' : 'text'}
                                    placeholder={this.props.intl.formatMessage({ id: 'set-password-old-password-hint' })}
                                    autoFocus
                                    onChange={this.handleOldPasswordChange}
                                    value={this.state.oldPasswordEntered}
                                    name="oldPassword" />}
                                <span className="toggle-password" onClick={this.toggleOldPasswordVisibility}>{oldPasswordIsMasked ? closeEye : openEye}</span>
                            </div>
                            {passwordResetErrorVisibility ? <div className="error reset-password-error">{this.props.passwordResetError} </div> : ""}
                        </div>
                        <div className="form-item">
                            <div>
                                <label className="input-label reset-label">
                                    <FormattedMessage id="set-password-new-password-header" />
                                </label>
                            </div>
                            <div className="input input-box input-div">
                                {<input
                                    className="no-border-input"
                                    type={newPasswordIsMasked ? 'password' : 'text'}
                                    placeholder={this.props.intl.formatMessage({ id: 'set-password-new-password-hint' })}
                                    onBlur={this.handleNewPasswordChange}
                                    onChange={this.clearNewPasswordError}
                                    name="newPassword" />}
                                <span className="toggle-password" onClick={this.toggleNewPasswordVisibility}>{newPasswordIsMasked ? closeEye : openEye}</span>
                            </div>
                            {this.state.newPasswordError ? <div className="error reset-password-error">{this.state.newPasswordError} </div> : ""}
                        </div>
                        <div className="form-item">
                            <div>
                                <label className="input-label reset-label">
                                    <FormattedMessage id="set-password-confirm-password-header" />
                                </label>
                            </div>
                            <div className="input input-box input-div">
                                {<input
                                    className="no-border-input"
                                    type="password"
                                    placeholder={this.props.intl.formatMessage({ id: 'set-password-confirm-password-hint' })}
                                    onBlur={this.handleConfirmPasswordChange}
                                    onChange={this.clearConfirmPasswordError} />}
                            </div>
                            {this.state.confirmPasswordError ? <div className="error reset-password-error">{this.state.confirmPasswordError} </div> : ""}
                        </div>
                        <button
                            className="button button-primary reset-password-button"
                            disabled={!(this.isOldPassword && this.isNewPassword && this.isConfirmPassword)}
                        >
                            <span className="reset-password-text">
                                <FormattedMessage id="change-password" />
                            </span>
                        </button>

                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        token: store.auth.token,
        passwordResetError: store.auth.passwordResetError,
        isPasswordChanging: store.auth.isPasswordChanging
    };
};

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ resetPassword }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResetPasswordForm));