import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, CircularProgress, Typography } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { fetchPartners } from '../../actions/adminActions';
import { FormattedMessage } from 'react-intl';
import { ROLE } from '../utils';
import { getMigrationRelevantPartnerIds } from '../../utils/bulk-migration';

const shouldFetchPartners = (userRole) => ![ROLE.PARTNER, ROLE.PARTNER_ADMIN].includes(userRole);

const filterPartners = (partners, partnerIdsToShow) => 
  partners.filter(partner => partnerIdsToShow.includes(partner.partnerId));

const PartnerSelection = ({ selectedPartnerId, setSelectedPartnerId, classes }) => {
  const dispatch = useDispatch();
  const { isPartnersInfoFetchInProgress, partnerList, isPartnerInfoFetchError } = useSelector((state) => state.users.partnerDetails);
  const { userRole, userPartners, token: authToken } = useSelector((state) => state.auth);
  
  const partnerIdsToShow = getMigrationRelevantPartnerIds();
  const partners = filterPartners([ROLE.PARTNER, ROLE.PARTNER_ADMIN].includes(userRole) ? userPartners : partnerList, partnerIdsToShow);

  useEffect(() => {
    if (shouldFetchPartners(userRole)) {
      dispatch(fetchPartners(authToken));
    }
  }, []);

  useEffect(() => {
    if (partners.length > 0 && !selectedPartnerId) {
      setSelectedPartnerId(partners[0].partnerId);
    }
  }, [partners, selectedPartnerId, setSelectedPartnerId]);

  if (isPartnersInfoFetchInProgress) {
    return <CircularProgress />;
  }
  if (isPartnerInfoFetchError) {
    return <Typography color="error">{isPartnerInfoFetchError}</Typography>;
  }
  if (!partners.length) {
    return <Typography><FormattedMessage id="no-partners-available" /></Typography>;
  }

  return (
    <FormControl variant="outlined" className={classes.dropdown} size="small">
      <InputLabel id="select-partner"><FormattedMessage id="select-partner" /></InputLabel>
      <Select
        labelId="select-partner"
        value={selectedPartnerId}
        onChange={(event) => setSelectedPartnerId(event.target.value)}
        label="Select Partner"
        classes={{ root: classes.selectedItem }}
      >
        {partners.map(partner => (
          <MenuItem key={partner.partnerId} value={partner.partnerId} className={classes.menuItem}>
            {partner.partnerName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(PartnerSelection);
