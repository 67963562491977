import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import styles from './styles';
import { BULK_MIGRATION_ACTION, HELP_ARTICLE_URLS } from '../../utils/const';

const instructions = {
  [BULK_MIGRATION_ACTION.CREATE_MAILBOXES_ONLY]: {
    yes: {
      task: ['task-create-mailboxes-and-migrate'],
      requirements: [
        'requirement-mailboxes-already-present',
        'requirement-imap-hostname',
        'requirement-plain-text-passwords',
      ],
    },
    no: {
      task: ['task-create-mailboxes'],
      requirements: [
        'requirement-mailboxes-already-present',
        'requirement-plain-text-passwords-only',
      ],
    },
  },
  [BULK_MIGRATION_ACTION.CREATE_ORDERS_AND_MAILBOXES]: {
    yes: {
      task: ['task-provision-domains-and-migrate'],
      requirements: [
        'requirement-list-of-domains',
        'requirement-imap-hostname',
        'requirement-plain-text-passwords',
      ],
    },
    no: {
      task: ['task-provision-domains'],
      requirements: [
        'requirement-list-of-domains',
        'requirement-plain-text-passwords-only',
      ],
    },
  },
};

const TaskInstructions = ({ classes, bulkMigrationFormData }) => {
  const { createOnTitan, migrateEmails } = bulkMigrationFormData || {};

  if (!createOnTitan || !migrateEmails) {
    return null;
  }

  const instructionSet = instructions[createOnTitan][migrateEmails];
  return (
    <div className={classes.noteContainer}>
      <div className={classes.noteTitle}>
        <FormattedMessage id="task-what-it-will-do" />
      </div>
      <ul className={classes.bulletList}>
        {instructionSet.task.map((task, index) => (
          <li key={index}>
            <FormattedMessage id={task} />
          </li>
        ))}
      </ul>
      <div className={classes.noteTitle}>
        {createOnTitan === BULK_MIGRATION_ACTION.CREATE_MAILBOXES_ONLY &&
        migrateEmails === "yes" ? (
          <FormattedMessage id="task-what-you-need" />
        ) : (
          <FormattedMessage id="task-make-sure" />
        )}
      </div>
      <ul className={classes.bulletList}>
        {instructionSet.requirements.map((requirement, index) => (
          <li key={index}>
            <FormattedMessage
              id={requirement}
              values={{
                link: (chunks) => (
                  <a
                    href={HELP_ARTICLE_URLS.TITAN_ORDER_ID}
                    target="_blank"
                  >
                    {chunks}
                  </a>
                ),
                b: (word) => <b>{word}</b>
              }}
            />
          </li>
        ))}
      </ul>
      <div>
        <FormattedMessage
          id="support_article"
          values={{
            link: (chunks) => (
              <a
                href={HELP_ARTICLE_URLS.MIGRATION_TOOLKIT}
                target="_blank"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(TaskInstructions);