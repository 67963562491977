const defaultForgotPasswordState = {
  inProgress: false,
  success: false,
  error: ""
}

const defaultSetNewPasswordState = {
  inProgress: false,
  success: false,
  error: ""
}

const defaultAutoLoginState = {
  inProgress: false,
  success: false,
  error: ""
}

const defaultState = {
  email: localStorage.getItem("email"),
  token: localStorage.getItem("token"),
  userRole: localStorage.getItem("userRole"),
  isLoggingIn: false,
  loginError: "",
  isLoggingOut: false,
  isPasswordChangeInitiate: false,
  isPasswordChanging: false,
  passwordResetError: "",
  isTokenInvalid: false,
  navbarOption: "dashboard",
  forgotPasswordState: defaultForgotPasswordState,
  setNewPasswordState: defaultSetNewPasswordState,
  autoLoginState: defaultAutoLoginState,
  autoLoginToken: localStorage.getItem("autoLoginToken"),
  userPartners: JSON.parse(localStorage.getItem("userPartners"))
};

const AuthReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "LOGIN_USER_REQUEST": {
      return {
        ...state,
        email: action.payload.email,
        loginError: "",
        isLoggingIn: true
      };
    }

    case "LOGIN_USER_SUCCESS": {
      return {
        ...state,
        token: action.payload.token,
        userRole: action.payload.role,
        isLoggingIn: false,
        loginError: "",
        autoLoginToken: ""
      };
    }

    case "LOGIN_USER_ERROR": {
      return {
        ...state,
        email: "",
        token: "",
        userRole: "",
        isLoggingIn: false,
        loginError: action.payload.error
      };
    }

    case "INITIATE_PASSWORD_RESET": {
      return {
        ...state,
        isPasswordChangeInitiate: true,
        passwordResetError: ""
      }
    }

    case "CANCEL_PASSWORD_RESET": {
      return {
        ...state,
        isPasswordChangeInitiate: false
      }
    }

    case "RESET_PASSWORD_REQUEST": {
      return {
        ...state,
        isPasswordChanging: true
      }
    }

    case "RESET_PASSWORD_SUCCESS": {
      return {
        ...state,
        token: action.payload.token,
        isPasswordChanging: false,
        isPasswordChangeInitiate: false,
        passwordResetError: ""
      }
    }

    case "RESET_PASSWORD_ERROR": {
      return {
        ...state,
        isPasswordChanging: false,
        passwordResetError: action.payload.error
      };
    }

    case "CLEAR_AUTH_STORE": {
      return {
        ...defaultState,
        email: "",
        token: "",
        userRole: "",
        autoLoginToken: "",
        userPartners: []
      }
    }

    case "INVALIDATE_TOKEN": {
      return {
        ...state,
        isTokenInvalid: true
      }
    }

    case "CLOSE_INVALIDATE_MODAL": {
      return {
        ...state,
        isTokenInvalid: false
      }
    }

    case "NAVBAR_OPTION": {
      return {
        ...state,
        navbarOption: action.payload
      }
    }

    case "ADD_USER_ROLE": {
      return {
        ...state,
        userRole: action.payload
      }
    }

    case "FORGOT_PASSWORD_REQUEST": {
      const newState = {
        ...state.forgotPasswordState
      }
      newState.inProgress = true
      newState.success = false
      newState.error = ""
      return {
        ...state,
        forgotPasswordState: newState
      }
    }

    case "FORGOT_PASSWORD_SUCCESS": {
      const newState = {
        ...state.forgotPasswordState
      }
      newState.inProgress = false
      newState.success = true
      newState.error = ""
      return {
        ...state,
        forgotPasswordState: newState
      }
    }

    case "FORGOT_PASSWORD_ERROR": {
      const newState = {
        ...state.forgotPasswordState
      }
      newState.inProgress = false
      newState.success = false
      newState.error = action.payload
      return {
        ...state,
        forgotPasswordState: newState
      }
    }

    case "CLEAR_FORGOT_PASSWORD_STATE": {
      return {
        ...state,
        forgotPasswordState: defaultForgotPasswordState
      }
    }

    case "SET_NEW_PASSWORD_REQUEST": {
      const newState = {
        ...state.setNewPasswordState
      }
      newState.inProgress = true
      newState.success = false
      newState.error = ""
      return {
        ...state,
        setNewPasswordState: newState
      }
    }

    case "SET_NEW_PASSWORD_SUCCESS": {
      const newState = {
        ...state.setNewPasswordState
      }
      newState.inProgress = false
      newState.success = true
      newState.error = ""
      return {
        ...state,
        setNewPasswordState: newState,
        token: action.payload.token,
        userRole: action.payload.role,
        email: localStorage.getItem("email")
      }
    }

    case "SET_NEW_PASSWORD_ERROR": {
      const newState = {
        ...state.setNewPasswordState
      }
      newState.inProgress = false
      newState.success = false
      newState.error = action.payload
      return {
        ...state,
        setNewPasswordState: newState
      }
    }

    case "UPDATE_USER_INFO_SUCCESS": {
      return {
        ...state,
        userRole: action.payload.role,
        userPartners: action.payload.partners
      }
    }

    case "AUTO_LOGIN_REQUEST": {
      const newState = {
        ...state.autoLoginState
      }
      newState.inProgress = true
      newState.success = false
      newState.error = ""
      return {
        ...state,
        autoLoginState: newState,
        autoLoginToken: action.payload
      }
    }

    case "AUTO_LOGIN_SUCCESS": {
      const newState = {
        ...state.autoLoginState
      }
      newState.inProgress = false
      newState.success = true
      newState.error = ""
      return {
        ...state,
        autoLoginState: newState,
        email: action.payload.emailId,
        token: action.payload.token,
        userRole: action.payload.role,
        autoLoginToken: ""
      }
    }

    case "AUTO_LOGIN_ERROR": {
      const newState = {
        ...state.autoLoginState
      }
      newState.inProgress = false
      newState.success = false
      newState.error = action.payload
      return {
        ...state,
        autoLoginState: newState
      }
    }

    default:
      return state;
  }
};

export default AuthReducer;
