import moment from "moment";
import store from "../store";

/**
 * Class to handle medusa events
 */
class Medusa {
    url = process.env.REACT_APP_MEDUSA_URL || "";

    _sendEvent(payload) {
        return fetch(this.url, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
    }

    _constructReqPayload(eventName, type, entityId, attrs) {
        const { auth } = store.getState();
        return {
            eventTs: Date.now(),
            product: "email",
            service: "partner_panel",
            type,
            entityId,
            eventName,
            attrs: {
                ...attrs,
                actor: "",
                role: auth?.userRole,
                actor_email: auth?.email,
            }
        }
    }

    markDomainSafe() {
        const state = store.getState();
        const order = state.orders.orderDetails.orderDetailsResponse;
        return this._sendEvent(
            this._constructReqPayload(
                "domain_marked_safe",
                "flock_order",
                order.domain.name, {
                domain_id: order.domain.id,
                expired_on: moment(order.domain.plan.expiry).valueOf(),
            })
        );
    }

    markCustomerSafe() {
        const state = store.getState();
        const { orderDetails, domainOrders } = state.orders;
        const { domain } = orderDetails.orderDetailsResponse;
        return this._sendEvent(
            this._constructReqPayload(
                "customer_marked_safe",
                "titan_customer",
                domain.userId, {
                customer_id: domain.userId,
                order_count: domainOrders.length,
            })
        );
    }

    markMailAccountSafe() {
        const state = store.getState();
        const { account } = state.orders.orderDetails.orderDetailsResponse;
        return this._sendEvent(
            this._constructReqPayload(
                "mail_account_marked_safe",
                "flock_account",
                account.id, {
                account_id: account.id,
            })
        );
    }
}

const medusa = new Medusa();

export default medusa;