import moment from "moment";
import { CustomerOrder } from "../model/customerSuspensionDetails";
import { createIntl, createIntlCache } from 'react-intl'
import messages_en from "../translations/en-US.json"
import messages_es from "../translations/es-ES.json"
import messages_pt from "../translations/pt-BR.json"
import { UserDetail } from "../reducers/userManagementReducer";

const messages: any = {
    'en': messages_en,
    'es': messages_es,
    'pt': messages_pt
}

export const DEFAULT_PAGINATION_LIMIT = 5;

export const getCurrentLocale = () => {
    return navigator.language.split(/[-_]/)[0];
}

let localeMessages = messages[getCurrentLocale()]
if (!localeMessages) {
    localeMessages = messages['en']
}

export const getLocaleMessages = () => {
    return localeMessages
}

const cache = createIntlCache()
const intl = createIntl({ locale: getCurrentLocale(), messages: localeMessages }, cache)

export const showEmailSection = (query: string) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(query);
}

const emailRegex = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}"
const ipRegex = "^(?=.*[^\.]$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.?){4}$"

export function getDateForLast24Hours() {
    const date = new Date()
    return date.getTime() - 24 * 60 * 60 * 1000
}

export function getDateForLast7Days() {
    const date = new Date()
    return date.getTime() - 7 * 24 * 60 * 60 * 1000
}

export function getDateForLast30Days() {
    const date = new Date()
    return date.getTime() - 30 * 24 * 60 * 60 * 1000
}

export function isEmailValid(email: string) {
    if (email.match(emailRegex)) {
        return true
    } else {
        return false
    }
}

export function isIpAddressValid(ip: string) {
    return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip))
}

export const STATUS = {
    SUCCESS: "Success",
    FAILURE: "Failure",
    DEFERRED: "Deferred",
    BOUNCED: "Bounced"
}

export const DIRECTION = {
    INCOMING: "Incoming",
    OUTGOING: "Outgoing"
}

export enum ENTITY_TYPE {
    DOMAIN = "domain",
    ACCOUNT = "account",
    CUSTOMER = "customer"
}

export const ORDER = {
    DOMAIN: "domain",
    CUSTOMER: "customer"
}

const domainTypes = new Map();
domainTypes.set("maindomain", "main");
domainTypes.set("subdomain", "sub");
domainTypes.set("addondomain", "addon");
domainTypes.set("parkeddomain", "parked");

export function getDomainType(value: string) {
    return domainTypes.get(value);
}

export const ROLE = {
    PARTNER: "partnerSupport",
    PARTNER_ADMIN: "partnerSupportAdmin",
    TITAN: "titanSupport",
    TITAN_ADMIN: "titanSupportAdmin"
}

const ACTOR_ROLE = {
    ACCOUNT: "account",
    ACCOUNT_ADMIN: "accountAdmin",
    DOMAIN_ADMIN: "domainAdmin",
    PARTNER_SUPPORT: "partnerSupport",
    PARTNER: "partner",
    TITAN_SUPPORT: "titanSupport",
    TITAN: "titan"
}

export const isRoleTitan = (role: string) => {
    if (role) {
        return role === ROLE.TITAN || role === ROLE.TITAN_ADMIN
    }
    else {
        return localStorage.getItem("superUser") === "true"
    }
}

export const isRoleAdmin = (role: string) => {
    return role === ROLE.TITAN_ADMIN || role === ROLE.PARTNER_ADMIN
}

export const disableSuspension = (userRole: string, suspensionRole: string) => {
    return userRole === ROLE.PARTNER && (suspensionRole === ACTOR_ROLE.TITAN_SUPPORT ||
        suspensionRole === ACTOR_ROLE.TITAN)
}

export const unwrapSuspensionReason = (reason?: string): string => {
    return (reason && reason.trim().length > 0) ? reason : "suspension-reason-unknown"
}

export const getActorRoleString = (actorRole: string, userRole: string) => {
    switch (actorRole) {
        case ACTOR_ROLE.ACCOUNT: return "Account"
        case ACTOR_ROLE.ACCOUNT_ADMIN: return "Account admin"
        case ACTOR_ROLE.DOMAIN_ADMIN: return "Primary admin"
        case ACTOR_ROLE.PARTNER_SUPPORT: return "Partner support"
        case ACTOR_ROLE.PARTNER: return "Partner"
        case ACTOR_ROLE.TITAN_SUPPORT: {
            if (isRoleTitan(userRole)) {
                return "Titan support"
            }
            else {
                return "Titan"
            }
        }
        case ACTOR_ROLE.TITAN: return "Titan"
        default: return actorRole
    }
}

export const getRoleString = (role: string) => {
    switch (role) {
        case ROLE.TITAN_ADMIN: return "Titan Admin"
        case ROLE.TITAN: return "Titan Support"
        case ROLE.PARTNER_ADMIN: return "Partner Admin"
        case ROLE.PARTNER: return "Partner Support"
        default: return role
    }
}

export const getSuspensionRolePriority = (suspensionRole: string) => {
    switch (suspensionRole) {
        case ACTOR_ROLE.ACCOUNT: case ACTOR_ROLE.ACCOUNT_ADMIN: return 0;
        case ACTOR_ROLE.DOMAIN_ADMIN: return 1;
        case ACTOR_ROLE.PARTNER_SUPPORT: case ACTOR_ROLE.PARTNER: return 2
        case ACTOR_ROLE.TITAN_SUPPORT: case ACTOR_ROLE.TITAN: return 3
    }
}

export enum PLAN_TYPE {
    FREE = "free",
    PRO_TRIAL = "pro_trial",
    PRO = "pro",
    PREMIUM_TRIAL = "premium_trial",
    PREMIUM = "premium"
}

export const getPlanType = (planType: string) => {
    switch (planType) {
        case "pro_trial": return "Pro trial"
        case "premium_trial": return "Premium trial"
        default: return capitalize(planType)
    }
}

export const getDomainNameFromQuery = (query: string) => {
    return query.substring(query.indexOf("@") + 1)
}

export const getFormattedDate = (timestamp: number) => {
    return moment(timestamp).utc().format("DD MMM YYYY HH:mm:ss z")
}

export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
        return capitalize(txt)
    });
}

export const isStatusActive = (status: string) => {
    return status === "active"
}

export const getActiveAccountsForCustomerOrders = (orders: CustomerOrder[]) => {
    let activeAccounts = 0;
    orders.forEach(order => {
        if (isStatusActive(order.status))
            activeAccounts += order.noOfAccountsCreated
    })
    return activeAccounts;
}

export const getPaidAccountsForCustomerOrders = (orders: CustomerOrder[]) => {
    let paidAccounts = 0;
    orders.forEach(order => {
        if (order.isPaid) {
            paidAccounts += order.noOfAccountsCreated
        }
    })
    return paidAccounts;
}

export const getUserIdsFromCustomerOrders = (orders: CustomerOrder[], region?: string) => {
    let filteredOrders = orders
    if (region) {
        filteredOrders = orders.filter(order => order.region === region)
    }
    const userIds = filteredOrders.map(order => order.userId)
    return Array.from(new Set(userIds))
}

const currency_symbols = new Map([
    ['USD', '$'], // US Dollar
    ['EUR', '€'], // Euro
    ['CRC', '₡'], // Costa Rican Colón
    ['GBP', '£'], // British Pound Sterling
    ['ILS', '₪'], // Israeli New Sheqel
    ['INR', '₹'], // Indian Rupee
    ['JPY', '¥'], // Japanese Yen
    ['KRW', '₩'], // South Korean Won
    ['NGN', '₦'], // Nigerian Naira
    ['PHP', '₱'], // Philippine Peso
    ['PLN', 'zł'], // Polish Zloty
    ['PYG', '₲'], // Paraguayan Guarani
    ['THB', '฿'], // Thai Baht
    ['UAH', '₴'], // Ukrainian Hryvnia
    ['VND', '₫'], // Vietnamese Dong
]);

export const getCurrencySymbol = (currencyCode: string) => {
    return currency_symbols.get(currencyCode);
}

export const getFormattedMessage = (key: string) => {
    return intl.formatMessage({ id: key })
}

export const getEntityTypeFromSuspensionType = (suspensionType: string) => {
    switch (suspensionType) {
        case ENTITY_TYPE.ACCOUNT: return intl.formatMessage({ id: 'account' }).toLowerCase()
        case ENTITY_TYPE.DOMAIN: return intl.formatMessage({ id: 'domain' }).toLowerCase()
        case ENTITY_TYPE.CUSTOMER: return intl.formatMessage({ id: 'customer' }).toLowerCase()
    }
}

export const getPartnerIdsForUser = (user: UserDetail) => {
    return user.partners.map(details => details.partnerId)
}

export enum REGION {
    US = "us",
    EU = "eu"
}

/**
 * function to validate password
 * checks: at least one char, number and special char and min 8 (max 20 as per existing code) digit
 * ref: https://stackoverflow.com/a/72686232/6748242
 * @param val string
 * @returns boolean
 */
export const validatePassword = (val: string): boolean => {
    return !!val.match(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*\W)(?!.* ).{8,20}$/);
}


/**
 * Validates the provided email field based on the direction and returns the appropriate error message.
 * @param {string} email - The email address to be validated.
 * @returns {JSX.Element|null} - Returns a FormattedMessage component with the appropriate error message, or null if the email is valid.
 */
export const validateEmailField = (email: string) => {
    if (!email) {
      return intl.formatMessage({ id: 'string.empty' });
    } else if (!isEmailValid(email)) {
      return intl.formatMessage({ id: 'invalid_email_address' });
    }
    return null;
  };

export const hasDnsError = (dnsInfo: any) => {
  return !dnsInfo || Object.keys(dnsInfo).length === 0 || dnsInfo.error;
};


export const copyToClipboard = (item: string) => {
    const el = document.createElement("textarea");
    el.value = item;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };  


/**
 * Checks if an object is empty.
 * @param obj - The object to check.
 * @returns True if the object is empty, false otherwise.
 */
export const isEmptyObject = (obj: Record<string, any>): boolean => {
    return Object.keys(obj).length === 0;
};
