import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { initializeBulkMigration } from "../../actions/createTaskActions";
import { BULK_MIGRATION_ACTION_LABELS, HELP_ARTICLE_URLS } from "../../utils/const";


const ReviewSummary = ({ classes, validationData, bulkMigrationFormData }) => {
  const dispatch = useDispatch();
  const { token, email } = useSelector((state) => state.auth);
  const [isTaskInitializing, setIsTaskInitializing] = useState(false);
  const [taskInitialized, setTaskInitialized] = useState(false);
  const [error, setError] = useState(null);
  const intl = useIntl();

  const handleInitTask = async () => {
    setIsTaskInitializing(true);
    setError(null);

    try {
      await dispatch(
        initializeBulkMigration(bulkMigrationFormData.selectedPartnerId, validationData.iid, token)
      );
      setTaskInitialized(true);
    } catch (error) {
      const errorMessage = error?.message || intl.formatMessage({ id: 'default-error-message' });
      setError(errorMessage);
    } finally {
      setIsTaskInitializing(false);
    }
  };

  return (
    <div>
      {taskInitialized ? (
        <div>
          <div className={classes.taskQueueSuccessTitle}>
            <FormattedMessage id="task-in-queue" />
          </div>
          <div className={classes.taskQueueSuccessSubtitle}>
            <FormattedMessage id="task-take-a-while" />
          </div>
          <div className={classes.nextStepsTitle}>
            <FormattedMessage id="here-are-the-next-steps" />
          </div>
          <ul className={classes.bulletList}>
            <li>
              <FormattedMessage
                id="task-complete-notification"
                values={{
                  email: email,
                  b: (word) => <b>{word}</b>,
                }}
              />
            </li>
            <li>
              <FormattedMessage id="customers-notified" />
            </li>
            <li>
              <FormattedMessage id="helptext-article" />
              <a
                href={HELP_ARTICLE_URLS.MIGRATION_TOOLKIT_FAQ}
                target="_blank"
                rel="noopener"
              >
                <FormattedMessage id="bulk-migrating-users" />
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <>
          <div className={classes.summaryTitle}>
            <FormattedMessage id="task-summary" />
          </div>
          <div className={classes.summaryContainer}>
            <div className={classes.summaryDetail}>
              <strong>
                <FormattedMessage id="task-name" />
              </strong>{" "}
              {validationData?.taskName}
            </div>
            <div className={classes.summaryDetail}>
              <strong>
                <FormattedMessage id="task-type" />{" "}
              </strong>{" "}
              <FormattedMessage id={`${BULK_MIGRATION_ACTION_LABELS[bulkMigrationFormData.createOnTitan]}`} />
            </div>
            <div className={classes.summaryDetail}>
              <strong>
                <FormattedMessage id="number-of-orders" />{" "}
              </strong>{" "}
              {validationData?.totalOrders}
            </div>
            <div className={classes.summaryDetail}>
              <strong>
                <FormattedMessage id="number-of-mailboxes" />{" "}
              </strong>{" "}
              {validationData?.totalAccounts}
            </div>
          </div>
          <button
            type="primary"
            className="button button-primary"
            onClick={handleInitTask}
            disabled={isTaskInitializing}
          >
            {isTaskInitializing ? (
              <div className="button-loader"></div>
            ) : (
              <FormattedMessage id="confirm-and-begin-task" />
            )}
          </button>
          {error && <Typography color="error">{error}</Typography>}
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(ReviewSummary);
