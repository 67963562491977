import { GET_SUPPORT_DOCS_SUCCESS } from "../actions/createTaskActions";


const initialState = {
  supportDocs: {
    sampleDomainCSV: "",
    sampleEmailCSV: "",
  },
};

const bulkMigrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPORT_DOCS_SUCCESS:
      return {
        ...state,
        supportDocs: action.payload,
      };
    default:
      return state;
  }
};

export default bulkMigrationReducer;
