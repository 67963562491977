import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { setNewPasswordAction } from "../actions/authActions";
import { ReactComponent as Titan } from "../assets/titan.svg"
import { RootState } from '../reducers';
import { ReactComponent as OpenEye } from "../assets/open-eye.svg"
import { ReactComponent as CloseEye } from "../assets/close-eye.svg"
import { ReactComponent as Success } from "../assets/checked.svg"
import { SetNewPasswordInfo } from "../model/SetNewPasswordInfo"
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { validatePassword } from './utils';

type Props = {
    setNewPasswordInfo: SetNewPasswordInfo,
    location: any,
    history: any,
    setNewPasswordAction: (emailId: string, resetToken: string, password: string) => void
} & WrappedComponentProps

interface State {
    newPassword: string,
    confirmPassword: string,
    newPasswordError: string,
    confirmPasswordError: string,
    isNewPasswordMasked: boolean,
    isConfirmPasswordMasked: boolean
}
class SetNewPassword extends Component<Props, State> {
    private timeoutId?: NodeJS.Timeout | null
    state = {
        newPassword: "",
        confirmPassword: "",
        newPasswordError: "",
        confirmPasswordError: "",
        isNewPasswordMasked: true,
        isConfirmPasswordMasked: true
    }
    constructor(props: Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
    }

    componentDidUpdate() {
        if (this.props.setNewPasswordInfo.success) {
            this.timeoutId = setTimeout(() => this.props.history.push("/"), 2000)
        }
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }

    handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        this.setState((prevState: State) => ({
            ...prevState,
            newPassword: newPassword,
            newPasswordError: "",
            confirmPasswordError: ""
        }))
    }

    handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const confirmPassword = e.target.value;
        this.setState((prevState: State) => ({
            ...prevState,
            confirmPassword: confirmPassword,
            newPasswordError: "",
            confirmPasswordError: ""
        }))
    }

    toggleNewPasswordVisibility = () => {
        this.setState((prevState: State) => ({
            ...prevState,
            isNewPasswordMasked: !prevState.isNewPasswordMasked
        }));
    }

    toggleConfirmPasswordVisibility = () => {
        this.setState((prevState: State) => ({
            ...prevState,
            isConfirmPasswordMasked: !prevState.isConfirmPasswordMasked
        }));
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        if (!this.validPassword()) return
        if (this.props.setNewPasswordInfo.inProgress) return
        const params = new URLSearchParams(this.props.location.search);
        const email = params.get("email") || ""
        const resetToken = params.get("resetToken") || ""
        this.props.setNewPasswordAction(email, resetToken, this.state.newPassword)
    };

    validPassword = () => {
        const newPassword = this.state.newPassword
        if (newPassword.length < 8) {
            this.setState(prevState => ({
                ...prevState,
                newPasswordError: "password-min-length-error"
            }))
            return false
        }
        if (newPassword.length > 20) {
            this.setState(prevState => ({
                ...prevState,
                newPasswordError: "password-max-length-error"
            }))
            return false
        }
        if (!validatePassword(newPassword)) {
            this.setState(prevState => ({
                ...prevState,
                newPasswordError: "password-weak-error"
            }))
            return false
        }
        if (newPassword !== this.state.confirmPassword) {
            this.setState(prevState => ({
                ...prevState,
                confirmPasswordError: "password-mismatch-error"
            }))
            return false
        }
        return true
    }

    render() {
        return (
            <div className="table-body">
                <div className="login-logo" >
                    <Titan />
                </div>
                {this.props.setNewPasswordInfo.success ? this.getSuccessLayout() : this.getPasswordLayout()}
            </div >
        );
    }

    getPasswordLayout = () => {
        const buttonText = <div className="action-button-div">
            <div className="action-button-spinner-container">
                {this.props.setNewPasswordInfo.inProgress ? <div className="spinner-action-button" /> : ""}
            </div>
            <span className="action-button-text">
                <FormattedMessage id="set-password-button" />
            </span>
        </div>
        const openEye = <OpenEye className="toggle-password-icon" />
        const closeEye = <CloseEye className="toggle-password-icon" />
        const disableButton = !this.state.newPassword || !this.state.confirmPassword
        return (
            <div className="login-layout">
                <div>
                    <div className="forgot-password-heading">
                        <FormattedMessage id="set-password-header" />
                    </div>
                    <div className="forgot-password-subheading">
                        <FormattedMessage id="set-password-subheader" />
                    </div>
                </div>
                <div className="form">
                    <form onSubmit={this.handleSubmit}>
                        <h4 className="input-label">
                            <FormattedMessage id="set-password-new-password-header" />
                        </h4>
                        <div className="form-item">
                            <div className="input input-box input-div">
                                <input
                                    className="no-border-input"
                                    placeholder={this.props.intl.formatMessage({ id: 'set-password-new-password-hint' })}
                                    type={this.state.isNewPasswordMasked ? 'password' : 'text'}
                                    onChange={this.handleNewPasswordChange}
                                    value={this.state.newPassword} />
                                <span className="toggle-password" onClick={this.toggleNewPasswordVisibility}>{this.state.isNewPasswordMasked ? closeEye : openEye}</span>
                            </div>
                            {this.state.newPasswordError ?
                                <div className="error reset-password-error">
                                    <FormattedMessage id={this.state.newPasswordError} />
                                </div> : ""}
                        </div>
                        <h4 className="input-label">
                            <FormattedMessage id="set-password-confirm-password-header" />
                        </h4>
                        <div className="form-item">
                            <div className="input input-box input-div">
                                <input
                                    className="no-border-input"
                                    placeholder={this.props.intl.formatMessage({ id: 'set-password-confirm-password-hint' })}
                                    type={this.state.isConfirmPasswordMasked ? 'password' : 'text'}
                                    onChange={this.handleConfirmPasswordChange}
                                    value={this.state.confirmPassword} />
                                <span className="toggle-password" onClick={this.toggleConfirmPasswordVisibility}>{this.state.isConfirmPasswordMasked ? closeEye : openEye}</span>
                            </div>
                            {this.state.confirmPasswordError ?
                                <div className="error reset-password-error">
                                    <FormattedMessage id={this.state.confirmPasswordError} />
                                </div> : ""}
                        </div>
                        <button
                            className="button button-primary reset-password-button"
                            disabled={disableButton}>
                            {buttonText}
                        </button>
                        {this.props.setNewPasswordInfo.error && <div className="error login-error">
                            {this.props.setNewPasswordInfo.error}</div>}
                    </form>
                </div>
            </div>
        )
    }

    getSuccessLayout = () => {
        return (
            <div className="login-layout password-changed-div">
                <Success />
                <div className="congratulations-text">
                    <FormattedMessage id="congratulations" />
                </div>
                <div className="password-changed-header">
                    <FormattedMessage id="password-change-success" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        setNewPasswordInfo: state.auth.setNewPasswordState
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ setNewPasswordAction }, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SetNewPassword));
