import React from "react";
import { FormattedMessage } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Form from "../Common/Form";
import TaskInstructions from "./TaskInstructions";
import { BULK_MIGRATION_ACTION, BULK_MIGRATION_ACTION_LABELS } from "../../utils/const";

export const formConfig = [
  {
    type: "text",
    labelId: "name-your-task",
    name: "taskName",
    variant: "outlined",
    fullWidth: true,
    margin: "normal",
    validation: {
      required: true,
    },
  },
  {
    type: "radio",
    labelId: "what-create-on-titan",
    name: "createOnTitan",
    options: [
      {
        value: BULK_MIGRATION_ACTION.CREATE_MAILBOXES_ONLY,
        labelId: BULK_MIGRATION_ACTION_LABELS[BULK_MIGRATION_ACTION.CREATE_MAILBOXES_ONLY],
      },
    ],
    validation: {
      required: true,
    },
  },
  {
    type: "radio",
    labelId: "do-you-want-migrate-email",
    name: "migrateEmails",
    options: [
      { value: "yes", labelId: "yes" },
    ],
    validation: {
      required: true,
    },
  },
];

const TaskDetails = ({
  classes,
  bulkMigrationFormData,
  handleInputChange,
  handleNextChooseFileUpload,
  validateForm,
}) => {

  return (
    <div className={classes.taskDetailsContainer}>
      <div className={classes.formContainer}>
        <Form
          formConfig={formConfig}
          bulkMigrationFormData={bulkMigrationFormData}
          handleInputChange={handleInputChange}
        />
        <button
          type="primary"
          className="button button-primary"
          onClick={handleNextChooseFileUpload}
          disabled={!validateForm(formConfig)}
        >
          <FormattedMessage id="choose-file-to-upload" />
        </button>
      </div>
      <div className={classes.instructionWrapper}>
        <TaskInstructions bulkMigrationFormData={bulkMigrationFormData} />
      </div>
    </div>
  );
};

export default withStyles(styles)(TaskDetails);
