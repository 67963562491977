import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import BodyComponent from "./BodyComponent";
import LoginForm from "./LoginForm";
import ResetPassword from "./ResetPassword";
import NavBar from "./Navbar";
import { cancelResetPassword, setUserRole } from "../actions/authActions";
import { ToastContainer } from 'react-toastify';
import { ReactComponent as CloseWhite } from "../assets/close-white.svg"
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import 'react-toastify/dist/ReactToastify.css';
import { ROLE } from "./utils";
import { withRouter } from "react-router";

class HomePage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const token = this.props.token;
    const closeButton = <button class="toast-button" type="button" aria-label="close"><CloseWhite /></button>;
    if (!token) {
      if (this.props.autoLoginToken && !this.props.autoLoginInfo.error) {
        this.props.history.replace(`/auto-login?token=${this.props.autoLoginToken}`)
        return null
      }
      return <LoginForm />;
    }
    else {
      return (
        <div className="master-layout">
          <NavBar />
          <BodyComponent />
          {this.props.isPasswordChangeInitiate ?
            <div className="modal">
              <div className="modal-content reset-password-modal">
                <span className="close-modal" onClick={this.props.cancelResetPassword}><CloseGrey /></span>
                <ResetPassword />
              </div>
            </div> : ""}
          <ToastContainer autoClose={2000} toastClassName="toast" bodyClassName="toast-body" position="bottom-center" closeButton={closeButton} hideProgressBar={true} />
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    isPasswordChangeInitiate: state.auth.isPasswordChangeInitiate,
    userRole: state.auth.userRole,
    autoLoginInfo: state.auth.autoLoginState,
    autoLoginToken: state.auth.autoLoginToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ cancelResetPassword, setUserRole }, dispatch)
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));