import { AddUsersResponse } from "../model/createUserPayload"

export interface UsersDetailsInfo {
    isUserListFetchInProgress: boolean,
    userListFetchError: boolean,
    userListFetchSuccess: boolean,
    userList: UserDetail[]
}

export interface UserDetail {
    emailId: string,
    partners: PartnerInfo[]
    role: string
}

export interface PartnerInfo {
    partnerName: string,
    partnerId: number
}

export interface PartnerDetailsInfo {
    isPartnersInfoFetchInProgress: boolean,
    isPartnerInfoFetchSuccess: boolean,
    isPartnerInfoFetchError: boolean,
    partnerList: PartnerDetail[]
}

export interface CreateUserInfo {
    isCreateUserRequestInProgress: boolean,
    isCreateUserRequestSuccess: AddUsersResponse | null,
    createUserRequestError: string
}

export interface UpdateUserInfo {
    isUpdateUserRequestInProgress: boolean,
    isUpdateUserRequestSuccess: boolean,
    updateUserRequestError: string
}

export interface DeleteUserInfo {
    isDeleteUserRequestInProgress: boolean,
    isDeleteUserRequestSuccess: boolean,
    deleteUserError: string
}

export interface PartnerDetail {
    partnerName: string,
    partnerId: number
}

const defaultUserDetailsInfo: UsersDetailsInfo = {
    isUserListFetchInProgress: false,
    userListFetchError: false,
    userListFetchSuccess: false,
    userList: []
}

const defaultPartnerDetailsInfo: PartnerDetailsInfo = {
    isPartnersInfoFetchInProgress: false,
    isPartnerInfoFetchError: false,
    isPartnerInfoFetchSuccess: false,
    partnerList: []
}

const defaultCreateUserInfo: CreateUserInfo = {
    isCreateUserRequestInProgress: false,
    isCreateUserRequestSuccess: null,
    createUserRequestError: ""
}

const defaultUpdateUserInfo: UpdateUserInfo = {
    isUpdateUserRequestInProgress: false,
    isUpdateUserRequestSuccess: false,
    updateUserRequestError: ""
}

const defaultDeleteUserInfo: DeleteUserInfo = {
    isDeleteUserRequestInProgress: false,
    isDeleteUserRequestSuccess: false,
    deleteUserError: ""
}

export interface UserManagementState {
    userDetails: UsersDetailsInfo,
    partnerDetails: PartnerDetailsInfo,
    createUserDetails: CreateUserInfo,
    updateUserDetails: UpdateUserInfo,
    deleteUserDetails: DeleteUserInfo,
}

const defaultState: UserManagementState = {
    userDetails: defaultUserDetailsInfo,
    partnerDetails: defaultPartnerDetailsInfo,
    createUserDetails: defaultCreateUserInfo,
    updateUserDetails: defaultUpdateUserInfo,
    deleteUserDetails: defaultDeleteUserInfo
}

const UserManagementReducer = (state: UserManagementState = defaultState, action: any) => {
    switch (action.type) {
        case "USER_LIST_REQUEST": {
            const newUserDetailsInfo: UsersDetailsInfo = {
                isUserListFetchInProgress: true,
                userListFetchError: false,
                userListFetchSuccess: false,
                userList: state.userDetails.userList
            }
            return {
                ...state,
                userDetails: newUserDetailsInfo
            }
        }
        case "USER_LIST_SUCCESS": {
            const newUserDetailsInfo: UsersDetailsInfo = {
                isUserListFetchInProgress: false,
                userListFetchError: false,
                userListFetchSuccess: true,
                userList: action.payload
            }
            return {
                ...state,
                userDetails: newUserDetailsInfo
            }
        }

        case "USER_LIST_ERROR": {
            const newUserDetailsInfo: UsersDetailsInfo = {
                isUserListFetchInProgress: false,
                userListFetchError: true,
                userListFetchSuccess: false,
                userList: state.userDetails.userList
            }
            return {
                ...state,
                userDetails: newUserDetailsInfo
            }
        }

        case "PARTNER_INFO_REQUEST": {
            const newPartnerDetailsInfo: PartnerDetailsInfo = {
                isPartnersInfoFetchInProgress: true,
                isPartnerInfoFetchSuccess: false,
                isPartnerInfoFetchError: false,
                partnerList: []

            }
            return {
                ...state,
                partnerDetails: newPartnerDetailsInfo
            }
        }
        case "PARTNER_INFO_SUCCESS": {
            const newPartnerDetailsInfo: PartnerDetailsInfo = {
                isPartnersInfoFetchInProgress: false,
                isPartnerInfoFetchSuccess: true,
                isPartnerInfoFetchError: false,
                partnerList: action.payload

            }
            return {
                ...state,
                partnerDetails: newPartnerDetailsInfo
            }
        }

        case "PARTNER_INFO_ERROR": {
            const newPartnerDetailsInfo: PartnerDetailsInfo = {
                isPartnersInfoFetchInProgress: false,
                isPartnerInfoFetchSuccess: false,
                isPartnerInfoFetchError: true,
                partnerList: []

            }
            return {
                ...state,
                partnerDetails: newPartnerDetailsInfo
            }
        }

        case "ADD_USERS_REQUEST": {
            const newCreateUserInfo: CreateUserInfo = {
                isCreateUserRequestInProgress: true,
                isCreateUserRequestSuccess: null,
                createUserRequestError: ""
            }
            return {
                ...state,
                createUserDetails: newCreateUserInfo
            }
        }

        case "ADD_USERS_SUCCESS": {
            const newCreateUserInfo: CreateUserInfo = {
                isCreateUserRequestInProgress: false,
                isCreateUserRequestSuccess: action.payload,
                createUserRequestError: ""
            }
            return {
                ...state,
                createUserDetails: newCreateUserInfo
            }
        }

        case "ADD_USERS_ERROR": {
            const newCreateUserInfo: CreateUserInfo = {
                isCreateUserRequestInProgress: false,
                isCreateUserRequestSuccess: null,
                createUserRequestError: action.payload
            }
            return {
                ...state,
                createUserDetails: newCreateUserInfo
            }
        }

        case "UPDATE_USER_REQUEST": {
            const newUpdateUserInfo: UpdateUserInfo = {
                isUpdateUserRequestInProgress: true,
                isUpdateUserRequestSuccess: false,
                updateUserRequestError: ""
            }
            return {
                ...state,
                updateUserDetails: newUpdateUserInfo
            }
        }

        case "UPDATE_USER_SUCCESS": {
            const newUpdateUserInfo: UpdateUserInfo = {
                isUpdateUserRequestInProgress: false,
                isUpdateUserRequestSuccess: true,
                updateUserRequestError: ""
            }
            return {
                ...state,
                updateUserDetails: newUpdateUserInfo
            }
        }

        case "UPDATE_USER_ERROR": {
            const newUpdateUserInfo: UpdateUserInfo = {
                isUpdateUserRequestInProgress: false,
                isUpdateUserRequestSuccess: false,
                updateUserRequestError: action.payload
            }
            return {
                ...state,
                updateUserDetails: newUpdateUserInfo
            }
        }

        case "DELETE_USER_REQUEST": {
            const newDeleteUserInfo: DeleteUserInfo = {
                isDeleteUserRequestInProgress: true,
                isDeleteUserRequestSuccess: false,
                deleteUserError: ""
            }
            return {
                ...state,
                deleteUserDetails: newDeleteUserInfo
            }
        }

        case "DELETE_USER_SUCCESS": {
            const newDeleteUserInfo: DeleteUserInfo = {
                isDeleteUserRequestInProgress: false,
                isDeleteUserRequestSuccess: true,
                deleteUserError: ""
            }
            return {
                ...state,
                deleteUserDetails: newDeleteUserInfo
            }
        }

        case "DELETE_USER_ERROR": {
            const newDeleteUserInfo: DeleteUserInfo = {
                isDeleteUserRequestInProgress: false,
                isDeleteUserRequestSuccess: false,
                deleteUserError: action.payload
            }
            return {
                ...state,
                deleteUserDetails: newDeleteUserInfo
            }
        }

        case "CLEAR_USER_LIST": {
            return {
                ...state,
                userDetails: defaultUserDetailsInfo,
            }
        }

        case "CLOSE_MODAL": {
            return {
                ...state,
                createUserDetails: defaultCreateUserInfo,
                updateUserDetails: defaultUpdateUserInfo,
                deleteUserDetails: defaultDeleteUserInfo
            }
        }

        case "CLEAR_ADMIN_INFO_STORE": {
            return {
                ...defaultState
            }
        }

        default:
            return state;
    }
}

export default UserManagementReducer
