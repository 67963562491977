import React from "react";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Grid,
  withStyles,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import {
  styles,
  useRadioStyles,
  useInputStyles,
  useLabelStyles,
} from "./styles";

const Form = ({
  formConfig,
  bulkMigrationFormData,
  handleInputChange,
  classes,
}) => {
  const radioClasses = useRadioStyles();
  const inputClasses = useInputStyles();
  const labelClasses = useLabelStyles();

  return (
    <form className={classes.formControl} noValidate autoComplete="off">
      <Grid container spacing={2}>
        {formConfig.map((field, index) => (
          <Grid item xs={12} key={`${field.labelId}-${index}`}>
            {field.type === "text" && (
              <FormControl fullWidth>
                <FormLabel className={classes.formLabel}>
                  <FormattedMessage id={field.labelId} />
                </FormLabel>
                <TextField
                  variant={field.variant}
                  fullWidth={field.fullWidth}
                  name={field.name}
                  value={bulkMigrationFormData[field.name]}
                  onChange={handleInputChange}
                  margin={field.margin}
                  InputLabelProps={{
                    className: classes.formLabel,
                  }}
                  InputProps={{
                    classes: {
                      root: inputClasses.root,
                      input: inputClasses.input,
                    },
                  }}
                />
              </FormControl>
            )}
            {field.type === "radio" && (
              <FormControl
                component="fieldset"
                className={classes.formControl}
                fullWidth
              >
                <FormLabel component="legend" className={classes.formLabel}>
                  <FormattedMessage id={field.labelId} />
                </FormLabel>
                <RadioGroup
                  aria-label={field.name}
                  name={field.name}
                  value={bulkMigrationFormData[field.name]}
                  onChange={handleInputChange}
                >
                  {field.options.map((option, idx) => (
                    <FormControlLabel
                      key={idx}
                      value={option.value}
                      control={
                        <Radio
                          classes={{
                            root: radioClasses.root,
                            checked: radioClasses.checked,
                          }}
                          size="small" 
                        />
                      }
                      label={
                        <span className={labelClasses.label}>
                          <FormattedMessage id={option.labelId} />
                        </span>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
        ))}
      </Grid>
    </form>
  );
};

export default withStyles(styles)(Form);
