import { ORDER, getSuspensionRolePriority, ENTITY_TYPE } from "../components/utils";

const defaultSuspensionInfo = {
  fetchSuspensionListInProgress: false,
  fetchSuspensionListSuccess: false,
  suspensionListError: "",
  suspensionList: []
}
const defaultRecoverEmailAccountsState = {
  fetchRecoverableAccountsInProgress: false,
  fetchRecoverableAccountsSuccess: false,
  fetchRecoverableAccountsError: false,
  recoverableOrders: {}
}
const defaultRecoverAccountsInfo = {
  recoverAccountsInProgress: false,
  recoverAccountsSuccess: false,
  recoverAccountsError: false,
  recoverAccountsData: []
}

const defaultReactivateDomainInfo = {
  isReactivateDomainInProgress: false,
  isReactivateDomainSuccess: false,
  isReactivateDomainError: false
}

const defaultDomainReactivationCheckInfo = {
  isDomainReactivationCheckInProgress: false,
  isDomainReactivationCheckSuccess: false,
  isDomainReactivationCheckFailure: false,
  isReactivationAllowed: false
}

const defaultChangeCustomerEmailInfo = {
  changingCustomerEmail: false,
  changedCustomerEmail: false,
  changeCustomerEmailError: ''
}

const defaultModifyAccountLimitProps = {
  changingAccountLimit: false,
  changedAccountLimit: false,
  changeAccountLimitError: ''
}

const defaultAllTransactionsInfo = {
  inProgress: false,
  success: false,
  failure: false,
  data: {}
}

const defaultRefundInfo = {
  inProgress: false,
  success: false,
  error: ""
}

const storedRecoveryTaskInfo = localStorage.getItem("recoveryTaskInfo")
const storedRecoveryWidgetTaskIds = localStorage.getItem("recoveryWidgetTaskIds")

const defaultCustomerSuspensionOrderInfo = {
  fetchCustomerOrderListInProgress: false,
  fetchCustomerOrderListSuccess: false,
  fetchCustomerOrderListError: false,
  orderInfo: {}
}

const defaultState = {
  queryText: "",
  currentDomainName: "",
  currentEmail: "",
  domainOrders: [],
  customerOrders: [],
  orderDetails: {},
  dnsDetails: {},
  orderListError: "",
  orderDetailsError: "",
  isOrderListLoading: false,
  isDnsLoading: false,
  isSingleOrder: false,
  partnerId: "",
  isOrderChangeInitiate: false,
  isOrderDeleting: false,
  isOrderDeleteSuccess: false,
  orderDeleteError: "",
  isOrderChangePlanInProgress: false,
  isOrderChangePlanSuccess: false,
  orderChangePlanError: "",
  isFetchAccountExceedingQuotaInProgress: false,
  noOfAccountsExceedingQuota: 0,
  fetchAccountExceedingQuotaError: "",
  currentOrderId: "",
  isFetchCustomerDomainListInProgress: false,
  domainsForCustomer: [],
  fetchCustomerDomainListError: "",
  isMailServiceChangeSuccess: false,
  isMailServiceChangeInProgress: false,
  mailServiceChangeError: "",
  toggledDomains: [],
  isOrderFetchCancelled: false,
  isSuspensionInProgress: false,
  isSuspensionSuccess: false,
  suspensionError: "",
  isUnsuspensionInProgress: false,
  isUnsuspensionSuccess: false,
  unsuspensionError: "",
  isFetchActionHistoryListInProgress: false,
  actionHistoryList: [],
  actionHistoryListSuccess: false,
  actionHistoryListError: "",
  isFetchActionHistoryPaginationInProgress: false,
  isFetchActionHistoryPaginationSuccess: false,
  isFetchActionHistoryPaginationError: "",
  actionHistoryEntityType: "",
  fetchActionHistoryDetailsInProgress: false,
  actionHistoryPaginationProps: {},
  actionHistoryDetails: {},
  actionHistoryDetailsError: "",
  actionType: "",
  selectedDomainName: "",
  suspensionListInfo: {},
  recoverEmailAccountState: defaultRecoverEmailAccountsState,
  recoverAccountsInfo: defaultRecoverAccountsInfo,
  reactivateDomainInfo: defaultReactivateDomainInfo,
  domainReactivationCheckInfo: defaultDomainReactivationCheckInfo,
  changeCustomerEmailInfo: defaultChangeCustomerEmailInfo,
  modifyAccountLimitState: defaultModifyAccountLimitProps,
  recoveryWidgetTaskIds: storedRecoveryWidgetTaskIds ? JSON.parse(storedRecoveryWidgetTaskIds) : {},
  recoveryTaskInfo: storedRecoveryTaskInfo ? JSON.parse(storedRecoveryTaskInfo) : [],
  customerSuspensionOrderInfo: defaultCustomerSuspensionOrderInfo,
  isCustomerSuspendedOrderList: false,
  isCustomerSuspendedOrderDetails: false,
  currentOrderSelected: {},
  allTransactionsInfo: defaultAllTransactionsInfo,
  refundInfo: defaultRefundInfo,
  customerIdRegionDetails: {},
  isFetchingOrderDetails: false
};

defaultState.suspensionListInfo[ENTITY_TYPE.DOMAIN] = defaultSuspensionInfo
defaultState.suspensionListInfo[ENTITY_TYPE.ACCOUNT] = defaultSuspensionInfo
defaultState.suspensionListInfo[ENTITY_TYPE.CUSTOMER] = defaultSuspensionInfo

const DetailsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "FETCH_ORDER_LIST_REQUEST": {
      const shouldClearList = action.payload.clearOrderList
      return {
        ...state,
        isOrderListLoading: true,
        domainOrders: shouldClearList ? [] : state.domainOrders,
        customerOrders: shouldClearList ? [] : state.customerOrders,
        orderListError: shouldClearList ? "" : state.orderListError,
        orderDetails: {},
        dnsDetails: {},
        isSingleOrder: false,
        orderDetailsError: "",
        isOrderFetchCancelled: false,
        suspensionListInfo: defaultState.suspensionListInfo,
        recoverEmailAccountState: defaultState.recoverEmailAccountState,
        currentOrderSelected: {},
        isUnsuspensionInProgress: false,
        isUnsuspensionSuccess: false,
        unsuspensionError: "",
        customerIdRegionDetails: {}
      };
    }

    case "FETCH_ORDER_LIST_SUCCESS": {
      if (!state.isOrderFetchCancelled) {
        let customerOrders = action.payload.data.customerOrders
        let newCustomerIdRegionDetails = {}
        let isCustomerSuspended = false
        action.payload.data.domainOrders.forEach(order => newCustomerIdRegionDetails[order.userId] = order.region)
        if (customerOrders.length !== 0) {
          const customerStatus = customerOrders[0].customerStatus
          isCustomerSuspended = (customerStatus === "suspended")
          customerOrders.forEach(order => {
            newCustomerIdRegionDetails[order.userId] = order.region
          })
        }
        let domainOrders = action.payload.data.domainOrders
        if (action.payload.data.domain === "endurance.com") {
          domainOrders = domainOrders.slice(0, 5)
          customerOrders = customerOrders.slice(0, 5)
        }
        return {
          ...state,
          domainOrders: domainOrders,
          customerOrders: customerOrders,
          currentDomainName: action.payload.data.domain,
          currentEmail: action.payload.data.email,
          isOrderListLoading: false,
          isOrderDetailsLoading: false,
          orderDetails: {},
          dnsDetails: {},
          orderListError: "",
          isSingleOrder: false,
          orderDetailsError: "",
          queryText: action.payload.queryText,
          isFetchActionHistoryListInProgress: false,
          actionHistoryList: [],
          actionHistoryListSuccess: false,
          actionHistoryListError: "",
          actionHistoryEntityType: "",
          fetchActionHistoryDetailsInProgress: false,
          actionHistoryDetails: {},
          actionHistoryDetailsError: "",
          actionType: "",
          isCustomerSuspendedOrderList: isCustomerSuspended,
          customerIdRegionDetails: newCustomerIdRegionDetails
        };
      }
      return {
        ...state
      }
    }

    case "FETCH_ORDER_LIST_ERROR": {
      if (!state.isOrderFetchCancelled) {
        return {
          ...state,
          domainOrders: [],
          customerOrders: [],
          orderDetails: {},
          dnsDetails: {},
          customerIdRegionDetails: {},
          currentDomainName: "",
          currentEmail: "",
          selectedDomainName: "",
          isOrderListLoading: false,
          orderListError: action.payload.error
        };
      }
      return {
        ...state
      }
    }

    case "FETCH_SINGLE_ORDER_LIST_DETAIL_SUCCESS": {
      if (!state.isOrderFetchCancelled) {
        const customerStatus = action.payload.currentOrder.details.orderDetailsResponse.domain.customerStatus
        let currentOrderSelected, currentDomainName
        const domainOrders = action.payload.data.domainOrders
        const customerOrders = action.payload.data.customerOrders
        if (domainOrders.length !== 0) {
          currentOrderSelected = domainOrders[0]
          currentDomainName = action.payload.data.domain
        }
        else {
          currentOrderSelected = customerOrders[0]
          currentDomainName = currentOrderSelected.domainName
        }
        const newCustomerIdRegionDetails = {}
        newCustomerIdRegionDetails[currentOrderSelected.userId] = currentOrderSelected.region
        const isCustomerSuspended = customerStatus === "suspended"
        return {
          ...state,
          domainOrders: domainOrders,
          customerOrders: customerOrders,
          currentOrderSelected: currentOrderSelected,
          currentDomainName: currentDomainName,
          selectedDomainName: currentDomainName,
          currentEmail: action.payload.data.email,
          currentOrderId: action.payload.currentOrder.orderId,
          isOrderListLoading: false,
          isOrderDetailsLoading: false,
          orderDetails: action.payload.currentOrder.details,
          dnsDetails: action.payload.currentOrder.details.dnsDetails,
          partnerId: action.payload.currentOrder.partnerId,
          orderListError: "",
          orderDetailsError: "",
          isSingleOrder: true,
          queryText: action.payload.queryText,
          isFetchActionHistoryListInProgress: false,
          actionHistoryList: [],
          actionHistoryListSuccess: false,
          actionHistoryListError: "",
          actionHistoryEntityType: "",
          fetchActionHistoryDetailsInProgress: false,
          actionHistoryDetails: {},
          actionHistoryDetailsError: "",
          actionType: "",
          suspensionListInfo: defaultState.suspensionListInfo,
          recoverEmailAccountState: defaultState.recoverEmailAccountState,
          isCustomerSuspendedOrderDetails: isCustomerSuspended,
          customerIdRegionDetails: newCustomerIdRegionDetails
        };
      }
      return {
        ...state
      }
    }

    case "FETCH_ORDER_DETAILS_REQUEST": {
      let newOrderList
      if (action.payload.orderType === ORDER.DOMAIN) {
        newOrderList = state.domainOrders.map((order, index) =>
          ({
            ...order,
            isOrderDetailsLoading: index === action.payload.index
          }))
      } else if (action.payload.orderType === ORDER.CUSTOMER) {
        newOrderList = state.customerOrders.map((order, index) =>
          ({
            ...order,
            isOrderDetailsLoading: index === action.payload.index
          }))
      }

      const newOrderId = action.payload.index == null ? state.currentOrderId : newOrderList[action.payload.index].orderId
      return {
        ...state,
        domainOrders: action.payload.orderType === ORDER.DOMAIN ? newOrderList : state.domainOrders,
        customerOrders: action.payload.orderType === ORDER.CUSTOMER ? newOrderList : state.customerOrders,
        currentOrderId: newOrderId,
        selectedDomainName: "",
        suspensionListInfo: defaultState.suspensionListInfo,
        recoverEmailAccountState: defaultState.recoverEmailAccountState,
        currentOrderSelected: action.payload.index !== null ? newOrderList[action.payload.index] : state.currentOrderSelected,
        isFetchingOrderDetails: true,
      };
    }

    case "FETCH_ORDER_DETAILS_SUCCESS": {
      if (!state.isOrderFetchCancelled) {
        const orderDetails = action.payload.data
        return {
          ...state,
          orderDetails: orderDetails,
          dnsDetails: action.payload.data.dnsDetails,
          partnerId: action.payload.data.partnerId,
          region: action.payload.data.region,
          orderDetailsError: "",
          selectedDomainName: orderDetails.orderDetailsResponse.domain.name,
          isCustomerSuspendedOrderDetails: orderDetails.orderDetailsResponse.domain.customerStatus === "suspended",
          isFetchingOrderDetails: false,
        };
      }
      return {
        ...state,
        isFetchingOrderDetails: false,
      }
    }

    case "FETCH_ORDER_DETAILS_ERROR": {
      if (!state.isOrderFetchCancelled) {
        return {
          ...state,
          domainOrders: [],
          customerOrders: [],
          currentDomainName: "",
          currentEmail: "",
          orderDetails: {},
          dnsDetails: {},
          orderDetailsError: action.payload.error,
          selectedDomainName: "",
          currentOrderSelected: {},
          isFetchingOrderDetails: false,
        };
      }
      return {
        ...state,
        isFetchingOrderDetails: false,
      }
    }

    case "NAVIGATE_BACK_TO_ORDER_LIST": {
      const newOrderList = state.domainOrders.map((order, index) => ({ ...order, isOrderDetailsLoading: false }))
      const newCustomerOrders = state.customerOrders ? state.customerOrders.map((order, index) => ({ ...order, isOrderDetailsLoading: false })) : null
      return {
        ...state,
        domainOrders: newOrderList,
        customerOrders: newCustomerOrders ? newCustomerOrders : state.customerOrders,
        orderDetails: {},
        dnsDetails: {},
        isOrderListLoading: false,
        isDnsLoading: false,
        orderListError: "",
        orderDetailsError: "",
        selectedDomainName: "",
        currentOrderSelected: {}
      }
    }

    case "FETCH_DNS_DETAILS_REQUEST": {
      return {
        ...state,
        isDnsLoading: true
      }
    }

    case "FETCH_DNS_DETAILS_SUCCESS": {
      return {
        ...state,
        dnsDetails: action.payload,
        isDnsLoading: false,
      }
    }

    case "FETCH_DNS_DETAILS_ERROR": {
      return {
        ...state,
        isDnsLoading: false,
      }
    }

    case "INITIATE_ORDER_CHANGE": {
      return {
        ...state,
        isOrderChangeInitiate: true,
        orderDeleteError: "",
        orderChangePlanError: "",
        mailServiceChangeError: "",
        suspensionError: "",
        unsuspensionError: "",
        isReactivateDomainError: false
      }
    }

    case "CLOSE_ORDER_CHANGE": {
      return {
        ...state,
        isOrderChangeInitiate: false,
        isOrderDeleteSuccess: false,
        isOrderChangePlanSuccess: false,
        isMailServiceChangeSuccess: false,
        isSuspensionSuccess: false,
        isUnsuspensionSuccess: false,
        reactivateDomainInfo: defaultReactivateDomainInfo,
        customerSuspensionOrderInfo: defaultCustomerSuspensionOrderInfo,
        domainReactivationCheckInfo: defaultDomainReactivationCheckInfo,
        allTransactionsInfo: defaultAllTransactionsInfo,
        refundInfo: defaultRefundInfo
      }
    }

    case "ORDER_DELETE_REQUEST": {
      return {
        ...state,
        isOrderDeleting: true
      }
    }

    case "ORDER_DELETE_SUCCESS": {
      return {
        ...state,
        isOrderDeleting: false,
        isOrderDeleteSuccess: true,
        orderDeleteError: ""
      }
    }

    case "ORDER_DELETE_ERROR": {
      return {
        ...state,
        isOrderDeleting: false,
        orderDeleteError: action.payload
      };
    }

    case "ORDER_CHANGE_PLAN_REQUEST": {
      return {
        ...state,
        isOrderChangePlanInProgress: true
      }
    }

    case "ORDER_CHANGE_PLAN_SUCCESS": {
      return {
        ...state,
        isOrderChangePlanSuccess: true,
        isOrderChangePlanInProgress: false,
        orderChangePlanError: ""
      }
    }

    case "ORDER_CHANGE_PLAN_ERROR": {
      return {
        ...state,
        isOrderChangePlanInProgress: false,
        orderChangePlanError: action.payload
      };
    }

    case "MAIL_SERVICE_CHANGE_REQUEST": {
      return {
        ...state,
        isMailServiceChangeInProgress: true,
        toggledDomains: []
      }
    }

    case "MAIL_SERVICE_CHANGE_SUCCESS": {
      return {
        ...state,
        isMailServiceChangeSuccess: true,
        isMailServiceChangeInProgress: false,
        mailServiceChangeError: "",
        toggledDomains: action.payload.toggledDomains
      }
    }

    case "MAIL_SERVICE_CHANGE_ERROR": {
      return {
        ...state,
        isMailServiceChangeSuccess: false,
        isMailServiceChangeInProgress: false,
        mailServiceChangeError: action.payload,
        toggledDomains: []
      }
    }

    case "FETCH_ACCOUNTS_EXCEEDING_QUOTA_REQUEST": {
      return {
        ...state,
        isFetchAccountExceedingQuotaInProgress: true,
        fetchAccountExceedingQuotaError: "",
        noOfAccountsExceedingQuota: 0
      }
    }

    case "FETCH_ACCOUNTS_EXCEEDING_QUOTA_SUCCESS": {
      return {
        ...state,
        isFetchAccountExceedingQuotaInProgress: false,
        noOfAccountsExceedingQuota: action.payload,
        fetchAccountExceedingQuotaError: ""
      }
    }

    case "FETCH_ACCOUNTS_EXCEEDING_QUOTA_ERROR": {
      return {
        ...state,
        isFetchAccountExceedingQuotaInProgress: false,
        noOfAccountsExceedingQuota: 0,
        fetchAccountExceedingQuotaError: action.payload
      }
    }

    case "FETCH_CUSTOMER_DOMAIN_LIST_REQUEST": {
      return {
        ...state,
        isFetchCustomerDomainListInProgress: true,
        fetchCustomerDomainListError: "",
      }
    }

    case "FETCH_CUSTOMER_DOMAIN_LIST_SUCCESS": {
      return {
        ...state,
        isFetchCustomerDomainListInProgress: false,
        domainsForCustomer: action.payload.domains,
        fetchCustomerDomainListError: ""
      }
    }

    case "FETCH_CUSTOMER_DOMAIN_LIST_ERROR": {
      return {
        ...state,
        isFetchCustomerDomainListInProgress: false,
        domainsForCustomer: [],
        fetchCustomerDomainListError: action.payload
      }
    }

    case "CLEAR_ORDER_STORE": {
      return {
        ...defaultState
      }
    }

    case "CANCEL_ORDER_REQUEST": {
      return {
        ...defaultState,
        isOrderFetchCancelled: true,
      }
    }

    case "SUSPENSION_REQUEST": {
      return {
        ...state,
        isSuspensionInProgress: true,
        isSuspensionSuccess: false,
        suspensionError: ""
      }
    }

    case "SUSPENSION_SUCCESS": {
      return {
        ...state,
        isSuspensionInProgress: false,
        isSuspensionSuccess: true,
        suspensionError: ""
      }
    }

    case "SUSPENSION_ERROR": {
      return {
        ...state,
        isSuspensionInProgress: false,
        isSuspensionSuccess: false,
        suspensionError: action.payload
      }
    }

    case "ALL_SUSPENSIONS_REQUEST": {
      const entityType = action.payload.entityType
      const newSuspensionListInfo = {
        ...state.suspensionListInfo,
      }
      newSuspensionListInfo[entityType] = {
        fetchSuspensionListInProgress: true,
        fetchSuspensionListSuccess: false,
        suspensionList: [],
        suspensionListError: "",
      }
      return {
        ...state,
        suspensionListInfo: newSuspensionListInfo
      }
    }

    case "ALL_SUSPENSIONS_SUCCESS": {
      const entityType = action.payload.entityType
      let suspensionList = action.payload.data.suspensions
      if (entityType === ENTITY_TYPE.CUSTOMER) {
        suspensionList = suspensionList.filter((suspensionInfo) => suspensionInfo.details.length !== 0)
      }
      suspensionList.sort((item1, item2) => {
        return getSuspensionRolePriority(item2.role) - getSuspensionRolePriority(item1.role)
      })
      const newSuspensionListInfo = {
        ...state.suspensionListInfo
      }
      newSuspensionListInfo[entityType] = {
        fetchSuspensionListInProgress: false,
        fetchSuspensionListSuccess: true,
        suspensionList: suspensionList,
        suspensionListError: ""
      }
      if (entityType === ENTITY_TYPE.CUSTOMER) {
        const newOrderInfo = {
          email: action.payload.data.email,
          orders: action.payload.data.orders
        }
        const newCustomerSuspensionOrderInfo = {
          fetchCustomerOrderListInProgress: false,
          fetchCustomerOrderListSuccess: true,
          fetchCustomerOrderListError: false,
          orderInfo: newOrderInfo
        }
        return {
          ...state,
          suspensionListInfo: newSuspensionListInfo,
          customerSuspensionOrderInfo: newCustomerSuspensionOrderInfo
        }
      }
      else {
        return {
          ...state,
          suspensionListInfo: newSuspensionListInfo
        }
      }
    }

    case "ALL_SUSPENSIONS_ERROR": {
      const entityType = action.payload.entityType
      const newSuspensionListInfo = {
        ...state.suspensionListInfo,
      }
      newSuspensionListInfo[entityType] = {
        fetchSuspensionListInProgress: false,
        fetchSuspensionListSuccess: false,
        suspensionList: [],
        suspensionListError: action.payload.error,
      }
      return {
        ...state,
        suspensionListInfo: newSuspensionListInfo
      }
    }

    case "UNSUSPENSION_REQUEST": {
      return {
        ...state,
        isUnsuspensionInProgress: true,
        isUnsuspensionSuccess: false,
        unsuspensionError: ""
      }
    }

    case "UNSUSPENSION_SUCCESS": {
      return {
        ...state,
        isUnsuspensionInProgress: false,
        isUnsuspensionSuccess: true,
        unsuspensionError: ""
      }
    }

    case "UNSUSPENSION_ERROR": {
      return {
        ...state,
        isUnsuspensionInProgress: false,
        isUnsuspensionSuccess: false,
        unsuspensionError: action.payload
      }
    }

    case "FETCH_HISTORY_REQUEST": {
      return {
        ...state,
        isFetchActionHistoryListInProgress: true,
        actionHistoryList: [],
        actionHistoryListSuccess: false,
        actionHistoryListError: "",
        actionHistoryEntityType: action.payload
      }
    }

    case "FETCH_HISTORY_PAGINATION_REQUEST": {
      return {
        ...state,
        isFetchActionHistoryPaginationInProgress: true,
        isFetchActionHistoryPaginationSuccess: false,
        isFetchActionHistoryPaginationError: "",
        actionHistoryEntityType: action.payload
      }
    }

    case "FETCH_HISTORY_SUCCESS": {
      return {
        ...state,
        isFetchActionHistoryListInProgress: false,
        actionHistoryListError: "",
        actionHistoryPaginationProps: {},
        actionHistoryList: action.payload.appendRecords
         ? [...state.actionHistoryList, ...action.payload.actionHistoryList]
         : action.payload.actionHistoryList,
        actionHistoryListSuccess: true,
        isFetchActionHistoryPaginationInProgress: false,
        isFetchActionHistoryPaginationSuccess: false,
        isFetchActionHistoryPaginationError: "",
      }
    }

    case "FETCH_HISTORY_ERROR": {
      return {
        ...state,
        isFetchActionHistoryListInProgress: false,
        actionHistoryList: [],
        actionHistoryListError: action.payload,
        actionHistoryListSuccess: false
      }
    }

    case "NAVIGATE_BACK_HISTORY_LIST": {
      return {
        ...state,
        isFetchActionHistoryListInProgress: false,
        actionHistoryList: [],
        actionHistoryListError: "",
        actionHistoryEntityType: "",
        actionHistoryListSuccess: false
      }
    }

    case "FETCH_HISTORY_DETAILS_REQUEST": {
      return {
        ...state,
        fetchActionHistoryDetailsInProgress: true,
        actionHistoryDetails: {},
        actionHistoryDetailsError: "",
        actionType: action.payload
      }
    }

    case "FETCH_HISTORY_DETAILS_SUCCESS": {
      return {
        ...state,
        fetchActionHistoryDetailsInProgress: false,
        actionHistoryDetails: action.payload.data,
        actionHistoryPaginationProps: action.payload.paginationProps,
        actionHistoryDetailsError: ""
      }
    }

    case "FETCH_HISTORY_DETAILS_ERROR": {
      return {
        ...state,
        fetchActionHistoryDetailsInProgress: false,
        actionHistoryDetails: {},
        actionHistoryDetailsError: action.payload
      }
    }

    case "NAVIGATE_BACK_HISTORY_DETAILS": {
      return {
        ...state,
        fetchActionHistoryDetailsInProgress: false,
        actionHistoryDetails: {},
        actionHistoryDetailsError: "",
        actionType: ""
      }
    }

    case "EMAIL_ACCOUNTS_TO_RECOVER_REQUEST": {
      const newRecoverEmailAccountState = {
        ...state.recoverEmailAccountState
      }
      newRecoverEmailAccountState.fetchRecoverableAccountsInProgress = true
      newRecoverEmailAccountState.fetchRecoverableAccountsSuccess = false;
      newRecoverEmailAccountState.fetchRecoverableAccountsError = false;
      newRecoverEmailAccountState.recoverableOrders = {}
      return {
        ...state,
        recoverEmailAccountState: newRecoverEmailAccountState
      }
    }

    case "EMAIL_ACCOUNTS_TO_RECOVER_SUCCESS": {
      const newRecoverEmailAccountState = {
        ...state.recoverEmailAccountState
      }
      newRecoverEmailAccountState.fetchRecoverableAccountsInProgress = false
      newRecoverEmailAccountState.fetchRecoverableAccountsSuccess = true;
      newRecoverEmailAccountState.fetchRecoverableAccountsError = false;
      newRecoverEmailAccountState.recoverableOrders = action.payload.recoverableOrders
      return {
        ...state,
        recoverEmailAccountState: newRecoverEmailAccountState
      }
    }

    case "EMAIL_ACCOUNTS_TO_RECOVER_ERROR": {
      const newRecoverEmailAccountState = {
        ...state.recoverEmailAccountState
      }
      newRecoverEmailAccountState.fetchRecoverableAccountsInProgress = false
      newRecoverEmailAccountState.fetchRecoverableAccountsSuccess = false;
      newRecoverEmailAccountState.fetchRecoverableAccountsError = true;
      newRecoverEmailAccountState.recoverableOrders = {}
      return {
        ...state,
        recoverEmailAccountState: newRecoverEmailAccountState
      }
    }

    case "NAVIGATE_BACK_RECOVER_EMAIL_ACCOUNTS": {
      return {
        ...state,
        recoverEmailAccountState: defaultRecoverEmailAccountsState,
        recoverAccountsInfo: defaultRecoverAccountsInfo
      }
    }

    case "RECOVER_ACCOUNTS_REQUEST": {
      const newRecoverAccountsInfo = {
        ...state.recoverAccountsInfo
      }
      newRecoverAccountsInfo.recoverAccountsInProgress = true
      newRecoverAccountsInfo.recoverAccountsSuccess = false
      newRecoverAccountsInfo.recoverAccountsData = [];
      newRecoverAccountsInfo.recoverAccountsError = false;
      return {
        ...state,
        recoverAccountsInfo: newRecoverAccountsInfo
      }
    }

    case "RECOVER_ACCOUNTS_SUCCESS": {
      const newRecoverAccountsInfo = {
        ...state.recoverAccountsInfo
      }
      newRecoverAccountsInfo.recoverAccountsInProgress = false
      newRecoverAccountsInfo.recoverAccountsSuccess = true
      newRecoverAccountsInfo.recoverAccountsData = action.payload.data;
      newRecoverAccountsInfo.recoverAccountsError = false;
      const newRecoveryWidgetTaskIds = { ...state.recoveryWidgetTaskIds }
      newRecoveryWidgetTaskIds[action.payload.domainName] = action.payload.data.taskIds
      localStorage.setItem("recoveryWidgetTaskIds", JSON.stringify(newRecoveryWidgetTaskIds))
      return {
        ...state,
        recoverAccountsInfo: newRecoverAccountsInfo,
        recoveryWidgetTaskIds: newRecoveryWidgetTaskIds
      }
    }

    case "RECOVER_ACCOUNTS_ERROR": {
      const newRecoverAccountsInfo = {
        ...state.recoverAccountsInfo
      }
      newRecoverAccountsInfo.recoverAccountsInProgress = false
      newRecoverAccountsInfo.recoverAccountsSuccess = false
      newRecoverAccountsInfo.recoverAccountsData = [];
      newRecoverAccountsInfo.recoverAccountsError = true;
      return {
        ...state,
        recoverAccountsInfo: newRecoverAccountsInfo
      }
    }

    case "DOMAIN_REACTIVATION_CHECK_REQUEST": {
      const newDomainReactivationCheckInfo = {
        isDomainReactivationCheckInProgress: true,
        isDomainReactivationCheckSuccess: false,
        isDomainReactivationCheckFailure: false,
        isReactivationAllowed: false
      }
      return {
        ...state,
        domainReactivationCheckInfo: newDomainReactivationCheckInfo
      }
    }

    case "DOMAIN_REACTIVATION_CHECK_SUCCESS": {
      const newDomainReactivationCheckInfo = {
        isDomainReactivationCheckInProgress: false,
        isDomainReactivationCheckSuccess: true,
        isDomainReactivationCheckFailure: false,
        isReactivationAllowed: action.payload.reactivationAllowed,
        reactivationNotAllowedReason: action.payload.reason
      }
      return {
        ...state,
        domainReactivationCheckInfo: newDomainReactivationCheckInfo
      }
    }

    case "DOMAIN_REACTIVATION_CHECK_FAILURE": {
      const newDomainReactivationCheckInfo = {
        isDomainReactivationCheckInProgress: false,
        isDomainReactivationCheckSuccess: false,
        isDomainReactivationCheckFailure: true,
        domainReactivationCheck: false
      }
      return {
        ...state,
        domainReactivationCheckInfo: newDomainReactivationCheckInfo
      }
    }

    case "CHANGE_CUSTOMER_EMAIL_REQUEST": {
      return {
        ...state,
        changeCustomerEmailInfo: {
          changingCustomerEmail: true,
          changedCustomerEmail: false,
          changeCustomerEmailError: '',
        }
      }
    }

    case "CHANGE_CUSTOMER_EMAIL_SUCCESS": {
      return {
        ...state,
        changeCustomerEmailInfo: {
          changingCustomerEmail: false,
          changedCustomerEmail: true,
          changeCustomerEmailError: '',
        }
      }
    }

    case "CHANGE_CUSTOMER_EMAIL_FAILURE": {
      return {
        ...state,
        changeCustomerEmailInfo: {
          changingCustomerEmail: false,
          changedCustomerEmail: false,
          changeCustomerEmailError: action.payload,
        }
      }
    }

    case "CHANGE_CUSTOMER_EMAIL_RESET_FLAGS": {
      return {
        ...state,
        changeCustomerEmailInfo: {
          ...defaultChangeCustomerEmailInfo
        }
      }
    }

    case "MODIFY_ACCOUNT_LIMIT_REQUEST": {
      return {
        ...state,
        modifyAccountLimitState: {
          changingAccountLimit: true,
          changedAccountLimit: false,
          changeAccountLimitError: '',
        }
      }
    }

    case "MODIFY_ACCOUNT_LIMIT_SUCCESS": {
      return {
        ...state,
        modifyAccountLimitState: {
          changingAccountLimit: false,
          changedAccountLimit: true,
          changeAccountLimitError: '',
        }
      }
    }

    case "MODIFY_ACCOUNT_LIMIT_FAILURE": {
      return {
        ...state,
        modifyAccountLimitState: {
          changingAccountLimit: false,
          changedAccountLimit: false,
          changeAccountLimitError: action.payload,
        }
      }
    }

    case "RESET_ACCOUNT_LIMIT_PROPS": {
      return {
        ...state,
        modifyAccountLimitState: {
          ...defaultModifyAccountLimitProps
        }
      }
    }

    case "REACTIVATE_DOMAIN_REQUEST": {
      const newReactivateDomainInfo = {
        isReactivateDomainInProgress: true,
        isReactivateDomainSuccess: false,
        isReactivateDomainError: false
      }
      return {
        ...state,
        reactivateDomainInfo: newReactivateDomainInfo
      }
    }

    case "REACTIVATE_DOMAIN_SUCCESS": {
      const newReactivateDomainInfo = {
        isReactivateDomainInProgress: false,
        isReactivateDomainSuccess: true,
        isReactivateDomainError: false
      }
      return {
        ...state,
        reactivateDomainInfo: newReactivateDomainInfo
      }
    }

    case "REACTIVATE_DOMAIN_ERROR": {
      const newReactivateDomainInfo = {
        isReactivateDomainInProgress: false,
        isReactivateDomainSuccess: false,
        isReactivateDomainError: true
      }
      return {
        ...state,
        reactivateDomainInfo: newReactivateDomainInfo
      }
    }

    case "RECOVERY_TASK_SYNC_SUCCESS": {
      const index = state.recoveryTaskInfo.findIndex(info => info.domainName === action.payload.domainName)
      const newrecoveryTaskInfo = [...state.recoveryTaskInfo]
      if (index === -1) {
        newrecoveryTaskInfo.push(action.payload)
      }
      else {
        newrecoveryTaskInfo[index] = action.payload
      }
      localStorage.setItem("recoveryTaskInfo", JSON.stringify(newrecoveryTaskInfo))
      return {
        ...state,
        recoveryTaskInfo: newrecoveryTaskInfo
      }
    }

    case "CLEAR_RECOVERY_TASK_INFO": {
      localStorage.removeItem("recoveryTaskInfo")
      localStorage.removeItem("recoveryWidgetTaskIds")
      return {
        ...state,
        recoveryTaskInfo: [],
        recoveryWidgetTaskIds: {}
      }
    }

    case "FETCH_CUSTOMER_ORDER_LIST_REQUEST": {
      const newCustomerSuspensionOrderInfo = {
        fetchCustomerOrderListInProgress: true,
        fetchCustomerOrderListSuccess: false,
        fetchCustomerOrderListError: false,
        orderInfo: {}
      }

      return {
        ...state,
        customerSuspensionOrderInfo: newCustomerSuspensionOrderInfo
      }
    }

    case "FETCH_CUSTOMER_ORDER_LIST_SUCCESS": {
      const newOrderInfo = {
        email: action.payload.email,
        orders: action.payload.orders
      }
      const newCustomerSuspensionOrderInfo = {
        fetchCustomerOrderListInProgress: false,
        fetchCustomerOrderListSuccess: true,
        fetchCustomerOrderListError: false,
        orderInfo: newOrderInfo
      }
      return {
        ...state,
        customerSuspensionOrderInfo: newCustomerSuspensionOrderInfo
      }
    }

    case "FETCH_CUSTOMER_ORDER_LIST_ERROR": {
      const newCustomerSuspensionOrderInfo = {
        fetchCustomerOrderListInProgress: false,
        fetchCustomerOrderListSuccess: false,
        fetchCustomerOrderListError: true,
        orderInfo: {}
      }

      return {
        ...state,
        customerSuspensionOrderInfo: newCustomerSuspensionOrderInfo
      }
    }

    case "RESET_CUSTOMER_SUSPENSION_CHANGES": {
      const newSuspensionListInfo = {
        ...state.suspensionListInfo,
      }
      const newSuspensionList = state.suspensionListInfo[ENTITY_TYPE.CUSTOMER].suspensionList.map(suspensionInfo => {
        return {
          ...suspensionInfo,
          details: suspensionInfo.details.map(suspension => {
            return {
              ...suspension,
              isChecked: false
            }
          })
        }
      })
      newSuspensionListInfo[ENTITY_TYPE.CUSTOMER] = {
        fetchSuspensionListInProgress: false,
        fetchSuspensionListSuccess: true,
        suspensionList: newSuspensionList,
        suspensionListError: "",
      }
      return {
        ...state,
        suspensionListInfo: newSuspensionListInfo,
        isUnsuspensionInProgress: false,
        isUnsuspensionSuccess: false,
        unsuspensionError: "",
      }
    }

    case "ALL_TRANSACTIONS_REQUEST": {
      const newState = {
        inProgress: true,
        success: false,
        failure: false,
        data: {}
      }
      return {
        ...state,
        allTransactionsInfo: newState
      }
    }

    case "ALL_TRANSACTIONS_SUCCESS": {
      const newState = {
        inProgress: false,
        success: true,
        failure: false,
        data: action.payload
      }
      return {
        ...state,
        allTransactionsInfo: newState
      }
    }

    case "ALL_TRANSACTIONS_ERROR": {
      const newState = {
        inProgress: false,
        success: false,
        failure: true,
        data: action.payload
      }
      return {
        ...state,
        allTransactionsInfo: newState
      }
    }

    case "REFUND_REQUEST": {
      const newState = {
        inProgress: true,
        success: false,
        error: ""
      }
      return {
        ...state,
        refundInfo: newState
      }
    }

    case "REFUND_SUCCESS": {
      const newState = {
        inProgress: false,
        success: true,
        error: ""
      }
      return {
        ...state,
        refundInfo: newState
      }
    }

    case "REFUND_ERROR": {
      const newState = {
        inProgress: false,
        success: false,
        error: action.payload
      }
      return {
        ...state,
        refundInfo: newState
      }
    }

    default:
      return state;
  }
};

export default DetailsReducer;
