import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { useForm, SubmitHandler } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import * as Joi from "joi";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl"

import { RootState } from '../reducers';
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import { ReactComponent as Success } from "../assets/suspend-success-tick.svg"
import { modifyAccountLimitAction, resetAccountLimitPropsAction } from '../actions/orderActions'

interface ParentComponentProps {
  closeModal: (domainId: string|null, accountId: string|null) => void,
  domainId: string,
  accountId: string,
  currentRegion: string,
  maxNoOfAccs: number,
  noOfAccsCreated: number,
  allowAccountLimitExtension: boolean
}

interface ReduxProps {
  changingAccountLimit: boolean,
  changedAccountLimit: boolean,
  changeAccountLimitError: string,
  authToken: string,
  resetAccountLimitPropsAction: () => any,
  modifyAccountLimitAction:
    (domainId: string, newAccountLimit: string, reason: string, authToken: string, region: string) => any,
}

type Props = ParentComponentProps & ReduxProps & WrappedComponentProps;

type FormValues = {
  newAccountLimit: any;
  reason: any;
};

const SetAccountLimitModal = ({
  closeModal, intl, authToken, allowAccountLimitExtension,
  modifyAccountLimitAction, resetAccountLimitPropsAction,
  currentRegion, domainId, accountId, maxNoOfAccs, noOfAccsCreated,
  changeAccountLimitError, changingAccountLimit, changedAccountLimit
}: Props) => {
  const maxLimit = allowAccountLimitExtension ? Infinity : maxNoOfAccs;
  const schema = Joi.object({
    newAccountLimit: Joi.number().integer().min(noOfAccsCreated || 1).max(maxLimit).required().messages({
      'number.base': intl.formatMessage({ id: "number.base" }),
      'number.integer': intl.formatMessage({ id: "number.integer" }),
      'number.min': intl.formatMessage({ id: "number.min" }, { min: noOfAccsCreated }),
      'number.max': intl.formatMessage({ id: "number.max" }, { max: maxLimit }),
    }),
    reason: Joi.string().trim().required().messages({
      'string.empty': intl.formatMessage({ id: "string.empty" }),
    })
  });
  const { register, handleSubmit, formState: { errors } } = useForm<FormValues>({
    resolver: joiResolver(schema)
  });
  const _onSubmit: SubmitHandler<FormValues> = (values) => {
    modifyAccountLimitAction(domainId, values.newAccountLimit, values.reason, authToken, currentRegion)
      .then(() => {
        setTimeout(() => {
          closeModal(domainId, accountId);
          resetAccountLimitPropsAction();
        }, 1000); // close modal after 1sec delay
      });
  };
  const _onCancel = () => closeModal(null, null);
  const _getSubmitButtonText = () => {
    if (changedAccountLimit) {
      return (
        <div className="modal-button-div">
          <Success className="modal-button-success-icon" />
          {intl.formatMessage({ id: "submitted" })}
        </div>
      );
    } else if (changingAccountLimit) {
      return (
        <div className="modal-button-div">
          <div className="modal-spinner" />
          {intl.formatMessage({ id: "submitting" })}
        </div>
      );
    } else {
      return (
        <div className="modal-button-div">
          {intl.formatMessage({ id: "submit" })}
        </div>
      );
    }
  }
  const disableSubmit = changingAccountLimit || changedAccountLimit;

  return (
    <div>
        <CloseGrey className="alert-modal-close-button" onClick={() => _onCancel()} />
        <div className="t-modal-content">
          <div className="form-heading">{intl.formatMessage({ id: "set-account-limit-heading" })}</div>
          <div className="form-subheading">{intl.formatMessage({ id: 'set-account-limit-subheading' }, {
            limit: maxNoOfAccs
          })}</div>
          <form onSubmit={handleSubmit(_onSubmit)}>
            <fieldset className='t-form-field'>
              <FormattedMessage tagName="label" id="new-account-limit-label" />
              <input
                placeholder={intl.formatMessage({ id: "new-account-limit-placeholder" })}
                {...register("newAccountLimit", { required: true })}
              />
              {errors.newAccountLimit?.message && <span>{errors.newAccountLimit.message}</span>}
            </fieldset>
            <fieldset className='t-form-field'>
              <FormattedMessage tagName="label" id="set-account-limit-reason-label" />
              <input
                placeholder={intl.formatMessage({ id: "set-account-limit-reason-placeholder" })}
                {...register("reason", { required: true })}
              />
              {errors.reason?.message && <span>{errors.reason.message}</span>}
            </fieldset>
            {changeAccountLimitError && <span className='server-error'>{changeAccountLimitError}</span>}
            <p className="form-note">{intl.formatMessage({ id: 'set-account-limit-note' })}</p>
            <div className="t-button-container">
              <button
                className="button button-primary"
                disabled={disableSubmit}
              >
                { _getSubmitButtonText() }
              </button>
              <button
                className="button button-secondary"
                onClick={_onCancel}
                disabled={changingAccountLimit}
              >
                <FormattedMessage id="cancel" />
              </button>
            </div>
          </form>
        </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    changingAccountLimit,
    changedAccountLimit,
    changeAccountLimitError
  } = (state.orders as any).modifyAccountLimitState;
  return {
    authToken: state.auth.token,
    changingAccountLimit,
    changedAccountLimit,
    changeAccountLimitError
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    ...bindActionCreators({ modifyAccountLimitAction }, dispatch),
    ...bindActionCreators({ resetAccountLimitPropsAction }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SetAccountLimitModal));
