import { initSentry } from "./sentry";
import { isDevMode } from "../utils/helper";

export const init3rdPartyIntegrations = () => {
    // start sentry integration
    if (!isDevMode()) {
      initSentry({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        release: process.env.REACT_APP_BUILD_VERSION,
        environment: process.env.REACT_APP_ENV,
      });
    }
}