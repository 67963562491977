import React from 'react';
import { connect } from "react-redux";
import { ENTITY_TYPE, getUserIdsFromCustomerOrders, getEntityTypeFromSuspensionType } from './utils';
import Dropdown, { DropdownOption } from './Dropdown'
import { RootState } from '../reducers';
import { bindActionCreators } from 'redux';
import { addSuspension, addCustomerSuspension } from '../actions/orderActions'
import { ReactComponent as Success } from "../assets/suspend-success-tick.svg"
import { ReactComponent as Alert } from "../assets/action_history_alert.svg"
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import { AddCustomerSuspensionPayload, CustomerSuspensionParams } from '../model/customerSuspensionDetails';
import { toTitleCase } from './utils';
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl"

interface SuspensionDetailsProps {
    token: string,
    isSuspensionInProgress: boolean
    isSuspensionSuccess: boolean,
    suspensionError: string,
    addSuspension: (queryParams: any, token: string, suspensionType: string, region: string) => any,
    addCustomerSuspension: (payload: AddCustomerSuspensionPayload, authToken: string, region: string) => void,
    customerSuspensionOrderInfo: CustomerSuspensionParams,
    isCustomerSuspended: boolean
}

interface ParentComponentProps {
    suspensionType: string,
    domainId: Number,
    domainName: string,
    accountId: Number,
    closeModal: (a: Number | null, b: Number | null) => void,
    email: string | undefined,
    currentRegion: string
}

const SUSPENSION_SOURCE = {
    ABUSE_MODEL: "abuse_model",
    AWS_COMPLAINT: "aws_complaint",
    ARF: "arf",
    SPAM: "spam",
    BOUNCE: "bounce",
    PHISHING_COMPLAINT: "phishing_complaint",
    GENERAL_COMPLAINT: "complaint",
    EMAIL_PHISHING_DETECTED: "phishing_detected_in_email"
}

const MODEL_FEEDBACK = {
    BRAND_NAME_IN_DOMAIN: "brand-name-in-domain",
    DOMAIN_PATTERN: "domain-pattern",
    BRAND_NAME_IN_EMAIL: "brand-name-in-email",
    GIBBERISH: "gibberish",
}

type Props = ParentComponentProps & SuspensionDetailsProps & WrappedComponentProps

type state = {
    reason: string,
    note: string,
    customReason: string,
    source: string,
    modelFeedback: {
        [key: string]: boolean;
    }
}

class Suspension extends React.Component<Props> {

    state: state = {
        reason: "",
        note: "",
        customReason: "",
        source: "",
        modelFeedback: {}
    }

    modelFeedbackOptions = [
        {
            label: this.props.intl.formatMessage({ id: 'brand-name-in-domain' }),
            value: MODEL_FEEDBACK.BRAND_NAME_IN_DOMAIN
        },
        {
            label: this.props.intl.formatMessage({ id: 'domain-pattern' }),
            value: MODEL_FEEDBACK.DOMAIN_PATTERN
        },
        {
            label: this.props.intl.formatMessage({ id: 'brand-name-in-email' }),
            value: MODEL_FEEDBACK.BRAND_NAME_IN_EMAIL
        },
        {
            label: this.props.intl.formatMessage({ id: 'gibberish' }),
            value: MODEL_FEEDBACK.GIBBERISH
        },
    ]

    suspensionReasons: DropdownOption[] = [
        {
            label: this.props.intl.formatMessage({ id: 'abuse' }),
            value: "abuse"
        },
        {
            label: this.props.intl.formatMessage({ id: 'payment_abuse' }),
            value: "payment_abuse"
        },
        {
            label: this.props.intl.formatMessage({ id: 'charge_back' }),
            value: "charge_back"
        },
        {
            label: this.props.intl.formatMessage({ id: 'expiry' }),
            value: "expiry"
        },
        {
            label: this.props.intl.formatMessage({ id: 'custom-reason' }),
            value: "custom"
        }
    ]

    suspensionSource = [
        {
            label: this.props.intl.formatMessage({ id: 'abuse-model' }),
            value: SUSPENSION_SOURCE.ABUSE_MODEL
        },
        {
            label: this.props.intl.formatMessage({ id: 'aws-complaint' }),
            value: SUSPENSION_SOURCE.AWS_COMPLAINT
        },
        {
            label: this.props.intl.formatMessage({ id: 'arf' }),
            value: SUSPENSION_SOURCE.ARF
        },
        {
            label: this.props.intl.formatMessage({ id: 'spam' }),
            value: SUSPENSION_SOURCE.SPAM
        },
        {
            label: this.props.intl.formatMessage({ id: 'bounce' }),
            value: SUSPENSION_SOURCE.BOUNCE
        },
        {
            label: this.props.intl.formatMessage({ id: 'phishing-complaint' }),
            value: SUSPENSION_SOURCE.PHISHING_COMPLAINT
        },
        {
            label: this.props.intl.formatMessage({ id: 'general-complaint' }),
            value: SUSPENSION_SOURCE.GENERAL_COMPLAINT
        },
        {
            label: this.props.intl.formatMessage({ id: 'email_phishing_detected' }),
            value: SUSPENSION_SOURCE.EMAIL_PHISHING_DETECTED
        },
    ]

    private timeoutId?: NodeJS.Timeout | null

    constructor(props: Props) {
        super(props)
        this.handleSuspensionItemClick = this.handleSuspensionItemClick.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCloseClick = this.handleCloseClick.bind(this)
        this.createFooterNoteForAccount = this.createFooterNoteForAccount.bind(this)
        this.createFooterNoteForBilling = this.createFooterNoteForBilling.bind(this)
        this.getCustomerOrders = this.getCustomerOrders.bind(this)
        this.getAlertDivForSuspension = this.getAlertDivForSuspension.bind(this)
        this.isSuspensionBlocked = this.isSuspensionBlocked.bind(this)
        this.createFooterNote = this.createFooterNote.bind(this)
        this.createSuspensionText = this.createSuspensionText.bind(this)
        this.createSuspendingText = this.createSuspendingText.bind(this)
    }

    componentDidUpdate() {
        if (this.props.isSuspensionSuccess) {
            this.timeoutId = setTimeout(() => this.props.closeModal(this.props.domainId, this.props.accountId), 1000)
        }
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }

    handleSuspensionItemClick = (item: DropdownOption) => {
        this.setState({
            reason: item.value
        })
    }

    handleSourceItemClick = (item: DropdownOption) => {
        this.setState({
            source: item.value
        });

        if (item.value !== SUSPENSION_SOURCE.ABUSE_MODEL && this.state.modelFeedback) {
            this.setState({ modelFeedback: "" });
        }
    }

    handleModelFeedback = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { modelFeedback = {} } = this.state;
        this.setState({
            modelFeedback: {
                ...modelFeedback,
                [e.target.value]: !!e.target.checked
            }
        })
    }

    handleNoteChange = (item: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            note: item.target.value
        })
    }

    handleCustomReasonChange = (item: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            customReason: item.target.value
        })
    }

    getCustomerOrders() {
        return this.props.customerSuspensionOrderInfo.orderInfo.orders
    }

    handleSubmit = () => {
        if (this.props.isSuspensionSuccess) {
            this.props.closeModal(this.props.domainId, this.props.accountId)
            return
        }
        let suspensionType = this.state.reason
        if (this.state.reason === "custom") {
            suspensionType = this.state.customReason
        }
        const modelFeedback = Object.keys(this.state.modelFeedback).filter(k => this.state.modelFeedback[k]).join(',');
        const commonProps = {
            "suspensionType": suspensionType,
            "note": this.state.note,
            "source": this.state.source,
            modelFeedback,
        };

        if (this.props.suspensionType === ENTITY_TYPE.CUSTOMER) {
            const payload: AddCustomerSuspensionPayload = {
                "userIds": getUserIdsFromCustomerOrders(this.getCustomerOrders(), this.props.currentRegion),
                ...commonProps
            }
            this.props.addCustomerSuspension(payload, this.props.token, this.props.currentRegion)
        } else {
            const payload: any = {
                "domainId": this.props.domainId,
                ...commonProps
            }
            if (this.props.suspensionType === ENTITY_TYPE.ACCOUNT) {
                payload["accountId"] = this.props.accountId
            }
            this.props.addSuspension(payload, this.props.token, this.props.suspensionType, this.props.currentRegion)
        }
    }

    handleCloseClick() {
        if (this.props.isSuspensionSuccess) {
            this.props.closeModal(this.props.domainId, this.props.accountId)
        }
        else {
            this.props.closeModal(null, null)
        }
    }

    createFooterNote = () => {
        return this.props.intl.formatMessage({ id: 'suspend-footer-note' }, { entity: getEntityTypeFromSuspensionType(this.props.suspensionType) })
    }

    createFooterNoteForAccount = () => {
        switch (this.props.suspensionType) {
            case ENTITY_TYPE.ACCOUNT: return this.props.intl.formatMessage({ id: "account-not-able-to-send-mail" })
            case ENTITY_TYPE.DOMAIN: return this.props.intl.formatMessage({ id: "accounts-not-able-to-send-mail" })
            case ENTITY_TYPE.CUSTOMER: return this.props.intl.formatMessage({ id: "all-accounts-not-able-to-send-mail" })
        }
    }

    createFooterNoteForBilling = () => {
        switch (this.props.suspensionType) {
            case ENTITY_TYPE.ACCOUNT: return this.props.intl.formatMessage({ id: "footer-note-billing-account" })
            case ENTITY_TYPE.DOMAIN: return this.props.intl.formatMessage({ id: "footer-note-billing-domain" })
            case ENTITY_TYPE.CUSTOMER: return this.props.intl.formatMessage({ id: "footer-note-billing-customer" })
        }
    }

    createSuspensionText = () => {
        switch (this.props.suspensionType) {
            case ENTITY_TYPE.ACCOUNT: return toTitleCase(this.props.intl.formatMessage({ id: "suspend-account" }))
            case ENTITY_TYPE.DOMAIN: return toTitleCase(this.props.intl.formatMessage({ id: "suspend-domain" }))
            case ENTITY_TYPE.CUSTOMER: return toTitleCase(this.props.intl.formatMessage({ id: "suspend-customer" }))
        }
    }

    createSuspendingText = () => {
        switch (this.props.suspensionType) {
            case ENTITY_TYPE.ACCOUNT: return toTitleCase(this.props.intl.formatMessage({ id: "suspending-account" }))
            case ENTITY_TYPE.DOMAIN: return toTitleCase(this.props.intl.formatMessage({ id: "suspending-domain" }))
            case ENTITY_TYPE.CUSTOMER: return toTitleCase(this.props.intl.formatMessage({ id: "suspending-customer" }))
        }
    }

    getAlertDivForSuspension = () => {
        return (
            <div className="alert-container-suspension">
                <div className="alert-container-icon">
                    <Alert />
                </div>
                <span className="alert-container-text">
                    <FormattedMessage id="action-blocked-customer-suspended"
                        values={{ entity: getEntityTypeFromSuspensionType(this.props.suspensionType) }} />
                </span>
            </div>
        )
    }

    isSuspensionBlocked = () => {
        return this.props.isCustomerSuspended && this.props.suspensionType !== ENTITY_TYPE.CUSTOMER
    }


    render() {
        const suspensionType = this.props.suspensionType;
        const modalSubtitle = suspensionType === ENTITY_TYPE.DOMAIN ? `@${this.props.domainName}` : this.props.email
        const suspensionNoteDefault = this.props.intl.formatMessage({ id: "suspension-note-default-text" })
        let disableSuspendButton = !this.state.reason || !this.state.source ||
            this.state.reason === "custom" ? !this.state.customReason?.trim().length : false;
        let suspendButtonText: any = this.createSuspensionText()
        if (this.props.isSuspensionInProgress) {
            suspendButtonText = <div className="modal-button-div"><div className="modal-spinner" />
                {this.createSuspendingText()}
            </div>
        }
        else if (this.props.isSuspensionSuccess) {
            suspendButtonText = <div className="modal-button-div">
                <Success className="modal-button-success-icon" />
                <FormattedMessage id="suspended" />
            </div>
        }
        if (suspensionType === ENTITY_TYPE.CUSTOMER) {
            this.suspensionReasons = this.suspensionReasons.filter(reason => reason.value !== "expiry")
        }
        return (
            <div>
                <CloseGrey className="alert-modal-close-button" onClick={this.handleCloseClick} />
                <div className="suspension-container">
                    <div className="suspension-heading">{this.createSuspensionText()}</div>
                    <div className="suspension-subheading">{modalSubtitle}</div>
                    {this.isSuspensionBlocked() ?
                        this.getAlertDivForSuspension()
                        : ""}

                    <div className="suspension-input-heading">
                        <FormattedMessage id="reason" />
                    </div>
                    <Dropdown
                        mainClass="suspension-dropdown-container"
                        headerClass="suspension-dropdown-header"
                        headerTitleClass="suspension-dropdown-header-title"
                        listContainerClass="suspension-dropdown-list-container"
                        dropDownItemClass="suspension-dropdown-menu-item"
                        onItemClick={this.handleSuspensionItemClick}
                        defaultItemSelectedIndexes={null}
                        placeholderText={this.props.intl.formatMessage({ id: "suspension-reason" })}
                        placeholderClass="suspension-dropdown-header-title-placeholder"
                        list={this.suspensionReasons}
                        shouldAllowMultiSelect={false}
                    />
                    {this.state.reason === "custom" ?
                        <input
                            className="suspension-custom-reason"
                            placeholder={this.props.intl.formatMessage({ id: "suspension-custom-region-placeholder" })}
                            onChange={this.handleCustomReasonChange}
                            value={this.state.customReason} />
                        : ""}
                    <div className="suspension-input-heading suspension-note-container">
                        <FormattedMessage id="enter-internal-note"
                            values={{ span: (word: any) => <span className="suspension-note-extra"> {word} </span> }} />
                    </div>
                    <textarea
                        className="suspension-note"
                        placeholder={suspensionNoteDefault}
                        onChange={this.handleNoteChange}
                        value={this.state.note} />

                    <div className="suspension-input-heading">
                        <FormattedMessage id="suspension-source" />
                    </div>
                    <Dropdown
                        mainClass="suspension-dropdown-container"
                        headerClass="suspension-dropdown-header"
                        headerTitleClass="suspension-dropdown-header-title"
                        listContainerClass="suspension-dropdown-list-container"
                        dropDownItemClass="suspension-dropdown-menu-item"
                        onItemClick={this.handleSourceItemClick}
                        defaultItemSelectedIndexes={null}
                        placeholderText={this.props.intl.formatMessage({ id: 'select-source' })}
                        placeholderClass="suspension-dropdown-header-title-placeholder"
                        list={this.suspensionSource}
                        shouldAllowMultiSelect={false}
                    />

                    {
                        this.state.source === SUSPENSION_SOURCE.ABUSE_MODEL ?
                        <div className="inaccuracy-points">
                            <FormattedMessage id="inaccuracy-points-label" tagName="label" />
                            <label></label>
                            <ul>
                                {this.modelFeedbackOptions.map(item => (
                                    <li key={item.value}>
                                        <input
                                            onChange={this.handleModelFeedback}
                                            name="inaccuracyReason"
                                            type="checkbox"
                                            id={item.value}
                                            value={item.value}
                                            checked={!!this.state.modelFeedback[item.value]}
                                        />
                                        <label htmlFor={item.value}>{item.label}</label>
                                    </li>
                                ))}
                            </ul>
                        </div> : null
                    }

                    <div className="suspension-footer">
                        {this.createFooterNote()}
                        <ul>
                            <li>{this.createFooterNoteForAccount()}</li>
                            <li> {this.createFooterNoteForBilling()}</li>
                        </ul>
                    </div>
                    <div className="suspension-button-container">
                        <button
                            className="button button-primary suspension-button"
                            disabled={disableSuspendButton || this.isSuspensionBlocked()}
                            onClick={this.handleSubmit}
                        >
                            {suspendButtonText}
                        </button>
                        <button
                            className="button suspension-button modal-cancel-button"
                            onClick={this.handleCloseClick}>
                            <FormattedMessage id="cancel" />
                        </button>
                    </div>
                    {this.props.suspensionError ? <div className="suspension-error">{this.props.suspensionError} </div> : ""}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        token: state.auth.token,
        //TODO - Convert detailsReducer to ts with correct types
        isSuspensionInProgress: (state.orders as any).isSuspensionInProgress,
        isSuspensionSuccess: (state.orders as any).isSuspensionSuccess,
        suspensionError: (state.orders as any).suspensionError,
        customerSuspensionOrderInfo: (state.orders as any).customerSuspensionOrderInfo,
        isCustomerSuspended: (state.orders as any).isCustomerSuspendedOrderDetails
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ addSuspension, addCustomerSuspension }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Suspension))