import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { fetchTasks, fetchTaskReport } from "../../actions/monitorTaskActions";
import GenericTable from "../Common/Table";
import TaskFilterForm from "./TaskFilterForm";
import TaskDetails from "./TaskDetails";
import { getFormattedDate } from "../../utils/helper";
import NoContent from "../noContent";
import PartnerSelection from "../PartnerSelection";
import { COLUMN_TYPES } from "../../utils/const";

const columns = [
  { field: "iid", headerName: "task-id" },
  { field: "taskName", headerName: "task-name-header" },
  { field: "requester", headerName: "initiated-by" },
  {
    field: "createdAt",
    headerName: "order-list-header-date",
    valueFormatter: (value) => getFormattedDate(value),
  },
  { field: COLUMN_TYPES.STATUS, headerName: "order-list-header-status" },
  {
    headerName: "actions",
    type: COLUMN_TYPES.ACTIONS,
    label: "order-list-button-text",
  },
];

const TaskFilter = ({ classes, intl }) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.token);
  const [selectedPartnerId, setSelectedPartnerId] = useState("");
  const [filter, setFilter] = useState({ type: "", value: "", inputError: "" });
  const [loading, setLoading] = useState(false);
  const [tasksData, setTasksData] = useState({ tasks: [], error: "" });
  const [selectedTask, setSelectedTask] = useState(null);
  const [report, setReport] = useState({ loading: false, error: "", url: "" });
  const [retryContext, setRetryContext] = useState(null);

  const fetchTasksData = async (type = null, value = null) => {
    if (!selectedPartnerId) return;

    setLoading(true);
    setTasksData({ ...tasksData, error: "" });
    setReport({ ...report, url: "", error: "" });

    try {
      const response = await dispatch(
        fetchTasks(type, value, selectedPartnerId, authToken)
      );
      setTasksData({ tasks: response.tasks, error: "" });
    } catch (error) {
      setTasksData({ tasks: [], error: error.message });
      setRetryContext(type && value ? "search" : "all");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPartnerId) {
      fetchTasksData();
    }
  }, [selectedPartnerId]);

  const handleFilterTypeChange = (event) => {
    setFilter({ ...filter, type: event.target.value, inputError: "" });
  };

  const handleFilterValueChange = (event) => {
    setFilter({ ...filter, value: event.target.value, inputError: "" });
  };

  const handleSearch = async () => {
    if (!filter.value.trim()) {
      setFilter({
        ...filter,
        inputError: intl.formatMessage({ id: "string.empty" }),
      });
      return;
    }
    if (!filter.type) {
      setFilter({
        ...filter,
        inputError: intl.formatMessage({ id: "select-filter-by" }),
      });
      return;
    }
    fetchTasksData(filter.type, filter.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleClearFilters = () => {
    setFilter({ type: "", value: "", inputError: "" });
    fetchTasksData();
  };

  const handleBack = () => {
    setSelectedTask(null);
    setReport({ loading: false, error: "", url: "" });
  };

  const openTaskDetails = (task) => {
    setSelectedTask(task);
    setReport({ loading: false, error: "", url: "" });
  };

  const handleViewReport = async () => {
    setReport({ ...report, loading: true, url: "", error: "" });

    try {
      const response = await dispatch(
        fetchTaskReport(selectedPartnerId, selectedTask.iid, authToken)
      );
      setReport({ loading: false, url: response.resultCSVUrl, error: "" });
    } catch (error) {
      setReport({ loading: false, url: "", error: error.message });
    }
  };

  const handleRetry = () => {
    if (retryContext === "search") {
      fetchTasksData(filter.type, filter.value);
    } else {
      fetchTasksData();
    }
  };

  return (
    <>
      {!selectedTask && (
        <div className={classes.formContainer}>
          <PartnerSelection
            selectedPartnerId={selectedPartnerId}
            setSelectedPartnerId={setSelectedPartnerId}
          />
          <TaskFilterForm
            filterType={filter.type}
            filterValue={filter.value}
            handleFilterTypeChange={handleFilterTypeChange}
            handleFilterValueChange={handleFilterValueChange}
            handleSearch={handleSearch}
            handleKeyPress={handleKeyPress}
            handleClearFilters={handleClearFilters}
            loading={loading}
            inputError={filter.inputError}
            classes={classes}
            selectedPartnerId={selectedPartnerId}
            setSelectedPartnerId={setSelectedPartnerId}
          />
        </div>
      )}
      {loading ? (
        <div className="button-loader" />
      ) : tasksData.error ? (
        <div>
          <div className={classes.errorMessage}>{tasksData.error}</div>
          <button className="button button-primary" onClick={handleRetry}>
            <FormattedMessage id="retry" />
          </button>
        </div>
      ) : selectedTask ? (
        <TaskDetails
          selectedTask={selectedTask}
          handleBack={handleBack}
          handleViewReport={handleViewReport}
          reportLoading={report.loading}
          reportUrl={report.url}
          reportError={report.error}
          classes={classes}
        />
      ) : tasksData.tasks.length === 0 ? (
        <NoContent errorText={<FormattedMessage id="no-tasks-found" />} />
      ) : (
        <div>
          <GenericTable
            columns={columns}
            data={tasksData.tasks}
            onRowClick={openTaskDetails}
          />
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(injectIntl(TaskFilter));
