import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { ReactComponent as Green } from "../../../assets/oval_green.svg";
import { ReactComponent as Grey } from "../../../assets/oval_grey.svg";
import { ReactComponent as Orange } from "../../../assets/oval_orange.svg";
import { ReactComponent as Red } from "../../../assets/oval_red.svg";
import { FormattedMessage } from "react-intl";
import styles from './styles';
import { COLUMN_TYPES } from '../../../utils/const';

const GenericTable = ({ classes, columns, data, onRowClick }) => {

  // Function to return the status icon based on the status value
  const getStatusLogo = (status) => {
    const statusLowerCase = status.toLowerCase();
    const statusMap = {
      "completed": <Green className="state-logo" />,
      "inprogress": <Orange className="state-logo" />,
      "failed": <Red className="state-logo" />,
      "queued": <Grey className="state-logo" />
    };
    
    return (
      <div>
        {statusMap[statusLowerCase] || <Grey className="state-logo" />} {status}
      </div>
    );
  };

  // Function to render the content of each cell based on the column type
  const renderCellContent = (column, row) => {
    const cellValue = row[column.field];

    if (column.type === COLUMN_TYPES.ACTIONS) {
      return (
        <button
          className={`button button-secondary ${classes.actionButton}`}
          onClick={() => onRowClick(row)}
        >
          <FormattedMessage id={column.label} />
        </button>
      );
    }

    if (column.field === COLUMN_TYPES.STATUS) {
      return getStatusLogo(cellValue);
    }

    if (column.valueFormatter) {
      return column.valueFormatter(cellValue);
    }

    return cellValue;
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.field || column.headerName} className={classes.tableHeader}>
                <FormattedMessage id={column.headerName} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowIndex) => (
            <TableRow key={rowIndex} className={`${classes.tableRow} ${classes.tableCellBorderBottom}`}>
              {columns.map((column) => (
                <TableCell key={column.field || column.headerName} className={classes.tableCell}>
                  {renderCellContent(column, row)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withStyles(styles)(GenericTable);
