import React, { Component } from 'react';
import { ReactComponent as NoContentIcon } from "../assets/no-content.svg"
import { connect } from "react-redux"
import { NavBarOptionValue } from '../model/navbarOptions';
import { FormattedMessage } from 'react-intl';


class NoContent extends Component {
    state = {}

    render() {
        const navbarOption = this.props.navbarOption;
        let errorText = this.props.errorText;
        if (navbarOption === NavBarOptionValue.ORDERS) {
            errorText = (this.props.orderListError || this.props.orderDetailsError) ?
                <FormattedMessage id="error-text-icon-main-page" /> :
                <FormattedMessage id="text-icon-main-page" />
        } else if (navbarOption === NavBarOptionValue.LOGS) {
            errorText = (this.props.logsError) ?
                <FormattedMessage id={this.props.logsError} /> :
                <FormattedMessage id="text-icon-main-page" />
        } else if (navbarOption === NavBarOptionValue.ADMIN) {
            errorText = <FormattedMessage id="error-text-icon-admin-page" />
        }
        return (
            <div className="no-content-container">
                <NoContentIcon />
                <div className="no-content-text">
                    <p className="no-content-text-line-one">{errorText}</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        orderListError: state.orders.orderListError,
        orderDetailsError: state.orders.orderDetailsError,
        logsError: state.logs.fetchLogsError,
        navbarOption: state.auth.navbarOption,
    }
}

export default connect(mapStateToProps)(NoContent);