import React, { ReactElement } from 'react';
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import { ReactComponent as Success } from "../assets/suspend-success-tick.svg"
import { FormattedMessage } from 'react-intl';

export interface Props {
    heading: string,
    subheading: string,
    description: string,
    buttonText: string,
    buttonLoadingText: string | null
    buttonSuccessText: string | null
    shouldShowLoaderInButton: boolean | null
    isLoadingInProgress: boolean | null,
    shouldShowError: boolean | null,
    shouldShowSuccess: boolean | null,
    errorText: string | null,
    descriptionClass: string | null
    buttonAction: () => any
    successAction: (() => any) | null
    closeAction: () => any
}

class AlertModal extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
        this.handleButtonClick = this.handleButtonClick.bind(this)
    }

    handleButtonClick() {
        if (this.props.shouldShowSuccess && this.props.successAction) {
            this.props.successAction()
        }
        else {
            this.props.buttonAction()
        }
    }
    render() {
        const descriptionCss = this.props.descriptionClass ? this.props.descriptionClass : 'alert-modal-description'
        let buttonText: string | ReactElement = this.props.buttonText;
        if (this.props.shouldShowLoaderInButton) {
            if (this.props.isLoadingInProgress) {
                buttonText = <div className="modal-button-div"><div className="modal-spinner" />{this.props.buttonLoadingText}</div>
            }
            else if (this.props.shouldShowSuccess) {
                buttonText = <div className="modal-button-div"><Success className="alert-modal-icon" />{this.props.buttonSuccessText}</div>
            }
        }
        return (
            <div>
                <CloseGrey className="alert-modal-close-button" onClick={this.props.closeAction} />
                <div>
                    <div className="alert-modal-heading">{this.props.heading}</div>
                    <div className="alert-modal-subheading">{this.props.subheading}</div>
                    <div className={descriptionCss}>{this.props.description}</div>
                    <div className="alert-modal-button-container">
                        <button
                            className="button button-primary retry-button"
                            onClick={this.handleButtonClick}>
                            {buttonText}
                        </button>
                        <button
                            className="button suspension-button modal-cancel-button"
                            onClick={this.props.closeAction}>
                            <FormattedMessage id="cancel" />
                        </button>
                    </div>
                    {this.props.shouldShowError ? <div className="alert-modal-error">{this.props.errorText}</div> : ""}
                </div>
            </div>
        )
    }
}

export default AlertModal