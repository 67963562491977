import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { loginUser } from "../actions/authActions";
import { ReactComponent as Titan } from "../assets/titan.svg"
import TokenInvalid from "./tokenInvalid.jsx"
import { FormattedMessage } from "react-intl";

class LoginForm extends Component {
  state = {
    emailEntered: "",
    passwordEntered: ""
  }
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.getLoginErrorVisibility = this.getLoginErrorVisibility.bind(this);
  }

  handleEmailChange = (e) => {
    const emailEntered = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      emailEntered: emailEntered
    }))
  }

  handlePasswordChange = (e) => {
    const passwordEntered = e.target.value;
    this.setState(prevState => ({
      ...prevState,
      passwordEntered: passwordEntered
    }))
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.loginUser(this.state.emailEntered.trim(), this.state.passwordEntered)
  };

  getLoginErrorVisibility = () => {
    return this.props.loginError ? "visible" : "hidden"
  }

  render() {
    return (
      <div className="table-body">
        <div className="login-logo" >
          <Titan />
        </div>
        <div className="login-layout">
          <div>
            <h3 className="login-heading">
              <FormattedMessage id="login-form-header" />
            </h3>
          </div>
          <div className="form">
            <form onSubmit={this.handleSubmit}>
              <h4 className="input-label">
                <FormattedMessage id="login-form-email-header" />
              </h4>
              <div className="input input-box input-div">
                <input
                  className="no-border-input"
                  placeholder="john.doe@flock.com"
                  onChange={this.handleEmailChange}
                  value={this.state.emailEntered} />
              </div>
              <div className="password-label-container">
                <h4 className="input-label">
                  <FormattedMessage id="login-form-password-header" />
                </h4>
                <Link className="forgot-password-link" to="/forgot-password">
                  <FormattedMessage id="login-form-forgot-password" />
                </Link>
              </div>
              <div className="input input-box input-div">
                <input
                  className="no-border-input"
                  type="password"
                  placeholder="Enter your Password"
                  onChange={this.handlePasswordChange}
                  value={this.state.passwordEntered} />
              </div>
              <button type="primary"
                className="button button-primary login-form-button"
                disabled={this.props.isLoggingIn || !this.state.emailEntered || !this.state.passwordEntered}>
                <FormattedMessage id="login-button-text" />
              </button>
              {this.props.loginError && <div className="error login-error" > {this.props.loginError} </div>}
            </form>
          </div>
        </div>
        {this.props.isTokenInvalid ? <TokenInvalid /> : ""}
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isTokenInvalid: state.auth.isTokenInvalid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ loginUser }, dispatch)
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
