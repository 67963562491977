import * as React from 'react';
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import { isRoleTitan, ROLE, getRoleString, isEmailValid, getPartnerIdsForUser } from './utils';
import { RootState } from '../reducers';
import { connect } from 'react-redux';
import { PartnerDetail, CreateUserInfo, UserDetail, UpdateUserInfo } from '../reducers/userManagementReducer';
import Dropdown, { DropdownOption } from './Dropdown'
import { ReactComponent as Success } from "../assets/suspend-success-tick.svg"
import { bindActionCreators } from 'redux';
import { createNewUsers, updateUser } from "../actions/adminActions"
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import ChipInput from './ChipInput'

type Props = {
    userRole: string
    closeModal: (makeApiCall: boolean) => void,
    partnerDetailsList: PartnerDetail[],
    createUserDetails: CreateUserInfo,
    token: string,
    createNewUsers: (emailIds: string[], partnerIds: number[], role: string, token: string) => void,
    updateUserDetails: UpdateUserInfo,
    updateUser: (emailId: string, partnerIds: number[], role: string, token: string) => void
    currentUserPartnerIds: number[],
    mode: MODE,
    user: UserDetail | null
} & WrappedComponentProps

interface State {
    emailIdsEntered: string[],
    partnerDropdownList: DropdownOption[],
    shouldMakeAdmin: boolean,
    role: string
}

export enum MODE {
    CREATE = "create",
    EDIT = "edit"
}

class AddUserModal extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
        this.handlePartnerClick = this.handlePartnerClick.bind(this)
        this.handleRoleClick = this.handleRoleClick.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCloseClick = this.handleCloseClick.bind(this)
        this.getInProgressState = this.getInProgressState.bind(this)
        this.getSuccessState = this.getSuccessState.bind(this)
        this.getPartnerDropdownList = this.getPartnerDropdownList.bind(this)
        this.getErrorState = this.getErrorState.bind(this)
    }

    getPartnerDropdownList = (selectedIds: number[]) => {
        return this.props.partnerDetailsList.map((partnerInfo: PartnerDetail) => {
            let isSelected;
            if (selectedIds.includes(partnerInfo.partnerId)) {
                isSelected = true
            }
            else {
                isSelected = false
            }
            return {
                label: partnerInfo.partnerName,
                value: partnerInfo.partnerId,
                isSelected: isSelected
            }
        })
    }

    state: State = this.props.mode === MODE.EDIT ? {
        emailIdsEntered: this.props.user && this.props.user.emailId ? [this.props.user.emailId] : [],
        partnerDropdownList: this.getPartnerDropdownList((this.props.user && getPartnerIdsForUser(this.props.user)) || []),
        shouldMakeAdmin: false,
        role: this.props.user && this.props.user.role || ""
    } : {
            emailIdsEntered: [],
            partnerDropdownList: this.getPartnerDropdownList([]),
            shouldMakeAdmin: false,
            role: ""
        }


    private timeoutId?: NodeJS.Timeout | null

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }

    handlePartnerClick = (item: DropdownOption) => {
        const newPartnerDropdownList = this.state.partnerDropdownList.map(partnerInfo => {
            let newPartnerInfo: DropdownOption = partnerInfo
            if (partnerInfo.label === item.label) {
                newPartnerInfo = {
                    ...partnerInfo,
                    isSelected: !partnerInfo.isSelected
                }
            }
            return newPartnerInfo
        })
        this.setState({
            partnerDropdownList: newPartnerDropdownList
        })
    }

    handleRoleClick = (item: DropdownOption) => {
        this.setState({
            role: item.value
        })
    }

    handleSubmit = () => {
        let partnerIds, role
        if (isRoleTitan(this.props.userRole)) {
            partnerIds = this.state.partnerDropdownList.filter(item => item.isSelected).map(item => item.value)
            role = this.state.role
        }
        else {
            partnerIds = this.props.currentUserPartnerIds
            role = this.state.shouldMakeAdmin ? ROLE.PARTNER_ADMIN : ROLE.PARTNER
        }
        switch (this.props.mode) {
            case MODE.CREATE: {
                this.props.createNewUsers(this.state.emailIdsEntered, partnerIds, role, this.props.token)
                break;
            }
            case MODE.EDIT: {
                const emailId = this.props.user ? this.props.user.emailId : ""
                this.props.updateUser(emailId, partnerIds, role, this.props.token)
                break;
            }
        }
    }

    handleCloseClick = () => {
        if (this.props.createUserDetails.isCreateUserRequestSuccess) {
            this.props.closeModal(true)
        }
        else {
            this.props.closeModal(false)
        }
    }

    handleAdminStateChange = () => {
        this.setState((prevState: State) => ({
            shouldMakeAdmin: !prevState.shouldMakeAdmin
        }))
    }

    getInProgressState = () => {
        if (this.props.mode === MODE.CREATE) {
            return this.props.createUserDetails.isCreateUserRequestInProgress
        }
        else {
            return this.props.updateUserDetails.isUpdateUserRequestInProgress
        }
    }

    getSuccessState = () => {
        if (this.props.mode === MODE.CREATE) {
            return this.props.createUserDetails.isCreateUserRequestSuccess
        }
        else {
            return this.props.updateUserDetails.isUpdateUserRequestSuccess
        }
    }

    getErrorState = () => {
        if (this.props.mode === MODE.CREATE) {
            return this.props.createUserDetails.createUserRequestError
        }
        else {
            return this.props.updateUserDetails.updateUserRequestError
        }
    }
    handleEmailIdsChange = (emailIds: any) => {
        this.setState({
            emailIdsEntered: emailIds
        })
    }
    render() {
        const roleList: DropdownOption[] = Object.entries(ROLE).map(value => (
            {
                label: getRoleString(value[1]),
                value: value[1]
            }
        ))
        let disableButton = this.state.emailIdsEntered.some((elem: string) => !isEmailValid(elem))
        const selectedPartnerIds = this.state.partnerDropdownList.map(item => item.isSelected)
        if (isRoleTitan(this.props.userRole)) {
            disableButton = disableButton || selectedPartnerIds.length === 0 || !this.state.role
        }
        let buttonText: string | React.ReactElement = this.props.mode === MODE.CREATE ?
            <FormattedMessage id='send_invite' /> : <FormattedMessage id='update_user' />

        if (this.getInProgressState()) {
            buttonText = <div className="modal-button-div"><div className="modal-spinner" />
                {this.props.mode === MODE.CREATE ? <FormattedMessage id='sending' /> :
                    <FormattedMessage id='updating' />}
            </div>
        }
        else if (this.getSuccessState()) {
            buttonText = <div className="modal-button-div"><Success className="modal-button-success-icon" />{this.props.mode === MODE.CREATE ? <FormattedMessage id='invite_sent' /> :
                <FormattedMessage id='updated' />}</div>
        }
        let defaultSelectedPartnerIndexes: number[] = []
        if (this.props.mode === MODE.EDIT) {
            this.props.partnerDetailsList.forEach((partnerInfo, index) => {
                const userPartnerIds = this.props.user ? getPartnerIdsForUser(this.props.user) : []
                if (userPartnerIds.includes(partnerInfo.partnerId)) {
                    defaultSelectedPartnerIndexes.push(index)
                }
            })
        }
        const defaultEmailIds = this.props.mode === MODE.CREATE ? [] : (this.props.user ? [this.props.user.emailId] : [])
        let selectedRoleIndex = null
        if (this.props.mode === MODE.EDIT) {
            selectedRoleIndex = Object.entries(ROLE).findIndex((data) => data[1] === this.state.role)
            if (selectedRoleIndex === -1) {
                selectedRoleIndex = null
            }
        }
        return (
            <div>
                <CloseGrey className="alert-modal-close-button" onClick={this.handleCloseClick} />
                <div className="add-user-modal-container">
                    <div className="add-user-modal-heading">
                        {this.props.mode === MODE.CREATE ?
                            this.props.intl.formatMessage({ id: "add-new-account" }) :
                            this.props.intl.formatMessage({ id: "edit-account" })}
                    </div>
                    <div className="input-box-heading">
                        <FormattedMessage id='new_user_add_email' />
                        <div className="chip-input-container">
                            <ChipInput
                                newChipKeys={['Enter', ' ', ',', ';']}
                                blurBehavior="add"
                                classes={{ root: "chip-input-root", input: "chip-input" }}
                                onChange={(chips: any) => this.handleEmailIdsChange(chips)}
                                validInput={(email: string) => isEmailValid(email)}
                                disableUnderline={true}
                                defaultValue={defaultEmailIds}
                                disabled={this.props.mode === MODE.EDIT}
                            />
                        </div>
                        {
                            isRoleTitan(this.props.userRole) ?
                                <div>
                                    <div className="input-box-heading"><FormattedMessage id='partner' /></div>
                                    <Dropdown
                                        mainClass="dropdown-container"
                                        headerClass="dropdown-header"
                                        headerTitleClass="dropdown-header-title"
                                        listContainerClass="dropdown-list-container"
                                        dropDownItemClass="partner-list-checkbox"
                                        onItemClick={this.handlePartnerClick}
                                        defaultItemSelectedIndexes={defaultSelectedPartnerIndexes}
                                        placeholderText={this.props.intl.formatMessage({ id: 'select_partner_placeholder' })}
                                        placeholderClass="partner-dropdown-placeholder"
                                        shouldAllowMultiSelect={true}
                                        list={this.state.partnerDropdownList}
                                        multiSelectTextClass="partner-list-item-text"
                                    />
                                    <div className="input-box-heading"><FormattedMessage id='select_role' /></div>
                                    <div>
                                        <Dropdown
                                            mainClass="dropdown-container"
                                            headerClass="dropdown-header"
                                            headerTitleClass="dropdown-header-title"
                                            listContainerClass="dropdown-list-container"
                                            dropDownItemClass="role-dropdown-menu-item"
                                            onItemClick={this.handleRoleClick}
                                            defaultItemSelectedIndexes={selectedRoleIndex !== null ? [selectedRoleIndex] : null}
                                            placeholderText={this.props.intl.formatMessage({ id: 'select_role_placeholder' })}
                                            placeholderClass="partner-dropdown-placeholder"
                                            shouldAllowMultiSelect={false}
                                            list={roleList}
                                        />
                                    </div>

                                </div>
                                :
                                <div className="make-admin-checkbox-container">
                                    <input type='checkbox' checked={this.state.shouldMakeAdmin}
                                        onClick={() => this.handleAdminStateChange()}
                                        className={"make-admin-checkbox"} />
                                    <FormattedMessage id='make_this_account_admin' />
                                </div>
                        }

                        <div className="create-user-button-container">
                            <button
                                className="button button-primary create-user-button"
                                disabled={disableButton}
                                onClick={this.handleSubmit}
                            >
                                {buttonText}
                            </button>
                            <button
                                className="button create-user-button modal-cancel-button"
                                onClick={this.handleCloseClick}>
                                <FormattedMessage id='cancel' />
                            </button>
                        </div>
                        {this.getErrorState() ?
                            <div className="create-user-error">
                                {this.getErrorState()}
                            </div> : ""}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        userRole: state.auth.userRole,
        partnerDetailsList: state.users.partnerDetails.partnerList,
        createUserDetails: state.users.createUserDetails,
        updateUserDetails: state.users.updateUserDetails,
        token: state.auth.token
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ createNewUsers, updateUser }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddUserModal));