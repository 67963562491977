import {
  getSupportDocsApi,
  getUploadUrls,
  uploadFileToUrl,
  validateMigration,
  startMigration,
} from "../service/bulkService";

// Action Types
export const GET_SUPPORT_DOCS_SUCCESS = "GET_SUPPORT_DOCS_SUCCESS";

// Action Creators
export const getSupportDocsSuccess = (data) => ({
  type: GET_SUPPORT_DOCS_SUCCESS,
  payload: data,
});

// Thunks
export const fetchSupportDocs = (partnerId, migrationType, migrateMailBox, authToken) => async (dispatch) => {
  try {
    const data = await getSupportDocsApi(partnerId, migrationType, migrateMailBox, authToken);
    dispatch(getSupportDocsSuccess(data));
    return data;
  } catch (error) {
    console.error("Error in fetchSupportDocs thunk:", error);
    throw new Error(error);
  }
};

export const uploadFilesToUrls = (partnerId, authToken, ordersFile, mailboxesFile) => async () => {
  try {
    // Step 1: Initial API Call to get upload URLs
    const data = await getUploadUrls(partnerId, authToken);
    const { domainCSVUploadUrl, emailCSVUploadUrl } = data;

    // Step 2: Upload Files to the URLs
    await Promise.all([
      uploadFileToUrl(domainCSVUploadUrl, ordersFile),
      uploadFileToUrl(emailCSVUploadUrl, mailboxesFile),
    ]);

    return data;
  } catch (error) {
    console.error("Error in uploadFilesToUrls thunk:", error);
    throw new Error(error);
  }
};

export const validateMigrationData = (partnerId, requestConfig, pathId, authToken) => async () => {
  try {
    const data = await validateMigration(partnerId, requestConfig, pathId, authToken);
    return data;
  } catch (error) {
    console.error("Error in validateMigrationData thunk:", error);
    throw new Error(error);
  }
};

export const initializeBulkMigration = (partnerId, iid, authToken) => async () => {
  try {
    const data = await startMigration(partnerId, iid, authToken);
    return data;
  } catch (error) {
    console.error("Error in initializeBulkMigration thunk:", error);
    throw new Error(error);
  }
};