import * as React from 'react';
import { RootState } from '../reducers';
import { connect } from "react-redux";
import { ReactComponent as NavigateBack } from "../assets/navigate_back.svg"
import { navigateBackActionHistoryDetails } from '../actions/orderActions'
import { bindActionCreators } from 'redux';
import { getActorRoleString, ENTITY_TYPE, getFormattedDate, isRoleTitan } from './utils';
import { FormattedMessage } from 'react-intl';

interface DetailsProp {
    actionHistoryDetails: any,
    selectedDomainName: string,
    currentEmail: string,
    actionType: string,
    actionHistoryEntityType: ENTITY_TYPE,
    userRole: string,
    navigateBackActionHistoryDetails: () => void
}

class ActionHisoryDetails extends React.Component<DetailsProp> {
    render() {
        const domainName = this.props.selectedDomainName
        const email = this.props.currentEmail
        const heading = (this.props.actionHistoryEntityType === ENTITY_TYPE.DOMAIN ? `${domainName}` : `${email}`) + ` : ${this.props.actionType}`
        const actionHistoryDetails = this.props.actionHistoryDetails
        const details = actionHistoryDetails.details
        const actor = details.actor
        const shouldShowActorId = actor.id && isRoleTitan(this.props.userRole)
        const shouldShowSourceId = isRoleTitan(this.props.userRole)
        const source = details.source
        let actionHistoryComponent: Array<any> = [
            <tr>
                <td className="action-history-details-key"><FormattedMessage id='heading_date_time'/></td>
                <td className="action-history-details-value">
                    {getFormattedDate(actionHistoryDetails.timestamp)}
                </td>
            </tr>,
            <tr>
                <td className="action-history-details-key"><FormattedMessage id='entity_name'/></td>
                <td className="action-history-details-value">{details.entityName}</td>
            </tr>,
            <tr>
                <td className="action-history-details-key"><FormattedMessage id='actor_role'/></td>
                <td className="action-history-details-value">{getActorRoleString(actor.role, this.props.userRole)}</td>
            </tr>,
            shouldShowActorId ? <tr>
                <td className="action-history-details-key"><FormattedMessage id='actor_id'/></td>
                <td className="action-history-details-value">{actor.id}</td>
            </tr> : "",
            actor.email ? <tr>
                <td className="action-history-details-key"><FormattedMessage id='actor_email'/></td>
                <td className="action-history-details-value">{actor.email}</td>
            </tr> : "",
            <tr>
                <td className="action-history-details-key"><FormattedMessage id='source_api'/></td>
                <td className="action-history-details-value">{source.api}</td>
            </tr>,
            <tr>
                <td className="action-history-details-key"><FormattedMessage id='source_ip'/></td>
                <td className="action-history-details-value">{source.ip}</td>
            </tr>,
            shouldShowSourceId ? <tr>
                <td className="action-history-details-key"><FormattedMessage id='source_id'/></td>
                <td className="action-history-details-value">{source.id}</td>
            </tr> : "",
            <tr>
                <td className="action-history-details-key"><FormattedMessage id='source_authflow'/></td>
                <td className="action-history-details-value">{source.authFlow}</td>
            </tr>,
            Object.keys(source.params).length !== 0 ? <tr>
                <td className="action-history-details-key"><FormattedMessage id='source_params'/></td>
                <td className="action-history-details-value">{JSON.stringify(source.params)}</td>
            </tr> : ""
        ]
        return (
            <div className="action-history-container">
                <div className="action-history-heading-container">
                    <NavigateBack className="action-history-navigate-back" onClick={this.props.navigateBackActionHistoryDetails} />
                    <div className="action-history-heading">
                        {heading}
                    </div>
                </div>
                <div className="action-history-list-container">
                    <table className="action-history-details">
                        <th><FormattedMessage id='action_details'/></th>
                        {actionHistoryComponent}
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const orders = state.orders as any
    return {
        actionHistoryDetails: orders.actionHistoryDetails,
        actionType: orders.actionType,
        selectedDomainName: orders.selectedDomainName,
        currentEmail: orders.currentEmail,
        actionHistoryEntityType: orders.actionHistoryEntityType,
        userRole: state.auth.userRole
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ navigateBackActionHistoryDetails }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionHisoryDetails)