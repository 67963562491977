import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { ReactComponent as ArrowDown } from "../../../assets/arrow_down.svg"
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

const CustomAccordion = ({ classes, title, children, expanded, onChange, disabled }) => (
  <Accordion
    className={classes.accordion}
    expanded={expanded}
    onChange={onChange}
    disabled={disabled}
  >
    <AccordionSummary expandIcon={<ArrowDown />} aria-controls={`${title}-content`} id={`${title}-header`}>
      <div className={classes.accordionTitle}>{title}</div>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>
);

export default withStyles(styles)(CustomAccordion);
