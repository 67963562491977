import { makeStyles } from "@material-ui/core/styles";

export const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1, 0),
  },
  formLabel: {
    fontWeight: 600,
    color: "#333",
    fontSize: "14px",
  },
});

export const useRadioStyles = makeStyles({
  root: {
    "&$checked": {
      color: "#2170F4",
    },
  },
  checked: {},
});

export const useInputStyles = makeStyles({
  root: {
    "&:focus": {
      outline: "0",
      boxShadow: "0 0 0 2px rgba(24, 144, 255, .2)",
      borderColor: "#40a9ff",
    },
  },
  input: {
    height: "28px",
    padding: "6px 12px",
    fontSize: "14px",
    borderRadius: "4px",
  },
});

export const useLabelStyles = makeStyles({
  label: {
    fontSize: "14px",
  },
});
