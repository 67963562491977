import * as Sentry from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";
import { isDevMode } from "../../utils/helper";
import { removeSensitiveDataFromSentryEvent } from "./helpers";

const initSetup = ({ dsn, release, environment }) => {
    Sentry.init({
        dsn,
        release,
        environment,
        integrations: [
            Sentry.browserTracingIntegration(),
            captureConsoleIntegration({
                levels: ["error"],
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // we'll capture all transactions for local development,
        // for the rest we'll set it to 10% to reduce incoming error logs
        tracesSampleRate: isDevMode() ? 1.0 : 0.1,
        beforeSend(event) {
            return removeSensitiveDataFromSentryEvent(event);
        },
    });
}

const attachGlobalHandlers = () => {
    window.addEventListener("unhandledrejection", (event) => {
        Sentry.captureException(event.reason);
    });
}


export const initSentry = ({ dsn, release, environment }) => {
    initSetup({ dsn, release, environment });
    attachGlobalHandlers();
}