import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { Popover } from "antd";
import OrderDetailsItem from "./orderDetailsItem";
import { fetchDnsDetails } from "../actions/orderActions";
import { ReactComponent as SuccessLogo } from "../assets/success.svg"
import { ReactComponent as ErrorLogo } from "../assets/error.svg"
import { ReactComponent as NoResponse } from "../assets/noresponse.svg"
import { ReactComponent as DnsHover } from "../assets/hover.svg"
import { FormattedMessage } from "react-intl";
import { hasDnsError } from "./utils";


class DnsDetails extends Component {
    state = {}

    constructor(props) {
        super(props);
        this.dnsMenu = this.dnsMenu.bind(this);
        this.dnsTimeoutMenu = this.dnsTimeoutMenu.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getClassWithLogo = this.getClassWithLogo.bind(this);
        this.getNoResponseLogo = this.getNoResponseLogo.bind(this);
        this.dnsPopupMenu = this.dnsPopupMenu.bind(this);
        this.toggleView = this.toggleView.bind(this);
    }

    dnsPopupMenu = (dnsMenuContent, dnsClassWithLogo) => {
        return (
            <Popover placement="right" content={dnsMenuContent} trigger="hover">
                <div className="dns-hover">
                    <div className="dns-hover-icon">
                        {dnsClassWithLogo}
                    </div>
                    <DnsHover className="popup-icon" />
                </div>
            </Popover>
        );
    }

    getClassWithLogo = (isVerified) => {
        if (isVerified) {
            return (<SuccessLogo className="success-logo" />);
        } else {
            return (<ErrorLogo className="error-logo" />)
        }
    }

    getNoResponseLogo = () => {
        return (<NoResponse className="error-logo" />);
    }

    toggleView = () => {
        console.log('Toggle view', this.state.loading);
        this.setState(prevState => ({
            ...prevState,
            loading: !this.state.loading
        }));

    }
    handleClick = () => {
        const domainId = this.props.domainId;
        const authToken = this.props.authToken;
        this.props.fetchDnsDetails(domainId, authToken, this.props.currentOrderSelected.region);
    }

    dnsTimeoutMenu = (mxClassWithLogo, spfClassWithLogo) => {
        let mxValues = [], spfValues = [];
        let retryLink = <a href="#" onClick={this.handleClick}>
            <FormattedMessage id="retry" />
        </a>;
        mxValues.push(<div>
            <FormattedMessage id="dns-service-did-not-respond" values={{ type: "MX" }} />
        </div>);
        spfValues.push(<div>
            <FormattedMessage id="dns-service-did-not-respond" values={{ type: "SPF" }} />
        </div>);
        return (
            <div>
                <div className="dns-layout" disabled={this.props.isDnsLoading}>
                    <div className="dns-header">
                        <div className="dns-status">
                            <FormattedMessage id="dns-popover-header" />
                        </div>
                        <div className="dns-retry-link">{retryLink}</div>
                    </div>
                    {this.props.isDnsLoading && <div className="loader" />}
                    <div className="dns-popup-item">
                        <div className="dns-popup-header">
                            {mxClassWithLogo}
                            <div className="dns-response no-response">MX</div>
                        </div>
                        <div className="reponse-content">
                            {mxValues}
                        </div>
                    </div>
                    <div className="dns-popup-item">
                        <div className="dns-popup-header">
                            {spfClassWithLogo}
                            <div className="dns-response no-response">SPF</div>
                        </div>
                        <div className="reponse-content">
                            {spfValues}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    dnsMenu = (dnsInfo, mxClassWithLogo, spfClassWithLogo) => {
        let mxValues = [], spfValues = [], nsRecords = [];
        const isMxActualAvlbl = dnsInfo.mx.actual.length !== 0;
        const isSpfActualAvlbl = dnsInfo.spf.actual.length !== 0;
        const isMxVerified = dnsInfo.mx.isVerified;
        const isSpfVerified = dnsInfo.spf.isVerified;
        const isNsAvailable = dnsInfo.ns.records.length != 0;
        const isNsOfPartner = dnsInfo.ns.nsOfPartner
        const mxTextClass = isMxVerified ? "dns-response success" : "dns-response dns-error";
        const spfTextClass = isSpfVerified ? "dns-response success" : (!isSpfActualAvlbl ? "dns-response no-response" : "dns-response dns-error");
        if (!isMxActualAvlbl) {
            mxValues.push(<div>
                <FormattedMessage id="dns-popover-records-not-created" values={{ type: "MX" }} />
            </div>);
        } else {
            dnsInfo.mx.actual.forEach((item, index) => {
                mxValues.push(<div>{item.target + "  " + item.priority}</div>);
            });
        }
        if (!isSpfActualAvlbl) {
            spfValues.push(<div>
                <FormattedMessage id="dns-popover-records-not-created" values={{ type: "SPF" }} />
            </div>);
        } else {
            dnsInfo.spf.actual.forEach((item, index) => {
                spfValues.push((<div>{item}</div>));
            });
        }
        if (isNsAvailable) {
            dnsInfo.ns.records.forEach((item, _) => {
                nsRecords.push((<div className="reponse-content">{item}</div>))
            })
        }

        return (
            <div>
                <div className="dns-layout" disabled={this.props.isDnsLoading}>
                    <div className="dns-header">
                        <div className="dns-status">
                            <FormattedMessage id="dns-popover-header" />
                        </div>
                    </div>
                    <div className="dns-popup-item">
                        <div className="dns-popup-header">
                            {mxClassWithLogo}
                            <div className={mxTextClass}>MX</div>
                        </div>
                        <div className="reponse-content">
                            {mxValues}
                        </div>
                    </div>
                    <div className="dns-popup-item">
                        <div className="dns-popup-header">
                            {spfClassWithLogo}
                            <div className={spfTextClass}>SPF</div>
                        </div>
                        <div className="reponse-content">
                            {spfValues}
                        </div>
                    </div>
                    {isNsAvailable ?
                        <div className="dns-popup-item">
                            <div className="dns-popup-header">
                                <div className="dns-ns-response">
                                    <span className="dns-menu-ns-header">NS</span>
                                    <span>({isNsOfPartner ?
                                        <FormattedMessage id="dns-popover-partner-ns" /> :
                                        <FormattedMessage id="dns-popover-non-partner-ns" />})
                                    </span>
                                </div>
                            </div>
                            <div>
                                {nsRecords}
                            </div>
                        </div> : ""}
                </div>
            </div>
        );
    }

    render() {
        const dnsInfo = this.props.dnsDetails;
        const dnsError = hasDnsError(dnsInfo);
        let dnsPopupMenuV, showDnsStatus;
        if (dnsError) {
            const mxClassWithLogo = this.getNoResponseLogo();
            const spfClassWithLogo = this.getNoResponseLogo();
            const dnsClassWithLogo = this.getNoResponseLogo();
            const dnsObject = this.dnsTimeoutMenu(mxClassWithLogo, spfClassWithLogo);
            dnsPopupMenuV = this.dnsPopupMenu(dnsObject, dnsClassWithLogo);
        } else {
            showDnsStatus = (dnsInfo.mx.expected.length === 0 && dnsInfo.spf.expected.length === 0) ? "none" : "block";
            const isMxVerified = dnsInfo.mx.isVerified;
            const isSpfVerified = dnsInfo.spf.isVerified;
            const isDnsVerified = isMxVerified && (isSpfVerified || dnsInfo.spf.actual.length == 0);
            const mxClassWithLogo = this.getClassWithLogo(isMxVerified);
            const spfClassWithLogo = dnsInfo.spf.actual.length === 0 ? this.getNoResponseLogo() : this.getClassWithLogo(isSpfVerified);
            const dnsClassWithLogo = this.getClassWithLogo(isDnsVerified);
            const dnsObject = this.dnsMenu(this.props.dnsDetails, mxClassWithLogo, spfClassWithLogo);
            dnsPopupMenuV = this.dnsPopupMenu(dnsObject, dnsClassWithLogo);
        }

        return (
            <div className="order-info-single-item" style={{ display: showDnsStatus }}>
                <OrderDetailsItem itemName="order-detail-dns-status" itemValue={dnsPopupMenuV} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authToken: state.auth.token,
        dnsDetails: state.orders.dnsDetails,
        isDnsLoading: state.orders.isDnsLoading,
        currentOrderSelected: state.orders.currentOrderSelected
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ fetchDnsDetails }, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DnsDetails);