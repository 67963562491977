const styles = () => ({
  tableContainer: {
    padding: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
  },
  tableHeader: {
    fontSize: "12px",
    color: "#888888",
    lineHeight: "1.33",
    padding: "10px 20px",
  },
  tableRow: {
    cursor: "pointer",
  },
  tableCell: {
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#333333",
    padding: "20px",
  },

  tableCellBorderBottom: {
    "&:not(:last-child)": {
      borderBottom: "1px solid #ddd",
    },
  },
  actionButton: {
    minWidth: "74px",
    height: "32px",
  },
});

export default styles;
