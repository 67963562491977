import { getLogs, getIpInfo } from "../service/logsService";

const fetchLogsRequest = (data) => {
  return {
    type: "FETCH_LOGS_REQUEST",
    payload: {
      senderMailSubmitted: data.senderMailSubmitted,
      receiverMailSubmitted: data.receiverMailSubmitted,
      timestamp: data.timestamp,
      status: data.status,
      direction: data.direction,
      recordsPerPage: data.recordsPerPage,
      page: data.page
    }
  };
};

const fetchIpLogsRequest = (data) => {
  return {
    type: "FETCH_IP_LOGS_REQUEST",
    payload: {
      ipSubmitted: data.ipSubmitted,
      recordsPerPage: data.recordsPerPage,
      page: data.page
    }
  }
}

const fetchLogsSuccess = (data) => {
  return {
    type: "FETCH_LOGS_SUCCESS",
    payload: { data: data }
  };
};

const fetchLogsError = error => {
  return {
    type: "FETCH_LOGS_ERROR",
    payload: { error: error }
  };
};

export const cancelLogsRequest = () => {
  return {
    type: "CANCEL_LOG_REQUEST"
  }
};

export const clearLogStore = () => {
  return {
    type: "CLEAR_LOG_STORE"
  }
}

export const updateDefaultNumberOfRecords = (noOfRecords) => {
  localStorage.setItem("defaultNoOfRecords", noOfRecords)
  return {
    type: "UPDATE_DEFAULT_NUMBER_OF_RECORDS",
    payload: {
      defaultNoOfRecords: noOfRecords
    }
  }
}

export const fetchLogs = (fetchLogsQueryParams, authToken) => {
  return function (dispatch) {
    const payload = {
      senderMailSubmitted: fetchLogsQueryParams.from,
      receiverMailSubmitted: fetchLogsQueryParams.to,
      timestamp: fetchLogsQueryParams.timestamp,
      status: fetchLogsQueryParams.status,
      direction: fetchLogsQueryParams.direction,
      recordsPerPage: fetchLogsQueryParams.records_per_page,
      page: fetchLogsQueryParams.page
    }
    dispatch(fetchLogsRequest(payload));
    getLogs(fetchLogsQueryParams, authToken)
      .then(responseData => {
        dispatch(fetchLogsSuccess(responseData));
      })
      .catch(error => {
        dispatch(fetchLogsError(error));
      });
  };
};

export const fetchIpLogs = (fetchLogsQueryParams, authToken) => {
  return function (dispatch) {
    const payload = {
      ipSubmitted: fetchLogsQueryParams.ip,
    }
    dispatch(fetchIpLogsRequest(payload));

    getIpInfo(fetchLogsQueryParams, authToken)
      .then(responseData => {
        dispatch(fetchLogsSuccess({
          ipLogsData: responseData,
        }));
      })
      .catch(error => {
        dispatch(fetchLogsError(error));
      });
  };
};