import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { deleteOrder } from "../actions/orderActions";
import { ReactComponent as SuccessfulDeletion } from "../assets/rubbish.svg";
import { FormattedMessage, injectIntl } from "react-intl";
import { validate } from "../utils/helper";
import { isRoleTitan } from "./utils";

class DeleteOrder extends Component {
    constructor(props) {
        super(props);
        this.domainName = this.props.domainName;
        this.domainId = this.props.domainId;
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.changeReason = this.changeReason.bind(this);
        this.changeLinkedOrderID = this.changeLinkedOrderID.bind(this);
        this.toggleLinkedOrderId = this.toggleLinkedOrderId.bind(this);
        this.setFormErrors = this.setFormErrors.bind(this);
    }

    state = {
        formErrors: {},
        reason: '',
        showLinkedOrderId: false,
        linkedOrderId: '',
    }

    toggleLinkedOrderId() {
        this.setState({
            showLinkedOrderId: !this.state.showLinkedOrderId,
            linkedOrderId: ''
        });
    }

    changeReason(e) {
        this.setState({ reason: e.target.value });
    }

    changeLinkedOrderID(e) {
        this.setState({ linkedOrderId: e.target.value });
    }

    setFormErrors(error) {
        this.setState({
            formErrors: {
                ...this.state.formErrors,
                ...error
            }
        })
    }

    validateForm() {
        if (this.state.showLinkedOrderId && !this.state.linkedOrderId) {
            this.setFormErrors({
                linkedOrderId: this.props.intl.formatMessage({ id: 'linked-order-id-missing'})
            });
            return false;
        } else if (this.state.linkedOrderId && !validate.number(this.state.linkedOrderId)) {
            this.setFormErrors({
                linkedOrderId: this.props.intl.formatMessage({ id: 'linked-order-id-invalid'})
            });
            return false;
        } else if (this.state.linkedOrderId === this.props.domainId.toString()) {
            this.setFormErrors({
                linkedOrderId: this.props.intl.formatMessage({ id: 'linked-order-id-same'})
            });
            return false;
        }

        return true;
    }

    handleDeleteClick() {
        if (!this.validateForm()) return false;

        this.props.deleteOrder({
            domainId: this.domainId,
            reason: this.state.reason,
            ...(this.state.linkedOrderId ? {
                linkedOrderId: this.state.linkedOrderId
            } : {})
        }, this.props.token, this.props.currentOrderSelected.region);
    }

    render() {
        const { intl } = this.props;
        const { formErrors, reason, showLinkedOrderId, linkedOrderId } = this.state;
        const roleIsTitan = isRoleTitan(this.props.userRole);

        const shouldShowError = this.props.orderDeleteError && !this.props.isOrderDeleting;
        const buttonText = this.props.isOrderDeleting ?
            <div className="deleting-order-div">
                <div className="spinner" />
                <span className="deleting">
                    <FormattedMessage id="deleting-button-text" />
                </span>
            </div> :
            <FormattedMessage id="delete-button-text" />;
        const isOrderDeleteSuccess = this.props.isOrderDeleteSuccess;
        if (isOrderDeleteSuccess) return (
            <div className="delete-order-success">
                <SuccessfulDeletion />
                <span className="delete-orders-header">{this.domainName}</span>
                <FormattedMessage id="delete-order-successful" />
            </div>
        );
        else return (
            <div className="delete-order-content">
                <span className="delete-orders-header">
                    <FormattedMessage id="delete-domain-order" values={{ domain: this.domainName }} />
                </span>
                {shouldShowError ? <div className="delete-order-error">{this.props.orderDeleteError} </div> : ""}
                <div className="delete-orders-message">
                    <FormattedMessage id="delete-order-modal-message" />
                </div>
                <fieldset className="t-form-field">
                    <FormattedMessage tagName="label" id="delete-order-reason-label" />
                    <input
                        placeholder={intl.formatMessage({ id: "delete-order-reason" })}
                        onChange={this.changeReason}
                        value={reason}
                    />
                </fieldset>
                {
                    roleIsTitan ?
                    <fieldset className="checkbox-field">
                        <input type='checkbox' checked={showLinkedOrderId}
                            onClick={() => this.toggleLinkedOrderId()}
                            className="make-admin-checkbox" />
                        <FormattedMessage id='has-linked-order' />
                    </fieldset> : null
                }
                {showLinkedOrderId ? <fieldset className="t-form-field">
                    <FormattedMessage tagName="label" id="order-link-field-label" />
                    <input
                        placeholder={intl.formatMessage({ id: "order-link-field" })}
                        onChange={this.changeLinkedOrderID}
                        value={linkedOrderId}
                    />
                    {formErrors?.linkedOrderId && <span>{formErrors?.linkedOrderId}</span>}
                </fieldset> : null}
                <div className="delete-buttons">
                    <button className='button button-delete delete-order-button'
                        onClick={this.handleDeleteClick}
                        disabled={this.props.isOrderDeleting}>
                        {buttonText}
                    </button>
                    <button className="button button-cancel delete-order-button"
                        onClick={() => this.props.closeModal(null, null)}
                        disabled={this.props.isOrderDeleting}>
                        <FormattedMessage id="cancel" />
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        userRole: state.auth.userRole,
        orderDeleteError: state.orders.orderDeleteError,
        isOrderDeleting: state.orders.isOrderDeleting,
        isOrderDeleteSuccess: state.orders.isOrderDeleteSuccess,
        currentOrderSelected: state.orders.currentOrderSelected
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ deleteOrder }, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DeleteOrder));