import store from '../store/index'
import { recoveryTaskSyncSuccess } from '../actions/orderActions'
import { fetchRecoveryTasksForDomain } from './orderService'
import { RecoveryTaskInfo, RecoveryTask } from '../model/recoveryTaskInfo'

interface RecoveryTaskAPIResponse {
    recoveryTasks: RecoveryTask[]
}


let isSyncingInProgress: String[] = []

export const startRecoveryTaskSync = (domainName: string, domainId: number, authToken: string, region: string) => {
    if (isSyncingInProgress.includes(domainName)) {
        console.log(`Recovery task sync already in progress for domain ${domainName}`)
        return
    }
    else {
        isSyncingInProgress.push(domainName)
        const interval = setInterval(function recoverySync() {
            recoveryTasksForDomainRequest(domainName, domainId, authToken, region)
                .then((response: RecoveryTaskAPIResponse) => {
                    const tasks = response.recoveryTasks
                    if (tasks.filter(task => {
                        return task.status === TASK_STATUS.INITIALIZED ||
                            task.status === TASK_STATUS.STARTED
                    }).length === 0) {
                        isSyncingInProgress = isSyncingInProgress.filter(item => item !== domainName)
                        clearInterval(interval)
                    }
                })
            return recoverySync
        }(), 30 * 1000)
    }
}

const recoveryTasksForDomainRequest = (domainName: string, domainId: number, authToken: string, region: string) => {
    return fetchRecoveryTasksForDomain(domainId, authToken, region)
        .then((response: RecoveryTaskAPIResponse) => {
            const tasks = response.recoveryTasks
            const filteredTaskIds = (store.getState().orders as any).recoveryWidgetTaskIds[domainName]
            const filteredTasks = tasks.filter((task: RecoveryTask) => {
                return filteredTaskIds.includes(task.taskId)
            })
            const filteredResponse: RecoveryTaskAPIResponse = {
                recoveryTasks: filteredTasks
            }
            updateStore(filteredResponse, domainName, domainId, region)
            return filteredResponse
        })
}

const updateStore = (apiResponse: RecoveryTaskAPIResponse, domainName: string, domainId: number, region: string) => {
    const payload: RecoveryTaskInfo = {
        domainName: domainName,
        recoveryTasks: apiResponse.recoveryTasks,
        domainId: domainId,
        region: region
    }
    store.dispatch(recoveryTaskSyncSuccess(payload))
}

export const TASK_STATUS = {
    INITIALIZED: "initialized",
    STARTED: "started",
    FAILED: "failed",
    SUCCESSFUL: "successful",
    PARTIAL_SUCCESSFUL: "partial_successful",
    INTERRUPTED: "interrupted",
    NO_MIGRATION: "no_migration"
}