import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { MenuItem, Select, InputLabel, FormControl } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

const TaskFilterForm = ({
  filterType,
  filterValue,
  handleFilterTypeChange,
  handleFilterValueChange,
  handleSearch,
  handleKeyPress,
  handleClearFilters,
  loading,
  inputError,
  classes,
  intl,
}) => {
  return (
    <div className={classes.filterContainer}>
      <div className={classes.filterSelect}>
        <FormControl variant="outlined" className={classes.filterDropdown} size="small">
          <InputLabel id="filter-type-label">
            <FormattedMessage id="filter-by" />
          </InputLabel>
          <Select
            labelId="filter-type-label"
            value={filterType}
            onChange={handleFilterTypeChange}
            label="Filter By"
            className={classes.select}
            classes={{ root: classes.selectedItem }}
          >
            <MenuItem value="domain" className={classes.menuItem} >
              <FormattedMessage id="order-details-domain" />
            </MenuItem>
            <MenuItem value="email" className={classes.menuItem}>
              <FormattedMessage id="email" />
            </MenuItem>
            <MenuItem value="iid" className={classes.menuItem}>
              <FormattedMessage id="task-id-dropdown" />
            </MenuItem>
            <MenuItem value="taskName" className={classes.menuItem}>
              <FormattedMessage id="task-name-dropdown" />
            </MenuItem>
          </Select>
        </FormControl>
        <button
          type="button"
          onClick={handleClearFilters}
          className={classes.clearFilter}
        >
          <FormattedMessage id="clear_filter" />
        </button>
      </div>
      <div>
        <input
          name="filterValue"
          type="text"
          className={`input ${classes.inputField}`}
          placeholder={intl.formatMessage({ id: "enter-value" })}
          onChange={handleFilterValueChange}
          onKeyPress={handleKeyPress}
          value={filterValue}
        />
        <div className={classes.errorMessage}>{inputError}</div>
      </div>
      <button
        type="primary"
        className="button button-primary"
        disabled={loading}
        onClick={handleSearch}
      >
        <FormattedMessage id="search" />
      </button>
    </div>
  );
};

export default withStyles(styles)(injectIntl(TaskFilterForm));
