import { UserDetail } from "../reducers/userManagementReducer"
import {
    userListRequest, partnerInfoRequest, createUsersRequest, deleteUserAccount,
    updateExistingUser
} from "../service/userManagementService"
import { AddUsersPayload, AddUsersResponse, User } from '../model/createUserPayload'
import { DeleteUserPayload } from "../model/deleteUserPayload"
import { toast } from "react-toastify"
import { getFormattedMessage, getCurrentLocale } from "../components/utils"

const fetchUsersRequest = () => {
    return {
        type: "USER_LIST_REQUEST"
    }
}

const fetchUsersSuccess = (data: UserDetail[]) => {
    return {
        type: "USER_LIST_SUCCESS",
        payload: data
    }
}

const fetchUsersError = () => {
    return {
        type: "USER_LIST_ERROR"
    }
}

const fetchPartnersRequest = () => {
    return {
        type: "PARTNER_INFO_REQUEST"
    }
}

const fetchPartnersSuccess = (data: UserDetail[]) => {
    return {
        type: "PARTNER_INFO_SUCCESS",
        payload: data
    }
}

const fetchPartnersError = () => {
    return {
        type: "PARTNER_INFO_ERROR"
    }
}

const addUsersRequest = () => {
    return {
        type: "ADD_USERS_REQUEST"
    }
}

const addUsersSuccess = (data: AddUsersResponse) => {
    return {
        type: "ADD_USERS_SUCCESS",
        payload: data
    }
}

const addUsersError = (error: string) => {
    return {
        type: "ADD_USERS_ERROR",
        payload: error
    }
}

const updateUserRequest = () => {
    return {
        type: "UPDATE_USER_REQUEST"
    }
}

const updateUserSuccess = () => {
    return {
        type: "UPDATE_USER_SUCCESS"
    }
}

const updateUserError = (error: string) => {
    return {
        type: "UPDATE_USER_ERROR",
        payload: error
    }
}

const deleteUserRequest = () => {
    return {
        type: "DELETE_USER_REQUEST"
    }
}

const deleteUserSuccess = () => {
    return {
        type: "DELETE_USER_SUCCESS"
    }
}

const deleteUserError = (error: string) => {
    return {
        type: "DELETE_USER_ERROR",
        payload: error
    }
}

export const closeModal = () => {
    return {
        type: "CLOSE_MODAL"
    }
}

export const clearUserList = () => {
    return {
        type: "CLEAR_USER_LIST"
    }
}

export const clearAdminInfoStore = () => {
    return {
        type: "CLEAR_ADMIN_INFO_STORE"
    }
}

export const fetchUsers = (authToken: string) => {
    return function (dispatch: any) {
        dispatch(fetchUsersRequest());
        userListRequest(authToken)
            .then(responseData => {
                dispatch(fetchUsersSuccess(responseData))
            })
            .catch(_ => {
                dispatch(fetchUsersError());
                toast.info(getFormattedMessage("error-toast-users-fetch"))
            });
    };
}

export const fetchPartners = (authToken: string) => {
    return function (dispatch: any) {
        dispatch(fetchPartnersRequest());
        partnerInfoRequest(authToken)
            .then(responseData => {
                dispatch(fetchPartnersSuccess(responseData))
            })
            .catch(_ => {
                dispatch(fetchPartnersError());
            });
    };
}

export const createNewUsers = (emailIds: string[], partnerIds: number[], role: string, authToken: string) => {
    return function (dispatch: any) {
        dispatch(addUsersRequest());
        const requestBody: AddUsersPayload = { emailIds, partnerIds, role, locale: getCurrentLocale() }
        createUsersRequest(requestBody, authToken)
            .then(responseData => {
                dispatch(addUsersSuccess(responseData))
            })
            .catch(error => {
                dispatch(addUsersError(error));
            });
    }
}

export const updateUser = (emailId: string, partnerIds: number[], role: string, authToken: string) => {
    return function (dispatch: any) {
        dispatch(updateUserRequest());
        const requestBody: User = { emailId, partnerIds, role }
        updateExistingUser(requestBody, authToken)
            .then(_ => {
                dispatch(updateUserSuccess())
            })
            .catch(error => {
                dispatch(updateUserError(error));
            });
    }
}

export const deleteUser = (emailId: string, authToken: string) => {
    return function (dispatch: any) {
        dispatch(deleteUserRequest())
        const requestBody: DeleteUserPayload = { emailId }
        deleteUserAccount(requestBody, authToken)
            .then(_ => {
                dispatch(deleteUserSuccess())
            })
            .catch(error => {
                dispatch(deleteUserError(error));
            });
    }
}


