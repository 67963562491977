import * as React from 'react';
import { RootState } from '../reducers';
import { connect } from "react-redux";
import { getActorRoleString, ENTITY_TYPE, getFormattedDate, DEFAULT_PAGINATION_LIMIT } from './utils';
import { ReactComponent as NavigateBack } from "../assets/navigate_back.svg"
import { ReactComponent as Alert } from "../assets/action_history_alert.svg"
import { ReactComponent as ArrowLeft } from "../assets/arrow_left.svg"
import { ReactComponent as ArrowRight } from "../assets/arrow_right.svg"
import Dropdown, { DropdownOption } from "./Dropdown";
import { navigateBackActionHistoryList, fetchAccountHistoryDetails, fetchActionHistory } from '../actions/orderActions'
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";

type DetailsProp = {
    actionHistoryList: ActionHistoryItem[],
    entityType: ENTITY_TYPE,
    selectedDomainName: string,
    currentEmail: string,
    token: string,
    domainId: string,
    region: string,
    userRole: string,
    actionHistoryPaginationProps: { currentPage: number, numberOfRecordsVisible: number},
    fetchActionHistoryDetailsInProgress: boolean,
    isFetchActionHistoryPaginationInProgress: boolean,
    currentOrderSelected: any,
    fetchActionHistory: (
        domainId: string, accountIds: null, entityType: string,
        token: string, region: string, limit: number, lastId: number
    ) => void,
    navigateBackActionHistoryList: () => void,
    fetchAccountHistoryDetails: (actionType: string, actionId: number, authToken: string, region: string, paginationProps: DetailsProp["actionHistoryPaginationProps"]) => any
} & WrappedComponentProps

interface ActionHistoryItem {
    id: number,
    timestamp: number,
    action: string,
    role: string
}

type Props = DetailsProp

type State = {
    currentPage: number
    itemIdClicked: number
    numberOfRecordsVisible: number
}

const recordsPerPageDropdownItems = [
    {
        label: '5',
        value: 5
    },
    {
        label: '10',
        value: 10
    },
    {
        label: '20',
        value: 20
    }
]

class ActionHistoryList extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        const {
            currentPage = 1,
            numberOfRecordsVisible = DEFAULT_PAGINATION_LIMIT
        } = this.props.actionHistoryPaginationProps;
        this.state = {
            currentPage,
            itemIdClicked: -1,
            numberOfRecordsVisible
        }
        this.handleActionItemClick = this.handleActionItemClick.bind(this)
        this.updateRecordsPerPage = this.updateRecordsPerPage.bind(this)
        this.loadNewChunk = this.loadNewChunk.bind(this)
        this.loadPreviousPage = this.loadPreviousPage.bind(this)
        this.loadNextPage = this.loadNextPage.bind(this)
        this.getAccountIdsForCurrentOrder = this.getAccountIdsForCurrentOrder.bind(this)
    }

    updateRecordsPerPage = (item: DropdownOption) => {
        if (item.value === this.state.numberOfRecordsVisible) return
        this.setState({
            currentPage: 1,
            numberOfRecordsVisible: item.value
        }, this.loadNewChunk)
    }

    handleActionItemClick = (item: ActionHistoryItem) => {
        this.setState({ itemIdClicked: item.id })
        this.props.fetchAccountHistoryDetails(item.action, item.id, this.props.token, this.props.currentOrderSelected.region, {
            currentPage: this.state.currentPage,
            numberOfRecordsVisible: this.state.numberOfRecordsVisible
        })
    }

    loadNewChunk = () => {
        const recordsPresent = this.props.actionHistoryList.length > (this.state.numberOfRecordsVisible * this.state.currentPage + 1);

        // skip fetch if records are already present under `actionHistoryList`
        // this is possible when user go back and forth
        if (recordsPresent) return

        this.props.fetchActionHistory(
            this.props.domainId, this.getAccountIdsForCurrentOrder(), this.props.entityType,
            this.props.token, this.props.region,
            this.state.numberOfRecordsVisible,
            this.props.actionHistoryList[this.props.actionHistoryList.length - 1].id
        );
    }

    getAccountIdsForCurrentOrder() {
        const accounts = this.props.currentOrderSelected.accounts;
        if (accounts) {
          return (accounts || []).map((account: { id: number }) => account.id)
        }
        return null;
    }

    loadPreviousPage = () => {
        if (this.state.currentPage <= 1) return

        // no need of fetch here, as records are assumed to be already
        // present/fetched under `actionHistoryList`
        this.setState({
            currentPage: this.state.currentPage - 1
        })
    }

    loadNextPage = (shouldAllowNextLoad: boolean) => {
        // skip fetch if current page has lesser records than page limit `numberOfRecordsVisible`
        // that means API doesn't have further records
        if (!shouldAllowNextLoad) return

        this.setState({
            currentPage: this.state.currentPage + 1
        }, this.loadNewChunk)
    }

    render() {
        const domainName = this.props.selectedDomainName
        const email = this.props.currentEmail
        const actionHistoryText = this.props.intl.formatMessage({ id: 'action_history' })
        const heading = this.props.entityType === ENTITY_TYPE.DOMAIN ? `${domainName} : ${actionHistoryText}` : `${email} : ${actionHistoryText}`
        let indexOfAlertRow = -1
        const isDomainActionLog = this.props.entityType === ENTITY_TYPE.DOMAIN;
        const startPoint = isDomainActionLog
            ? this.state.numberOfRecordsVisible * (this.state.currentPage - 1)
            : 0;
        const endPoint = isDomainActionLog
            ? this.state.numberOfRecordsVisible * this.state.currentPage
            : this.props.actionHistoryList.length;
        const actionHistoryComponent = (this.props.actionHistoryList || [])
            .slice(startPoint, endPoint)
            .map((item, i) => {
                let columns = []
                const index = item.action.indexOf(":");
                let actionHyperlink
                let actionText = ""
                if (index === -1) {
                    actionHyperlink = item.action
                } else {
                    actionHyperlink = item.action.substring(0, index + 1)
                    actionText = item.action.substring(index + 1, item.action.length)
                }
                const showSpinner = (this.props.fetchActionHistoryDetailsInProgress) &&
                    this.state.itemIdClicked === item.id
                const formattedDate = getFormattedDate(item.timestamp)
                if (moment(formattedDate).isBefore('27 Aug 2020') && indexOfAlertRow === -1) {
                    indexOfAlertRow = i
                }
                columns.push(<td className="action-history-time">{formattedDate}</td>)
                columns.push(
                    <td className="action-history-info">
                        <div className="action-link-div">
                            <span className="action-detail-link" onClick={() => this.handleActionItemClick(item)}>{actionHyperlink}</span>{actionText}
                            <div className="spinner-action-continer">
                                {showSpinner ? <div className="spinner-action" /> : ""}
                            </div>
                        </div>
                    </td>
                )
                columns.push(<td className="action-history-info">{getActorRoleString(item.role, this.props.userRole)}</td>)
                return <tr>{columns}</tr>
            })
        const shouldAllowNextLoad = actionHistoryComponent.length === this.state.numberOfRecordsVisible;
        const shouldAllowPrevLoad = this.state.currentPage > 1;
        if (this.props.isFetchActionHistoryPaginationInProgress) {
            actionHistoryComponent.push(<div className="spinner-action-continer pagination-spinner"><div className="spinner-action" /></div>)
        }
        if (!actionHistoryComponent.length) {
            const entityText = this.props.entityType === ENTITY_TYPE.DOMAIN ? "order" : "account"
            actionHistoryComponent.push(<tr><td colSpan={3} className="no-action-history-list">
                <FormattedMessage
                    id={this.state.currentPage ? "end_of_action" : "no_action_found"}
                    values={{ entity: entityText }}
                />
            </td></tr>)
        }
        if (indexOfAlertRow !== -1) {
            const alertRow = <tr>
                <td colSpan={3} className="action-history-alert">
                    <div className="flex">
                        <Alert />
                        <span className="action-history-alert-text">
                            <FormattedMessage id='action_history_alert_text' />
                        </span>
                    </div>
                </td>
            </tr>
            actionHistoryComponent.splice(indexOfAlertRow, 0, alertRow)
        }
        const itemSelectedIndex = recordsPerPageDropdownItems
            .findIndex(dropDownOption => dropDownOption.value === this.state.numberOfRecordsVisible)

        return (
            <div className="action-history-container pagination-details-container">
                <div className="action-history-heading-container">
                    <NavigateBack className="action-history-navigate-back" onClick={this.props.navigateBackActionHistoryList} />
                    <div className="action-history-heading">
                        {heading}
                    </div>
                </div>
                <table className="pagination-details">
                    <tr>
                        <th className="action-history-time-heading">
                            <FormattedMessage id='action_history_date_time' /></th>
                        <th className="action-history-info"><FormattedMessage id='action' /></th>
                        <th className="action-history-info"><FormattedMessage id='action_history_role' /></th>
                    </tr>
                    {actionHistoryComponent}
                </table>
                {
                    isDomainActionLog ?
                    <div className="page-div">
                        <text className="page-text"><FormattedMessage id='label_records_per_page' /></text>
                        <Dropdown
                            mainClass="records-div"
                            headerClass="records-dd-header"
                            headerTitleClass="records-dd-header-title"
                            listContainerClass="records-dd-list-container"
                            dropDownItemClass="records-dd-menu-item"
                            onItemClick={this.updateRecordsPerPage}
                            placeholderText=""
                            placeholderClass=""
                            list={recordsPerPageDropdownItems}
                            defaultItemSelectedIndexes={[itemSelectedIndex]}
                            shouldAllowMultiSelect={false}
                        />
                        <text className="page-text"><FormattedMessage id='pagination_current_page' values={{ pageNo: this.state.currentPage }} /></text>
                        <div className="page-buttons">
                            <ArrowLeft className={`prev-page ${!shouldAllowPrevLoad ? 'disabled-arrow' : ''}`} onClick={() => this.loadPreviousPage()} />
                            <ArrowRight className={`next-page ${!shouldAllowNextLoad ? 'disabled-arrow' : ''}`} onClick={() => this.loadNextPage(shouldAllowNextLoad)} />
                        </div>
                    </div> : null
                }
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const orders = state.orders as any
    return {
        region: orders.currentOrderSelected.region,
        actionHistoryList: orders.actionHistoryList,
        entityType: orders.actionHistoryEntityType,
        selectedDomainName: orders.selectedDomainName,
        currentEmail: orders.currentEmail,
        actionHistoryPaginationProps: orders.actionHistoryPaginationProps,
        isFetchActionHistoryPaginationInProgress: orders.isFetchActionHistoryPaginationInProgress,
        fetchActionHistoryDetailsInProgress: orders.fetchActionHistoryDetailsInProgress,
        token: state.auth.token,
        userRole: state.auth.userRole,
        currentOrderSelected: orders.currentOrderSelected,
        domainId: orders.orderDetails.orderDetailsResponse
            ? orders.orderDetails.orderDetailsResponse.domain.id
            : '',
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({
            navigateBackActionHistoryList,
            fetchAccountHistoryDetails,
            fetchActionHistory
        }, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ActionHistoryList))