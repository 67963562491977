import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { RootState } from '../reducers'
import { AutoLoginInfo } from '../model/autoLoginInfo';
import { autoLoginUser } from '../actions/authActions'

export interface AutoLoginProps {
    autoLoginInfo: AutoLoginInfo
    location: any,
    history: any,
    token: string,
    autoLoginUser: (token: string) => void
}

class AutoLogin extends React.Component<AutoLoginProps> {

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const autoLoginToken = params.get("token")
        if (autoLoginToken && !this.props.token) {
            this.props.autoLoginUser(autoLoginToken)
        }
        else {
            this.props.history.replace("/")
        }
    }

    componentDidUpdate() {
        if (this.props.autoLoginInfo.success || this.props.autoLoginInfo.error) {
            this.props.history.replace("/")
        }
    }

    render() {
        return (
            <div>
                {this.props.autoLoginInfo.inProgress ? <div className="log-loader"></div> : ""}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        autoLoginInfo: state.auth.autoLoginState,
        token: state.auth.token
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        ...bindActionCreators({ autoLoginUser }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogin);