import { login, reset, forgotPassword, setNewPassword, fetchUserInfo } from "../service/authService";
import { clearOrderStore } from "./orderActions";
import { clearLogStore } from "./logsActions"
import { toast } from "react-toastify";
import { clearAdminInfoStore } from "./adminActions";
import { getFormattedMessage } from "../components/utils";

const loginUserRequest = (email, password) => {
  return {
    type: "LOGIN_USER_REQUEST",
    payload: { email: email, password: password }
  };
};

const loginUserSuccess = payload => {
  return {
    type: "LOGIN_USER_SUCCESS",
    payload: payload
  };
};

const loginUserError = error => {
  return {
    type: "LOGIN_USER_ERROR",
    payload: { error: error }
  };
};

const initiatePasswordReset = () => {
  return {
    type: "INITIATE_PASSWORD_RESET",
  };
};

const cancelPasswordReset = () => {
  return {
    type: "CANCEL_PASSWORD_RESET",
  };
};

const resetPasswordRequest = (oldPassword, newPassword) => {
  return {
    type: "RESET_PASSWORD_REQUEST",
    payload: { oldPassword: oldPassword, newPassword: newPassword }
  };
};

const resetPasswordSuccess = payload => {
  return {
    type: "RESET_PASSWORD_SUCCESS",
    payload: payload
  };
};

const resetPasswordError = error => {
  return {
    type: "RESET_PASSWORD_ERROR",
    payload: { error: error }
  };
};

const clearAuthStore = () => {
  return {
    type: "CLEAR_AUTH_STORE"
  }
}

const invalidateTokenAction = () => {
  return {
    type: "INVALIDATE_TOKEN"
  }
}

const closeInvalidateModalAction = () => {
  return {
    type: "CLOSE_INVALIDATE_MODAL"
  }
}

const navbarOption = payload => {
  return {
    type: "NAVBAR_OPTION",
    payload: payload
  }
}

const addUserRole = (role) => {
  return {
    type: "ADD_USER_ROLE",
    payload: role
  }
}

const forgotPasswordRequest = (email) => {
  return {
    type: "FORGOT_PASSWORD_REQUEST",
    payload: { email }
  };
};

const forgotPasswordSuccess = payload => {
  return {
    type: "FORGOT_PASSWORD_SUCCESS",
    payload: payload
  };
};

const forgotPasswordError = error => {
  return {
    type: "FORGOT_PASSWORD_ERROR",
    payload: error
  };
};

const setNewPasswordRequest = (emailId, resetToken, password) => {
  return {
    type: "SET_NEW_PASSWORD_REQUEST",
    payload: { emailId, resetToken, password }
  };
};

const setNewPasswordSuccess = payload => {
  return {
    type: "SET_NEW_PASSWORD_SUCCESS",
    payload: payload
  };
};

const setNewPasswordError = error => {
  return {
    type: "SET_NEW_PASSWORD_ERROR",
    payload: error
  };
};

const updateUserInfoRequest = () => {
  return {
    type: "UPDATE_USER_INFO_REQUEST"
  };
};

const updateUserInfoSuccess = payload => {
  return {
    type: "UPDATE_USER_INFO_SUCCESS",
    payload: payload
  };
};

const updateUserInfoError = error => {
  return {
    type: "UPDATE_USER_INFO_ERROR",
    payload: error
  };
};

const autoLoginRequest = (token) => {
  return {
    type: "AUTO_LOGIN_REQUEST",
    payload: token
  }
}

const autoLoginSuccess = payload => {
  return {
    type: "AUTO_LOGIN_SUCCESS",
    payload: payload
  }
}


const autoLoginError = (error) => {
  return {
    type: "AUTO_LOGIN_ERROR",
    payload: error
  }
}

export const cancelResetPassword = () => {
  return function (dispatch) {
    dispatch(cancelPasswordReset());
  }
}

export const setUserRole = (role) => {
  localStorage.setItem("userRole", role)
  return function (dispatch) {
    dispatch(addUserRole(role))
  }
}

export const initiateResetPassword = () => {
  return function (dispatch) {
    dispatch(initiatePasswordReset());
  }
}

export const resetPassword = (oldPassword, newPassword, authToken) => {
  return function (dispatch) {
    dispatch(resetPasswordRequest(oldPassword, newPassword));
    reset(oldPassword, newPassword, authToken)
      .then(responseData => {
        localStorage.setItem("token", responseData.token);
        dispatch(resetPasswordSuccess(responseData));
        toast.info(getFormattedMessage("toast-password-reset-successful"));
      })
      .catch(error => {
        dispatch(resetPasswordError(error));
      });
  }
}

export const logoutUser = () => {
  return function (dispatch) {
    localStorage.clear()
    dispatch(clearOrderStore())
    dispatch(clearAuthStore())
    dispatch(clearLogStore())
    dispatch(clearAdminInfoStore())
  }
}

export const loginUser = (email, password) => {
  return function (dispatch) {
    dispatch(loginUserRequest(email, password));
    login(email, password)
      .then(responseData => {
        saveUserInfoInLocalStorage(email, responseData.token, responseData.role)
        localStorage.setItem("superUser", responseData.superUser);
        localStorage.removeItem("autoLoginToken")
        dispatch(loginUserSuccess(responseData));
      })
      .catch(error => {
        dispatch(loginUserError(error));
      });
  };
};

export const invalidateToken = () => {
  return function (dispatch) {
    dispatch(invalidateTokenAction())
  }
}

export const closeInvalidateModal = () => {
  return function (dispatch) {
    dispatch(closeInvalidateModalAction())
  }
}

export const changeNavbarOption = option => {
  return function (dispatch) {
    dispatch(navbarOption(option))
  }
}

export const forgotPasswordAction = (email) => {
  return function (dispatch) {
    dispatch(forgotPasswordRequest(email));
    forgotPassword(email)
      .then(responseData => {
        dispatch(forgotPasswordSuccess(responseData));
      })
      .catch(error => {
        dispatch(forgotPasswordError(error));
      });
  }
}

export const setNewPasswordAction = (emailId, resetToken, password) => {
  return function (dispatch) {
    dispatch(setNewPasswordRequest(emailId, resetToken, password));
    setNewPassword(emailId, resetToken, password)
      .then(responseData => {
        saveUserInfoInLocalStorage(emailId, responseData.token, responseData.role)
        dispatch(setNewPasswordSuccess(responseData));
      })
      .catch(error => {
        dispatch(setNewPasswordError(error));
      });
  }
}

export const autoLoginUser = (authToken) => {
  return function (dispatch) {
    localStorage.setItem("autoLoginToken", authToken)
    dispatch(autoLoginRequest(authToken))
    fetchUserInfo(authToken)
      .then(responseData => {
        localStorage.removeItem("autoLoginToken")
        saveUserInfoInLocalStorage(responseData.emailId, authToken, responseData.role)
        var payload = { ...responseData, "token": authToken }
        dispatch(autoLoginSuccess(payload))
      })
      .catch(error => {
        dispatch(autoLoginError(error))
      })
  }
}

export const fetchUserInfoAction = (authToken) => {
  return function (dispatch) {
    dispatch(updateUserInfoRequest());
    fetchUserInfo(authToken)
      .then(responseData => {
        localStorage.setItem("userRole", responseData.role);
        localStorage.setItem("userPartners", JSON.stringify(responseData.partners))
        dispatch(updateUserInfoSuccess(responseData));
      })
      .catch(error => {
        dispatch(updateUserInfoError(error));
      });
  }
}

export const clearForgotPasswordState = () => {
  return function (dispatch) {
    dispatch({ type: "CLEAR_FORGOT_PASSWORD_STATE" })
  }
}

const saveUserInfoInLocalStorage = (email, token, role) => {
  localStorage.setItem("token", token);
  localStorage.setItem("email", email);
  localStorage.setItem("userRole", role);
}