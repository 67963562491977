import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import DetailsReducer from "./detailsReducer";
import LogsReducer from './logsReducer'
import UserManagementReducer from './userManagementReducer'
import bulkMigrationReducer from "./bulkMigrationReducer";
import redisCachedMailboxReducer from "./redisCachedMailboxReducer";

export const rootReducer = combineReducers({
  auth: AuthReducer,
  orders: DetailsReducer,
  logs: LogsReducer,
  users: UserManagementReducer,
  bulkMigration: bulkMigrationReducer,
  redisCachedMailbox: redisCachedMailboxReducer,
});

export type RootState = ReturnType<typeof rootReducer>
