import React from 'react'
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import { RootState } from '../reducers';
import { connect } from 'react-redux'
import { CustomerSuspensionParams, CustomerOrder } from "../model/customerSuspensionDetails"
import { ReactComponent as Green } from "../assets/oval_green.svg"
import { ReactComponent as Red } from "../assets/oval_red.svg"
import { capitalize, isStatusActive, getPaidAccountsForCustomerOrders, getActiveAccountsForCustomerOrders, getPlanType } from "./utils"
import { FormattedMessage } from 'react-intl';

interface CustomerDetailsProps {
    customerSuspensionOrderInfo: CustomerSuspensionParams
}

interface ParentProps {
    closeModal: () => void
    handleProceedClick: () => void
}

type Props = CustomerDetailsProps & ParentProps

class SuspendCustomerConfirmation extends React.Component<Props> {
    constructor(props: Props) {
        super(props)
        this.getNumberOfOrders = this.getNumberOfOrders.bind(this)
        this.getOrders = this.getOrders.bind(this)
    }

    getOrders = () => {
        return this.props.customerSuspensionOrderInfo.orderInfo.orders;
    }

    getNumberOfOrders = () => {
        return this.getOrders().length
    }

    getStatus = (status: string) => {
        let icon;
        if (isStatusActive(status)) {
            icon = <Green className="state-logo" />
        }
        else {
            icon = <Red className="state-logo" />
        }
        return <div> {icon} {capitalize(status)}</div>
    }

    compareCustomerOrder = (o1: CustomerOrder, o2: CustomerOrder) => {
        const orderStatusPriority = ["active", "suspended", "deleted", "pending"]
        const orderPlanPriority = ["premium", "premium_trial", "pro", "pro_trial", "free"]
        const orderStatusDifference = orderStatusPriority.indexOf(o1.status) - orderStatusPriority.indexOf(o2.status)
        if (orderStatusDifference === 0) {
            if ((o1.isPaid && !o2.isPaid) || (!o1.isPaid && o2.isPaid)) {
                return o1.isPaid ? -1 : 1
            }
            else {
                const orderPlanTypeDifference = orderPlanPriority.indexOf(o1.planType) - orderPlanPriority.indexOf(o2.planType)
                if (orderPlanTypeDifference !== 0) {
                    return orderPlanTypeDifference;
                }
                else {
                    return o2.noOfAccountsCreated - o1.noOfAccountsCreated
                }
            }
        }
        else return orderStatusDifference;
    }

    render() {
        const customerOrderComponents = this.getOrders().sort(this.compareCustomerOrder).map(order => {
            return (
                <tr>
                    <td className="customer-order-table-domain">
                        <span className="customer-order-domain">{order.domainName}:</span>{order.source}
                    </td>
                    <td className="customer-order-table-plan customer-order-table-center-text">
                        {getPlanType(order.planType)}
                    </td>
                    <td className="customer-order-table-account customer-order-table-center-text">
                        {order.noOfAccountsCreated}/{order.maxNoOfAccounts}
                    </td>
                    <td className="customer-order-table-status">
                        {this.getStatus(order.status)}
                    </td>
                </tr>
            )
        })
        return (<div>
            <CloseGrey className="alert-modal-close-button" onClick={() => this.props.closeModal()} />
            <div className="customer-suspension-container">
                <div className="suspension-heading">
                    <FormattedMessage id="suspend-customer" />
                </div>
                <div className="suspension-subheading">{this.props.customerSuspensionOrderInfo.orderInfo.email}</div>
                <div className="customer-suspension-order-summary">
                    <div className="customer-suspension-order-summary-heading">
                        <FormattedMessage id="orders-header" />
                    </div>
                    <div className="customer-suspension-order-summary-value">
                        {this.getNumberOfOrders()}
                    </div>
                    <div className="customer-suspension-order-summary-heading">
                        <FormattedMessage id="active-accounts-header" />
                    </div>
                    <div className="customer-suspension-order-summary-value">
                        {getActiveAccountsForCustomerOrders(this.getOrders())}
                    </div>
                    <div className="customer-suspension-order-summary-heading">
                        <FormattedMessage id="paid-accounts-header" />
                    </div>
                    <div className="customer-suspension-order-summary-value">
                        {getPaidAccountsForCustomerOrders(this.getOrders())}
                    </div>
                </div>
                <div className="customer-suspension-order-table">
                    <table>
                        <th className="customer-order-table-domain">
                            <FormattedMessage id="suspend-customer-domain-name-header" />
                        </th>
                        <th className="customer-order-table-plan customer-order-table-center-text">
                            <FormattedMessage id="suspend-customer-plan-header" />
                        </th>
                        <th className="customer-order-table-account customer-order-table-center-text">
                            <FormattedMessage id="suspend-customer-accounts-header" />
                        </th>
                        <th className="customer-order-table-status">
                            <FormattedMessage id="suspend-customer-status-header" />
                        </th>
                        {customerOrderComponents}
                    </table>
                </div>
                <div className="customer-suspension-button-container">
                    <button
                        className="button button-primary"
                        onClick={this.props.handleProceedClick}>
                        <FormattedMessage id="suspend-customer-confirmation-button-text" />
                    </button>
                    <button
                        className="button suspension-button modal-cancel-button"
                        onClick={this.props.closeModal}>
                        <FormattedMessage id="cancel" />
                    </button>
                </div>
            </div>
        </div>);
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        customerSuspensionOrderInfo: (state.orders as any).customerSuspensionOrderInfo
    }
}

export default connect(mapStateToProps)(SuspendCustomerConfirmation);