import React, { ReactElement } from 'react';
import { ReactComponent as CloseGrey } from "../assets/close-grey.svg"
import { ReactComponent as Success } from "../assets/suspend-success-tick.svg"

export interface Props {
    heading: string,
    description: string,
    positiveButtonText: string,
    negativeButtonText: string,
    shouldShowLoader: boolean | null,
    isLoadingInProgress: boolean | null,
    buttonLoadingText: string | null,
    shouldShowSuccess: boolean | null,
    buttonSuccessText: string | null,
    requestFailed: boolean | null,
    positiveButtonAction: () => any,
    negativeButtonAction: () => any,
    successAction: () => any | null,
    failureAction: () => any | null,
    closeAction: () => any
}

class ConfirmationModal extends React.Component<Props> {
    private timeoutId?: NodeJS.Timeout | null
    constructor(props: Props) {
        super(props)
        this.handlePositiveButtonClick = this.handlePositiveButtonClick.bind(this)
    }
    componentDidUpdate() {
        if (this.props.shouldShowSuccess) {
            this.timeoutId = setTimeout(() => this.props.successAction(), 1000)
        } else if (this.props.requestFailed) {
            this.props.failureAction()
        }
    }
    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }
    handlePositiveButtonClick() {
        if (this.props.shouldShowSuccess) {
            this.props.successAction()
        } else {
            this.props.positiveButtonAction()
        }
    }
    render() {
        let positiveButtonText: string | ReactElement = this.props.positiveButtonText;
        if (this.props.shouldShowLoader) {
            if (this.props.isLoadingInProgress) {
                positiveButtonText = <div className="modal-button-div"><div className="modal-spinner" />{this.props.buttonLoadingText}</div>
            }
            else if (this.props.shouldShowSuccess) {
                positiveButtonText = <div className="modal-button-div"><Success className="confirmation-modal-icon" />{this.props.buttonSuccessText}</div>
            }
        }
        return (
            <div>
                <CloseGrey className="confirmation-modal-close-button" onClick={this.props.closeAction} />
                <div>
                    <div className="confirmation-modal-heading">{this.props.heading}</div>
                    <div className="confirmation-modal-description">{this.props.description}</div>
                    <div className="confirmation-modal-button-div">
                        <button
                            className="button button-primary confirmation-modal-button-positive"
                            onClick={this.handlePositiveButtonClick}>
                            {positiveButtonText}
                        </button>
                        <button
                            className="button button-primary confirmation-modal-button-negative"
                            onClick={this.props.negativeButtonAction}>
                            {this.props.negativeButtonText}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
export default ConfirmationModal