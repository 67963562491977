import * as React from 'react';
import { ReactComponent as Copy } from "../assets/copy.svg"
import { ReactComponent as CopyActive } from "../assets/copy-active.svg"
import { FormattedMessage } from "react-intl";

interface CopyButtonProps {
  onClick: () => any
}

interface CopyButtonState {
  showCopiedText: boolean,
  showCopyActiveState: boolean
}

export class CopyButton extends React.Component<CopyButtonProps> {

  state: CopyButtonState

  constructor(props: CopyButtonProps) {
    super(props)
    this.state = {
      showCopiedText: false,
      showCopyActiveState: false
    }
  }

  copyClicked = () => {
    this.props.onClick()
    this.setState({
      showCopiedText: true,
      showCopyActiveState: true
    })
    setTimeout(
      () => this.setState({ showCopiedText: false }),
      1500
    );
    setTimeout(
      () => this.setState({ showCopyActiveState: false }),
      100
    );
  }

  render() {
    return (
      <div className="copy-div">
        <span className={this.state.showCopiedText ? "copied-text-visible copied-text" :
          "copied-text-hidden copied-text"}>
          <FormattedMessage id='copied'/>
        </span>
        {!this.state.showCopyActiveState ? <Copy onClick={this.copyClicked} /> : <CopyActive />}
      </div>
    );
  }
}